import gql from "graphql-tag";

import recipe_fields from "@js/api/graphql/recipe_fields";
import conversion_fields from "@js/api/graphql/conversion_fields";

export default gql`
query KitchenRecipes(
  $macro_targets: MacroTargets
  $search_term: String
  $tag_filters: [TagFilter]
  $strict_match: Boolean
  $user_id: Int!
) {
  kitchen_recipes(
    macro_targets: $macro_targets
    search_term: $search_term
    tag_filters: $tag_filters
    strict_match: $strict_match
    user_id: $user_id
  ) {
    ${recipe_fields}
    is_favorite
    tags {
      id
      name
    }
    is_bad_match
    conversion {
      ${conversion_fields}
    }
    conversions {
      ${conversion_fields}
    }
    foods {
      name
      quantity
      conversion {
        ${conversion_fields}
      }
      conversions {
        ${conversion_fields}
      }
    }
    child_recipes {
      ${recipe_fields}
      conversion {
        ${conversion_fields}
      }
      conversions {
        ${conversion_fields}
      }
      foods {
        name
        quantity
        conversion {
          ${conversion_fields}
        }
      }
    }
  }
}
`;
