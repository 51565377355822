<template>
  <div id="progress-photos-comparison">
    <header class="header mobile-header">
      <a href="/inputs" class="close-x icon-close-icon-x"></a>
      <h1 class="header-text">
        <span v-if="comparison">Compare Photos</span>
        <span v-else>Weekly Photos</span>
      </h1>
    </header>
    <div class="heading-holder input-title">
      <h1 class="tertiary-text-xtra-large">
        <span v-if="comparison">Compare Photos</span>
        <span v-else>Weekly Photos</span>
      </h1>
    </div>

    <div class="content">
      <ProgressPhotosWeek
        side="left"
        :isComparison="comparison"
        :week="firstWeek"
        :year="firstYear"
        :class="{'display-none': !comparison}"
      />
      <ProgressPhotosWeek
        side="right"
        :isComparison="comparison"
        :week="currentWeek"
        :year="currentYear"
        ref="right"
      />
    </div>

    <div class="button-wrap relative">
      <button class="button-primary accent-button compare-button" @click="toggleComparison()">
        <span v-if="comparison">Weekly View</span>
        <span v-else>Compare</span>
      </button>
    </div>
  </div>
</template>
<script>

import ProgressPhotosWeek from "@js/profile/progress_photos_week.vue";

export default {
  components: { ProgressPhotosWeek },
  props: {
    isComparison: { type: Boolean, required: true },
    // REVIEW: are these props required? if so, flag them as such
    firstWeek: { type: String },
    firstYear: { type: String },
    currentWeek: { type: String },
    currentYear: { type: String }
  },
  data() {
    return {
      comparison: this.isComparison
    };
  },
  created() {
    // hide annoying white header
    $("#header").css({backgroundColor: "rgba(255, 255, 255, 0)"});
  },
  methods: {
    toggleComparison() {
      window.showSpinner();
      this.comparison = !this.comparison;
      $("#app")[0].scrollTo(0, 0);

      setTimeout(() => {
        this.$refs.right.resizePhotos();
        window.hideSpinner();
      }, 100);
    }
  }
};

</script>
<style scoped lang="scss">

@import "@css/vendors/_include-media";

@import "@css/base/_functions.scss";
@import "@css/base/_mixins.scss";
@import "@css/base/_helpers.scss";

.heading-holder.input-title {
  @include media("<=tablet") {
    display: none;
  }
}
.tertiary-text-xtra-large {
  text-align: center;
  line-height: 28px;
}
.display-none {
  display: none;
}
.content {
  display: flex;
}
.button-wrap {
  background-color: #f3f3f3;
  min-height: 72px;
  height: 72px;
  max-height: 72px;
  .compare-button {
    width: 50%;
    font-size: 15px;
  }
}

</style>
