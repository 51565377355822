<template lang="html">
  <div :class="{ 'loading-screen': true, 'iphonex': iphonex }">
    <div class="loading-screen-content">
      <img :src="icons[icon]" />
      <p v-if="title" class="title">{{ title }}</p>
      <p v-if="description" class="description">{{ description }}</p>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    icon: {type: String, default: "carrot_search"},
    title: {type: String, default: "Please Wait"},
    description: {type: String, default: "Macrostax is loading..."}
  },
  data() {
    return {
      icons: {
        carrot_calculator: "https://d38il9bndsnkm4.cloudfront.net/assets/images/carrot-calculator.png",
        carrot_search: "https://d38il9bndsnkm4.cloudfront.net/assets/images/carrot-search.png"
      }
    };
  },
  computed: {
    iphonex() {
      return window.iphonex();
    }
  }
};
</script>

<style scoped lang="scss">
  .loading-screen {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding: 24px;
    background-color: #ffffff;
    z-index: 50;
    .loading-screen-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%;
      max-width: 250px;
      img {
        height: 200px;
        margin-bottom: 12px;
      }
      .title {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        color: #4f4f4f;
        text-align: center;
        margin: 0px 0px 6px 0px;
      }
      .description {
        font-size: 12px;
        font-weight: 600;
        color: #4f4f4f;
        text-align: center;
        margin: 0px 0px 8px 0px;
      }
      .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: $macrostax_blue;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(24px, 0);
        }
      }
    }
  }
</style>
