<template>

  <div class="week-container">

    <div class="week-selector-container">
      <div class="week-selector-right">
        <img class="prev" :src="activeCarouselArrorIcon" @click="getPhotos(previousYear, previousWeek)">
        <h2 class="tertiary-text-large">{{displayDate}}</h2>
        <img class="next" :src="activeCarouselArrorIcon" @click="getPhotos(nextYear, nextWeek)">
      </div>
    </div>

    <div v-bind:class="{'comparison-display': comparison, 'comparison-pr': (comparison && side === 'left'), 'comparison-pl': (comparison && side === 'right')}" class="content">
      <div v-for="row in rows" v-bind:key="row.type" v-bind:class="{'comparison-brr': (comparison && side === 'left'), 'comparison-brl': (comparison && side === 'right')}" class="photo-row">
        <p>{{row.type}}</p>
        <div class="photo" v-bind:class="{'comparison-mr': (comparison && side === 'left'), 'comparison-ml': (comparison && side === 'right')}">
          <img v-if="row && row.photo" :src="row.photo.url" :ref="`${row.type}Photo`" @click="editPhoto(`${row.type}Photo`, `${row.type}Form`, `progress_photo[${row.type}_photo]`, row.photo)">
          <div v-else class="placeholder" @click="addPhoto(`${row.type}Form`, `progress_photo[${row.type}_photo]`)">
            <div class="inner-container">
              <div class="icon-add-photo-icon"></div>
              <p>Add a Photo</p>
            </div>
          </div>
          <form :ref="`${row.type}Form`" enctype="multipart/form-data" novalidate action="/inputs/photos" method="post">
            <input :id="`progress_photo_${row.type}_photo`" type="file" class="progress-photo-input" :ref="`progress_photo[${row.type}_photo]`" @change="filesChange($event.target.files[0])" :accept="acceptableImageTypes" />
            <input id="progress_photo_year" :value="currentYear" class="hidden" type="hidden" name="progress_photo[year]" />
            <input id="progress_photo_week" :value="currentWeek" class="hidden" type="hidden" name="progress_photo[week]" />
            <input v-if="row.photo" id="progress_photo_id" :value="row.photo.id" class="hidden" type="hidden" name="progress_photo[id]" />
            <input type="hidden" :value="csrf_token" name="authenticity_token" />
          </form>
        </div>
      </div>
    </div>

    <ProgressPhotoModal
      ref="progressPhotoModal"
      :previewImageProp="previewImage"
      :currentFormProp="currentForm"
      :currentFileProp="currentFile"
      :currentInputProp="currentInput"
      :currentPhotoProp="currentPhoto"
      @replacePhoto="replacePhoto"
      @savePhoto="savePhoto"
      @deletePhoto="deletePhoto"
    ></ProgressPhotoModal>

  </div>

</template>
<script>

import ProgressPhotoModal from "@js/profile/progress_photo_modal.vue";
import activeCarouselArrorIcon from "@images/active-carousel-arrow.svg";

export default {
  components: { ProgressPhotoModal },
  props: {
    side: { type: String, required: true },
    isComparison: { type: Boolean, required: true },
    // REVIEW: are these props required? if so, flag them as such
    week: { type: String },
    year: { type: String }
  },
  data() {
    return {
      displayDate: "This Week",
      previousYear: null,
      previousWeek: null,
      currentYear: this.year,
      currentWeek: this.week,
      nextYear: null,
      nextWeek: null,
      rows: [
        {
          type: "front",
          photo: {}
        },
        {
          type: "back",
          photo: {}
        },
        {
          type: "side",
          photo: {}
        }
      ],
      previewImage: null,
      currentForm: null,
      currentFile: null,
      currentInput: null,
      currentPhoto: null,
      cropperEnabled: false,
      acceptableImageTypes: "image/jpeg,image/png",
      activeCarouselArrorIcon
    };
  },
  computed: {
    comparison() {
      return this.isComparison;
    },
    csrf_token() {
      return document.querySelector("meta[name='csrf-token']").content;
    }
  },
  mounted() {
    this.getPhotos(this.currentYear, this.currentWeek);

    document.addEventListener('orientationchange', (ev) => {
      setTimeout(() => {
        this.resizePhotos();
      }, 300);
    });
  },
  methods: {
    getPhotos(year, week) {
      window.showSpinner();

      $.ajax({
        url: `/inputs/photos/by_date?year=${year}&week=${week}`,
        data: {},
        dataType: "json",
        type: "GET",
        success: function(data) {
          window.hideSpinner();

          this.rows.forEach(row => {
            row.photo = data.imgs.find(img => img.type === row.type);
          });

          this.displayDate = data.displayDate;
          this.previousYear = data.prev.year;
          this.previousWeek = data.prev.week;
          this.currentYear = data.current.year;
          this.currentWeek = data.current.week;
          this.nextYear = data.next.year;
          this.nextWeek = data.next.week;

          setTimeout(() => {
            this.resizePhotos();
          }, 500);
        }.bind(this),
        error: function() {
          window.hideSpinner();
        }
      });
    },
    resizePhotos() {
      $(".photo img").each(function() {
        $(this).css("height", $(this).width()/0.75);
      });

      $(".photo .placeholder").each(function() {
        $(this).css("height", $(this).width()/0.75);
        $(this).css("padding-top", 0);
        $($(this).children(".inner-container")[0]).css("margin-top", 0);
      });
    },
    editPhoto(photoRef, form, input, photoData) {
      this.currentForm = this.$refs[form][0];
      this.currentInput = input;
      this.previewImage = this.$refs[photoRef][0].src;
      this.currentFile = null;
      this.currentPhoto = photoData;
      this.$refs.progressPhotoModal.openPhotoPreview(false);
    },
    addPhoto(form, input) {
      this.currentForm = this.$refs[form][0];
      this.currentInput = input;
      $(this.$refs[this.currentInput][0]).click();
    },
    replacePhoto() {
      $(this.$refs[this.currentInput][0]).click();
    },
    filesChange(file) {
      let reader = new FileReader();
      this.currentFile = file;
      this.currentPhoto = null;

      reader.onload = function(event) {
        this.$refs.progressPhotoModal.openPhotoPreview(event.target.result);
        this.$refs[this.currentInput][0].value = null;
      }.bind(this);

      reader.readAsDataURL(file);
    },
    savePhoto(dataUrl) {
      window.showSpinner();

      let formData = new FormData(this.currentForm);
      let fileName = "";
      if (this.currentFile) {
        fileName = this.currentFile.name;
      } else if (this.currentPhoto) {
        fileName = this.currentPhoto.file_name;
      }

      let newFile = this.dataURLtoFile(dataUrl, fileName);
      formData.append(this.currentInput, newFile);

      this.uploadPhoto(formData).then(() => {
        this.$refs.progressPhotoModal.closePhotoPreview();
        this.getPhotos(this.currentYear, this.currentWeek);
      }).finally(() => {
        window.hideSpinner();
      });
    },
    uploadPhoto(formData) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: "/inputs/photos",
          data: formData,
          dataType: "json",
          contentType: false,
          processData: false,
          type: "POST",
          success: function(data) {
            resolve();
          },
          error: function(err) {
            reject(err);
          }
        });
      });
    },
    deletePhoto() {
      let formData = new FormData(this.currentForm);
      window.showSpinner();

      $.ajax({
        url: "/inputs/photos",
        data: formData,
        dataType: "json",
        contentType: false,
        processData: false,
        type: "DELETE",
        success: function(data) {
          this.$refs.progressPhotoModal.closePhotoPreview();
          this.getPhotos(this.currentYear, this.currentWeek);
          window.hideSpinner();
        }.bind(this),
        error: function() {
          window.hideSpinner();
        }
      });
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    }
  }
};

</script>
<style scoped lang="scss">

@import "@css/vendors/_include-media";
@import "@css/base/_functions.scss";
@import "@css/base/_mixins.scss";
@import "@css/base/_helpers.scss";

.week-container {
  width: 100%;
}
.week-selector-container {
  width: 100%;
  height: 50px;
  display: flex;
  border-bottom: 1px solid #e2e2e2;
  @include media(">tablet") {
    border-top: 1px solid #e2e2e2;
  }
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 30px;
      min-width: 30px;
      height: 100%;
      padding: 8px;
      cursor: pointer !important;
    }
    .next {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    h2 {
      margin: 0px;
      font-size: 19px;
      text-align: center;
      line-height: 20px;
    }
  }
  .week-selector-left {
    border-right: 1px solid #e2e2e2;
  }
}
form {
  input {
    display: none;
  }
}

// comparison view css tweaks
.comparison-display {
  display: block !important;
}
.comparison-pr {
  padding-right: 0px !important;
}
.comparison-pl {
  padding-left: 0px !important;
}
.comparison-brr {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.comparison-brl {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.comparison-mr {
  margin-right: 2px;
  @include media(">tablet") {
    margin-right: 5px;
  }
}
.comparison-ml {
  margin-left: 2px;
  @include media(">tablet") {
    margin-left: 5px;
  }
}
////////////////////////

.content {
  width: 100%;
  height: auto;
  padding: 8px;
  background-color: #f3f3f3;
  @include media(">tablet") {
    display: flex;
    padding: 15px;
  }
  .photo-row {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: white;
    overflow: hidden;
    @include media(">tablet") {
      margin-right: 15px;
    }
    p {
      width: 100%;
      height: 33px;
      color: black;
      font-size: 15px;
      font-weight: 600;
      text-align: center;
      margin: 0px;
      padding: 5px;
      text-transform: capitalize;
      @include media(">tablet") {
        margin-right: 5px;
        padding: 10px;
        height: 43px;
      }
    }
    .photo {
      width: 100%;
      display: block;
      img, .placeholder {
        width: 100%;
        max-width: 100%;
        border: none !important;
        object-fit: cover;
      }
      .placeholder {
        height: 100%;
        min-height: 200px;
        padding-top: calc(4/3 * 100%);
        background-color: #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .inner-container {
          margin-top: calc(4/3 * 100% * -1);
          .icon-add-photo-icon {
            text-align: center;
            font-size: 30px;
            margin-bottom: 5px;
            color: $macrostax_blue;
          }
          p {
            height: 40px;
            line-height: 40px;
            font-weight: 400;
            color: $macrostax_blue;
          }
        }
      }
    }
  }
  .photo-row:last-child {
    margin-right: 0px;
  }
}

</style>
