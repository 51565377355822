<template>
  <div id="faq" class="cut-tab faq active form-pane">
    <ul class="accordion">
      <input class="faq-search" type="text" placeholder="Search FAQ..." v-model="searchTerm" @input="search" />
      <p class="page-header">Browse by Topic</p>
      <div v-for="category in filteredResults" :key="category.name" class="category">
        <div class="category-header" :class="{'active': category.expanded}" ref="category" @click="categoryClicked($event, category)"><p>{{category.name}}</p></div>
        <ul v-if="category.expanded" class="category-content accordion">
          <li v-for="item in category.content" :key="item.fields.question" class="accordion-item feature-link" ref="item" @click="itemClicked($event, item)">
            <a class="opener feature-link" href="#">
              {{item.fields.question}}
              <i class="icon-arrow-bottom feature-link"></i>
            </a>
            <div class="slide">
              <div class="box-holder">
                <div class="box">
                  <div v-html="item.fields.answer" class="faq-details"></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </ul>
  </div>
</template>

<script>
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

// Clubhouse story ID: 733
export default {
  data() {
    return {
      categories: [],
      selectedCategoryIndex: null,
      searchTerm: null,
      filteredResults: []
    };
  },
  mounted() {
    this.load();

    let intercom_updated = false;
    $(document).on("click", ".opener", function(evt) {
      if (!intercom_updated) {
        intercom_updated = true;

        $.ajax({
          url: "/intercom_attributes",
          type: "post",
          contentType: "application/json",
          data: JSON.stringify({ has_visited_faq: true })
        });
      }
    });

    let topic = window.location.search.split("=")[1];
    const topic_list_item = $("#" + topic);
    if (topic_list_item.length > 0) {
      topic_list_item.addClass("active");
      $("html, body").scrollTop(topic_list_item.offset().top);
    }
  },
  computed: {
    selectedCategory() {
      return this.selectedCategoryIndex;
    }
  },
  methods: {
    load() {
      Promise.all([
        this.$contentful.getEntries({ content_type: "faq" }),
        this.$contentful.getEntries({ content_type: "faqCategory" })
      ])
      .then(([faq, faqCategory]) => {
        const faqItems = this.convertToHtml(faq.items);
        const faqCategories = this.sortByOrder(faqCategory.items);

        this.categories = faqCategories.map((category) => {
          return {
            name: category.fields.name,
            order: category.fields.order,
            content: this.sortByOrder(faqItems.filter(item => item.fields.category === category.fields.name)),
            expanded: false
          };
        });

        this.filteredResults = this.categories;
      });
    },
    convertToHtml(content) {
      let renderOptions = {
        renderNode: {
          "embedded-asset-block": function(node) {
            if (node.data.target.fields.file.contentType === "image/png") {
              return "<img class='image' src='" + node.data.target.fields.file.url + "'/>";
            } else {
              return "<iframe class='video' src='" + node.data.target.fields.description + "' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
            }
          }
        }
      };

      content.forEach(c => c.fields.answer = documentToHtmlString(c.fields.answer, renderOptions));
      return content;
    },
    sortByOrder(array, areCategories) {
      // sort categories by their order number
      return array.sort((a, b) => {
        let aHas = a.fields.hasOwnProperty("order");
        let bHas = b.fields.hasOwnProperty("order");
        if (aHas && bHas) {
          return a.fields.order - b.fields.order;
        }
        return aHas ? -1 : bHas ? 1 : 0;
      });
    },
    search() {
      if (this.searchTerm) {
        let currentCategories = JSON.parse(JSON.stringify(this.categories));

        let filteredResults = currentCategories.filter(category => {
          if (category.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
            return true;
          } else {
            category.content = category.content.filter(c => c.fields.question.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1);
            return category.content.length > 0;
          }
        });
        filteredResults.map(cat => cat.expanded = true);
        this.filteredResults = filteredResults;
      } else {
        this.filteredResults = this.categories;
        this.filteredResults.map(cat => cat.expanded = false);
      }
    },
    categoryClicked(event, category) {
      // collapse all other categories
      this.categories = this.categories.map(cat => {
        if (category.name === cat.name) {
          cat.expanded = !cat.expanded;
        } else {
          cat.expanded = false;
        }
        return cat;
      });

      // scroll to top of selected category
      setTimeout(() => {
        let el = this.$refs.category.find(cat => cat.className === "category-header active");
        if (el) {
          document.getElementById("app").scrollTop = el.offsetTop - 15;
        }
      }, 100);
    },
    itemClicked(event, item) {
      // scroll to top of selected item
      setTimeout(() => {
        let el = this.$refs.item.find(item => item.className === "accordion-item feature-link active");
        if (el) {
          document.getElementById("app").scrollTop = el.offsetTop - 15;
        }
      }, 100);
    }
  }
};

</script>

<style scoped lang="scss">
.faq-search {
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 20px;
}
.faq-search:focus {
  border: 1px solid #34B6E4;
  outline: none !important;
}
.page-header {
  font-size: 20px;
  font-weight: 500;
  margin: 0px 0px 20px 15px;
}
.category-header {
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  p {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    color: #34B6E4;
  }
}
.category-header.active {
  border: 1px solid #34B6E4;
  background-color: #34B6E4;
  p {
    color: white;
  }
}
.category-content {
  padding: 0px 10px;
}

</style>
