<template>
  <div id="measurements" class="dashboard-section">
    <header>
      <span class="title">{{
        last_measurement ? "Measurements" : "Weekly Measurements"
      }}</span>
      <img :src="measuring_tape_icon" />
    </header>

    <p v-if="!last_measurement" class="explanation">
      Add two or more measurements to track your own progress with graphs
    </p>

    <div class="content">
      <div v-if="last_measurement" class="last-measurement">
        <p class="primary-gray-text-medium">{{ last_measurement_header }}</p>

        <div class="measurements">
          <div class="stat-row">
            <div class="stat-label">Chest:</div>
            <div class="stat-value">{{ last_measurement.chest }} in</div>
          </div>

          <div class="stat-row">
            <div class="stat-label">Waist:</div>
            <div class="stat-value">{{ last_measurement.waist }} in</div>
          </div>

          <div class="stat-row">
            <div class="stat-label">Hips:</div>
            <div class="stat-value">{{ last_measurement.hips }} in</div>
          </div>
        </div>
      </div>

      <div class="graph">
        <Chart
          v-if="measurement_chart"
          :options="measurement_chart"
          class="highcharts-container "
        />
        <img v-else :src="chart_placeholder" />
      </div>
    </div>

    <footer>
      <a href="/inputs/measurements" class="button">
        <span>Add Measurements</span>
      </a>
    </footer>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

import measuring_tape_icon from "@images/measuring-tape.svg";
import chart_placeholder from "@images/weekly-measurements-chart-placeholder.png";

export default {
  components: { Chart },
  data() {
    return {
      measuring_tape_icon,
      chart_placeholder,
    };
  },
  computed: {
    measurements() {
      return this.$store.state.rails.measurements;
    },
    last_measurement() {
      // array is in descending order
      return this.measurements[0];
    },
    last_measurement_header() {
      return `Week of ${moment(this.last_measurement.measurement_date, "YYYY-MM-DD").startOf('isoWeek').format(
        "MMMM Do, YYYY"
      )}`;
    },
    measurement_chart() {
      if (this.measurements.length < 2) {
        return null;
      }
      const {
        series_data,
        options: { title, xAxis, yAxis, colors },
      } = this.$store.state.rails.dashboard.measurement_chart;

      // HighCharts API: https://api.highcharts.com/highcharts/
      return {
        title,
        xAxis,
        yAxis,
        colors,
        series: series_data,
        credits: false,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@css/components/inputs";
.explanation {
  width: 70%;
}

.last-measurement {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 90%;

  .measurements {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .stat-row {
      display: flex;
      align-items: baseline;
    }
  }
}
</style>
