import { mapValues, escapeRegExp } from "lodash";
import Fraction from "fraction.js";

export function filter_by_search_term(search_results, search_term, field = "name") {
  if (!search_term.trim()) {
    return search_results;
  }

  // TODO use a fuzzy algorithm to match name
  const pattern = new RegExp(escapeRegExp(normalize_search_term(search_term)), "i");

  return search_results.filter(result => pattern.test(normalize_search_term(result[field])));
}

function normalize_search_term(search_term) {
  return search_term.replace(/[\s-_"'\\]/g, "");
}

export function is_element_overflown(element) {
  return element && (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
}

export function multiply_macros(conversion, quantity) {
  return mapValues(conversion, (val) => quantity * val)
};

export function add_macros(macros_a, macros_b) {
  return mapValues(macros_a, (val, macro) => val + macros_b[macro]);
}

export function subtract_macros(macros_a, macros_b) {
  return mapValues(macros_a, (val, macro) => val - macros_b[macro]);
}

export function sum_macros(portions, to_nearest = 0.5) {
  const macros = portions.reduce(
    (total, portion) => add_macros(total, portion),
    { carbohydrates: 0, protein: 0, fat: 0, calories: 0 }
  );

  if (to_nearest) {
    return round_macros(macros, to_nearest, false);
  }
  return macros;
}

export function calculate_calories(rails_ingredient, forceRecalc = false) {
  if (rails_ingredient && rails_ingredient.calories && !forceRecalc) {
    return rails_ingredient.calories;
  } else {
    return (rails_ingredient.carbohydrates * 4) + (rails_ingredient.protein * 4) + (rails_ingredient.fat * 9);
  }
}

export function round_macros(macros, to_nearest = 1, set_floor = true) {
  const rounded_macros = mapValues(macros, (val, macro) => (
    macro === "calories" ? round(val) : round(val, to_nearest)
  ));

  if (set_floor) {
    return mapValues(rounded_macros, val => Math.max(0, val));
  }
  return rounded_macros;
}

// called for "gram" quantities
export function round(quantity, to_nearest = 1) {
  const multiplier = 1.0 / to_nearest;
  return Math.round(quantity * multiplier) / multiplier;
}

// called for "common" quantities
export function to_fraction(quantity) {
  // default error threshold for simplify() is 0.001
  // boolean arg is 'extract_whole', i.e. display "1 1/3" instead of "4/3"
  return new Fraction(quantity).simplify().toFraction(true);
}

export function is_kitchen_measurable(quantity) {
  const acceptable_denominators = [1, 2, 3, 4, 8];
  const denominator = (new Fraction(quantity).simplify()).d;
  return acceptable_denominators.includes(denominator);
}

// https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
export function is_mobile_browser() {
  const user_agent = navigator.userAgent || navigator.vendor || window.opera;

  const user_agent_regex = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;

  const user_agent_prefix_regex = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;

  return user_agent_regex.test(user_agent) || user_agent_prefix_regex.test(user_agent.substr(0, 4));
}

// Really basic check for the iOS platform
// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export function is_ios_device() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

// iPhone X Detection
export function isIphoneX() {
  return is_ios_device();
};

export function testAsyncError(errorMsg = "async error test!", timeout = 1000) {
  return new Promise((_, reject) => (
    setTimeout(() => reject(new Error(errorMsg)), timeout)
  ));
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function setLocalStorage(key, val) {
  try {
    localStorage[key] = val;
  } catch (ex) {}
}

export function getLocalStorage(key) {
  try {
    return localStorage[key];
  } catch (ex) {}
}