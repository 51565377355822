<template>
  <div id="progress-overview">
    <div class="nav-bar">
      <router-link to="/profile">
        <i class="fa fa-chevron-left" />
      </router-link>
      <div class="title">Progress</div>
      <div class="flex-placeholder" />
    </div>
    <div class="inputs-wrapper">
      <MacroIntake />
      <Weight />
      <Measurements />
      <Photos />
    </div>
  </div>
</template>

<script>
import MacroIntake from "@js/profile/progress/macro_intake.vue";
import Weight from "@js/profile/progress/weight.vue";
import Measurements from "@js/profile/progress/measurements.vue";
import Photos from "@js/profile/progress/photos.vue";

export default {
  components: { MacroIntake, Weight, Measurements, Photos },
};
</script>

<style scoped lang="scss">
#progress-overview {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: $background-lightest-gray;
  display: flex;
  flex-direction: column;

  // Review: This Wrapper is responsoble for Scrolling the Input Elements
  .inputs-wrapper {
    height: 81.5vh;
    overflow-y: scroll;
  }

  .nav-bar {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    background-color: #00a881;
    position: relative;
    top: 0;
    width: 100%;
    max-width: 450px;
    z-index: 1;

    i.fa-chevron-left {
      margin-left: 24px;
      color: white;
      cursor: pointer;
    }

    .title {
      font-weight: bold;
      color: white;
    }

    .flex-placeholder {
      width: 40px;
    }
  }
}
</style>
