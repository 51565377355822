<template lang="html">
  <div class="meal-card-wrapper" :class="{ expanded }">
    <div
      class="meal-card"
      :class="{ empty, expanded, mtc_challenge, [`position-${swipe_position}`]: true }"
    >
      <div
        class="meal-summary"
        @click.stop="toggle_meal_card"
        v-touch:swipe.left="on_swipe"
        v-touch:swipe.right="on_swipe"
      >
        <img v-if="!expanded" :src="plus_purple_icon" class="open-icon" alt="Purple Plus" />

        <div class="meal-info">
          <div class="meal-type">
            <p>{{meal_type}}</p>
            <span v-if="peri_workout" class="badge">
              {{peri_workout}}
            </span>
          </div>

          <p v-if="!expanded" class="portion-names">{{ portion_names }}</p>

          <p v-if="!empty || expanded" class="meal-macros">
            <span class="macro-container">
              <span class="carbohydrates macro">C</span>
              <span class="carbohydrates value-actual">
                {{ macros.carbohydrates }}g
              </span>
              <span v-if="expanded" class="separator">/</span>
              <span v-if="expanded" class="carbohydrates value-target">
                {{ macro_targets.carbohydrates }}g
              </span>
            </span>

            <span class="macro-container">
              <span class="protein macro">P</span>
              <span class="protein value-actual">
                {{ macros.protein }}g
              </span>
              <span v-if="expanded" class="separator">/</span>
              <span v-if="expanded" class="protein value-target">
                {{ macro_targets.protein }}g
              </span>
            </span>

            <span class="macro-container">
              <span class="fat macro">F</span>
              <span class="fat value-actual">
                {{ macros.fat }}g
              </span>
              <span v-if="expanded" class="separator">/</span>
              <span v-if="expanded" class="fat value-target">
                {{ macro_targets.fat }}g
              </span>
            </span>

            <span class="macro-container">
              <span class="calories macro">Cals</span>
              <span class="calories value-actual">
                {{ macros.calories }}
              </span>
              <span v-if="expanded" class="separator">/</span>
              <span v-if="expanded" class="calories value-target">
                {{ macro_targets.calories }}
              </span>
            </span>
          </p>

          <CopyYesterdayButton v-if="expanded" :meal="meal" />
        </div>
      </div>

      <div v-if="expanded" class="meal-actions">
        <Search :meal_id="meal_id" :add_item="add_to_meal" :close_portion_edit='close_edit_view' />

        <div class="ingredient-sources">
          <router-link
            :to="{ path: '/fill_macros', query: { meal_id } }"
            class="kitchen-button meal-card-button"
          >
            <i class="fa fa-magic" />
            <p>Fill Macros</p>
          </router-link>
          <router-link
            :to="{ path: customPath, query: { meal_id } }"
            class="custom-food meal-card-button"
          >
            <img :src="chef_hat_icon" />
            <p>Custom</p>
          </router-link>
          <router-link
            :to="{ path: '/favorites', query: { meal_id } }"
            class="favorite-food meal-card-button"
          >
            <i class="fa fa-heart-o" />
            <p>Favorites</p>
          </router-link>
        </div>

        <div v-if="!empty" class="bulk-actions" :class="{ disabled: arePortionsPersisting }">
          <MiniSpinner v-if="arePortionsPersisting" color="pink" />
          <div class="action">
            <img :src="heart_dark_gray_outline_icon" @click.stop="bulk_toggle_favorite" />
          </div>
          <div class="action">
            <img :src="trash_can_gray_outline_icon" @click.stop="() => delete_portion()" />
          </div>
        </div>
      </div>

      <div v-if="expanded" class="portions">
        <!-- use index rather than portion.id as key so optimistic portion isn't replaced upon persistenc -->
        <Portion
          v-for="(portion, i) in meal.portions"
          :key="i"
          :portion="portion"
          :meal_id="meal_id"
          :editing="portion.id === edit_portion_id"
          :edit_portion="edit_portion"
          :toggle_favorite="toggle_favorite"
          :delete_portion="delete_portion"
          :view-recipe="viewRecipe"
        />
      </div>
    </div>

    <div class="swipe-button delete" @click.stop="() => delete_portion()">
      <img :src="trash_can_white_outline_icon" />
    </div>
  </div>
</template>

<script>
import { capitalize, mapValues, last } from "lodash";
import { map, uniq, size, flow } from "lodash/fp";
import { validate as is_uuid } from "uuid";

import API from "@js/api/rails_client";
import { sum_macros, round_macros, round } from "@js/lib/utils";
import {
  is_pre_workout,
  is_post_workout,
  portion_servings_to_quantity,
  serving_units,
  show_favorited_alert,
  show_unfavorited_alert,
  to_phx_macro_targets
} from "@js/lib/rails_compatibility_helpers";

import Search from "@js/shared/search/search.vue";
import Portion from "@js/food_log/portion.vue";
import CopyYesterdayButton from "@js/food_log/copy_yesterday_button.vue";
import MiniSpinner from "@js/shared/mini_spinner.vue";

import plus_purple_icon from "@images/plus-purple.svg";
import chef_hat_icon from "@images/chef-hat.svg";
import trash_can_gray_outline_icon from "@images/trash-can-gray-outline.svg";
import trash_can_white_outline_icon from "@images/trash-can-white-outline.svg";
import heart_dark_gray_outline_icon from "@images/heart-dark-gray-outline.svg";

export default {
  components: { Search, Portion, CopyYesterdayButton, MiniSpinner },
  props: {
    meal_id: { type: String, required: true },
    expanded: { type: Boolean, required: true },
    mtc_challenge: { type: Boolean }
  },
  data() {
    return {
      edit_portion_id: null,
      swipe_position: "middle",
      swipeAnimationInProgress: false,
      plus_purple_icon,
      chef_hat_icon,
      trash_can_gray_outline_icon,
      trash_can_white_outline_icon,
      heart_dark_gray_outline_icon
    };
  },
  computed: {
    meal() {
      return this.$store.state.rails.meals[this.meal_id];
    },
    meal_type() {
      return capitalize(this.meal.meal_type);
    },
    empty() {
      return this.meal.portions.length === 0;
    },
    peri_workout() {
      // NOTE: first implementation mirrored legacy JS and matched meal.name (e.g. this.meal.name.match("post-workout")),
      // but that turned into a headache when updating meal planner settings - and in any case, this is more elegant
      const { day_type, workout_time } = this.$store.state.rails.day_details;

      if (day_type !== "rest" && is_pre_workout(this.meal_id, workout_time)) {
        return "Pre-workout";
      } else if (day_type !== "rest" && is_post_workout(this.meal_id, workout_time)) {
        return "Post-workout";
      } else {
        return false;
      }
    },
    portions() {
      return this.meal.portions;
    },
    arePortionsPersisting() {
      return this.portions.filter(portion => is_uuid(portion.id)).length > 0;
    },
    portions_pending_deletion() {
      return this.$store.state.rails.portions_to_delete;
    },
    portion_names() {
      return this.portions.map(portion => portion.name).join(", ");
    },
    macros() {
      return round_macros(this.$store.getters["rails/macros_logged_per_meal"][this.meal_id], 0.5);
    },
    macro_targets() {
      return round_macros(this.$store.getters["rails/macro_targets_per_meal"][this.meal_id]);
    },
    customPath() {
      const path = '/custom/';
      const storedTab = localStorage && localStorage.customTab ? localStorage.customTab : 'recipes';
      return `${path}${storedTab}`;
    }
  },
  watch: {
    portions: {
      deep: true,
      handler(portions, previous_portions) {
        const last_portion_was_optimistic = last(previous_portions) && is_uuid(last(previous_portions).id);

        if (portions.length > previous_portions.length ||
            portions.length === previous_portions.length && last_portion_was_optimistic) {
          this.edit_portion_id = last(portions).id;
        }

        this.persistingPortions = portions.filter(p => is_uuid(p.id));
      }
    },
    portions_pending_deletion(portions) {
      if (!portions) {
        // wait for delete modal cancel/confirm before hiding swipe button
        this.swipe_position = "middle";
      }
    }
  },
  methods: {
    toggle_meal_card() {
      // swipeAnimationInProgress is a hack to prevent un-swiping a meal card from simultaneously opening the meal card.
      // This hack isn't required if toggle_meal_card is triggered via the v-touch:tap rather than @click event,
      // but earlier in QA we discovered that v-touch:tap wasn't working properly on Android devices.
      if (this.swipe_position === "middle" && !this.swipeAnimationInProgress) {
        const route = this.expanded ? "/food_log" : { path: "/food_log", query: { meal_id: this.meal_id } };
        this.$router.replace(route);
        this.edit_portion_id = null;
      }
    },
    edit_portion(id_or_null) {
      this.edit_portion_id = id_or_null;
    },
    close_edit_view(){
      this.edit_portion_id = null;
    },
    viewRecipe(id) {
      // assumes id is the root_recipe_id if present
      const portions = this.meal.portions.filter(({ recipe_id, root_recipe_id }) => recipe_id === id || root_recipe_id === id );

      // const recipeMacros = flow(
      //   map(portion => pick(portion, ["carbohydrates", "protein", "fat", "calories"])),
      //   // ensure macro targets reflect the rounded macros displayed in each portion
      //   map(portion => round_macros(portion)),
      //   sum_macros,
      //   to_phx_macro_targets
      // )(portions);

      // REVIEW: seems like portion sizes more consistently match if exact instead of rounded portion macros are used?
      const recipeMacros = to_phx_macro_targets(sum_macros(portions));

      this.$router.push({
        path: `/recipes/${id}`,
        query: { meal_id: this.meal_id, ...recipeMacros }
      });
    },
    async bulk_toggle_favorite() {
      if (!this.arePortionsPersisting) {
        let portions = this.meal.portions.filter(portion => portion.favoritable);

        const has_mixed_favorites = flow(
          map(portion => portion.is_favorite),
          uniq,
          size
        )(portions) > 1;

        if (has_mixed_favorites) {
          // when some portions are favorites and others are non-favorites, make them all favorites
          portions = portions.filter(portion => !portion.is_favorite);
        }

        await Promise.all(portions.map(portion => this.toggle_favorite(portion)));
      }
    },
    add_to_meal(item) {
      if (item.__typename == "Recipe") {
        this.$router.push({
          path: `/recipes/${item.id}`,
          query: { meal_id: this.meal_id, ...to_phx_macro_targets(this.macro_targets) }
        });
      } else {
        const food_type = item.is_custom ? "custom_food" : "food";

        this.$store.dispatch(`rails/log_${food_type}_portion`, {
          food: item,
          meal_id: this.meal_id,
          use_conversion: item.is_favorite,
        });

        this.$store.dispatch("notify_user", {
          type: "info",
          msg: "Food has been successfully added to your food log :)"
        });
      }
    },
    async toggle_favorite(portion) {
      if (!is_uuid(portion.id)) {
        let { is_favorite, food_id, recipe_id, root_recipe_id } = portion;
        const action = is_favorite ? "unfavorite" : "favorite";
        let action_prefix = "";
        let ingredient_type, params;

        // non-null root_recipe_id => this is a portion of a child recipe, in which case we want to
        // favorite/unfavorite the root/parent recipe
        recipe_id = root_recipe_id || recipe_id;

        if (recipe_id) {
          ingredient_type = "recipe";

          if (action === "favorite") {
            params = {
              id: recipe_id,
              macro_targets: this.$store.getters["rails/default_portion_macro_targets"]
            };
          } else {
            params = recipe_id;
          }
        } else if (food_id) {
          ingredient_type = "food";

          if (action === "favorite") {
            params = {
              id: food_id,
              serving_qty: portion_servings_to_quantity(portion),
              conversion_name: serving_units(portion),
            };
          } else {
            params = food_id;
          }
        } else {
          action_prefix = "rails/";
          ingredient_type = "custom_food";
          params = portion.ingredient_id;
        }

        try {
          await this.$store.dispatch(`${action_prefix}${action}_${ingredient_type}`, params);

          if (action === "favorite") {
            show_favorited_alert();
          } else {
            show_unfavorited_alert();
          }
        } catch(e) {
          const msg = `Unable to ${action} ${ingredient_type.replace("custom_", "")}`;
          this.$store.dispatch("notify_user", { type: "error", msg });
          console.error(`${msg} ${food_id || recipe_id || portion.ingredient.id} portion!\n\n`, e);
        }
      }
    },
    delete_portion(portion) {
      if (portion && !is_uuid(portion.id) || !this.arePortionsPersisting) {
        const portions = portion ? [portion] : this.meal.portions;
        this.$store.dispatch("rails/set_portions_to_delete", { meal_id: this.meal_id, portions });
      }
    },
    on_swipe(direction) {
      if (!this.expanded && !this.arePortionsPersisting && this.meal.portions.length > 0) {
        this.swipe_position = this.swipe_position === "middle" ? direction : "middle";
        this.swipeAnimationInProgress = true;
        setTimeout(() => this.swipeAnimationInProgress = false, 200); // CSS transition duration
      }
    }
  }
};
</script>

<style scoped lang="scss">
.meal-card-wrapper {
  flex-shrink: 0;
  display: flex;
  margin-bottom: 8px;

  &:not(.expanded) {
    flex-grow: 1;
  }
}

.meal-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-decoration: inherit;
  color: $primary-gray;
  cursor: pointer;
  background: white;
  box-shadow: 0px 1px 2px rgba(79, 79, 79, 0.25);
  cursor: pointer;
  border-radius: 8px;
  padding: 16px 24px;
  width: 100%;
  min-width: 100%;
  transition: margin 200ms linear;

  &.position-left {
    margin-left: -70px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.position-middle {
    margin-left: 0;
  }

  &.expanded {
    padding: 12px;

    &:not(.empty) {
      padding-bottom: 0;
    }
  }

  &:not(.expanded) .meal-summary {
    // expand area in which a click to expand meal card will be detected
    height: 100%;
  }

  &.empty:not(.expanded) {
    // replicates pre-rewrite styling (though I question if this is actually optimal...)
    flex: 1;
  }

  .open-icon {
    height: 14px !important;
    width: 14px !important;
    margin-right: 12px;
  }
}

.swipe-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  cursor: pointer;
  max-height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 1px 1px 2px rgba(79, 79, 79, 0.25);

  &.delete {
    right: 1px;
    background-color: #e84c71;
  }

  img {
    width: 27px;
    height: 27px;
  }
}

.meal-summary {
  display: flex;
  align-items: center;

  .meal-info {
    padding: 4px 12px;
    max-width: calc(100% - 12px);

    p {
      margin-bottom: 0;
      text-align: left;

      &.portion-names {
        font-size: 12px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0px;
        padding: 0px;
      }
    }

    .meal-type {
      display: flex;
      align-items: center;

      .badge {
        text-transform: uppercase;
      }

      p {
        display: inline;
        box-shadow: none;
        color: #000000;
        font-size: 16px;
        font-family: Montserrat, san-serif;
        font-weight: 600;
        background: white;
        text-transform: unset;
        padding: 0;
        line-height: normal;
      }
    }

    .meal-macros {
      display: flex;
      justify-content: flex-start;
      margin: 4px 0px 0px 0px;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      color: #4f4f4f;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .macro-container {
        display: flex;
        margin-right: 6px;
      }

      .value-actual {
        margin-left: 4px;
      }

      .separator,
      .value-target {
        font-weight: 500;
      }
    }
  }
}

.meal-actions {
  margin: 16px 8px 0px 8px;

  .ingredient-sources {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    margin-bottom: 4px;
  }

  .meal-card-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 24px;
    margin-right: 8px;
    border: 1px solid $endurance;
    border-radius: 12px;
    background-color: #ffffff;

    &:last-child {
      margin-right: 0;
    }

    i {
      margin-right: 6px;
      color: $endurance;
      font-size: 10px;
    }

    img {
      width: 12px;
      margin-right: 6px;
    }

    p {
      margin: 0px;
      color: $endurance;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .bulk-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 14px -8px 0px -8px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .action {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.portions {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0px -12px;
}
</style>
