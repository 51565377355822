<template lang="html">

  <div v-if="collapsed" class="radial collapsed">
    <img v-if="macro.short_name === 'calories' && macros_on_target" :src="carrotIcon" class="carrot" />
    <p v-if="macro.short_name !== 'calories' || !macros_on_target" class="short-label">{{ macro.abbrev }}</p>
    <div v-if="macro.short_name !== 'calories' || !macros_on_target" :class="{'radial-progress': true, 'progress': true, 'carbs': macro.short_name === 'carbs', 'protein': macro.short_name === 'protein', 'fat': macro.short_name === 'fat', 'calories': macro.short_name === 'calories', 'over-100': over_target, 'on-target': on_target}" :data-percentage="percentage === 100 ? 0 : percentage">
      <span class="progress-left">
        <span class="progress-bar"></span>
      </span>
      <span class="progress-right">
        <span class="progress-bar"></span>
      </span>
      <div :class="{'progress-flexwrap': true, 'thumbs': on_target}">
        <div class="progress-value">
          <img v-if="on_target" class="green-thumb" :src="greenThumbDarkIcon" />
          <p v-else class="remaining-value">{{ Math.abs(remaining) }}</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="radial">
    <img v-if="macro.short_name === 'calories' && macros_on_target" :src="carrotIcon" class="carrot" />
    <template v-else>
      <div :class="{'radial-progress': true, 'progress': true, 'carbs': macro.short_name === 'carbs', 'protein': macro.short_name === 'protein', 'fat': macro.short_name === 'fat', 'calories': macro.short_name === 'calories', 'over-100': over_target, 'on-target': on_target}" :data-percentage="percentage === 100 ? 0 : percentage">
        <span class="progress-left">
          <span class="progress-bar"></span>
        </span>
        <span class="progress-right">
          <span class="progress-bar"></span>
        </span>
        <div :class="{'progress-flexwrap': true, 'thumbs': on_target}">
          <div class="progress-value">
            <img v-if="on_target" class="green-thumb" :src="greenThumbDarkIcon" />
            <span v-else>
              <p class="remaining-value">{{ Math.abs(remaining) }}<span v-if="macro.short_name !== 'calories'">g</span></p>
              <p v-if="over_target" class="over">OVER</p>
            </span>
          </div>
        </div>
      </div>
      <div class="allowance">
        <p class="label">{{ macro.short_name }}</p>
      </div>
    </template>
  </div>

</template>

<script>
import carrotIcon from "@images/carrot.png";
import greenThumbDarkIcon from "@images/green-thumb-dark.svg";

export default {
  props: {
    macro: {type: Object, default: () => {
      return {
        long_name: "",
        short_name: "",
        abbrev: "",
        on_target_thresholds: {
          over: 0,
          under: 0
        }
      };
    }},
    target: {type: Number, default: 0},
    actual: {type: Number, default: 0},
    remaining: {type: Number, default: 0},
    percentage: {type: Number, default: 0},
    on_target: {type: Boolean, default: false},
    over_target: {type: Boolean, default: false},
    macros_on_target: {type: Boolean, default: false},
    collapsed: {type: Boolean, default: false}
  },
  data() {
    return {
      carrotIcon: carrotIcon,
      greenThumbDarkIcon: greenThumbDarkIcon,
      green_thumb: "data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNhZGUyY2Q7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5ncmVlbi10aHVtYjwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMCwwSDI0VjI0SDBaTTAsMEgyNFYyNEgwWiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTEzLjEyLDIuMDYsNy41OCw3LjZBMiwyLDAsMCwwLDcsOVYxOWEyLDIsMCwwLDAsMiwyaDlhMiwyLDAsMCwwLDEuODQtMS4yMWwzLjI2LTcuNjFBMywzLDAsMCwwLDIwLjM0LDhIMTQuNjlsMS00LjU4YTEuNTEsMS41MSwwLDAsMC0uNDEtMS4zN0ExLjQ5LDEuNDksMCwwLDAsMTMuMTIsMi4wNlpNMywyMWEyLDIsMCwwLDAsMi0yVjExYTIsMiwwLDAsMC00LDB2OEEyLDIsMCwwLDAsMywyMVoiLz48L3N2Zz4="
    }
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="scss">
  @import "@css/vendors/_radial_progress.scss";

  $borderWidth: 4px;
  $borderWidthCollapsed: 3px;
  $border-color-gray: #FAFAFA;
  $border-color-actual: #E7E7E7;
  $border-color-default: #00A881;
  $border-color-carbs: #FFCD3C;
  $border-color-protein: #FF6699;
  $border-color-fat: #34B6E4;
  $border-color-calories: #B254B2;
  $border-color-green: #ADE2CD;
  $border-color-over-100: #FF9234;
  $size: 68px;
  $sizeCollapsed: 40px;

  .macro-success {
    color: $border-color-actual !important;
  }

  .radial {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    &.collapsed {
      align-items: flex-start;
      .progress {
        width: $sizeCollapsed;
        height: $sizeCollapsed;
        line-height: $sizeCollapsed;
        &:after {
          border: $borderWidthCollapsed solid $border-color-default !important;
        }
        &.carbs {
          &:after {
            border: $borderWidthCollapsed solid $border-color-carbs !important;
          }
        }
        &.protein {
          &:after {
            border: $borderWidthCollapsed solid $border-color-protein !important;
          }
        }
        &.fat {
          &:after {
            border: $borderWidthCollapsed solid $border-color-fat !important;
          }
        }
        &.calories {
          &:after {
            border: $borderWidthCollapsed solid $border-color-calories !important;
          }
        }
        &.over-100 {
          &:after {
            border: $borderWidthCollapsed solid $border-color-gray !important;
          }
          .progress-bar {
            color: $border-color-over-100 !important;
            border-color: $border-color-over-100 !important;
          }
        }
        &.on-target {
          &:after {
            border: $borderWidthCollapsed solid $border-color-default !important;
          }
          .progress-bar {
            color: $border-color-green !important;
            border-color: $border-color-green !important;
          }
        }
        .progress-left .progress-bar {
          border-top-right-radius: ($sizeCollapsed/2);
          border-bottom-right-radius: ($sizeCollapsed/2);
        }
        .progress-right {
          .progress-bar {
            border-top-left-radius: ($sizeCollapsed/2);
            border-bottom-left-radius: ($sizeCollapsed/2);
          }
        }
        .progress-flexwrap {
          background-color: #ffffff;
          border-radius: 50%;
          &.thumbs {
            background-color: $border-color-green;
          }
          .green-thumb {
            width: 18px;
          }
        }
        .progress-value {
          .remaining-value {
            color: #000000;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: 600;
            margin: 0px;
          }
          .check {
            font-size: 16px;
            color: #00A881;
            margin: 0px;
          }
          .over {
            color: #000000;
            font-family: "Open Sans";
            font-size: 6px;
            font-weight: 600;
            margin: 4px 0px -6px 0px;
          }
        }
      }
      .carrot {
        width: 30px;
        margin-bottom: 0px;
      }
    }
    .carrot {
      width: 40px;
      margin-bottom: 8px;
    }
    .short-label {
      color: #000000;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 600;
      margin: 0px;
      text-align: left;
      width: 100%;
      text-transform: capitalize;
    }
    .allowance {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4px;
      .label, .value {
        color: #000000;
        font-family: "Open Sans";
        font-size: 12px !important;
        font-weight: 600;
        text-align: center;
        text-transform: capitalize;
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
      .remaining-small {
        color: #4f4f4f;
        font-family: "Open Sans";
        font-size: 12px !important;
        font-weight: 600;
        &.remaining-small-hidden {
          display: none;
        }
      }
    }
    .progress {
      width: $size;
      height: $size;
      line-height: $size;
      &:after {
        border: $borderWidth solid $border-color-default !important;
      }
      &.carbs {
        &:after {
          border: $borderWidth solid $border-color-carbs !important;
        }
      }
      &.protein {
        &:after {
          border: $borderWidth solid $border-color-protein !important;
        }
      }
      &.fat {
        &:after {
          border: $borderWidth solid $border-color-fat !important;
        }
      }
      &.calories {
        &:after {
          border: $borderWidth solid $border-color-calories !important;
        }
      }
      &.over-100 {
        &:after {
          border: $borderWidth solid $border-color-gray !important;
        }
        .progress-bar {
          color: $border-color-over-100 !important;
          border-color: $border-color-over-100 !important;
        }
      }
      &.on-target {
        &:after {
          border: $borderWidth solid $border-color-default !important;
        }
        .progress-bar {
          color: $border-color-green !important;
          border-color: $border-color-green !important;
        }
      }
      .progress-bar {
        border-width: $borderWidth !important;
        color: $border-color-actual !important;
        border-color: $border-color-actual !important;
      }
      .progress-left .progress-bar {
        border-top-right-radius: ($size/2);;
        border-bottom-right-radius: ($size/2);;
      }
      .progress-right {
        .progress-bar {
          border-top-left-radius: ($size/2);;
          border-bottom-left-radius: ($size/2);;
        }
      }
      .progress-flexwrap {
        background-color: #ffffff;
        border-radius: 50%;
        &.thumbs {
          background-color: $border-color-green;
        }
        .green-thumb {
          width: 23px;
        }
      }
      .progress-value {
        color: #000000;
        font-family: "Open Sans";
        font-size: 10px;
        font-weight: 700;

        .remaining-value {
          color: #000000;
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: 600;
          margin: 0px;
        }
        .over {
          color: #000000;
          font-family: "Open Sans";
          font-size: 10px;
          font-weight: 700;
          margin: 4px 0px -6px 0px;
        }
      }
    }
  }
</style>
