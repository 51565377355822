<template lang="html">
  <canvas :ref="'canvas'"></canvas>
</template>

<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: {
    macros: { type: Object, required: true }
  },
  data() {
    return {
      options: {
        cutoutPercentage: 85,
        animation: {
          animateRotate: true,
          animateScale: true
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      }
    };
  },
  computed: {},
  watch: {
    macros(macros) {
      this.render();
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      const { carb_grams, protein_grams, fat_grams } = this.macros;
      let dataset;

      if (carb_grams === 0 && protein_grams === 0 && fat_grams === 0) {
        dataset = {
          data: [100],
          backgroundColor: ["#DDD"]
        };
      } else {
        dataset = {
          data: [carb_grams, protein_grams, fat_grams],
          backgroundColor: ["#FFCD3C", "#FF6699", "#34B6E4"]
        };
      }

      this.renderChart({ datasets: [{ ...dataset, borderWidth: 0 }] }, this.options);
    }
  }
};
</script>
