<template lang="html">
  <div class="profile-container">
    <div class="nav">
      <div class="back-button" @click="$router.back()">
        <i class="fa fa-chevron-left"></i>
        <p>Summary</p>
      </div>
      <p class="title">Diet</p>
    </div>

    <div class="content">
      <p class="page-header">Please specify your dietary preferences below</p>
      <p class="page-info">
        The dietary preferences you select will help us recommend recipes and other foods you will enjoy
      </p>
      <div class="toggles">
        <div
          v-for="{ id, name } in dietary_restrictions"
          class="toggle"
          :class="{ selected: dietary_restriction_ids.includes(id) }"
          @click="toggle_dietary_restriction(id)"
        >
          <p>{{ name }}</p>
          <div class="indicator">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gluten_free_icon from "@images/gluten-free.svg";
import dairy_free_icon from "@images/dairy-free.svg";
import vegetarian_icon from "@images/vegetarian.svg";
import vegan_icon from "@images/vegan.svg";
import pescatarian_icon from "@images/pescatarian.svg";

const dietary_restriction_icons = {
  "Gluten Free": gluten_free_icon,
  "Dairy Free": dairy_free_icon,
  "Vegetarian": vegetarian_icon,
  "Vegan": vegan_icon,
  "Pescatarian": pescatarian_icon
};


export default {
  computed: {
    dietary_restriction_ids() {
      return this.$store.state.rails.user.dietary_restriction_ids;
    },
    dietary_restrictions() {
      return this.$store.state.rails.dietary_restrictions.map(({ id, name }) => (
        { id, name, icon: dietary_restriction_icons[name] }
      ));
    }
  },
  methods: {
    async toggle_dietary_restriction(id) {
      try {
        this.$store.dispatch("loading", true);

        await this.$store.dispatch("rails/toggle_dietary_restriction", id);

        this.$store.dispatch("notify_user", { type: "info", msg: "Profile successfully updated :)" });
      } catch (e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to update profile." });
      } finally {
        this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/components/profile";

.content {
  padding-top: 30px;
}

.toggles {
  padding: 18px 0px 0px 0px;

  .toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 12px 0px;
    padding: 10px 0px;
    cursor: pointer;

    &.selected .indicator {
      background-color: #BDA8FB;

      div {
        left: calc(100% - 8px);
        background-color: $endurance;
      }
    }

    p {
      color: #4f4f4f;
      font-size: 13px;
      font-weight: 700;
      margin: 0px;
    }

    .indicator {
      position: relative;
      width: 24px;
      height: 10px;
      background-color: #ADADAD;
      border-radius: 5px;
      box-shadow: 0px 1px 3px 0px rgba(175,175,175,0.5);
      margin: 0px 0px 0px auto;

      div {
        position: absolute;
        top: -3px;
        left: -8px;
        width: 16px;
        height: 16px;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 1px 3px 0px rgba(175,175,175,0.5);
        transition: all 0.2s;
      }
    }
  }
}
</style>
