<template>
  <div id="favorites" @click="toggle_options">
    <div class="nav-bar">
      <div class="left-button" @click="$router.back()">
        <i class="fa fa-chevron-left fa-lg" />
        Back
      </div>
      <div class="title">
        Favorites
      </div>
    </div>

    <div class="tabs-container">
      <div :class="{ tab: true, selected: selected_tab === 'recipes' }" @click="selected_tab = 'recipes'">
        <p>RECIPES</p>
      </div>
      <div :class="{ tab: true, selected: selected_tab === 'foods' }" @click="selected_tab = 'foods'">
        <p>FOODS</p>
      </div>
    </div>

    <div class="search-container">
      <div class="search-input">
        <div class="icon">
          <i class="fa fa-search" />
        </div>
        <input
          v-bind:value="search_term"
          v-on:input="search_term = $event.target.value.trim()"
          type="text"
          :placeholder="selected_tab === 'recipes' ? 'Search Favorite Recipes' : 'Search Favorite Foods'"
        />
        <div class="icon clear-button" @click="search_term = ''">
          <i class="fa fa-times" />
        </div>
      </div>

      <Select
        :options="sort_options"
        class="sort-select"
        @select="change_sort"
      >
        <div class="selected-field">
          <i class="fa fa-sort icon" />
          <p>{{ selected_sort }}</p>
        </div>
      </Select>
    </div>

    <div :class="search_term ? 'search-results-container' : 'food-results-container'">
      <div class="food-results">
        <div
          v-for="food in displayed_favorites"
          v-bind:key="food.id"
          class="food-result"
        >
          <div class="info">
            <p class="food-name">{{food.name}}</p>
            <div class="macros-container">
              <div class="macro">
                <div class="name">C</div>
                <div class="actual">{{ Math.round(food.conversion.carb_grams) }}g</div>
              </div>
              <div class="macro">
                <div class="name">P</div>
                <div class="actual">{{ Math.round(food.conversion.protein_grams) }}g</div>
              </div>
              <div class="macro">
                <div class="name">F</div>
                <div class="actual">{{ Math.round(food.conversion.fat_grams) }}g</div>
              </div>
              <div class="macro">
                <div class="name">Cals</div>
                <div class="actual">{{ Math.round(food.conversion.calories) }}</div>
              </div>
            </div>
          </div>
          <div class="options-container" @click.stop="toggle_options(food.id)">
            <i class="fa fa-ellipsis-v" />
          </div>
          <div v-if="selected_tab === 'foods'" class="checkbox-container" @click="toggle_add(food.id)">
            <div class="box" :class="{ selected: is_selected(food) }">
              <i class="fa fa-check" />
            </div>
          </div>
          <div v-if="selected_tab === 'recipes'" class="plus-container" @click="add_recipe(food)">
            <i class="fa fa-plus-circle" />
          </div>
          <div v-if="visible_options === food.id" class="options-dropdown">
            <p v-if="selected_tab === 'recipes'" @click="$router.push(`recipes/${food.id}`)">
              <i class="fa fa-eye"></i>View Recipe
            </p>
            <p @click="delete_favorite_food(food)">
              <i class="fa fa-trash"></i>Remove
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selected_tab === 'foods'" class="button-row">
      <div
        class="add-to-food-log-button"
        :class="{ disabled: selected_ids.length === 0 }"
        :disabled="selected_ids.length === 0"
        @click="add_foods"
      >
        Add to Food Log
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import inflect from "@js/lib/inflect";
import { filter_by_search_term } from "@js/lib/utils";

import Select from "@js/shared/select.vue";

export default {
  components: { Select },
  props: {
    meal_id: { type: String, required: true }
  },
  data() {
    return {
      search_term: "",
      selected_tab: "recipes",
      selected_food_ids: [],
      selected_recipe_ids: [],
      visible_options: null,
      sort_by: "alphabetical"
    };
  },
  computed: {
    favorites() {
      return this.selected_tab === "recipes" ? this.$store.state.favorite_recipes : this.$store.state.favorite_foods;
    },
    selected_ids() {
      return this.selected_tab === "recipes" ? this.selected_recipe_ids : this.selected_food_ids;
    },
    displayed_favorites() {
      const filtered_favorites = filter_by_search_term(this.favorites, this.search_term);

      if (this.sort_by === "recent") {
        // TODO: sorting by recent won't work without additional support from backend - we need the ID or inserted_at
        // for the favorite join record - not of the food/recipe record itself
        return orderBy(filtered_favorites, item => item.id, "desc");
      } else if (this.sort_by === "alphabetical") {
        return orderBy(filtered_favorites, item => item.name.toLowerCase(), "asc");
      }
    },
    sort_options() {
      return [
        { name: "Alphabetical", id: "alphabetical" },
        // { name: "Recently Created", id: "recent" }
      ].map(option => ({ ...option, selected: option.id === this.sort_by}));
    },
    selected_sort() {
      return this.sort_options.find(option => option.id === this.sort_by).name;
    },
  },
  methods: {
    change_sort(sort_by) {
      this.sort_by = sort_by;
    },
    is_selected(favorite) {
      return this.selected_ids.includes(favorite.id);
    },
    toggle_add(food_id) {
      const index = this.selected_ids.indexOf(food_id);

      if (index !== -1) {
        this.selected_ids.splice(index, 1);
      } else {
        this.selected_ids.push(food_id);
      }
    },
    toggle_options(food_id) {
      if (food_id) {
        this.visible_options = food_id;
      } else {
        this.visible_options = null;
      }
    },
    add_foods() {
      if (this.selected_ids.length > 0) {
        const foods = this.favorites.filter(favorite => this.selected_ids.includes(favorite.id));

        foods.forEach(food => this.$store.dispatch("rails/log_food_portion", { food, meal_id: this.meal_id, use_conversion: true }));

        const message_subject = `${inflect("Food", foods.length)} ${inflect("has", foods.length)}`;
        this.$store.dispatch("notify_user", { type: "info", msg: message_subject + " been successfully added to your food log :)" });

        this.$router.push({ path: "/food_log", query: { meal_id: this.meal_id } });
      }
    },
    add_recipe(recipe) {
      this.$store.dispatch("rails/log_recipe_portions", { recipe, meal_id: this.meal_id });
      this.$store.dispatch("notify_user", { type: "info", msg: "Recipe has been successfully added to your food log :)" });

      this.$router.push({ path: "/food_log", query: { meal_id: this.meal_id } });
    },
    async delete_favorite_food(food) {
      if (this.selected_tab === "recipes") {
        try {
          await this.$store.dispatch("unfavorite_recipe", food.id);
          this.$store.dispatch("notify_user", { type: "info", msg: "Unfavorited recipe" });
        } catch(err) {
          console.log(err);
          this.$store.dispatch("notify_user", { type: "error", msg: "Unable to unfavorite recipe" });
        }
      } else {
        try {
          await this.$store.dispatch("unfavorite_food", food.id);
          this.$store.dispatch("notify_user", { type: "info", msg: "Unfavorited food" });
        } catch(err) {
          console.log(err);
          this.$store.dispatch("notify_user", { type: "error", msg: "Unable to unfavorite food" });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "@css/_reset.scss";
  @import "@css/_tracker_reset.scss";

  #favorites {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    z-index: 50;
    background-color: #ffffff;

    .nav-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 58px;
      min-height: 58px;
      background-color: #ffffff;
      &.favorites {
        background-color: #00A881;
        .left-button {
          top: 0px;
          left: 0px;
          i {
            color: #ffffff;
            margin: 0px;
          }
        }
        .title {
          color: #ffffff;
        }
      }

      .left-button, .right-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 58px;
        height: 58px;
        cursor: pointer;
        color: $endurance;
        font-size: 12px;
      }
      .left-button {
        top: 0px;
        left: 12px;
        i {
          margin-right: 8px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
    }

    .tabs-container {
      display: flex;
      flex-direction: row;
      padding: 0px;
      margin: 0px 0px 12px 0px;
      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #E6E6E6;
        width: 100%;
        height: 40px;
        cursor: pointer;
        p {
          font-weight: 600;
          font-size: 14px;
          color: #4f4f4f;
        }
        &.selected {
          border-bottom: 2px solid $endurance;
          p {
            color: $endurance;
          }
        }
      }
    }

    .search-container {
      display: flex;
      flex-direction: row;
      padding: 16px 24px;
      min-height: 72px;
      .search-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #F0E8FB;
        border-radius: 8px;
        margin: 0px;
        width: 100%;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          min-width: 30px;
          height: 100%;
          cursor: pointer;
          i {
            color: $endurance;
            font-size: 12px;
          }
          &.clear-button {
            margin-left: auto;
          }
        }
        input {
          font-size: 16px;
          font-weight: normal;
          color: #4f4f4f;
          background-color: transparent;
          padding: 0px;
          height: 40px !important;
          line-height: 40px;
          width: 100%;
          min-width: 0px;
          text-overflow: ellipsis;
        }
        input::placeholder {
          color: #4f4f4f;
          font-size: 16px;
          font-weight: normal;
        }
        input:focus {
          outline: none !important;
        }
      }
      .filters-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 0px 0px auto;
        i {
          color: $endurance;
          font-size: 12px;
        }
        p {
          font-size: 12px;
          font-weight: 600;
          color: $endurance;
        }
      }

      .sort-select {
        display: inline-block;
        width: 25%;
        margin: 8px 0px 0px 8px;
        ::v-deep .select-list {
          min-width: 200px !important;
        }
      }
      ::v-deep .selected-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: none;
        margin: 0px;
        padding: 6px;
        max-width: 80px;
        min-width: 80px;
        p {
          margin: 0px 0px 0px 6px;
          color: $endurance;
          font-size: 12px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        i {
          color: $endurance;
          font-size: 12px;
          margin: 0px 0px 0px auto;
        }
      }
      &.collapsed-header {
        .sort-select {
          display: inline-block;
          width: 100%;
          margin-top: 0px;
          ::v-deep .select-list {
            min-width: 200px !important;
          }
        }
        ::v-deep .selected-field {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          border-bottom: none;
          margin: 0px;
          padding: 6px;
          p {
            margin: 0px 8px 0px 0px;
            color: #4f4f4f;
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: right;
          }
          i {
            color: #4f4f4f;
            font-size: 8px;
            margin: 0px 0px 0px auto;
          }
        }
        ::v-deep .select-list {
          min-width: 200px;
          right: 0px;
        }
      }
    }

    .meal-select-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 41px;
      align-items: center;
      transition: visibility 0s, opacity 0.5s linear;
      padding: 0px 24px;
      margin: 0px 0px 24px;
      .meal-select {
        display: inline-block;
        width: 75%;
        margin-top: 8px;
      }
      ::v-deep .selected-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 2px solid $endurance;
        margin: 0px;
        padding: 6px 0px 6px 6px;
        p {
          margin: 0px 8px 0px 0px;
          color: #4f4f4f;
          font-size: 14px;
        }
        i {
          color: $endurance;
          font-size: 8px;
          margin: 0px 0px 0px auto;
        }
      }
      &.collapsed-header {
        .meal-select {
          display: inline-block;
          width: 100%;
          margin-top: 0px;
        }
        ::v-deep .selected-field {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          border-bottom: none;
          margin: 0px;
          padding: 6px;
          p {
            margin: 0px 8px 0px 0px;
            color: #4f4f4f;
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: right;
          }
          i {
            color: #4f4f4f;
            font-size: 8px;
            margin: 0px 0px 0px auto;
          }
        }
        ::v-deep .select-list {
          min-width: 220px;
          right: 0px;
        }
      }
    }

    .search-results-container {
      width: 100%;
      height: 100%;
      margin: -16px 0px 0px 0px;
      padding: 0px 24px 16px 24px;
      overflow: auto;
      .food-results {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 8px 8px;
        .food-result {
          cursor: pointer;
        }
      }
    }

    .food-results-container {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .shuffle-foods-container {
      display: flex;
      flex-direction: row;
      padding: 8px 24px 16px 24px;
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        width: 24px;
        height: 100%;
        i {
          color: $endurance;
          font-size: 16px;
        }
      }
      p {
        font-size: 12px;
        font-weight: 600;
        color: $endurance;
      }
    }

    .food-results {
      padding: 18px 24px;
      width: 100%;
      height: 100%;
      overflow: auto;

      .food-result {
        position: relative;
        display: flex;
        flex-direction: row;
        border-left: 8px solid #C4C4C4;
        padding: 0px 0px 0px 12px;
        margin: 0px 0px 12px 0px;
        .info {
          display: flex;
          flex-direction: column;
          margin: 0px 14px 0px 0px;
          .food-name {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            margin: 0px 0px 4px 0px;
          }
          .macros-container {
            display: flex;
            flex-direction: row;
            margin: 0px 0px 4px 0px;
            .macro {
              display: flex;
              flex-direction: row;
              white-space: nowrap;
              padding-right: 0.3rem;
              .name, .actual {
                font-family: 'Open Sans';
                font-size: 12px;
                font-weight: 600;
                color: #4f4f4f !important;
              }
              .name {
                margin-left: 0.1rem;
                padding-right: 0.2rem;
              }
              .actual {
                padding-right: 0px;
                margin-right: 0px;
              }
            }
          }
        }
        .options-container, .checkbox-container, .plus-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 0px 0px 0px;
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
          cursor: pointer;
          i {
            font-size: 12px;
            color: $endurance;
          }
        }
        .options-container {
          margin-left: auto;
        }
        .plus-container {
          i {
            font-size: 16px;
          }
        }
        .checkbox-container {
          margin-left: 0px;
          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            border: 1px solid $endurance;
            border-radius: 2px;
            width: 14px;
            height: 14px;
            &.selected {
              background-color: $endurance;
              i {
                display: block;
              }
            }
            i {
              display: none;
              font-size: 10px;
              color: #ffffff;
            }
          }
        }
      }
    }

    .button-row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 0px;
      .add-to-food-log-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        background-color: $endurance;
        height: 40px;
        padding: 0px 24px;
        border-radius: 22px;
        cursor: pointer;
        &.disabled {
          background-color: #E6E6E6;
          color: #959595;
        }
      }
    }

  }

  .options-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    top: 40px;
    right: 55px;
    z-index: 49;
    background-color: #ffffff;
    p {
      padding: 12px 18px 6px 18px;
      font-size: 12px;
      font-weight: 600;
      color: #4f4f4f;
      cursor: pointer;
      i {
        margin-right: 6px;
        font-size: 10px;
      }
    }
    p:last-child {
      padding: 6px 18px 12px 18px;
    }
  }
</style>
