<template>
  <div id="fill-macros">
    <!-- LoadingScreens are rendered in MatchingRecipes and MatchingFoods, configured accordingly -->
    <div :class="{ 'nav-bar': true } ">
      <div class="left-button" @click="$router.back()">
        <i class="fa fa-chevron-left fa-lg" />
        Back
      </div>
      <div class="title">
        <i class="fa fa-magic" />
        Fill My Macros
      </div>
    </div>

    <div class="tabs-container">
      <div :class="{ tab: true, selected: view === 'recipes' }" @click="view = 'recipes'">
        <p>RECIPES</p>
      </div>
      <div :class="{ tab: true, selected: view === 'foods' }" @click="view = 'foods'">
        <p>FOODS</p>
      </div>
    </div>

    <div v-if="macro_targets" class="target-macros-container" @click="select_macro_profile('custom')">
      <div class="target-macro">
        <div class="label">C</div>
        <div class="value">{{ macro_targets.carb }}g</div>
      </div>
      <div class="target-macro">
        <div class="label">P</div>
        <div class="value">{{ macro_targets.protein }}g</div>
      </div>
      <div class="target-macro">
        <div class="label">F</div>
        <div class="value">{{ macro_targets.fat }}g</div>
      </div>
    </div>

    <div class="meal-select-row">
      <Select
        :options="macro_profiles"
        class="meal-select"
        @select="select_macro_profile"
      >
        <div class="selected-field">
          <p>{{ selected_macro_profile.name }}</p>
          <i class="fa fa-chevron-down fa-sm icon" />
        </div>
      </Select>
    </div>

    <MatchingRecipes
      v-show="view === 'recipes'"
      :meal_id="meal_id"
      :macro_targets="macro_targets"
      :open_filters="() => { view = 'filters' }"
      :open_add_to_meal="open_add_to_meal"
    />

    <MatchingFoods
      v-show="view === 'foods'"
      ref="foods"
      :selected_profile_name="selected_macro_profile && selected_macro_profile.name"
      :macro_targets="macro_targets"
      :meal_id="add_to_meal_id"
      :add_to_meal="add_foods_to_meal"
    />

    <Filters
      v-if="view == 'filters'"
      :loading="loading_recipes"
      :results_count="recipes_count"
      :context="'match'"
      :close="() => { view = 'recipes' }"
    />

    <AddToMealModal
      v-if="add_to_meal_visible"
      :recipe="selected_recipe"
      :default_meal_id="add_to_meal_id"
      :add_to_meal="add_recipe_to_meal"
      :close="() => { add_to_meal_visible = false }"
    />

    <CustomMacros
      v-if="custom_macros_visible"
      :default_macro_targets="macro_targets"
      :update="update_custom_macros"
      :close="() => { custom_macros_visible = false }"
    />
  </div>
</template>

<script>
import { values, mapValues, isEqual } from "lodash";

import { round_macros } from "@js/lib/utils";
import { to_phx_macro_targets } from "@js/lib/rails_compatibility_helpers";

import MatchingRecipes from "@js/recipes/fill_macros/matching_recipes.vue";
import MatchingFoods from "@js/recipes/fill_macros/matching_foods.vue";
import Select from "@js/shared/select.vue";
import Filters from "@js/recipes/filters.vue";
import CustomMacros from "@js/recipes/fill_macros/custom_macros.vue";
import AddToMealModal from "@js/recipes/add_to_meal_modal.vue";
import LoadingScreen from "@js/recipes/loading_screen.vue";

const MEAL_IDS = ["meal-1", "meal-2", "meal-3", "meal-4", "meal-5"];
const PROFILE_IDS = MEAL_IDS.concat(["daily_remaining", "custom"]);

export default {
  components: { MatchingRecipes, MatchingFoods, Select, Filters, CustomMacros, AddToMealModal, LoadingScreen },
  props: {
    meal_id: { type: String, required: false, validator: id => MEAL_IDS.includes(id) },
  },
  data() {
    /*
      When it's the user's last meal, instead of trying to fill meal-specific macros we want to fill their daily remaining macros.
      If some meal *other than meal_id* doesn't have any logged portions, it isn't the last meal.
      If no meal_id is specified (i.e. FillMacros wasn't opened from a MealCard), then we either want to fill macros
      for the next empty meal, or their daily remaining macros if none are empty.
    */
    const meals = values(this.$store.state.rails.meals);
    const empty_meals = meals.filter(meal => meal.portions.length === 0).map(meal => meal.identifier);
    const next_empty_meal = empty_meals[0];

    let selected_profile_id;

    if (empty_meals.length === 0 || (empty_meals.length === 1 && this.meal_id === next_empty_meal)) {
      selected_profile_id = "daily_remaining";
    } else if (this.meal_id) {
      selected_profile_id = this.meal_id;
    } else {
      // first clause == false => there's at least one empty meal
      selected_profile_id = next_empty_meal
    }

    const macro_targets = mapValues(
      this.$store.getters["rails/macros_remaining_per_meal"],
      meal_macros => to_phx_macro_targets(round_macros(meal_macros, 0.5))
    );

    const daily_remaining_targets = to_phx_macro_targets(
      round_macros(this.$store.getters["rails/macros_remaining_for_day"], 0.5)
    );

    return {
      view: "recipes",
      selected_recipe: null,
      selected_foods: null,
      selected_profile_id,
      next_empty_meal,
      macro_profiles: [
        {
          id: "daily_remaining",
          name: "Daily Remaining",
          helper: "(perfect match)",
          macro_targets: daily_remaining_targets
        },
        {
          id: "custom",
          name: "Custom Macros",
          helper: "(perfect match)"
          /* macro_targets are user configurable */
        },
        { id: "meal-1", name: "Breakfast", macro_targets: macro_targets["meal-1"] },
        { id: "meal-2", name: "Snack 1", macro_targets: macro_targets["meal-2"] },
        { id: "meal-3", name: "Lunch", macro_targets: macro_targets["meal-3"] },
        { id: "meal-4", name: "Snack 2", macro_targets: macro_targets["meal-4"] },
        { id: "meal-5", name: "Dinner", macro_targets: macro_targets["meal-5"] }
      ],
      macro_profile_default_filters: {
        "meal-1": ["is_breakfast"],
        "meal-2": ["is_lunch", "is_dinner", "is_side", "is_snack", "is_dessert", "is_shake"],
        "meal-3": ["is_lunch", "is_dinner", "is_side", "is_snack", "is_dessert", "is_shake"],
        "meal-4": ["is_lunch", "is_dinner", "is_side", "is_snack", "is_dessert", "is_shake"],
        "meal-5": ["is_lunch", "is_dinner", "is_side", "is_snack", "is_dessert", "is_shake"]
      },
      add_to_meal_visible: false,
      custom_macros_visible: false,
      custom_macro_targets: null
    };
  },
  computed: {
    selected_macro_profile() {
      return this.macro_profiles.find(profile => profile.id === this.selected_profile_id);
    },
    macro_targets() {
      if (this.selected_profile_id === "custom") {
        return this.custom_macro_targets;
      } else {
        return this.selected_macro_profile.macro_targets
      }
    },
    loading_recipes() {
      return this.$store.state.loading.recipe_match;
    },
    recipes_count() {
      return this.$store.state.recipe_match_results.length;
    },
    selected_filters() {
      return this.$store.getters.selected_filters;
    },
    meal_filters() {
      return this.$store.getters.meal_filters;
    },
    selected_meal_filters() {
      const meal_filter_ids = this.meal_filters.map(({ id }) => id);
      return this.selected_filters.filter(filter => meal_filter_ids.includes(filter.id));
    },
    add_to_meal_id() {
      if (this.selected_profile_id && this.selected_profile_id.match(/meal-\d/)) {
        // ensure we don't attempt to add recipes/foods to invalid meals, i.e. "custom" or "daily_remaining"
        return this.selected_profile_id;
      } else if (this.meal_id) {
        // user opened FillMacros from a meal card and then changed their macro profile selection to
        // custom or daily_remaining => add portions to the originating meal card
        return this.meal_id;
      } else {
        // user opened FillMacros from global nav and then changed their macro profile selection to
        // custom or daily_remaining => add portions to the next empty meal, or the last meal if none are empty
        return this.next_empty_meal || "meal-5";
      }
    }
  },
  watch: {
    macro_targets(new_targets, previous_targets) {
      // wait until user sets new macro_targets to run custom match
      if (this.selected_profile_id === "custom" && !isEqual(new_targets, previous_targets)) {
        this.match();
        this.$refs.foods.build_foods(false, new_targets);
      }
    },
    // make match run in background and update result count when applying filters
    selected_filters(new_filters, previous_filters) {
      if (this.view == "filters") {
        this.match();
      }
    }
  },
  created() {
    if (!localStorage.hasOwnProperty("preserveRecipeSearch")) {
      this.$store.dispatch("select_user_default_filters")
        // select_macro_profile applies profile filters and starts match/build
        .then(() => this.select_macro_profile(this.selected_profile_id));
    }
  },
  destroyed() {
    if (!localStorage.hasOwnProperty("preserveRecipeSearch")) {
      this.$store.dispatch("deselect_all_filters");
    }
  },
  methods: {
    match() {
      const strict_match = ["daily_remaining", "custom"].includes(this.selected_profile_id);
      const params = { macro_targets: this.macro_targets, strict_match };

      this.$store.dispatch("fetch_matching_recipes", params);
    },
    async select_macro_profile(profile_id) {
      // ensure custom macros always default to currently selected macros
      this.custom_macro_targets = this.macro_targets;
      this.selected_profile_id = profile_id;

      // without await, match/build will run before targets & filters computed properties have actually updated
      await this.apply_default_filters_for_profile(profile_id);

      if (profile_id === "custom") {
        this.custom_macros_visible = true;
      } else {
        // this unnecessarily re-runs match when switching between profiles with same targets and filters (e.g. "Snack 2" and "Dinner"), but:
        //  a) users might feel like it's not working without LoadingScreen visual
        //  b) this implementation is simpler and easier to grok than the prior implementation with watchers
        this.match();
        this.$refs.foods.build_foods(false);
      }
    },
    update_custom_macros(custom_macros) {
      this.custom_macro_targets = custom_macros;
      this.custom_macros_visible = false;
    },
    async apply_default_filters_for_profile(profile_id) {
      let filter_names;
      if (["daily_remaining", "custom"].includes(profile_id)) {
        filter_names = this.macro_profile_default_filters[this.meal_id || "meal-5"];
      } else {
        filter_names = this.macro_profile_default_filters[profile_id];
      }

      const to_deselect = this.selected_meal_filters.filter(({ name }) => !filter_names.includes(name)).map(({ id }) => id);
      const to_select = filter_names.map(filter_name => this.meal_filters.find(({ name }) => name === filter_name).id);

      await this.$store.dispatch("deselect_filters", to_deselect);
      return this.$store.dispatch("select_filters", to_select);
    },
    select_filter(filter_id) {
      this.$store.dispatch("select_filter", filter_id);
    },
    deselect_filter(filter_id) {
      this.$store.dispatch("deselect_filter", filter_id);
    },
    add_recipe_to_meal(recipe, meal_id) {
      this.$store.dispatch("rails/log_recipe_portions", { recipe, meal_id });

      this.add_to_meal_visible = false;

      this.$store.dispatch("notify_user", {
        type: "info",
        msg: "Recipe has been successfully added to your food log :)"
      });
      this.$router.push({ path: "/food_log", query: { meal_id } });
    },
    add_foods_to_meal(foods, meal_id) {
      foods.forEach(food => this.$store.dispatch("rails/log_fmf_food_portion", { food, meal_id }));

      this.add_to_meal_visible = false;

      this.$store.dispatch("notify_user", {
        type: "info",
        msg: "Foods have been successfully added to your food log :)"
      });
      this.$router.push({ path: "/food_log", query: { meal_id } });
    },
    open_add_to_meal(recipe) {
      if (this.meal_id && this.selected_profile_id === this.meal_id) {
        // if user opened FillMacros from a meal card and didn't change the macro profile selection
        // assume that's the meal to which they'd like to add this recipe and immediately add it
        this.add_recipe_to_meal(recipe, this.meal_id);
      } else {
        // otherwise open the AddToMealModal
        this.selected_recipe = recipe;
        this.add_to_meal_visible = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "@css/_reset.scss";
  @import "@css/_tracker_reset.scss";

  #fill-macros {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: white;

    .nav-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 58px;
      min-height: 58px;
      background-color: #ffffff;
      &.favorites {
        background-color: #00A881;
        .left-button {
          top: 0px;
          left: 0px;
          i {
            color: #ffffff;
            margin: 0px;
          }
        }
        .title {
          color: #ffffff;
        }
      }

      .left-button, .right-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 58px;
        height: 58px;
        cursor: pointer;
        color: $endurance;
        font-size: 12px;
      }
      .left-button {
        top: 0px;
        left: 12px;
        i {
          margin-right: 8px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
    }

    .tabs-container {
      display: flex;
      flex-direction: row;
      padding: 0px;
      margin: 0px 0px 12px 0px;
      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #E6E6E6;
        width: 100%;
        height: 40px;
        cursor: pointer;
        p {
          font-weight: 600;
          font-size: 14px;
          color: #4f4f4f;
        }
        &.selected {
          border-bottom: 2px solid $endurance;
          p {
            color: $endurance;
          }
        }
      }
    }

    .target-macros-container {
      display: flex;
      flex-direction: row;
      padding: 24px;
      justify-content: space-between;
      width: 100%;
      min-height: 86px;
      //margin: 0px 0px 12px 0px;
      .target-macro {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 38px;
        padding: 0px;
        .label, .value {
          font-weight: bold;
          font-size: 14px;
          color: #4f4f4f;
          margin: 0px;
          padding: 8px 0px !important;
          line-height: 20px !important;
          height: 38px;
        }
        .value {
          width: 100%;
          border-bottom: 2px solid $endurance;
          text-align: center;
          margin: 0px 8px 0px 4px  !important;
        }
      }
    }

    .add-to-meal-match {
      width: 100%;
    }

    .meal-select-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 41px;
      align-items: center;
      transition: visibility 0s, opacity 0.5s linear;
      padding: 0px 24px;
      margin: 0px 0px 24px;
      .meal-select {
        display: inline-block;
        width: 75%;
        margin-top: 8px;
      }
      ::v-deep .selected-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 2px solid $endurance;
        margin: 0px;
        padding: 6px;
        p {
          margin: 0px 8px 0px 0px;
          color: #4f4f4f;
          font-size: 14px;
        }
        i {
          color: $endurance;
          font-size: 8px;
          margin: 0px 0px 0px auto;
        }
      }
      &.collapsed-header {
        .meal-select {
          display: inline-block;
          width: 100%;
          margin-top: 0px;
        }
        ::v-deep .selected-field {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          border-bottom: none;
          margin: 0px;
          padding: 6px;
          p {
            margin: 0px 8px 0px 0px;
            color: #4f4f4f;
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: right;
          }
          i {
            color: #4f4f4f;
            font-size: 8px;
            margin: 0px 0px 0px auto;
          }
        }
        ::v-deep .select-list {
          min-width: 220px;
          right: 0px;
        }
      }
    }

    .search-container {
      display: flex;
      flex-direction: row;
      padding: 16px 24px;
      min-height: 72px;
      .search-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #F0E8FB;
        border-radius: 8px;
        margin: 0px 12px 0px 0px;
        width: 100%;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 100%;
          cursor: pointer;
          i {
            color: $endurance;
            font-size: 12px;
          }
          &.clear-button {
            margin-left: auto;
          }
        }
        input {
          font-size: 16px;
          font-weight: normal;
          color: #4f4f4f;
          background-color: transparent;
          padding: 0px;
          height: 40px !important;
          line-height: 40px;
        }
        input::placeholder {
          color: #4f4f4f;
          font-size: 16px;
          font-weight: normal;
        }
        input:focus {
          outline: none !important;
        }
      }
      .filters-button {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 0px 0px auto;
        i {
          color: $endurance;
          font-size: 14px;
        }
        p {
          font-size: 12px;
          font-weight: 600;
          color: $endurance;
        }
        .active-indicator {
          position: absolute;
          top: 2px;
          right: -1px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #FFCD3C;
          margin-right: 8px;
        }
      }
    }

    .recipe-results {
      padding: 18px 24px 0px 24px;
      width: 100%;
      height: calc(100% - 58px);
      overflow: auto;
    }

    .no-results-warning {
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      line-height: 21px;
      letter-spacing: 0.5px;
      span {
        color: $endurance;
        font-weight: 700;
      }
    }
  }
</style>
