<template lang="html">
  <div class="calendar">
    <datepicker
      :value="date"
      :inline="true"
      @selected="select_date"
    />
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: { Datepicker },
  props: {
    date: { type: Date, required: true },
    select_date: { type: Function, required: true }
  }
};
</script>

<style scoped lang="scss">
::v-deep .vdp-datepicker__calendar {
  height: auto;
  transition: 0.3s all;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(65, 39, 39, 0.15) !important;
  border: 1px solid #f4f4f4;

  .cell.day, div .cell.day {
    width: 42px;
    height: 42px;
  }
  .cell.selected, div .cell.selected {
    background: $endurance !important;
    color: #ffffff !important;
    border-radius: 4px !important;
  }
  .cell.day.selected, div .cell.day.selected {
    border-radius: 50% !important;
  }
  .cell:hover, div .cell:hover {
    border: none !important;
  }
}
</style>
