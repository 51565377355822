<template lang="html">
  <div class="">
    <div class="tabs-container">
      <div :class="{'tab': true, 'selected': selected_tab === 0}" @click="selected_tab = 0">
        <p>Ingredients</p>
        <div class="border"></div>
      </div>
      <div :class="{'tab': true, 'selected': selected_tab === 1}" @click="selected_tab = 1">
        <p>Preparation</p>
        <div class="border"></div>
      </div>
      <div v-if="!recipe.user_id" :class="{'tab': true, 'selected': selected_tab === 2}" @click="selected_tab = 2">
        <p>Notes</p>
        <div class="border"></div>
      </div>
      <div v-else class="tab">
      </div>
    </div>

    <div class="tabs-content">
      <div v-if="selected_tab === 0" class="tab-content">
        <div v-for="child_recipe in children" :key="child_recipe.id">
          <div v-if="child_recipe.foods && child_recipe.foods.length > 0" class="section">
            <div v-if="is_multi_recipe" class="section-header-card" @click="toggleCollapse(0, child_recipe.id)">
              <div class="section-header-card-name">
                <p v-if="is_multi_recipe" v-text="child_recipe.name"></p>
                <i v-if="isCollapsed(0, child_recipe)" class="fa fa-chevron-right"></i>
                <i v-else class="fa fa-chevron-down"></i>
              </div>
              <div class="eat-make">
                <img :src="serving_icon" class="icon" />
                <div class="eat-make-inner">
                  <div v-if="child_recipe.show_make" class="makes-container">
                    <p>Makes</p>
                    <Macros
                      :food="child_recipe"
                      :quantity="child_recipe.makeQuantity"
                      :quantityKind="child_recipe.quantity_kind"
                      :cookLossFactor="child_recipe.cook_loss_factor"
                      :initials="true"
                      :only="'none'"
                      class="amount inline macrostax-font"
                    />
                  </div>
                  <div v-if="child_recipe.show_make" class="separator">|</div>
                  <div class="eat-container has-border">
                    <p>Eat</p>
                    <Macros
                      :food="child_recipe"
                      :gramStep="1"
                      :quantity="child_recipe.eatQuantity"
                      :quantityKind="child_recipe.quantity_kind"
                      :cookLossFactor="child_recipe.cook_loss_factor"
                      :initials="true"
                      :only="'none'"
                      class="amount inline macrostax-font"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isCollapsed(0, child_recipe)" class="section-body">
              <router-link
                v-if="!is_multi_recipe && !recipe.user_id"
                :to="`/custom/recipes/${recipe.id}/customize`"
                class="customize-button"
              >
                <img class="icon" :src="custom_recipes_icon">
                <span>Customize this Recipe</span>
              </router-link>
              <ul class="ingredients-list">
                <li
                  v-for="(ingredient, i) in child_recipe.foods"
                  :key="ingredient.id"
                >
                  <Macros
                    :food="ingredient"
                    :gramStep="1"
                    :proportion="child_recipe.proportion"
                    :quantity="ingredient.quantity"
                    :quantityKind="ingredient.is_seasoning ? 'common' : 'both'"
                    :showKitchenUnmeasureable="!!recipe.user_id"
                    :pluralize="false"
                    class="amount inline"
                    :only="'none'"
                  /> 
                  <p v-text="ingredient.name" class="name"></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selected_tab === 1" class="tab-content">
        <div v-for="child_recipe in children" :key="child_recipe.id">
          <div v-if="child_recipe.prep" class="section">
            <div v-if="is_multi_recipe" class="section-header-card" @click="toggleCollapse(1, child_recipe.id)">
              <div class="section-header-card-name">
                <p v-text="child_recipe.name"></p>
                <i v-if="isCollapsed(1, child_recipe)" class="fa fa-chevron-right"></i>
                <i v-else class="fa fa-chevron-down"></i>
              </div>
              <div class="eat-make">
                <img :src="serving_icon" class="icon" />
                <div class="eat-make-inner">
                  <div v-if="child_recipe.show_make" class="makes-container">
                    <p>Makes</p>
                    <Macros
                      :food="child_recipe"
                      :quantity="child_recipe.makeQuantity"
                      :quantityKind="child_recipe.quantity_kind"
                      :cookLossFactor="child_recipe.cook_loss_factor"
                      :initials="true"
                      :only="'none'"
                      class="amount inline macrostax-font"
                    />
                  </div>
                  <div v-if="child_recipe.show_make" class="separator">|</div>
                  <div class="eat-container has-border">
                    <p>Eat</p>
                    <Macros
                      :food="child_recipe"
                      :gramStep="1"
                      :quantity="child_recipe.eatQuantity"
                      :quantityKind="child_recipe.quantity_kind"
                      :cookLossFactor="child_recipe.cook_loss_factor"
                      :initials="true"
                      :only="'none'"
                      class="amount inline macrostax-font"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isCollapsed(1, child_recipe)" class="section-body">
              <VueMarkdown :source="child_recipe.prep" :postrender="sanitize" class="preparation" />
            </div>
          </div>
        </div>

        <div v-if="is_multi_recipe && recipe.prep">
          <p class="final-steps-header">Final Steps</p>
          <div class="final-steps">
            <VueMarkdown :source="recipe.prep" :postrender="sanitize" />
          </div>
        </div>
      </div>

      <div v-if="selected_tab === 2" class="tab-content">
        <VueMarkdown
          v-if="is_multi_recipe && recipe.notes"
          :source="recipe.notes"
          :postrender="sanitize"
          class="section multimeal-notes"
        />

        <div v-for="child_recipe in children" :key="child_recipe.id">
          <div v-if="child_recipe.notes" class="section">
            <div v-if="is_multi_recipe" class="section-header-card" @click="toggleCollapse(2, child_recipe.id)">
              <div class="section-header-card-name">
                <p v-text="child_recipe.name"></p>
                <i v-if="isCollapsed(2, child_recipe)" class="fa fa-chevron-right"></i>
                <i v-else class="fa fa-chevron-down"></i>
              </div>
            </div>

            <div v-if="!isCollapsed(2, child_recipe)" class="section-body">
              <VueMarkdown
                :source="child_recipe.notes"
                :postrender="sanitize"
                class="notes"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import VueMarkdown from "vue-markdown";

import Macros from "@js/shared/macros.vue";

export default {
  components: { VueMarkdown, Macros },
  props: {
    recipe: {type: Object, required: true},
    is_multi_recipe: {type: Boolean, required: true},
    children: {type: Array, required: true},
    sanitize: {type: Function, required: true},
  },
  data() {
    return {
      collapseCutoff: 3,
      expandedSectionsIngredients: [],
      expandedSectionsPreparation: [],
      expandedSectionsNotes: this.children.map(child => child.id),
      selected_tab: 0,
      serving_icon: "https://d38il9bndsnkm4.cloudfront.net/assets/images/icons/serving.svg",
      custom_recipes_icon: "https://d38il9bndsnkm4.cloudfront.net/assets/images/icons/chef-hat-purple.svg"
    };
  },
  methods: {
    toggleCollapse(tab, id) {
      let expandedSections = [];
      if (tab === 0) {
        this.expandedSectionsIngredients = this.toggleCollapseSection(this.expandedSectionsIngredients, id);
      } else if (tab === 1) {
        this.expandedSectionsPreparation = this.toggleCollapseSection(this.expandedSectionsPreparation, id);
      } else {
        this.expandedSectionsNotes = this.toggleCollapseSection(this.expandedSectionsNotes, id);
      }
    },
    toggleCollapseSection(expandedSections, id) {
      if (expandedSections.includes(id)) {
        return _.without(expandedSections, id);
      } else {
        return expandedSections.concat([id]);
      }
    },
    isCollapsed(tab, child_recipe) {
      let expandedSections = [];
      if (tab === 0) {
        expandedSections = this.expandedSectionsIngredients;
      } else if (tab === 1) {
        expandedSections = this.expandedSectionsPreparation;
      } else {
        expandedSections = this.expandedSectionsNotes;
      }

      return this.isCollapsible(child_recipe) && !expandedSections.includes(child_recipe.id);
    },
    isCollapsible(child_recipe) {
      return this.children.length > 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@css/_tracker_reset.scss";

$side-padding: 0.8rem;
$list-item-line-height: 1rem;
$bullet-color: lighten($muted_blue, 10%);

.customize-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $endurance;
  margin-top: 0px;
  margin-bottom: 18px;
  cursor: pointer;

  .icon {
    margin-right: 14px;
  }
}

.tabs-container {
  display: flex;
  flex-direction: row;
  padding: 14px 24px;
  justify-content: space-between;
  .tab {
    display: flex;
    flex-direction: column;
    padding: 8px;
    cursor: pointer;
    min-width: 60px;
    p {
      color: #4f4f4f;
      font-weight: 700;
      font-size: 15px;
      margin: 0px 0px 4px 0px;
    }
    .border {
      display: none;
      background-color: $energy;
      width: 40px;
      height: 4px;
    }
    &.selected {
      p {
        color: $energy;
      }
      .border {
        display: block;
      }
    }
  }
}

.tabs-content {
  padding: 8px 24px 14px 24px;
  .tab-content {
    padding: 0px;
    .section {
      padding: 0px;
      margin: 0px 0px 20px 0px;
      &.multimeal-notes {
        margin: 0px 0px 30px 0px;
      }
      .section-header-card {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #e2e2e2;
        padding: 0px 0px 18px 0px;
        cursor: pointer;
        .section-header-card-name {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 12px;
          p {
            color: $energy;
            font-family: Montserrat;
            font-size: 17px;
            font-weight: 700;
            margin: 0px 12px 0px 0px;
          }
          i {
            color: $energy;
            font-size: 15px;
            font-weight: 700;
            margin-left: auto;
          }
        }
        .eat-make {
          display: flex;
          flex-direction: row;
          align-items: center;
          .icon {
            width: 22px;
            height: 22px;
            margin-right: 12px;
          }
          .eat-make-inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .separator {
              margin: 0px 3px;
            }
            .makes-container, .eat-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 4px 0px;
              &.has-border {
                border-bottom: 2px solid $movement;
              }
              p {
                font-size: 13px;
                font-style: italic;
                font-weight: 500;
                margin: 0px 6px 0px 0px;
              }
              .amount {
                font-size: 12px !important;
                font-style: normal !important;
                font-weight: 600 !important;
              }
            }
          }
        }
      }
      .section-body {
        padding: 0px;
      }
    }
    ::v-deep ul, ::v-deep ol {
      li {
        color: #4f4f4f;
        font-size: 14px;
        font-weight: 500;
        margin: 0px 0px 18px 0px;
        .amount {
          font-size: 14px;
          font-weight: 400;
          margin: 0px 0px 6px 0px;
        }
        .name {
          font-size: 14px;
          font-weight: 600;
          margin: 0px;
        }
      }
      li:last-child {
        margin: 0px;
      }
    }
    ::v-deep ul {
      list-style: none;
      padding-left: 25px;
      li::before {
        position: absolute;
        content: "\2022";
        color: #00A881;
        font-weight: bold;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 20px;
        height: auto;
        margin-left: -25px;
        margin-top: -9px;
        font-size: 25px;
      }
    }
    ::v-deep ol {
      padding-left: 25px;
      li::before {
        position: absolute;
        color: #00A881;
        font-weight: bold;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 20px;
        height: auto;
        margin-left: -25px;
        margin-top: -9px;
        font-size: 25px;
      }
    }
    .final-steps-header {
      color: $movement;
      font-size: 17px;
      font-weight: 700;
      margin: 22px 0px 16px 0px;
    }
    .final-steps {
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 500;
      margin: 0px 0px 18px 0px;
    }
  }
}

// markdown formatting
::v-deep blockquote {
  border-left: 5px solid #eee;
  padding: 10px 20px;
}
::v-deep pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;

  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}
::v-deep code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
</style>
