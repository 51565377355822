<template>
  <div v-show="isVisible" id="add-to-homescreen-banner">
    <div class="content">
      <p>Want to add Macrostax to your homescreen?</p>
      <div class="choices">
        <p class="chose-no" @click="dismiss">No, don't ask me again.</p>
        <p class="chose-yes" @click="showInstructions">Yeah! Show me how!</p>
      </div>
    </div>
    <i class="close icon-close-icon-x" @click="isVisible = false" />
  </div>
</template>

<script>
// REVIEW: this very old, very unmaintained library sucks - find a better way to do this
// (maybe start with is_mobile_browser func in lib/utils.js)
import "@js/lib/addtohomescreen";

import iosExportIcon from "@images/ios-export-btn.svg";

export default {
  data() {
    return {
      isVisible: false,
      iosExportIcon
    };
  },
  created() {
    const step1 = `<li>First, tap the <img src="${this.iosExportIcon}" class="ios-export"></img> button, then tap "Add to Home Screen"</li>`;
    const step2 = `<li>On the following screen tap "Add"</li>`;
    const message = `<ol>${step1}${step2}</ol>`;

    window.addToHomescreen({
      modal: true,
      maxDisplayCount: 0,
      icon: false,
      lifespan: 0,
      displayPace: 1440,
      message,
      startDelay: 5,
      onShow: () => { this.isVisible = addToHomescreen.OS === "ios" }
      // REVIEW: maybe only update user preferences if they *finish* adding the PWA to their homescreen?
      // onAdd: async () => this.dismiss()
    });
  },
  methods: {
    showInstructions() {
      const athModal = document.querySelector(".ath-modal");
      athModal.style.display = "block";
      this.dismiss();
    },
    async dismiss() {
      this.isVisible = false;

      try {
        await this.$store.dispatch("rails/update_user_preferences", { show_ath: false });
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>

<style lang="scss">
#add-to-homescreen-banner {
  position: relative;
  display: flex;
  height: 65px;
  width: 100%;
  background-color: $accent-darker;
  align-items: center;
  justify-content: space-between;
  padding-left: 14px;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: $white;

  .content {
    width: 100%;
    padding-right: 14px;
    position: relative;
    margin: 0 auto;

    & > p {
      margin: 10px 0;
    }
  }

  .choices {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chose-yes,
    .chose-no {
      letter-spacing: 0;
      font-weight: bold;
      text-decoration: underline;
      color: $white;
    }
  }

  .close {
    color: $white;
    font-size: 14px;
    padding: 8px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.ath-modal * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ath-modal {
  display: none;
  position: fixed;
  z-index: 1338;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  text-size-adjust: none;
  background: rgba(41,62,107,0.5)
}

.ath-modal {
    pointer-events: auto !important;
    background: rgba(0,0,0,0.6);
}

.ath-mandatory {
    background: #000;
}

.ath-container {
  pointer-events: auto !important;
  position: absolute;
  z-index: 3;
  padding: 0.7em 0.6em;
  width: 18em;
  background: $white;
  background-size: 100% auto;
  font-size: 15px;
  line-height: 1.5em;
  border-radius: 22px;
  color: $secondary;

  img.ios-export {
    height: 19px;
    width: 17px;
  }
}

.ath-container small {
  font-size: 0.8em;
  line-height: 1.3em;
  display: block;
  margin-top: 0.5em;
}

.ath-ios.ath-phone {
  bottom: 1.8em;
  left: 50%;
  margin-left: -9em;
}

.ath-ios6.ath-tablet {
  left: 5em;
  top: 1.8em;
}

.ath-ios7.ath-tablet {
  left: 0.7em;
  top: 1.8em;
}

.ath-ios8.ath-tablet {
  right: 0.4em;
  top: 1.8em;
}

.ath-android {
  bottom: 1.8em;
  left: 50%;
  margin-left: -9em;
}

/* close icon */
.ath-container:before {}

.ath-container.ath-icon:before {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  float: none;
}

.ath-mandatory .ath-container:before {
  display: none;
}

.ath-container.ath-android:before {
  float: left;
  margin: -0.7em 0.5em 0 -0.6em;
}

.ath-container.ath-android.ath-icon:before {
  position: absolute;
  right: auto;
  left: 0;
  margin: 0;
  float: none;
}

/* applied only if the application icon is shown */
.ath-container p {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 4;
  font-size: 1.1em;
  color: $secondary;
}

.ath-ios.ath-phone:after {
  content: '';
  background: $white;
  position: absolute;
  width: 2em;
  height: 2em;
  bottom: -0.9em;
  left: 50%;
  margin-left: -1em;
  -webkit-transform: scaleX(0.9) rotate(45deg);
  transform: scaleX(0.9) rotate(45deg);
}

.ath-ios.ath-tablet:after {
  content: '';
  background: $white;
  position: absolute;
  width: 2em;
  height: 2em;
  top: -0.9em;
  left: 50%;
  margin-left: -1em;
  -webkit-transform: scaleX(0.9) rotate(45deg);
  transform: scaleX(0.9) rotate(45deg);
  z-index: 2147483641;
}

.ath-application-icon {
  position: relative;
  padding: 0;
  border: 0;
  margin: 0 auto 0.2em auto;
  height: 6em;
  width: 6em;
  z-index: 2147483642;
}

.ath-container.ath-ios .ath-application-icon {
  border-radius: 1em;
  box-shadow: 0 0.2em 0.4em rgba(0,0,0,0.3),
      inset 0 0.07em 0 rgba(255,255,255,0.5);
  margin: 0 auto 0.4em auto;
}

@media only screen and (orientation: landscape) {
  .ath-container.ath-phone {
    width: 24em;
  }

  .ath-android.ath-phone {
    margin-left: -12em;
  }

  .ath-ios.ath-phone {
    margin-left: -12em;
  }

  .ath-ios6:after {
    left: 39%;
  }

  .ath-ios8.ath-phone {
    left: auto;
    bottom: auto;
    right: 0.4em;
    top: 1.8em;
  }

  .ath-ios8.ath-phone:after {
    bottom: auto;
    top: -0.9em;
    left: 68%;
    z-index: 2147483641;
    box-shadow: none;
  }
}
</style>
