<template lang="html">
  <div class='meal-copy'>
    <p>
      <button @click.stop="copy_meal">
        <p>
          <img :src="copy_meal_icon"/>
          <span>Copy Yesterday's Meal</span>
        </p>
      </button>
    </p>
  </div>
</template>

<script>
import { reloadPage } from "@js/router";

import copy_meal_icon from "@images/copy-meal-purple.svg";

export default {
  props: {
    meal: { type: Object, required: true }
  },
  data() {
    return {
      copy_meal_icon
    }
  },
  computed: {
    today() {
      return this.$store.state.rails.selected_date;
    }
  },
  methods: {
    async copy_meal() {
      const { identifier, meal_type } = this.meal;
      const yesterday = moment(this.today).subtract(1, "day").format("YYYY-MM-DD");

      try {
        await this.$store.dispatch("rails/copy_meal", {
          source_date: yesterday,
          target_date: this.today,
          source_meal_id: identifier,
          target_meal_id: identifier,
          target_meal_type: meal_type
        });
      } catch (error_response) {
        if (error_response.status == 404) {
          this.$store.dispatch("showMessageModal", {
            text: "Whoops! It looks like the selected meal is empty!"
          });
        } else {
          this.$store.dispatch("notify_user", {
            type: "error",
            msg: "Whoops! Something went wrong copying that meal!"
          });
          console.error(error_response);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .meal-copy {
    font-size: 12px;
    padding-top: 8px;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    button {
      border: none;
      padding: 0px;
      background: none;
      p {
        color: #6B40AB;
        background: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          height: 14px;
          width: 14px;
        }
        span {
          padding-left: 8px;
        }
      }
    }
  }
</style>
