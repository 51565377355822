<template lang="html">
  <div @click="viewRecipe(recipe.id)" class="featured-recipe">
    <div class="recipe-photo">
      <img :src="recipe.photo_urls.small" />
    </div>
    <div class="recipe-name">
      <p ref="recipe_name" v-text="recipe.name" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recipe: {type: Object, required: true},
    viewRecipe: { type: Function, required: true }
  }
}
</script>

<style scoped lang="scss">
.featured-recipe {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 24px;
  min-width: 80px;
  max-width: 80px;
  white-space: normal;
  cursor: pointer;

  .recipe-photo {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 4px;

    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }

  .recipe-name {
    display: flex;
    flex-direction: column;

    p {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      color: #000000;
      text-align: left;
      width: 100%;
      overflow: hidden;
      margin: 0px;
    }

    .overflow-ellipsis {
      margin: 0px;
      padding: 0px 0px 3px 0px;
      height: 10px;
      line-height: 10px;
    }
  }
}
</style>
