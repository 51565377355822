import fat_loss_icon from "@images/weight-loss.svg";
import gain_icon from "@images/gain.svg";
import maintenance_icon from "@images/maintain.svg";

// TODO load these into Gon server-side and hydrate store, appending descriptions as needed in Vue computed props
// NOTE: 'goal' values directly map to statistic_sets.goal DB column values
const programs = [
  {
    goal: "fat loss",
    label: "Fat Loss",
    icon: fat_loss_icon,
    phases: [
      { id: "cut_1", label: "Phase 1" },
      { id: "cut_2", label: "Phase 2" },
      { id: "cut_3", label: "Phase 3" }
    ],
    description: "Select Fat Loss if you have a goal of losing weight, getting leaner, or decreasing body fat",
    card_description: "You have a goal of losing weight, getting leaner, or decreasing body fat"
  },
  {
    goal: "weight gain",
    label: "Muscle Gain",
    icon: gain_icon,
    phases: [
      { id: "gain_1", label: "Phase 1" },
      { id: "gain_2", label: "Phase 2" },
      { id: "gain_3", label: "Phase 3" }
    ],
    description: "Select Muscle Gain if you have a goal of gaining weight, building muscle, or increasing size",
    card_description: "You have a goal of gaining weight, building muscle, or increasing size"
  },
  {
    goal: "maintain",
    label: "Maintenance",
    icon: maintenance_icon,
    phases: [
      { id: "maint_1", label: "Phase 1" },
      { id: "maint_2", label: "Phase 2" },
      { id: "maint_3", label: "Phase 3" },
      { id: "maint_4", label: "Phase 4" }
    ],
    description: "Select Maintenance if you have a goal of maintaining your current size and weight while eating more balanced for fueling performance",
    card_description: "You have a goal of maintaining your current size and weight while eating more balanced for fueling performance"
  }
];

export default programs;
