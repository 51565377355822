import gql from "graphql-tag";
// inverse_name
// kind
// group
// derived

export default gql`
  query Tags {
    tags {
      id
      name
      type
      display_name
      inverse_name
    }
  }
`;
