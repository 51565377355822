<template lang="html">
  <div id="save-food">
    <img :src="confetti_icon" class="confetti" />
    <div class="icon-row">
      <img :src="carrot_icon" class="large-icon" />
      <p class="large-icon-title">Woohoo!</p>
      <p class="large-icon-description">Your food has been saved.<br>Keep it up!</p>
    </div>
    <div class="button-row save centered">
      <div class="save-button" @click="go_to_inputs">VIEW PROGRESS</div>
    </div>
  </div>
</template>

<script>
import confetti_icon from "@images/confetti.svg";
import carrot_icon from "@images/carrot.png";

export default {
  props: {
    close: { type: Function, default: () => {} }
  },
  data() {
    return {
      carrot_icon,
      confetti_icon
    };
  },
  methods: {
    go_to_inputs() {
      window.location.href = "/inputs";
    }
  }
};
</script>

<style scoped lang="scss">
#save-food {
  position: relative;
}

.icon-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  .large-icon {
    align-self: center;
    height: 150px;
    margin-bottom: 16px;
  }

  .large-icon-title {
    font-family: 'open sans';
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    color: #000000;
    margin: 0px 0px 12px 0px;
  }

  .large-icon-description {
    font-family: 'open sans';
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #4f4f4f;
    margin: 0px;
    line-height: 20px;
  }
}

.confetti {
  background-color: rgba(255, 255, 255, 0) !important;
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
</style>
