<template lang="html">
  <div class="mtc-header">
    <div class="mtc-col">
      <div class="start-date">{{start_date}}</div>
      <div class="title">Macrostax</div>
      <div class="title">Transformation </div>
      <div class="title">Challenge</div>
    </div>
    <div class="award-col">
      <img src="https://sfo2.digitaloceanspaces.com/icons/mtc-award.svg"/>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    start_date() {
      return moment(this.$store.state.rails.mtc_challenge.start_date).format("MMMM D, YYYY").toUpperCase();
    }
  }
};
</script>

<style scoped lang="scss">
  .mtc-header {
    display: flex;
    flex-direction: row;
    margin-top: 31px;

    .mtc-col {
      display: flex;
      flex-direction: column;
      width: 60%;

      .start-date {
        color:#000000;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px
      }

      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 17px;
        letter-spacing: -0.25px;
        margin-bottom: 15px;
      }
    }

    .award-col {
      width: 40%;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      img {
        margin-top: 10px;
        height: 60px;
        width: 60px;
      }
    }
  }
</style>
