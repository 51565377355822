<template lang="html">
  <!-- conditionally render so if this route is loaded directly, computed props won't break
   before data has been fetched and loaded into store -->
  <div v-if="mtc_challenge" class="container">
    <div class="signup">
      <div class="back-icon" @click="$router.push('/food_log')">
        <img src="https://sfo2.digitaloceanspaces.com/icons/mtc-back.svg" class="mtc-back-button" alt="Back" />
      </div>

      <Header />

      <Countdown />

      <div v-if="last_chance" class="still-time-label">
        <p>IT’S NOT TOO LATE!</p>
      </div>

      <div class="info-text">
        <p>Join our 2nd annual Macrostax Transformation Challenge! Win up to $5,000 in cash and prizes AND reach your goals during this 8-week nutrition challenge, FREE for Macrostax subscribers! Just enter below!</p>
      </div>

      <div class="mtc-faq">
        <a href="https://www.macrostax.com/mtc">Challenge FAQ</a>
      </div>

      <div class="info-text">
        <p>Setting goals is always a great way to set yourself up for success. What do you want to achieve during this challenge?</p>
      </div>

      <GoalsForm :submit_action="signup" submit_text="Join Now" />
    </div>
  </div>
</template>

<script>
import moment from "moment";

import API from '@js/api/rails_client';

import Countdown from '@js/food_log/mtc/shared/countdown.vue';
import Header from '@js/food_log/mtc/shared/header.vue';
import GoalsForm from '@js/food_log/mtc/shared/goals_form.vue';

export default {
  components: { Countdown, Header, GoalsForm},
  computed: {
    mtc_challenge() {
      return this.$store.state.rails.mtc_challenge;
    },
    last_chance() {
      const { start_date, signup_deadline } = this.mtc_challenge;
      return moment().isBetween(start_date, signup_deadline);
    }
  },
  methods: {
    async signup(goals) {
      try {
        this.$store.dispatch("loading", true);

        await this.$store.dispatch("rails/enroll_in_mtc_challenge", goals);

        this.$store.dispatch("showMessageModal", {
          text: "Thanks for joining Macrostax Transformation Challenge!",
          icon_url: "https://sfo2.digitaloceanspaces.com/icons/mtc-award.svg",
          callback: () => this.$router.push('/food_log')
        });
      } catch (e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to join Challenge" });
        console.error(e);
      } finally {
        this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .container {
    background: white;
    overflow: scroll !important;
    margin-bottom: 64px;

    .signup {
      margin-left: 25px;
      margin-right: 25px;
      margin-top: 25px;
      // until we get fabs into parent vue app
      // iphone was showing fabs above mtc pages
      z-index: 20;
      position: relative;

      .back-icon {
        margin-top: 25px;
        width: 25%;
      }

      .info-text {

        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          margin-top: 24px;
          letter-spacing: 0.5px;
          color: #4F4F4F;
        }
      }

      .still-time-label {
        display: flex;

        p {
          background: linear-gradient(0.50turn, white 70%, #34B6E4 50% 100%);
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 0px;
          margin-top: 24px;
          color: #000000;
          padding-right: 10px;
        }
      }

      .mtc-faq {
        margin-top: 10px;
        margin-bottom:45px;

        a {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #6B40AB;
        }
      }
    }
  }
</style>
