<template lang="html">
  <div class="profile-container">
    <div class="nav">
      <div class="back-button" @click="$router.back()">
        <i class="fa fa-chevron-left"></i>
        <p>Summary</p>
      </div>
      <p class="title">Goal</p>
    </div>

    <ActivityTooltip
      v-if="tooltip"
      :type="tooltip"
      :goal="tooltip_goal || current_goal"
      :phase="tooltip_phase || current_phase"
      :action="tooltip_action"
      :close="close_tooltip"
    />

    <div class="content">
      <p class="page-header">Please select your goal below</p>
      <p class="page-info">
        The goal you select will help us recommend custom macro numbers to get the best results
      </p>
      <div class="radios">
        <div
          v-for="program in programs"
          class="radio"
          :class="{ active: current_goal === program.goal }"
          @click="update_program(program.goal, program.phases[0])"
        >
          <div class="indicator">
            <div class="outer">
              <div class="inner"></div>
            </div>
          </div>
          <div class="info">
            <div class="row">
              <div class="phase">
                {{ program.label }}
                <span v-if="current_goal === program.goal"> | Phase {{ phase_number }}</span>
              </div>
            </div>
            <div v-if="current_goal === program.goal" class="selector">
              <div
                v-for="(phase, i) in program.phases"
                class="option"
                @click.stop="open_tooltip('phase_change', phase.id)"
              >
                <div class="line">
                  <div
                    class="fill"
                    :style="{width: phase.id === current_phase ? '50%' : i + 1 < phase_number ? '100%' : '0%'}"
                  />
                </div>
                <div class="circle" :style="{opacity: phase.id === current_phase ? 1 : 0}"></div>
                <p :style="{color: phase.id === current_phase ? '#6B40AB' : '#4f4f4f'}">
                  {{ phase.label }}
                </p>
              </div>
            </div>
            <p class="description">{{ program.description }}</p>
            <div class="button" @click.stop="open_tooltip('program_info', program.goal)">
              LEARN MORE
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityTooltip from "@js/shared/activity_tooltip.vue";
import updateIntercom from "@js/lib/intercom";
import programs from "@js/profile/programs";

export default {
  components: { ActivityTooltip },
  data() {
    return {
      programs,
      tooltip: null,
      tooltip_goal: null,
      tooltip_phase: null,
      tooltip_action: null
    };
  },
  computed: {
    current_goal() {
      return this.$store.state.rails.statistic_set.goal;
    },
    current_phase() {
      return this.$store.state.rails.meal_planner_settings.phase;
    },
    phase_number() {
      return this.current_phase.slice(-1);
    }
  },
  methods: {
    async update_program(goal, phase) {
      if (goal !== this.current_goal) {
        try {
          this.$store.dispatch("loading", true);

          const statistic_set = await this.$store.dispatch("rails/update_user_statistic_set", { goal });
          const now = moment().unix();
          const g = programs.find(p => p.goal === goal);
          updateIntercom({ goal_updated_at: now, goal: g.label, phase_updated_at: now, mkt_phase: phase.id, phase: phase.label });

          this.$store.dispatch("notify_user", { type: "info", msg: "Profile successfully updated :)" });
        } catch (e) {
          this.$store.dispatch("notify_user", { type: "error", msg: "Unable to update profile." });
          console.error(e);
        } finally {
          this.$store.dispatch("loading", false);
        }
      }
    },
    async update_phase() {
      try {
        this.$store.dispatch("loading", true);
        await this.$store.dispatch("rails/update_meal_planner_settings", { phase: this.tooltip_phase });
        const g = programs.find(p => p.goal === this.current_goal);
        updateIntercom({ phase_updated_at: moment().unix(), mkt_phase: this.tooltip_phase, goal: g.label, phase: g.phases.find(p => p.id === this.tooltip_phase).label });

        this.$store.dispatch("notify_user", { type: "info", msg: "Profile successfully updated :)" });
      } catch (e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to update profile." });
        console.error("Unable to update meal planner settings.\n\n", e);
      } finally {
        this.$store.dispatch("loading", false);
        this.close_tooltip();
      }
    },
    open_tooltip(type, phase_or_goal) {
      if (type === "phase_change") {
        this.tooltip_action = this.update_phase;
        this.tooltip_phase = phase_or_goal;
        this.tooltip_goal = null;

      } else {
        this.tooltip_action = null;
        this.tooltip_phase = null;
        this.tooltip_goal = phase_or_goal;
      }
      this.tooltip = type;
    },
    close_tooltip() {
      this.tooltip = null;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/components/profile";

.content {
  padding-top: 30px;
}

.radios {
  flex-direction: column;
  padding-top: 20px;

  .radio {
    margin-bottom: 24px;
    margin-right: 16px;
    align-items: flex-start;

    .description {
      margin-top: 16px;
    }

    .indicator {
      margin-top: 6px;
    }

    &.active .phase {
      background-color: $endurance;
      color: #ffffff;

      span {
        color: #ffffff;
      }
    }

    .row {
      display: flex;
    }

    .phase {
      background-color: #ececec;
      border-radius: 8px;
      padding: 6px 8px;
      font-size: 11px;
      font-weight: 700;
      color: #4f4f4f;
      margin-right: 12px;
      text-transform: uppercase;

      span {
        font-size: 9px;
        text-transform: uppercase;
      }
    }

    .selector {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 26px;

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        cursor: pointer;

        &:first-child .line {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        &:last-child .line {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }

        .line {
          width: 100%;
          height: 4px;
          background-color: #e2e2e2;
          overflow: hidden;

          .fill {
            height: 100%;
            background-color: $endurance;
          }
        }

        .circle {
          width: 14px;
          height: 14px;
          border: 2px solid $endurance;
          background-color: #ffffff;
          border-radius: 50%;
          margin-top: -9px;
        }

        p {
          color: $endurance;
          font-size: 12px;
          font-weight: 700;
          margin: 8px 0px 0px 0px;
        }
      }
    }

    .button {
      align-self: flex-end;
    }
  }
}
</style>
