<template>
  <div class="search-list">
    <div class="search-list--header">
      {{search_list_heading}}
    </div>
    <div v-if="search_results.length === 0" class="search-list--no-result">
      No results found!
    </div>
    <SearchResult
        v-for="item in results"
        :key="item.id"
        :item="item"
        :search_term="search_term"
        :show_macros="show_macros && !!item.conversion"
        :select_item="() => select_item(item)"
    />
    <div 
      class="search-list--expand"
      v-if="add_list_length !== 0 && list_length < search_results.length"
      @click="extend_list_length"
    >
      <div>
        Show More <span class="capitalize">{{search_list_heading}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import SearchResult from "@js/shared/search/search_result.vue";

export default {
  components: { SearchResult },
  props: {
    search_term: { type: String, required: true },
    search_list_heading: {type: String, required: true},
    search_results: { type: Array, required: true },
    show_macros: {type: Boolean, required: true },
    starting_list_length: {type: Number, required: true },
    add_list_length: {type: Number, required: true },
    add_to_recipe: { type: Function },
    meal_id: { type: String},
    select_item: {type: Function}
  },
  data: function () {
    return { list_length: this.starting_list_length === 0 
             ? this.search_results.length
             : this.starting_list_length
           };
  },
  computed: {
    results () {
      return this.search_results.slice(0, this.list_length);
    }
  },
  methods: {
    extend_list_length () { 
      this.list_length += this.add_list_length
    }
  }
};
</script>

<style scoped lang="scss">
  .search-list {
  
    &--header {
      padding: 12px;
      text-transform: capitalize;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.5px;

    }

    &--no-result {
      text-align: center;
      height: 50px;
      line-height: 50px;
    }
    
    &--expand {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      
      div {
          padding: 4px 8px;
          background: #EDE7F5;
          border-radius: 8px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #6B40AB;
      }

      .capitalize {
          text-transform: capitalize;
      }
    }
  }
</style>
