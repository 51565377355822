<template>
  <div id="recipes">
    <LoadingScreen
      v-if="initial_load"
      :icon="'carrot_search'"
      :title="'On the Lookout!'"
      :description="'We\'re finding recipes that we think you\'ll like.'"
    />
    <div v-else class="results-screen">
      <div :ref="'sub_header'" class="sub-header">
        <div :class="{ 'search-row': true }">
          <div class="search-container">
            <template v-if="loading">
              <Spinner
                :radius="10"
                :stroke="2"
                :logo="false"
                :forward="true"
                :loop="true"
                :start_color="'#6b40ab'"
                :end_color="'#6b40ab'"
              />
            </template>
            <div v-else class="icon">
              <i class="fa fa-search fa-sm" />
            </div>
            <input
              v-bind:value="search_term"
              v-on:input="search_term = $event.target.value"
              placeholder="Search for recipes"
              type="text"
            >
          </div>

          <div class="filter-button" @click="filters_visible = true">
            <i class="fa fa-filter fa-sm icon" />
            <div v-if="selected_filters.length > 0" class="indicator"></div>
            <p>Filters</p>
          </div>

          <router-link to="/custom/recipes" tag="div" class="custom-recipes-button">
            <img class="icon" :src="custom_recipes_icon" />
            <p>Custom</p>
          </router-link>

          <router-link to="/recipes/favorites" class="favs-button">
            <i class="fa fa-heart fa-sm icon" />
            <div v-if="favorites.length > 0" class="indicator"></div>
            <p>Favs</p>
          </router-link>
        </div>
      </div>
      <template>
        <div :ref="'recipes-container'" class="recipes-container">
          <div class="results-header">
            <p v-if="!featured_collapsed" style="margin-right: auto;">Featured Recipes</p>
            <p v-else style="margin-right: auto;">{{ search_results.length }} Recipe Results</p>
          </div>

          <div v-scroll="on_scroll" :ref="'recipes'" class="recipe-list">
            <div v-if="search_term.length === 0" class="featured-horizontal">
              <div class="featured-horizontal-content">
                <div class="featured-horizontal-content-inner">
                  <FeaturedRecipe
                    v-for="recipe in featured_recipes"
                    :key="recipe.id"
                    :recipe="recipe"
                    :viewRecipe="viewRecipe"
                  />
                </div>
              </div>
            </div>

            <RecipeCard
              v-for="(recipe,index) in search_results"
              :key="recipe.id"
              :recipe="recipe"
              :selected_filters="selected_filters"
              :viewRecipe="viewRecipe"
              :class="{single: false, first: index === 0, last: index+1 === search_results.length}"
              @quick_add="open_add_to_meal($event)"
            />
          </div>
        </div>

        <div class="scroll-to-top" @click="scroll_to_top">
          <i class="fa fa-chevron-up fa-lg" />
        </div>
      </template>
    </div>

    <Filters
      v-if="filters_visible"
      :loading="loading"
      :results_count="search_results.length"
      :context="'search'"
      :close="() => { filters_visible = false }"
    />

    <div v-if="view === 'favorites'" class="quick-add-container">
      <div class="nav-bar favorites">
        <router-link to="/recipes" class="left-button">
          <i class="fa fa-chevron-left fa-lg" />
        </router-link>
        <div class="title">
          Favorite Recipes
        </div>
      </div>

      <div class="quick-add-results favorites">
        <RecipeCard
          v-for="(recipe, index) in favorites"
          :key="recipe.id"
          :recipe="recipe"
          :viewRecipe="viewRecipe"
          :class="{single: false, first: index === 0, last: index+1 === favorites.length}"
          @quick_add="open_add_to_meal($event)"
        />
      </div>
    </div>

    <AddToMealModal
      v-if="add_to_meal_visible"
      :recipe="recipe_to_add"
      :add_to_meal="add_recipe_to_meal"
      :close="() => { add_to_meal_visible = false }"
    />
  </div>
</template>

<script>
import _ from "lodash";

import recipe_details_query from "@js/api/graphql/recipe_details_query";

import Spinner from "@js/recipes/spinner.vue";
import LoadingScreen from "@js/recipes/loading_screen.vue";

import FeaturedRecipe from "@js/recipes/featured_recipe.vue";
import RecipeCard from "@js/recipes/recipe_card.vue";
import Filters from "@js/recipes/filters.vue";
import AddToMealModal from "@js/recipes/add_to_meal_modal.vue";

export default {
  components: { FeaturedRecipe, RecipeCard, Spinner, Filters, AddToMealModal, LoadingScreen },
  props: {
    view: { type: String, default: "search" }
  },
  data() {
    return {
      search_term: localStorage.preserveRecipeSearch || "",
      recipe_to_add: null,
      applying_initial_filters: true,
      filters_visible: false,
      add_to_meal_visible: false,
      scroll_position: 0,
      initial_load: !localStorage.hasOwnProperty("preserveRecipeSearch"),
      custom_recipes_icon: "https://d38il9bndsnkm4.cloudfront.net/assets/images/icons/chef-hat-white.svg"
    };
  },
  computed: {
    normalized_search_term() {
      // NOTE: when we changed v-model.trim="search_term" to v-bind:value + v-on:input to fix the Android debounce bug,
      // apparently $event.target.value.trim() isn't a 1-to-1 replacement. It seems like the former
      // tracks something about Vue component state to prevent an intentional whitespace from being removed upon
      // rapidly typing a character followed by a whitespace when the updated search_term triggers
      // some (but not all) types of secondary state updates (?).
      return this.search_term.trim();
    },
    selected_filters() {
      return this.$store.getters.selected_filters;
    },
    search_results() {
      return this.$store.state.recipe_search_results;
    },
    featured_recipes() {
      return this.$store.state.featured_recipes;
    },
    favorites() {
      return this.$store.state.favorite_recipes;
    },
    loading() {
      return this.$store.state.loading.recipe_search;
    },
    featured_collapsed() {
      return this.normalized_search_term || this.scroll_position > 100;
    }
  },
  watch: {
    loading(now_loading, was_loading) {
      if (!now_loading) {
        this.initial_load = false;
      }
    },
    normalized_search_term(new_term, previous_term) {
      this.search();
    },
    selected_filters(new_filters, previous_filters) {
      // don't fire search() twice upon mounting component if user has dietary preferences
      if (!this.initial_load) {
        this.search();
      }
    }
  },
  created() {
    this.search = _.debounce(() => {
      this.$store.dispatch("fetch_recipes", {
        search_term: this.normalized_search_term,
        use_tag_filters: true,
        featured_first: false
      });
    }, 300);

    if (localStorage.hasOwnProperty("preserveRecipeSearch")) {
      localStorage.removeItem("preserveRecipeSearch");
    } else {
      this.$store.dispatch("select_user_default_filters").then(() => this.search());
      this.$store.dispatch("fetch_featured_recipes");
    }
  },
  destroyed() {
    if (!localStorage.hasOwnProperty("preserveRecipeSearch")) {
      this.$store.dispatch("deselect_all_filters");
    }
  },
  methods: {
    on_scroll(event, position) {
      this.scroll_position = position.scrollTop;
    },
    scroll_to_top() {
      let scroll_container = this.$refs["recipes"];
      if (window.jQuery) {
        $(scroll_container).animate({scrollTop: 0}, 500, "swing");
      } else {
        scroll_container.scrollTo(0, 0);
      }
    },
    viewRecipe(id) {
      localStorage.preserveRecipeSearch = this.normalized_search_term;
      this.$router.push(`/recipes/${id}`);
    },
    add_recipe_to_meal(recipe, meal_id) {
      this.recipe_to_add = recipe; // redundant right now

      this.$store.dispatch("rails/log_recipe_portions", { recipe, meal_id });

      this.$store.dispatch("notify_user", {
        type: "info",
        msg: "Recipe has been successfully added to your food log :)"
      });
    },
    async open_add_to_meal(recipe) {
      try {
        const response = await this.$apollo.query({
          query: recipe_details_query,
          fetchPolicy: "no-cache",
          variables: {
            id: recipe.id,
            macro_targets: this.$store.getters["rails/default_portion_macro_targets"]
          }
        })

        this.recipe_to_add = response.data.recipe_details;
        this.add_to_meal_visible = true;
      } catch (e) {
        console.error(error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "@css/_reset.scss";
  @import "@css/_tracker_reset.scss";

  #recipes {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    box-sizing: border-box;
  }

  .results-screen {
    display: flex;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
  }

  .recipes-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    background-color: #f7f7f7;
    margin-bottom: 64px;
    margin-top: 10px
  }

  .recipe-list {
    margin-top: 40px;
    flex: 1;
    overflow: auto;
    padding: 0px 24px 24px 24px;
    transition: top 0.3s;
  }

  .nav-bar-container {
    width: 100%;
    transition: margin-top 0.3s ease;
    background-color: #ffffff;
    z-index: 2;
    margin-top: 0px;

    &.minimize {
      margin-top: -54px;
    }
  }

  .sub-header {
    width: 100%;
    max-width: 450px;
    z-index: 1;

  }

  .search-row {
    display: flex;
    align-items: center;
    font-family: 'Open Sans';
    padding: 16px 24px;
    font-size: 10px;
    background-color: #00A881;

    .search-container {
      display: flex;
      align-items: center;
      width: 100%;
      border: none;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0px;
      padding: 0px 8px 0px 12px;
      height: 32px;

      .icon {
        width: 12px;
        margin-right: 8px;
        i {
          font-size: 12px;
          color: #4f4f4f;
        }
      }
      input {
        width: 100%;
        height: 100%;
        min-height: 0px;
        color: #4f4f4f;
        padding: 0px;
        background-color: transparent;
        font-size: 12px;
        font-weight: 600;
        margin-left: 3px;
      }
      input::placeholder {
        color: #4f4f4f;
        font-size: 12px;
        font-weight: 500;
      }
      input:focus {
        outline: none !important;
      }
    }

    .custom-recipes-button, .filter-button, .favs-button {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #ffffff;
      cursor: pointer;
      margin-left: 12px;
      .icon {
        margin-bottom: 4px;
        font-size: 16px;
      }
      p {
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 600;
      }
      .indicator {
        position: absolute;
        top: -2px;
        right: 2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #FF6699;
        margin-right: 8px;
      }
    }
    .custom-recipes-button {
      width: 64px;
      margin-left: 16px;
    }
    .filter-button {
      width: 64px;
      margin-left: 10px;
      .indicator {
        background-color: #FFCD3C;
      }
    }
    .favs-button {
      width: 64px;
      margin-left: 10px;
      .indicator {
        background-color: #FF6699;
      }
    }
  }

  .selected-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    color: #4f4f4f;
    cursor: pointer;
    margin-left: auto;
    .icon {
      font-size: 16px;
    }
    p {
      font-family: 'Open Sans';
      font-size: 10px;
      font-weight: 600;
      margin: 0px 8px 0px 0px !important;
    }
  }

  .results-count {
    padding: 2px 24px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    color: #4f4f4f;
  }

  .results-header {
    position: fixed;
    z-index: 1;
    width: 100%;
    max-width: 450px;
    margin: 0px;
    padding:  12px 24px 12px 24px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    background-color: #f7f7f7;
    p {
      font-size: 12px;
      font-weight: 600;
      color: #000000;
      margin: 0px;
    }
  }

  .featured-horizontal {
    margin: 0px -24px 18px -24px;
    padding: 0px 0px 8px 0px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #f7f7f7;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    .featured-horizontal-header {
      display: flex;
      flex-direction: row;
      margin: 0px;
      padding: 0px 24px;
      p {
        font-size: 12px;
        font-weight: 600;
        color: #000000;
        margin: 0px;
      }
    }
    .featured-horizontal-content {
      display: block;
      padding: 0px 24px;
      width: 100%;
      margin: 0px;
      .featured-horizontal-content-inner {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .select-container {
    padding: 8px 24px;

    .select-box {
      display: flex;
      align-items: center;
      padding: 16px 12px;
      border-bottom: 1px solid #e2e2e2;
      margin-top: 8px;
      cursor: pointer;

      p {
        margin: 0px 8px 0px 0px;
      }
      .indicator {
        display: none;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #CC98D0;
        margin-right: 8px;
      }
      .icon {
        margin-left: auto;
      }
    }
  }

  .no-results-warning {
    color: #4f4f4f;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    line-height: 16px;
    span {
      color: $endurance;
      font-weight: 700;
    }
  }

   .title {
     font-weight: bold;
     font-size: 1.1rem;
     padding: 0.5rem 0;
   }
   .description {
     font-size: 0.9rem;
     line-height: 1.1rem;
     margin: 0 1rem;
     margin-bottom: 1rem;

     .highlight {
       font-style: italic;
       color: $energy;
     }
   }
   .substitutions {
     font-size: 0.8rem;
     line-height: 1rem;
     color: #7B756A;
     border: 1px solid #ECE8CA;
     border-radius: 5px;
     background-color: #F9F7EA;
     padding: 0.5rem;
     margin: 0.8rem;
   }

   .scroll-to-top {
     display: flex;
     align-items: center;
     justify-content: center;
     width: 45px;
     height: 45px;
     position: absolute;
     bottom: 84px;
     right: 18px;
     background-color: $endurance;
     border-radius: 50%;
     font-size: 20px;
     box-shadow: 0 2px 4px rgba(0,0,0,0.20);
     cursor: pointer;

     i {
       color: white;
     }
   }

  .quick-add-container {
    width: 100%;
    height: 100%;
    z-index: 50;
    position: absolute;
    background-color: #ffffff;
  }

  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    background-color: #ffffff;
    &.favorites {
      background-color: #00A881;
      .left-button {
        top: 0px;
        left: 0px;
        i {
          color: #ffffff;
          margin: 0px;
        }
      }
      .title {
        color: #ffffff;
      }
    }

    .left-button, .right-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 58px;
      height: 58px;
      cursor: pointer;
      color: $endurance;
      font-size: 12px;
    }
    .left-button {
      top: 0px;
      left: 12px;
      i {
        margin-right: 8px;
      }
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      color: #000000;
    }
  }

  .quick-add-results {
    padding: 18px 24px 0px 24px;
    width: 100%;
    height: calc(100% - 58px);
    overflow: auto;
    .quick-add-recipe {
      margin-bottom: 12px;
    }
    &.favorites {
      .description {
        color: #4f4f4f;
        font-size: 12px;
        font-weight: 600;
        margin: 0px 0px 32px 0px;
      }
    }
  }
</style>
