<template>
  <div id="vue-app" :class="{ 'iphone-x': iphoneX }">
    <div class="vue-app-container">
      <barepay v-pre />
      <ScrollPoint ref='scrollPoint'/>
      <LoadingSpinner v-if="loading" />
      <div id="modal-background"></div>
      <template v-if="storeInitialized">
        <CancellationReminder v-if="cancellationReminder" />
        <AddToHomeScreenBanner v-if="showAth" />
        <Toast />
        <MessageModal v-if="messageModal" v-bind="messageModal.props" />
        <router-view v-bind="{scrollToTop: this.scrollToTop}" />
        <NavFooter />
      </template>
    </div>
  </div>
</template>

<script>
import { isIphoneX } from "@js/lib/utils";

import NavFooter from '@js/app/nav_footer.vue';
import Toast from '@js/app/toast.vue';
import MessageModal from '@js/app/message_modal.vue';
import LoadingSpinner from '@js/app/loading_spinner.vue';
import AddToHomeScreenBanner from "@js/app/modals/add_to_home_screen_banner.vue";
import ScrollPoint from "@js/app/scroll_point.vue";
import CancellationReminder from "@js/app/modals/cancellation_reminder.vue";
import setActiveCampaignListeners from "@js/lib/active_campaign"

export default {
  components: { NavFooter, Toast, MessageModal, LoadingSpinner, AddToHomeScreenBanner, ScrollPoint, CancellationReminder },
  data() {
    return {
      storeInitialized: false
    }
  },
  async created() {
    setActiveCampaignListeners();

    try {
      await this.$store.dispatch("rails/getBillingData");
      await this.$store.dispatch("rails/initialize_state");

      if (window.barepay && window.barepay.created) {
        window.console && console.error && console.error("Barepay snippet included twice.");
      } else { 
        window.barepay = {
          created: !0
        };
        var a = document.createElement("script");
        a.src = "https://baremetrics-dunning.baremetrics.com/js/application.js";
        a.async = !0;
        var b = document.getElementsByTagName("script")[0];
        b.parentNode.insertBefore(a,b);
        window.barepay.params = {
          access_token_id: process.env.BAREMETRICS_ACCESS_TOKEN_ID,
          customer_oid: this.$store.state.rails.user.stripe_customer_id,
        };
      }

      if (!this.$route.path.match("/inputs")) {
        this.$store.dispatch("fetch_filters");
        this.$store.dispatch("rails/fetch_mtc_challenge");

        await Promise.all([
          this.$store.dispatch("fetch_favorites"),
          this.$store.dispatch("fetch_custom_recipes"),
          // TODO: initialize user dietary preferences and fetch_recipes with use_tag_filters: true
          this.$store.dispatch("fetch_recipes", { initial_fetch: true })
        ]);
        // ^ this data must be loaded prior to initializing favorite portions
        this.$store.dispatch("rails/initialize_favorite_portions");
      }

      this.storeInitialized = true;
    } catch (e) {
      console.log('error creating Vue app', e);
      this.storeInitialized = false;
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading.global || !this.storeInitialized;
    },
    messageModal() {
      return this.$store.state.message_modal;
    },
    showAth() {
      return this.$store.state.rails.user.preferences.show_ath;
    },
    iphoneX() {
      // iphone-x class is set in one place - on this root component - so child component stylesheets can reference it
      // with the SASS parent selector to conditionally toggle styles as needed
      return isIphoneX();
    },
    cancellationReminder() {
      return this.$store.state.rails.billing.cancellation.reminder;
    },
  },
  methods: {
    scrollToTop() {
      this.$refs.scrollPoint.$el.scrollIntoView();
    },
  },
}
</script>

<style scoped lang="scss">
#vue-app {
  min-height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  background-color: #999999;
  overflow: auto;
}

.vue-app-container {
  // https://stackoverflow.com/questions/13533153/how-can-i-get-overlapping-divs-with-relative-positions
  position: relative;

  display: block;
  min-height: 100%;
  width: 100%;
  max-width: 450px;
  background-color: #f9f9f9;
  overflow-x: hidden;
  overflow-y: auto;

  #modal-background {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>

