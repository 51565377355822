/*
  Instructions: any Webpack entry points that import Appsignal should also add a decorator that captures, at a minimum,
  the bundle context. The context, by convention, will be the name of the bundle file outputted by Webpack (e.g. diary.js,
  kitchen.js, signup.js, etc.).


    Appsignal.addDecorator(span => span.setAction("kitchen.js"));

  By using setAction() for the context it will be visible in the top level list of errors next to the namespace in the
  AppSignal Dashboard. Additionally, tags may be optionally added to capture additional metadata that will be visible
  when viewing a specific error.

    Appsignal.addDecorator((span) => {
      return span.setTags({ user_id: 1337, charlie_sheen: "tigerblood"})
    });

  Reference: https://docs.appsignal.com/front-end/hooks.html
*/

import Appsignal from "@appsignal/javascript";
import { plugin as window_events_plugin } from "@appsignal/plugin-window-events";
import { plugin as path_decorator_plugin } from "@appsignal/plugin-path-decorator";

const appsignal = new Appsignal({
  key: process.env.APPSIGNAL_FRONTEND_API_KEY,
  revision: process.env.HEROKU_SLUG_COMMIT
});

appsignal.use(window_events_plugin({ onerror: true, onunhandledrejection: true }));
appsignal.use(path_decorator_plugin());

export default appsignal;
