<template lang="html">
  <div
    :ref="'toast'"
    class='toast-container'
    :class="type"
    :style="{
      opacity: visible ? 1 : 0,
      top: '60px',
      right: visible ? '0px' : `-${width}`,
      'border-top-left-radius': `${border_radius}px`,
      'border-bottom-left-radius': `${border_radius}px`
    }"
    @click="hide"
  >
    <img v-if="type === 'error'" :src="error_icon" width="16" height="16" />
    <i v-if="type === 'info'" class="fa fa-times" width='16' height='15'></i>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import error_icon from "@images/error.svg";

export default {
  data() {
    return {
      visible: false,
      border_radius: 12,
      width: "100%",
      error_icon
    };
  },
  computed: {
    notification() {
      return this.$store.state.notification;
    },
    type() {
      return this.notification && this.notification.type;
    },
    message() {
      return this.notification && this.notification.msg;
    }
  },
  watch: {
    notification(notification) {
      // CLEAR_NOTIFICATION sets this back to null
      if (notification) {
        setTimeout(() => this.show(), 300);
      } else {
        this.hide();
      }
    }
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      this.border_radius = this.calc_border_radius();
      this.width = this.calc_width();
      this.visible = true;
    },
    // REVIEW: this.$refs.toast seems to be invariant - what's the point of this?
    calc_border_radius() {
      if (this.$refs.toast && this.$refs.toast.offsetHeight) {
        return this.$refs.toast.offsetHeight / 2;
      } else {
        return 12;
      }
    },
    calc_width() {
      if (this.$refs.toast && this.$refs.toast.offsetWidth) {
        return `${this.$refs.toast.offsetWidth}px`;
      } else {
        return "100%";
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "@css/base/_variables.scss";
  .toast-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    padding: 16px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
    position: absolute;
    right: -100%;
    max-width: 50%;
    font-family: 'open sans';
    font-size: 12px;
    font-weight: 600;
    z-index: 200;
    cursor: pointer;
    transition: 0.3s opacity, 0.5s right;
    .icon {
      height: 16px;
      width: 16px;
      margin-right: 12px;
    }
    p {
      margin: 0px;
      padding-left: 16px;
      font-size: 11px
    }
  }
  .toast-container.error {
    background-color: #ffffff!important;
    border: 1px solid #E84C71;
    color: #E84C71!important;
    margin-right: -1px;
    img {
      filter: invert(37%) sepia(18%) saturate(4871%) hue-rotate(314deg) brightness(101%) contrast(82%);
    }
  }
  .toast-container.info {
    background-color: #DEF6FF;
    color: #356859;
  }
</style>
