<template>
  <div id="barcode-scanner">
    <div class="controls">
      <div class="hide" @click="$router.back()">
        <i :style="{color: '#34b6e4'}" class="fa fa-arrow-left fa-lg" />
      </div>
      <p class="title">Scan a Barcode</p>
      <div class="logo-container">
        <!-- <img src="images/logo.svg" /> -->
      </div>
    </div>
    <div id="barcode-picker" ref="barcode_picker"/>
    <div class="manual-entry">
      <input v-model="barcode" type="text" placeholder="Enter barcode here">
      <button class="button-primary" @click="manual_search">Search</button>
    </div>
  </div>
</template>

<script>
import { BarcodePicker, ScanSettings, configure } from "scandit-sdk";

export default {
  name: "BarcodeScanner",
  data() {
    return {
      barcode: null,
      barcode_picker: null
    };
  },
  mounted() {
    try {
      setTimeout(() => {
        configure(process.env.SCANDIT_API_KEY, {
          engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
          preloadBlurryRecognition: false,
          preloadEngine: true,
        }).then(() => {
          // console.log('configure resolved');
          const props = {
            visible: false,
            accessCamera: false,
            guiStyle: "laser",
            videoFit: "cover",
            playSoundOnScan: true,
            vibrateOnScan: true,
            singleImageMode: {
              desktop: { always: false, allowFallback: true },
              mobile: { always: false, allowFallback: true }
            },
            scanSettings: new ScanSettings({
              enabledSymbologies: ["upca", "upce", "ean8", "ean13"],
              codeDuplicateFilter: 1000
            })
          };
          BarcodePicker.create(this.$refs.barcode_picker, props).then(barcode_picker => {
            this.barcode_picker = barcode_picker;
            this.barcode_picker.on("processFrame", this.on_attempted_scan);
            this.barcode_picker.on("scan", this.on_successful_scan);
            this.barcode_picker.on("error", this.on_scan_error);
            this.barcode_picker.setVisible(true);
            if (!this.barcode_picker.cameraAccess){
              this.barcode_picker.accessCamera();
            }
            this.barcode_picker.resumeScanning();
          }).catch(err => { console.log('error creating BarcodePicker', err); });
        }).catch(err => { console.log('promise rejected', err); });
      });
      
    } catch (e) {
      console.log('error initializing scandit', e);
    }
  },
  beforeDestroy() {
    if (this.barcode_picker !== null) {
      this.barcode_picker.destroy();
    }
  },
  methods: {
    manual_search() {
      // if (this.barcode && (barcode = this.validate_barcode(this.barcode))) {
      if (this.barcode && this.validate_barcode(this.barcode)) {
        this.search_nix(this.barcode);
      } else {
        alert("You did not submit a valid barcode.");
      }
    },
    async search_nix(barcode) {
      try {
        const food_was_found = await this.$store.dispatch("fetch_food_by_barcode", barcode);

        if (food_was_found) {
          this.$router.back();
        } else {
          this.barcode_picker.resumeScanning();
          alert("Sorry, we were unable to find this item in our database.");
        }
      } catch (error) {
        console.error("Barcode Lookup Error", error);
        this.barcode_picker.resumeScanning();
        alert("Sorry, there was an error looking up this item in our database.");
      }
    },
    on_attempted_scan(scan) {
      // 'processFrame' fires once when operating in singleImageMode but continuously when operating in video streaming
      // mode, so ensure we're in singleImageMode lest thousands of timers get set.
      if (!this.barcode_picker.cameraManager.mediaStream) {
        // Successful extraction of a barcode from the image will generate a 'scan' event, but no event is generated if a
        // barcode isn't found. So as a hack to provide appropriate feedback in the latter case, set a timer to display
        // alert if successful 'scan' event doesn't subsequently cancel it.
        this.barcode_not_found = setTimeout(() => {
          alert("We were unable to identify a supported barcode format. Make sure you aim the camera directly at the barcode and hold it steady before trying again.");
        }, 1000);
      }
    },
    on_successful_scan({ barcodes }) {
      // result fields: {barcodes: [...], imageData: Uint8ClampedArray(), imageSettings: {...}, Set()}
      clearTimeout(this.barcode_not_found);

      let barcode;

      if (barcode = barcodes.find(barcode => barcode.symbology.match(/^(upca|ean8|ean13)$/))) {
        this.barcode_picker.pauseScanning(true);
        this.search_nix(barcode.data);
      } else if (barcode = barcodes.find(barcode => barcode.symbology.match(/^upce$/))) {
        this.barcode_picker.pauseScanning(true);
        const upc_a = this.upc_e_to_upc_a(barcode.data);
        this.search_nix(upca_a);
      } else {
        alert("We were unable to identify a supported barcode format. Make sure you aim the camera directly at the barcode and hold it steady before trying again.");
      }
    },
    on_scan_error(scan_error) {
      console.error("Scan Error", scan_error);
      this.barcode_picker.resumeScanning();
      alert("Sorry, there was an error scanning this barcode.");
    },
    validate_barcode(code) {
      if (code.match(/^0\d{7}$/)) { /* UPC-E */
        return this.upc_e_to_upc_a(code);
      } else if (code.match(/^\d{12}$/) /* UPC-A */ || code.match(/^\d{8}$/) /* EAN-8 */ || code.match(/^\d{13}$/) /* EAN-13 */) {
        return code;
      } else {
        return false;
      }
    },
    upc_e_to_upc_a(code) {
      if (!code.match(/^0\d{7}$/)) {
        throw `Not a UPC-E code: ${code}`;
      }

      let manufacturer_code, product_code;

      if (code[6] in ["0", "1", "2"]) {
        manufacturer_code = code[1] + code[2] + code[6] + "00";
        product_code = "00" + code[3] + code[4] + code[5];
      } else if (code[6] == "3") {
        manufacturer_code = code[1] + code[2] + code[3] + "00";
        product_code = "000" + code[4] + code[5];
      } else if (code[6] == "4") {
        manufacturer_code = code[1] + code[2] + code[3] + code[4] + "0";
        product_code = "0000" + code[5];
      } else {
        manufacturer_code = code.substring(1, 6);
        product_code = "0000" + code[6];
      }
      const check_digit = code[7];

      return "0" + manufacturer_code + product_code + check_digit;
    }
  }
};
</script>

<style scoped lang="scss">
#barcode-scanner {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1338;

  #barcode-picker {
    height: 100%;
    width: 100%;

    .scandit.scandit-barcode-picker {
      height: 100%;
      width: 100%;
      max-height: none !important;
      max-width: none !important;
    }
  }

  .controls, .manual-entry {
    background-color: white;
    font-size: 14px;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin: 0px;
    }

    .hide {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      cursor: pointer;
    }

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .manual-entry {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 15px 30px 15px;

    input {
      flex: 1;
      border-radius: 5px;
      height: 100%;
    }
    input:focus {
      outline: none !important;
      border: 1px solid #34b6e4;
    }

    button {
      height: 100%;
      text-align: center;
      color: white;
      background-color: #34B6E4;
      border-radius: 5px;
      margin-left: 8px;
      padding: 8px;
      cursor: pointer;
      border: none;
      -webkit-appearance: none;
    }
  }
}
</style>
