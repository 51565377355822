<template lang="html">
  <div class="container">
    <div v-if="page == 1" class="page">
      <p>Are you sure you want to stop auto renew?</p>
      <!-- <div style="display: flex;justify-content: center;height:95px;">
        <img src="https://icons.sfo2.cdn.digitaloceanspaces.com/carrot.png" width="51px" height="95px">
      </div> -->
      <div class="content stay">
        <p>Macrostax offers exclusive features to help you reach your goals:</p>
        <ul>
          <li><span>Free nutrition coaching in the app with <a id="Intercom">StaxChat</a></span></li>
          <li><span>Customized meals and recipes that fit your macros</span></li>
          <li><span>Ability to switch plans and macros with no additional fees</span></li>
        </ul>
      </div>
      <p class="concerned"><strong>Concerned about the costs?</strong> Reach out to StaxChat to discuss billing options with a representative! :)</p>
      <div class="navigation-buttons">
        <button @click="cancelAction">PLEASE CANCEL</button>
        <button @click="$emit('close')">I'LL STAY</button>
      </div>
    </div>

    <div v-if="page == 2" class="page">
      <p class="cancel-label">Cancel Subscription</p>
      <div class="content">
        <p>We're sad to see you go.  Please let us know why you've decided to cancel:</p>

        <div class="reasons-container" v-for="reason in reasonsList">
          <div class="reason" @click="toggleReason(reason)">
            <div class="checkbox-container">
              <label class="radio-label">
                <input disabled="true" type="radio" :id="reason.id" :value="reason.id" v-model="selectedReasonID">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="value-container">
              <label :for="reason">{{ reason.content }}</label>
            </div>
          </div>
        </div>
        <div class="custom-reason reasons-container">
          <div class="reason" @click="toggleReason({id: 'custom'})">
            <div class="checkbox-container">
              <label class="radio-label">
                <input disabled="true" type="radio" value="custom" v-model="selectedReasonID">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="value-container">
              <p>Other</p>
              <textarea type="text" id="custom-reason" placeholder="Enter reason for cancellation"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="navigation-buttons">
        <button @click="cancelAction">PLEASE CANCEL</button>
        <button @click="$emit('close')">I'LL STAY</button>
      </div>
    </div>

    <div v-if="page == 3" class="page">
      <p class="cancel-label">Cancel Subscription</p>
      <div class="content">
        <p>Your automatic billing renewal has been suspended.  Your account will remain active until the end of this billing cycle.</p>
        <p class="last-bill-label">Account End Date</p>
        <p class="last-bill">{{ nextBillDate }}</p>
        <div class="reactivate">
          <button @click="reactivateSubscription">REACTIVATE ACCOUNT</button>
          <button @click="$emit('close')">CLOSE</button>
        </div>
      </div>
    </div>

    <!-- Note: for some reason this div doesn't always render when it should, so we're scrapping DRY and 
    adding it to each of the two pages above where it should render. See CH3677. -->
    <!-- <div v-if="page != 3" class="navigation-buttons">
      <button @click="cancelAction">PLEASE CANCEL</button>
      <button @click="$emit('close')">I'll STAY</button>
    </div> -->
  </div>
</template>

<script>
import API from "@js/api/rails_client";

export default {
  props: {
    reasonsList: { type: Array },
    nextBillDate: { type: String },
    reactivateSubscription: { type: Function },
    scrollToTop: { type: Function },
  },
  data() {
    return {
      page: 1,
      selectedReasonID: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToTop();
    });
  },
  methods: {
    submitCancel() {
      if (this.validate()) {
        this.createCancellationReason().then(() => {
          this.createCancellation();
        });
      }
    },
    async createCancellationReason() {
      this.$store.dispatch("loading", true);
      if (this.selectedReasonID === 'custom') {
        try {
          const customReason = $('#custom-reason').val();
          const response = await API.createCancellationReason(customReason);
          this.selectedReasonID = response.data.cancellation_reason.id ;
        } catch(e) {
          this.$store.dispatch("notify_user", { type: "error", msg: "Unable to cancel subscription." });
        }
      }
    },
    async createCancellation() {
      try {
        const response = await API.createCancellation(this.selectedReasonID);

        this.$store.dispatch("rails/setCanceledSubscription", response.data);
        this.page = 3;
      } catch(e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to cancel subscription." });
      }
      this.$store.dispatch("loading", false);
    },
    cancelAction() {
      this.page == 1 ? this.page = 2 : this.submitCancel();
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    validate() {
      if (!this.selectedReasonID) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Please select a reason." });
        return false;
      } else {
        return true;
      }
    },
    toggleReason(reason) {
      this.selectedReasonID = reason.id;
    },
  }
};
</script>

<style scoped lang="scss">
  .container {
    position: absolute;
    z-index: 101;
    top: 0;
    padding-left: 22px;
    padding-right: 22px;
    background: #FFFFFF;
    width: 272px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    &.stay {
      margin-bottom: 20px;
    }

    #Intercom {
      cursor: pointer
    }

    .page {
      margin-top: 20px;

      .cancel-label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
      }

      .content {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: #4F4F4F;
        margin-top: 19px;

        p {
          margin-bottom: 30px;
        }

        .last-bill {
          font-weight: 600;
          font-size: 16px;
        }

        .last-bill-label {
          margin-bottom: 10px;
        }

        .radio-label {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          margin-top: 5px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 12px;
            width: 12px;
            background-color: #FFFFFF;
            border: 1px solid #4F4F4F;
            border-radius: 50%;
          }

          input:checked ~ .checkmark {
            background-color: #6B40AB;
          }
        }

        .reasons-container {
          margin-bottom: 14px;

          .reason {
            display: flex;
            flex-direction: row;

            .checkbox-container {
              display: flex;
              justify-content: center;
              align-items: flex-start;
            }
          }
        }

        .custom-reason {
          display: flex;
          flex-direction: row;

          .reason {
            display: flex;
            flex-direction: row;
            .checkbox-container {
              display: flex;
              input {
                margin-top: 15px;
              }
            }

            .value-container {
              width: 195px;
              height: 126px;
              background: #FAFAFA;
              border-radius: 8px 8px 0px 0px;
              border-bottom: 2px solid #6B40AB;

              div {
                margin-top: 4px;
              }

              p {
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #6B40AB;
                margin-left: 16px;
                margin-bottom: 0px;
                margin-top: 4px;
              }

              textarea {
                height: 80%;
                border: none;
                background: transparent;
                resize: none;
                width: 100%;
              }
            }
          }
        }

        ul  {
          padding-left: 18px;
          li {
            color: #00A881;
            margin-top: 23px;
            span {
              color: #4F4F4F;
            }
          }
        }

        .reactivate {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 43px;
          margin-bottom: 32px;

          button {
            Font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.25px;
            color: #6B40AB;
            background: none;
            border: none;
            outline: none;
            padding: 0px;
          }
        }
      }

      .concerned {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: #4F4F4F;
      }
    }

    .navigation-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 63px;
      margin-bottom: 32px;

      button {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #6B40AB;
        outline: none;
        background: none;
        border: none;
      }

    }
  }
</style>
