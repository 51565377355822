import gql from 'graphql-tag';

import recipe_fields from '@js/api/graphql/recipe_fields';
import conversion_fields from '@js/api/graphql/conversion_fields';

export default gql`
query Frequents($user_id: Int! $macro_targets: MacroTargets) {
  frequent_foods(user_id: $user_id) {
    id
    name
    brand_name
    nix_photo_url
    quantity
    is_favorite
    conversion {
      ${conversion_fields}
      default_serving_qty
    }
    conversions {
      ${conversion_fields}
      default_serving_qty
    }
  }
  frequent_restaurant_foods(user_id: $user_id) {
    id
    name
    brand_name
    nix_photo_url
    quantity
    is_favorite
    conversion {
      ${conversion_fields}
      default_serving_qty
    }
    conversions {
      ${conversion_fields}
      default_serving_qty
    }
  }
  frequent_recipes(user_id: $user_id macro_targets: $macro_targets) {
    ${recipe_fields}
    is_favorite
    conversion {
      ${conversion_fields}
    }
    conversions {
      ${conversion_fields}
    }
    foods {
      name
      quantity
      conversion {
        ${conversion_fields}
      }
      conversions {
        ${conversion_fields}
      }
    }
    child_recipes {
      ${recipe_fields}
      conversion {
        ${conversion_fields}
      }
      conversions {
        ${conversion_fields}
      }
      foods {
        name
        quantity
        conversion {
          ${conversion_fields}
        }
      }
    }
  } 
}
`;

/*


  
 */
