<template lang="html">
  <div id="activity">
    <ActivityTooltip
      v-if="tooltip_visible"
      type="phase_info"
      :goal="goal"
      :phase="phase"
      :close="toggle_tooltip"
    />
    <div class="phase-row">
      <div class="label">{{ program }} | <span>Phase {{ phase_number }}</span></div>
      <div class="help-button" @click="toggle_tooltip">
        <i class="fa fa-question-circle" />
      </div>
    </div>
    <p>Your Macro Targets for Today</p>
    <div class="chart-row">
      <MacrosChart :ref="'macros_chart'"></MacrosChart>
      <div class="legend">
        <div class="legend-row">
          <p>Total Calories</p><p class="value">{{ targets.calories }}</p>
        </div>
        <div class="legend-row">
          <div class="legend-dot carbs"></div>
          <p>Carbs</p>
          <p class="value">{{ targets.carbohydrates }}g</p>
        </div>
        <div class="legend-row">
          <div class="legend-dot protein"></div>
          <p>Protein</p><p class="value">{{ targets.protein }}g</p>
        </div>
        <div class="legend-row">
          <div class="legend-dot fat"></div>
          <p>Fat</p><p class="value">{{ targets.fat }}g</p>
        </div>
      </div>
    </div>
    <p>Set your activity for the day</p>
    <div class="button-row">
      <div :class="{'radio': true, 'active': day_type === 'workout'}" @click="update_day_details('day_type', 'workout')">
        <div class="indicator">
          <div></div>
        </div>
        <span>Workout</span>
      </div>
      <div :class="{'radio': true, 'active': day_type === 'rest'}" @click="update_day_details('day_type', 'rest')">
        <div class="indicator">
          <div></div>
        </div>
        <span>Rest</span>
      </div>
      <div :class="{'radio': true, 'active': day_type === 'refeed'}" @click="update_day_details('day_type', 'refeed')">
        <div class="indicator">
          <div></div>
        </div>
        <span>Refeed</span>
      </div>
    </div>
    <p>When are you working out?</p>
    <div class="button-row">
      <div :class="{'radio': true, 'active': workout_time === 'morning', 'disabled': day_type === 'rest'}" @click="update_day_details('workout_time', 'morning')">
        <div class="indicator">
          <div></div>
        </div>
        <span>Morning</span>
      </div>
      <div :class="{'radio': true, 'active': workout_time === 'afternoon', 'disabled': day_type === 'rest'}" @click="update_day_details('workout_time', 'afternoon')">
        <div class="indicator">
          <div></div>
        </div>
        <span>Afternoon</span>
      </div>
      <div :class="{'radio': true, 'active': workout_time === 'evening', 'disabled': day_type === 'rest'}" @click="update_day_details('workout_time', 'evening')">
        <div class="indicator">
          <div></div>
        </div>
        <span>Evening</span>
      </div>
    </div>
    <div class="button-row save">
      <div class="save-button" @click="close">SAVE SELECTION</div>
    </div>
  </div>
</template>

<script>
import { pick, startCase } from "lodash";

import ActivityTooltip from "@js/shared/activity_tooltip.vue";
import MacrosChart from "@js/food_log/macros_chart.vue";

import confettiIcon from '@images/confetti.svg';
import carrotIcon from '@images/carrot.png';

export default {
  components: { ActivityTooltip, MacrosChart },
  props: {
    close: { type: Function, default: true }
  },
  data() {
    return {
      tooltip_visible: false
    };
  },
  computed: {
    day_details() {
      return this.$store.state.rails.day_details;
    },
    targets() {
      return this.$store.getters["rails/macro_targets_for_day"];
    },
    phase() {
      return `${this.day_details.phase_type}_${this.phase_number}`;
    },
    phase_number() {
      return this.day_details.phase_number;
    },
    day_type() {
      return this.day_details.day_type;
    },
    workout_time() {
      return this.day_details.workout_time;
    },
    goal() {
      return this.$store.state.rails.statistic_set.goal;
    },
    program() {
      return startCase(this.goal.replace('weight', 'muscle'));
    }
  },
  methods: {
    toggle_tooltip() {
      this.tooltip_visible = !this.tooltip_visible;
    },
    async update_day_details(setting, val) {
      if (setting === "workout_time" && this.day_type === "rest") {
        return false;
      }

      try {
        await this.$store.dispatch("rails/update_meal_planner_settings", { [setting]: val, date: this.$store.state.rails.selected_date });

        this.$store.dispatch("notify_user", { type: "info", msg: "Activity has been successfully updated :)" });
      } catch (e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to update day details." });
      }
    },
  }
};
</script>

<style scoped lang="scss">
.phase-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  .label {
    background-color: #ececec;
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 11px;
    font-weight: 700;
    color: #4f4f4f;
    margin-right: 12px;
    text-transform: uppercase;

    span {
      font-size: 9px;
      text-transform: uppercase;
    }
  }

  .help-button {
    font-size: 16px;
    color: $endurance;
    cursor: pointer;
  }
}

p {
  font-size: 13px;
  font-weight: 700;
  color: #4f4f4f;
  text-align: left;
  margin: 0px 0px 16px 0px;

  &.error {
    font-size: 12px;
    color: #d65f5f;
  }
}

.chart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;

  ::v-deep canvas {
    width: 50% !important;
    height: auto !important;
  }

  .legend {
    width: 100%;
    padding: 0px 12px 0px 0px;

    .legend-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 6px;

      .legend-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 12px;

        &.carbs {
          background-color: #FECE3C;
        }
        &.protein {
          background-color: #F77975;
        }
        &.fat {
          background-color: #35B6E2;
        }
      }

      p {
        font-size: 13px;
        font-weight: 500;
        color: #000000;
        margin: 0px 12px 0px 0px;

        &.value {
          font-weight: 700;
          text-align: right;
          margin: 0px 0px 0px auto;
        }
      }
    }

    .legend-row:last-child {
      margin: 0px;
    }
  }
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 24px;

  &.save {
    margin: 12px 0px 0px 0px;
  }

  .pill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25px;
    line-height: 25px;
    font-size: 11px;
    font-weight: 700;
    color: $endurance;
    padding: 6px 8px 6px 8px;
    border-radius: 13px;
    border: 2px solid $endurance;
    margin-right: 12px;
    cursor: pointer;

    &.active {
      background-color: $endurance;
      color: #ffffff;
    }

    &.disabled {
      background-color: #e2e2e2;
      color: #4f4f4f;
      border: 2px solid #e2e2e2;
    }
  }

  .pill:last-child {
    margin-right: 0px;
  }

  .radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;

    .indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $endurance;
      background-color: #ffffff;
      margin-right: 8px;

      div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }

    &.disabled .indicator {
      border: 1px solid #e2e2e2;
      background-color: #e2e2e2;

      div {
        display: none;
      }
    }

    &.active .indicator div {
      background-color: $endurance;
    }

    span {
      font-size: 11px;
      font-weight: 700;
      color: #000000;
    }
  }
}

.save-button {
  border-color: transparent;
  font-family: Montserrat, 'open sans';
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  padding: 8px 16px;
  background-color: $endurance;
  border-radius: 20px;
  cursor: pointer;

  &.clear {
    background-color: transparent;
    color: $endurance;
  }

  &:focus {
    outline: 0px;
  }
}
</style>
