<template lang="html">
  <div class="new-modal-backdrop" @click="close">
    <div class="new-modal-card" v-on:click.stop>
      <div class="new-modal-content">
        <p class="modal-header">Fill Custom Macros</p>
        <p class="modal-text">Please specify what macro numbers you would like your results to hit</p>
        <div class="custom-macros-row">
          <div class="custom-macro-input">
            <p class="select-meal-prompt">Carb (g)</p>
            <input v-model="custom_macros.carb" type="number" placeholder="0" />
          </div>
          <div class="custom-macro-input">
            <p class="select-meal-prompt">Protein (g)</p>
            <input v-model="custom_macros.protein" type="number" placeholder="0" />
          </div>
          <div class="custom-macro-input">
            <p class="select-meal-prompt">Fat (g)</p>
            <input v-model="custom_macros.fat" type="number" placeholder="0" />
          </div>
        </div>
      </div>
      <div class="close-button-bottom">
        <p @click="close">CANCEL</p>
        <p :class="{ disabled: !valid_inputs }" @click="save">SAVE</p>
      </div>
    </div>
  </div>
</template>
<script>

function is_number(val) {
  return val && !isNaN(val);
}

export default {
  props: {
    default_macro_targets: {type: Object, required: false, default: () => ({ carb: 0, protein: 0, fat: 0 })},
    update: {type: Function, required: true},
    close: {type: Function, required: true}
  },
  data() {
    return {
      // clone object so the selected macros outside of the modal don't update until save
      custom_macros: {...this.default_macro_targets}
    };
  },
  computed: {
    valid_inputs() {
      return is_number(this.custom_macros.carb) && is_number(this.custom_macros.protein) && is_number(this.custom_macros.fat);
    }
  },
  methods: {
    save() {
      if (this.valid_inputs) {
        const { carb, protein, fat } = this.custom_macros;
        const parsed_macros = { carb: parseFloat(carb), protein: parseFloat(protein), fat: parseFloat(fat) };
        this.update(parsed_macros);
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .new-modal-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 50;
    .new-modal-card {
      position: relative;
      width: 80%;
      max-width: 375px;
      max-height: 85%;
      background-color: #ffffff;
      border-radius: 12px;
      overflow: auto;
      .new-modal-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 18px 0px 0px 0px;
        padding: 0px 20px 14px 20px;
        .modal-header {
          font-family: Montserrat;
          color: #000000;
          font-size: 18px;
          font-weight: 600;
          margin: 0px 0px 18px 0px;
          text-align: left;
        }
        .modal-sub-header {
          color: #000000;
          font-size: 15px;
          font-weight: 600;
          margin: 0px 0px 8px 0px;
          text-align: left;
        }
        .modal-text {
          color: #4f4f4f;
          font-size: 12px;
          font-weight: 600;
          margin: 0px 0px 12px 0px;
          text-align: left;
        }
      }
      .close-button-top {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 14px;
        cursor: pointer;
        i {
          color: $endurance;
          font-size: 15px;
        }
      }
      .close-button-bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        //padding: 14px;
        cursor: pointer;
        p {
          font-family: Montserrat;
          color: $endurance;
          font-size: 15px;
          font-weight: 700;
          margin: 0px;
          padding: 14px 14px 20px 14px;
          &.disabled {
            color: #4f4f4f;
          }
        }
      }
    }
  }

  .select-meal-prompt {
    color: $endurance;
    font-size: 12px;
    font-weight: 600;
    margin: 8px 0px 0px 0px;
    text-align: left;
  }

  .custom-macros-row {
    display: flex;
    flex-direction: row;
    .custom-macro-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 2px solid $endurance;
      margin-right: 12px;
      input {
        width: 100%;
        text-align: center;
        font-weight: 600;
        border: none !important;
      }
      input:focus {
        outline: none !important;
      }
    }
    .custom-macro-input:last-child {
      margin-right: 0px;
    }
  }
</style>
