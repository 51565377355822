<template lang="html">
  <div class="profile-container">
    <div class="nav">
      <p class="title">Summary</p>
    </div>

    <div class="content summary-page">
      <div class="profile-header-card">
        <div class="profile-header">
          <div class="info">
            <p class="name">{{ user.first_name }} {{ user.last_name }}</p>
            <div class="stats">
              <p class="gender">{{ statistic_set.gender }}</p>
              <p>{{ statistic_set.age }} yo</p>
              <p>{{ weight }}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="phase">{{ program.label }} | <span>Phase {{ phase }}</span></div>
        </div>

        <router-link to="/profile/user" class="manage-profile">
          <p>Manage Profile</p>
          <i class="fa fa-chevron-right"></i>
        </router-link>
      </div>

      <router-link to="/inputs" tag="div" class="profile-card progress-card">
        <div class="profile-card-header">
          <p>Your Progress</p>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="profile-card-body">
          <div class="info">
            <img class="graph-placeholder" :src="graph_icon" />
          </div>
        </div>
      </router-link>

      <router-link to="/profile/program" tag="div" class="profile-card">
        <div class="profile-card-header">
          <p>Your Goal</p>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="profile-card-body">
          <div class="info">
            <img :src="program.icon" class="icon" />
            <div class="text">
              <p class="title">{{ program.label }}, Phase {{ phase }}</p>
              <p class="description">{{ program.card_description }}</p>
            </div>
          </div>
        </div>
      </router-link>

      <router-link to="/profile/activity" tag="div" class="profile-card">
        <div class="profile-card-header">
          <p>Activity Level</p>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="profile-card-body">
          <div class="info">
            <img :src="activity_level.icon" class="icon" />
            <div class="text">
              <p class="title">{{ activity_level.title }}</p>
              <p class="description">{{ activity_level.description }}</p>
            </div>
          </div>
        </div>
      </router-link>

      <router-link to="/profile/diet" tag="div" class="profile-card">
        <div class="profile-card-header">
          <p>Dietary Preferences</p>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="profile-card-body">
          <div v-if="dietary_restriction_ids.length > 0" class="tag-cloud">
            <div v-for="option in dietary_restriction_ids" class="tag">
              <img :src="dietary_restriction_options.find(p => p.id === option).icon" class="icon" />
              <p class="title">{{ dietary_restriction_options.find(p => p.id === option).label }}</p>
            </div>
          </div>
          <div v-if="!dietary_restriction_ids || dietary_restriction_ids.length == 0" class="info">
            <div class="text">
              <p class="description">No dietary restrictions</p>
            </div>
          </div>
        </div>
      </router-link>

      <router-link v-if="this.$store.state.rails.billing" to="/billing" tag="div" class="profile-card">
        <div v-if="currentPlan !== 'macrostax_yearly' && currentPlan !== 'grandfathered' && currentPlan !== 'lifetime'" class="profile-card-body">
          <div class="label">
            <p class="subscription-plan">You're on the {{ planNames[currentPlan] }} Plan</p>
            <i class="fa fa-chevron-right"></i>
          </div>
          <div class="info">
            <div class="text">
              <p class="description">You're doing great! Why not stick around?<br><br>Switch to yearly TODAY and save ${{ saveAmount }}!</p>
            </div>
          </div>
          <!-- <router-link to="/upgrade-subscription" class="subscription-button"> -->
          <router-link to="/billing/upgrade" class="subscription-button">
            SWITCH TO YEARLY
          </router-link>
        </div>
        <div v-else>
          <div class="profile-card-header">
            <p>Your Subscription</p>
            <i class="fa fa-chevron-right"></i>
          </div>
          <div class="profile-card-body">
            <p class="subscription-plan">You're on the {{ planNames[currentPlan] }} Plan</p>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Store from "@js/store";

import activity_levels from "@js/profile/activity_levels";
import programs from "@js/profile/programs";

import graph_icon from "@images/graph-placeholder.svg";
import carrot_icon from "@images/graph-placeholder.svg";
import gluten_free_icon from "@images/gluten-free.svg";
import dairy_free_icon from "@images/dairy-free.svg";
import vegetarian_icon from "@images/vegetarian.svg";
import vegan_icon from "@images/vegan.svg";
import pescatarian_icon from "@images/pescatarian.svg";

const dietary_restriction_icons = {
  2: gluten_free_icon,
  3: dairy_free_icon,
  4: vegetarian_icon,
  5: vegan_icon,
  6: pescatarian_icon
};

export default {
  data() {
    return {
      activity_levels,
      programs,
      graph_icon,
      carrot_icon
    };
  },
  beforeRouteEnter (to, from, next) {
    if (!Store.state.rails.billing) {
      Store.dispatch("rails/getBillingData").then( () => next() );
    } else {
      next();
    }
  },
  computed: {
    user() {
      return this.$store.state.rails.user;
    },
    dietary_restriction_ids() {
      return this.$store.state.rails.user.dietary_restriction_ids;
    },
    dietary_restriction_options() {
      return this.$store.state.rails.dietary_restrictions.map(({ id, name }) => (
        { id, label: name, icon: dietary_restriction_icons[id] }
      ));
    },
    saveAmount() {
      if (this.$store.state.rails.billing.subscription.plan.name === 'macrostax_quarterly') {
        return process.env.YEARLY_SAVINGS_AMOUNT_QUARTERLY
      }
      return process.env.YEARLY_SAVINGS_AMOUNT_MONTHLY
    },
    statistic_set() {
      return this.$store.state.rails.statistic_set;
    },
    program() {
      return this.programs.find(({ goal }) => goal === this.statistic_set.goal);
    },
    phase() {
      return this.$store.state.rails.meal_planner_settings.phase.slice(-1);
    },
    activity_level() {
      return this.activity_levels.find(({ value }) => value === this.statistic_set.activity_level);
    },
    weight() {
      const unit = this.$store.state.rails.user.preferences.preferred_input_weight_unit;

      let weight = this.statistic_set.weight;
      if (unit === "kg") {
        weight = Math.round(weight / 2.205);
      }

      return `${weight} ${unit}`;
    },
    currentPlan() {
      let name = this.$store.state.rails.billing.subscription.plan.name;
      if (!name.length && (this.$store.state.rails.user.stripe_plan_id === this.$store.state.rails.lifetime_subscription_plan_id || this.$store.state.rails.user.stripe_plan_id === 'macrostax_lifetime')) {
        name = 'lifetime';
      }
      return name;
    },
    planNames() {
      return {
        'macrostax_monthly': 'Monthly',
        'macrostax_quarterly': 'Quarterly',
        'macrostax_yearly': 'Yearly',
        'active_gym_challenge': 'Gym Challenge Group',
        'grandfathered': 'Grandfathered',
        'lifetime': 'Lifetime',
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      Store.dispatch("rails/getBillingData"),
      Store.dispatch("rails/getCancellationReasons")
    ]).then(() => {
      next();
    })
  }
};
</script>

<style scoped lang="scss">
@import "@css/components/profile";

.summary-page {
  background-color: white;
  border: 1px solid white;

  .profile-header-card {
    border-radius: 14px;
    background-color: #FCFCFC;
    border: 1px solid #F7F8FA;
    padding: 24px;
    margin: -24px;
    margin-bottom: -20px;
  }

  .profile-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px 12px 0px;

    .info {
      margin: 0px 12px 0px 0px;

      .name {
        color: #000000;
        font-family: Montserrat;
        font-size: 19px;
        font-weight: 700;
        margin: 0px 0px 6px 0px;
      }

      .stats {
        display: flex;
        flex-direction: row;

        p {
          color: #4f4f4f;
          font-size: 13px;
          font-weight: 600;
          margin: 0px 12px 0px 0px;
        }

        .gender {
          text-transform: capitalize;
        }
      }
    }
  }

  .manage-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $endurance;
    cursor: pointer;
    padding: 16px 0px;

    p {
      font-size: 14px;
      font-weight: 700;
      margin: 0px 12px 0px 0px;
    }

    i {
      font-size: 11px;
      font-weight: 600;
      margin: 0px;
    }
  }

  .profile-card {
    padding: 16px 24px;
    background-color: #F7F8FA;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(175,175,175,0.5);
    margin-bottom: 24px;
    cursor: pointer;

    .profile-card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $endurance;
      margin-bottom: 12px;

      p {
        font-size: 14px;
        font-weight: 600;
        margin: 0px 8px 0px 0px;
      }
      i {
        font-size: 11px;
        font-weight: 600;
        margin: 0px 0px 0px auto;
      }
    }

    .profile-card-body {
      .info {
        display: flex;
        flex-direction: row;

        .icon {
          min-width: 30px;
          width: 30px;
          height: 30px;
          margin: 0px 8px 0px 0px;
          color: #4f4f4f;
          font-size: 20px;
          &.carrot {
            width: 50px;
            height: 80px;
            margin: 0px 22px 0px 0px;
          }
        }
        .text {
          .title {
            color: #000000;
            font-size: 13px;
            font-weight: 700;
            margin: 0px 0px 6px 0px;
          }
          .description {
            color: #4f4f4f;
            font-size: 12px;
            font-weight: 600;
            margin: 0px;
          }
        }
      }

      .tag-cloud {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        ul {
          font-size: 13px;
          font-weight: 600;
        }

        .tag {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0px 24px 12px 0px;

          .icon {
            width: 32px;
            height: 32px;
            margin: 0px 8px 0px 0px;
          }

          .title {
            color: #4f4f4f;
            font-size: 13px;
            font-weight: 600;
            margin: 0px;
          }
        }
      }

      .subscription-plan {
        color: #000000;
        font-size: 13px;
        font-weight: 700;
        margin: 0px 0px 18px 0px;
      }

      .label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        i {
          font-size: 11px;
          font-weight: 600;
          margin: 0px 0px 0px auto;
          color: #6B40AB;
        }
      }
    }
  }

  .profile-card:last-child {
    margin: 0px;
  }
}

.row {
  display: flex;

  .phase {
    background-color: #ececec;
    border-radius: 8px;
    padding: 6px 8px;
    font-size: 11px;
    font-weight: 700;
    color: #4f4f4f;
    margin-right: 12px;
    text-transform: uppercase;

    span {
      font-size: 9px;
      text-transform: uppercase;
    }
  }
}

.subscription-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  background-color: $endurance;
  color: white;
  font-size: 14px;
  font-weight: 700;
  margin: 18px 0px 18px 0px;
  border-radius: 15px;
  line-height: 30px;
}

.graph-placeholder {
  width: 100%;
}
</style>
