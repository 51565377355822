import gql from "graphql-tag";

import recipe_fields from "@js/api/graphql/recipe_fields";
import conversion_fields from "@js/api/graphql/conversion_fields";

export default gql`
query CustomRecipes($user_id: Int!) {
  custom_recipes(user_id: $user_id) {
    ${recipe_fields}
    is_favorite
    conversion {
      ${conversion_fields}
    }
    conversions {
      ${conversion_fields}
    }
    child_recipes {
      id
    }
    foods {
      id
      name
      quantity
      conversion {
        ${conversion_fields}
      }
      conversions {
        ${conversion_fields}
      }
    }
  }
}
`;
