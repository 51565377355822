<template>
  <div class="new-modal-backdrop" @click="close">
    <div class="new-modal-card" v-on:click.stop>
      <div class="new-modal-content">
        <p class="modal-header">Add to Food Log</p>
        <div class="add-to-meal-recipe">
          <div class="recipe-photo">
            <img :src="recipe.photo_urls.small" />
          </div>
          <div class="recipe-info">
            <div class="recipe-name">
              <p ref="recipe_name" v-text="recipe.name" />
              <p v-if="is_name_overflown" class="overflow-ellipsis">...</p>
            </div>
            <Macros
              :food="recipe"
              :cook-loss-factor="recipe.cook_loss_factor"
              :quantity="recipe.eat_quantity"
              :show-quantity="false"
              :initials="true"
            />
          </div>
        </div>
        <p class="select-meal-prompt">Select Meal</p>
        <div class="select-row">
          <Select
            :options="meal_select_options"
            class="meal-select"
            style="width: 100%"
            @select="select_meal"
          >
            <div class="selected-field">
              <p v-if="selected_meal">{{ selected_meal.name }}</p>
              <p v-else>No meal selected</p>
              <i class="fa fa-chevron-down fa-sm icon" />
            </div>
          </Select>
        </div>
      </div>
      <div class="close-button-bottom">
        <p @click="close">CANCEL</p>
        <p :class="{ 'disabled': !selected_meal }" @click="track">SAVE</p>
      </div>
    </div>
  </div>
</template>

<script>
import { is_element_overflown } from "@js/lib/utils";

import Macros from "@js/shared/macros.vue";
import Select from "@js/shared/select.vue";

export default {
  components: { Macros, Select },
  props: {
    recipe: { type: Object, required: true },
    close: { type: Function, required: true },
    add_to_meal: { type: Function, required: true },
    default_meal_id: { type: String, required: false }
  },
  data() {
    return {
      selected_meal_id: this.default_meal_id,
      meal_select_options: [
        { id: "meal-1", name: "Breakfast" },
        { id: "meal-2", name: "Snack 1" },
        { id: "meal-3", name: "Lunch" },
        { id: "meal-4", name: "Snack 2" },
        { id: "meal-5", name: "Dinner" }
      ],
    }
  },
  created() {
    this.$store.dispatch("set_modal_visible", true);
  },
  beforeDestroy() {
    this.$store.dispatch("set_modal_visible", false);
  },
  computed: {
    selected_meal() {
      return this.meal_select_options.find(option => option.id === this.selected_meal_id);
    },
    // TODO: name is never truncated, template just adds an ellipsis
    is_name_overflown() {
      return is_element_overflown(this.$refs["recipe_name"]);
    }
  },
  methods: {
    select_meal(meal_id) {
      this.selected_meal_id = meal_id;
    },
    track() {
      this.add_to_meal(this.recipe, this.selected_meal_id);
      this.close();
    }
  }
}
</script>

<style scoped lang="scss">
@import "@css/_reset.scss";
@import "@css/_tracker_reset.scss";

.macrostax-select {
  ::v-deep .select-list.expanded {
    max-height: 280px !important;
  }
}

.new-modal-backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
}
.new-modal-card {
  position: relative;
  width: 80%;
  max-width: 375px;
  height: 80%;
  background-color: #ffffff;
  border-radius: 12px;
  overflow: visible;
  margin-top: -40px;

  .new-modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 18px 0px 0px 0px;
    padding: 0px 20px 14px 20px;

    .modal-header {
      font-family: Montserrat;
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      margin: 0px 0px 18px 0px;
      text-align: left;
    }

    .modal-sub-header {
      color: #000000;
      font-size: 15px;
      font-weight: 600;
      margin: 0px 0px 8px 0px;
      text-align: left;
    }

    .modal-text {
      color: #4f4f4f;
      font-size: 12px;
      font-weight: 600;
      margin: 0px 0px 12px 0px;
      text-align: left;
    }
  }
  .close-button-top {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 14px;
    cursor: pointer;

    i {
      color: $endurance;
      font-size: 15px;
    }
  }
  .close-button-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //padding: 14px;
    cursor: pointer;

    p {
      font-family: Montserrat;
      color: $endurance;
      font-size: 15px;
      font-weight: 700;
      margin: 0px;
      padding: 14px 14px 20px 14px;

      &.disabled {
        color: #4f4f4f;
      }
    }
  }
}

.add-to-meal-recipe {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  .recipe-photo {
    border-radius: 8px;
    overflow: hidden;
    margin: 0px 12px 0px 0px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    max-width: 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .recipe-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 16px;
    .recipe-name {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin: 0px 0px 4px 0px;
      p {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        color: #000000;
        text-align: left;
        margin: 0px;
      }
      .overflow-ellipsis {
        margin: 0px 0px 0px 2px !important;
        padding: 0px 0px 3px 0px;
        min-width: 12px;
        max-width: 12px;
      }
    }
    .macros {
      margin: 0px 0px 4px 0px;
      overflow: hidden;
      ::v-deep * {
        font-family: 'Open Sans';
        font-size: 10px;
        font-weight: 600;
        color: #4f4f4f !important;
      }
    }
    .view-recipe {
      text-align: left;
      font-size: 10px;
      color: $endurance;
      font-weight: 600;
    }
  }
  .quick-add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px !important;
    height: 50px !important;
    margin: 0px 0px 0px auto;
    i {
      color: $endurance;
      font-size: 16px;
    }
  }
}

.select-meal-prompt {
  color: $endurance;
  font-size: 12px;
  font-weight: 600;
  margin: 8px 0px 0px 0px;
  text-align: left;
}

.select-helper-text {
  margin: 8px 0px 0px 0px;
  color: #4f4f4f;
  font-size: 10px;
  text-align: left;
  font-weight: 600;
}

.select-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    transition: visibility 0s, opacity 0.5s linear;
    .meal-select {
      display: inline-block;
      width: 85%;
      margin-top: 8px;
    }
    ::v-deep .selected-field {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 2px solid $endurance;
      margin: 0px;
      padding: 6px;
      p {
        margin: 0px 8px 0px 0px;
        color: #4f4f4f;
        font-size: 14px;
      }
      i {
        color: $endurance;
        font-size: 8px;
        margin: 0px 0px 0px auto;
      }
    }
    &.collapsed-header {
      .meal-select {
        display: inline-block;
        width: 100%;
        margin-top: 0px;
      }
      ::v-deep .selected-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: none;
        margin: 0px;
        padding: 6px;
        p {
          margin: 0px 8px 0px 0px;
          color: #4f4f4f;
          font-size: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          text-align: right;
        }
        i {
          color: #4f4f4f;
          font-size: 8px;
          margin: 0px 0px 0px auto;
        }
      }
      ::v-deep .select-list {
        min-width: 220px;
        right: 0px;
      }
    }
  }
</style>
