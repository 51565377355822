<template>
  <div class="expandable-row">
    <div class="row-header" @click="toggle_section">
      <p>Serving Size</p>
      <div v-if="!is_valid" class="indicator" />
      <i v-if="expanded" class="fa fa-chevron-up fa-sm icon purple" />
      <i v-else class="fa fa-chevron-down fa-sm icon purple" />
    </div>
    <transition name="fade">
      <div v-if="expanded" class="row-content">
        <div class="inputs">
          <div v-if="is_grams" class="instructions final-weight">
            Please update the final weight based on any changes you've made to the ingredients.
          </div>
          <div class="instructions">
            1. Enter details for 1 serving
          </div>
          <div class="input-row">
            <div class="input-container">
              <p class="input-label">Number</p>
              <input v-model.number="serving.quantity" placeholder="1" type="number" min="0" />
            </div>

            <div class="input-container">
              <p class="input-label">Unit</p>
              <input v-model.trim="serving.serving_name" placeholder="cups, grams" type="text" />
            </div>
          </div>
          <div v-if="!is_number(serving.quantity)" class="error-temp-class">
            Please enter a number here!
          </div>
          <div class="instructions step-2">
            2. Enter the final number of {{ serving_name_plural }}
          </div>
          <div class="input-row">
            <div class="input-container">
              <p class="input-label">{{ serving_name_capital_plural }} Per Recipe</p>
              <input v-model.number="serving.bulk_make_qty" placeholder="1" type="number" min="0" />
            </div>
          </div>
          <div v-if="quantity_mismatch" class="error-temp-class">
            The recipe must make at least 1 serving
          </div>
          <div v-if="!is_number(serving.bulk_make_qty)" class="error-temp-class">
            Please enter a number here!
          </div>
        </div>

        <div class="macro-summary">
          <div class="label">{{ portion_summary }}</div>
          <div class="macros">
            <div>C {{ macros.carb_grams }}g</div>
            <div>P {{ macros.protein_grams }}g</div>
            <div>F {{ macros.fat_grams }}g</div>
            <div>Cal {{ macros.calories }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import capitalize from "lodash/capitalize";

import inflect from "@js/lib/inflect";
import { portion_macros } from "@js/lib/utils";

export default {
  props: {
    serving: { type: Object, required: true },
    macros: { type: Object, required: true },
    expanded: { type: Boolean, required: true },
    is_valid: { type: Boolean, required: true },
    toggle_section: { type: Function, required: true },
    mode: { type: String, required: false }
  },
  computed: {
    is_grams() {
      return this.serving.serving_name && this.serving.serving_name.toLowerCase().match("gram");
    },
    serving_name() {
      const name = this.serving.serving_name || "serving";
      const quantity = this.serving.quantity || 1;

      return inflect(name, quantity);
    },
    serving_name_plural() {
      return inflect(this.serving_name);
    },
    serving_name_capital_plural() {
      return capitalize(this.serving_name_plural);
    },
    portion_summary() {
      return `Macros Per ${this.serving.quantity || ""} ${capitalize(this.serving_name)}`;
    },
    quantity_mismatch() {
      let quantity = this.serving.quantity || 1;
      let bulk_make_qty = this.serving.bulk_make_qty || 1;

      return (quantity > bulk_make_qty);
      //return this.serving.quantity && this.serving.bulk_make_qty && this.serving.quantity > this.serving.bulk_make_qty;
    }
  },
  methods: {
    is_number(str) {
      // we are allowing empty values
      if (!str) return true;
      return !isNaN(str) && !isNaN(parseFloat(str));
    }
  }
};
</script>

<style scoped lang="scss">
  @import "@css/_reset.scss";
  @import "@css/_tracker_reset.scss";
  @import "@css/transitions";

  .expandable-row {
    padding: 0px;
    margin-bottom: 8px;

    .row-header {
      display: flex;
      align-items: center;
      padding: 16px 12px;
      border-bottom: 1px solid #e2e2e2;
      margin-top: 8px;
      cursor: pointer;

      p {
        margin: 0px 12px 0px 0px;
      }

      .indicator {
        display: block;
        min-width: 8px;
        min-height: 8px;
        border-radius: 50%;
        background-color: #a189cb;
        margin-right: 8px;
      }

      .icon {
        margin-left: auto;
        color: $endurance;
      }
    }

    .row-content {
      padding: 20px 12px 0px 12px;

      .serving-type {
        line-height: 21px;
        letter-spacing: 0.5px;
        margin-bottom: 18px;

        p {
          font-size: 12px;
          margin-bottom: 16px;
        }

        .select {
          display: flex;
          flex-direction: row;

          .option {
            cursor: pointer;
            color: $endurance;
            background: white;
            border: 1px solid $endurance;
            box-sizing: border-box;
            padding: 5px 14px;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.5px;

            &.left {
              border-radius: 8px 0px 0px 8px;
            }

            &.right {
              border-radius: 0px 8px 8px 0px;
            }

            &.selected {
              color: white;
              background: $endurance;
            }
          }
        }
      }

      .inputs {
        .explanation {
          color: #4F4F4F;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.5px;
          margin-top: -12px;
          margin-bottom: 24px;
        }

        .instructions {
          font-weight: 600;
          font-size: 12px;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: #4F4F4F; /* Gray */
          margin: 15px 0px;

          &.final-weight {
            margin-bottom: 25px;
          }

          &.step-2 {
            margin-top: 10px;
          }
        }

        /* Rails CSS defines global .error and .error-message styles - until that's refactored use a scoped class name */
        .error-temp-class {
          font-weight: 600;
          font-size: 12px !important;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: #E84C71; /* Red */
          margin-top: -16px;
          margin-bottom: 24px;
        }

        .input-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;

          .unit {
            color: black;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.25px;
          }

          .input-container {
            background-color: #FAFAFA;
            border-radius: 8px 8px 0px 0px;
            border-bottom: 2px solid $endurance;
            padding: 6px 12px;
            margin: 0px 24px 24px 0px;

            .input-label {
              color: $endurance;
              font-size: 12px;
              font-weight: 600;
              margin: 0px 0px 6px 0px;
            }

            input {
              color: #000000;
              background-color: transparent;
              font-size: 14px;
              font-weight: 600;
              padding: 0px;
              margin: 0px;
              resize: none;
              width: 100%;
              height: 28px;
            }

            input::placeholder {
              color: #acacac;
              font-size: 16px;
              font-weight: normal;
            }

            input:focus {
              outline: none !important;
            }

            textarea {
              color: #000000;
              background-color: transparent;
              font-size: 14px;
              font-weight: 600;
              padding: 0px;
              margin: 0px;
              resize: none;
              width: 100%;
            }

            textarea::placeholder {
              color: #4f4f4f;
              font-size: 16px;
              font-weight: normal;
            }

            textarea:focus {
              outline: none !important;
            }
          }
        }
      }

      .macro-summary {
        font-size: 12px;
        margin-top: 4px;

        .label {
          color: #4f4f4f;
          font-weight: 600;
          margin-bottom: 7px;
        }

        .macros {
          display: flex;
          color: black;
          font-weight: normal;
          line-height: 16px;

          div:not(:first-child) {
            margin: 0px 4px;
          }
        }
      }

      .button-container {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $endurance;
        font-weight: 600;
        margin: 0px 0px 12px 0px;
        height: 40px;
        cursor: pointer;

        i {
          font-size: 14px;
          color: $endurance;
          margin-right: 8px;
          font-weight: normal;
        }
      }
    }

    .eat-make {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 4px 0px 24px 0px;

      .icon {
        width: 22px;
        height: 22px;
        margin-right: 12px;
      }

      .eat-make-inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .separator {
          margin: 0px 6px;
          padding-top: 2px;
        }

        .makes-container, .eat-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 0px;

          &.has-border {
            border-bottom: 2px solid $movement;
          }

          p {
            font-size: 12px;
            font-style: italic;
            font-weight: 500;
            margin: 0px 6px 0px 0px;
          }
          
          .amount {
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
  }
</style>
