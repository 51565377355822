import gql from "graphql-tag";
import recipe_fields from "@js/api/graphql/recipe_fields";
import conversion_fields from "@js/api/graphql/conversion_fields";

export default gql`
query RecipeDetails($id: Int! $macro_targets: MacroTargets) {
  recipe_details(id: $id macro_targets: $macro_targets) {
   ${recipe_fields}
   proportion
   is_bad_match
   conversion {
     ${conversion_fields}
   }
   conversions {
     ${conversion_fields}
   }
   foods {
     id
     name
     quantity
     tags {
       name
     }
     conversion {
       ${conversion_fields}
     }
     conversions {
       ${conversion_fields}
     }
   }
   child_recipes {
     ${recipe_fields}
     proportion
     conversion {
       ${conversion_fields}
     }
     conversions {
       ${conversion_fields}
     }
     foods {
       id
       name
       quantity
       tags {
         name
       }
       conversion {
         ${conversion_fields}
       }
     }
   }
  }
}
`;
