<template lang="html">
  <div class="loading-container">
    <div v-if="logo" class="logo-icon">
      <img :src="`${cdn_url}/logo-white.svg`">
    </div>
    <svg
      :height="radius * 2"
      :width="radius * 2"
    >
      <circle
        v-if="looping"
        :ref="'circle'"
        :stroke="start_color"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="circle_style"
        :stroke-width="stroke"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
        fill="transparent"
      />
    </svg>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    radius: {type: Number, default: 70},
    stroke: {type: Number, default: 8},
    start_color: {type: String, default: "#34B6E4"},
    end_color: {type: String, default: "#34B6E4"},
    duration: {type: Number, default: 1200},
    loop: {type: Boolean, default: false},
    logo: {type: Boolean, default: true},
    forward: {type: Boolean, default: true},
    reset: {type: Boolean, default: false}
  },
  data() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    return {
      normalizedRadius,
      circumference,
      progress: this.forward ? 0 : 100,
      color: this.start_color,
      looping: false,
      start_timeout: false,
      finish_timeout: false,
      loop_timeout: false
    };
  },
  computed: {
    cdn_url() { return this.$store.state.cdn_url; },
    circle_style() {
      return {
        strokeDashoffset: this.circumference - this.progress / 100 * this.circumference,
        transitionDuration: `${this.duration}ms`,
        stroke: this.color
      };
    }
  },
  watch: {
    reset() {
      if (this.reset) {
        this.reset = false;
        start_over();
      }
    }
  },
  mounted() {
    this.start_over();
  },
  methods: {
    start_over() {
      clearTimeout(this.start_timeout);
      clearTimeout(this.finish_timeout);
      clearTimeout(this.loop_timeout);
      setTimeout(() => { this.loopdeloop(); }, 20);
    },
    loopdeloop() {
      this.looping = false;

      // Based on CSS animations, so give some time in between changes
      this.start_timeout = setTimeout(() => {
        this.looping = true;
        // reset to starting conditions
        this.progress = this.forward ? 0 : 100;
        this.color = this.start_color;

        this.finish_timeout = setTimeout(() => {
          // specifying ending conditions
          this.color = this.end_color;
          this.progress = this.forward ? 100 : 0;
        }, 20);
      }, 20);

      // Do it again!
      this.loop_timeout = setTimeout(() => {
        if (this.loop) { this.loopdeloop(); }
      }, this.duration + 40);
    }
  }
};
</script>

<style scoped lang="scss">
  // @import "@css/signup.scss";

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .logo-icon {
      width: 90px;
      height: 90px;
      position: absolute;
    }
  }
  circle {
    transition: stroke 2s, stroke-dashoffset 2s; // duration can be overridden
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
</style>
