<template lang="html">
  <div class="recipe-result" @click="goto_recipe(recipe.id)">
    <div class="recipe-photo">
      <img :src="recipe.photo_urls.small" />
    </div>
    <div class="recipe-info">
      <div class="recipe-name">
        <p ref="recipe_name" v-text="recipe.name" />
      </div>
      <Macros
        :food="recipe"
        :cook-loss-factor="recipe.cook_loss_factor"
        :quantity="recipe.eat_quantity"
        :show-quantity="false"
        :initials="true"
      />
      <p class="view-recipe">View Recipe</p>
    </div>
    <div class="quick-add-button" v-on:click.stop @click="$emit('quick_add', recipe)">
      <i class="fa fa-plus-circle"></i>
    </div>
  </div>
</template>

<script>
import Macros from "@js/shared/macros.vue";

export default {
  components: { Macros },
  props: {
    recipe: { type: Object, required: true },
    goto_recipe: { type: Function, required: true },
    meal_id: { type: String }
  }
};
</script>

<style scoped lang="scss">
  .recipe-result {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px;
    .recipe-photo {
      border-radius: 8px;
      overflow: hidden;
      margin: 0px 12px 0px 0px;
      width: 80px;
      height: 80px;
      min-width: 80px;
      max-width: 80px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .recipe-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 16px;
      .recipe-name {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin: 0px 0px 4px 0px;
        p {
          font-family: "Open Sans";
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          color: #000000;
          text-align: left;
          margin: 0px;
        }
        .overflow-ellipsis {
          margin: 0px 0px 0px 2px !important;
          padding: 0px 0px 3px 0px;
          min-width: 12px;
          max-width: 12px;
        }
      }
      .macros {
        margin: 0px 0px 4px 0px;
        overflow: hidden;
        /deep/ * {
          font-family: "Open Sans";
          font-size: 12px;
          font-weight: 600;
          color: #4f4f4f !important;
        }
        /deep/ .actual {
          padding-right: 0px;
          margin-right: 6px;
        }
      }
      .view-recipe {
        text-align: left;
        font-size: 12px;
        color: $endurance;
        font-weight: 600;
      }
    }
    .quick-add-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px !important;
      height: 50px !important;
      margin: 0px 0px 0px auto;
      i {
        color: $endurance;
        font-size: 24px;
      }
    }
  }
</style>
