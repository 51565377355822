import Vue from "vue";
import VueApollo from "vue-apollo";
import VueRouter from "vue-router";
import VueScroll from "vue-scroll";
import VueTouchEvents from "vue2-touch-events";
import VueSanitize from "vue-sanitize";
import ContentfulVue from "contentful-vue";
import { errorHandler } from "@appsignal/vue";

import store from "@js/store";
import router from "@js/router";
import phx_apollo_client from "@js/api/phx_apollo_client";
import rails_api_client from "@js/api/rails_client";

import App from "@js/app.vue";
import Resources from "@js/faq/resources.vue";
import LogWeightCard from "@js/profile/progress/log_weight_card.vue";
import ProgressPhotosComparison from "@js/profile/progress_photos_comparison.vue";

Vue.use(VueApollo);
Vue.use(VueRouter);
Vue.use(VueScroll);
Vue.use(VueTouchEvents);
Vue.use(VueSanitize);
Vue.use(ContentfulVue, {
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_API_KEY
});

if (process.env.NODE_ENV === "production") {
  const Appsignal = require("@js/lib/appsignal");
  Vue.config.errorHandler = errorHandler(Appsignal, Vue);
}

// expose RailsAPI as global var for use in non-Webpacked JS (until all legacy JS is migrated/deleted)
window.RailsAPI = rails_api_client;

document.addEventListener('DOMContentLoaded', async () => {
  const non_vue_routes = [
    "/",
    "/enroll",
    "/sign_in",
    "/inputs/measurements",
    "/inputs/intake",
    "/billing/new",
    "/billing/upgrade",
    "/billing/charge_confirmation"
  ];

  if (window.location.pathname === "/resources") {
    new Vue({
      el: "#faq",
      render: h => h(Resources)
    });
  } else if (["/inputs/photos", "/inputs/photos/comparison"].includes(window.location.pathname)) {
    // ProgressPhotosComparison, Resources, and Profile need to
    // be updated before they can be add to router.  see clubhouse
    //
    // Clubhouse story ID: 732
    const {
      comparison,
      firstWeek,
      firstYear,
      currentWeek,
      currentYear
    } = document.getElementById("progress-photos-comparison").dataset;

    const context = {
      props: {
        isComparison: true,
        firstWeek,
        firstYear,
        currentWeek,
        currentYear
      }
    };

    new Vue({
      el: "#progress-photos-comparison",
      render: h => h(ProgressPhotosComparison, context)
    });
  } else if (!non_vue_routes.includes(window.location.pathname)) {
    const apolloProvider = new VueApollo({ defaultClient: phx_apollo_client });

    new Vue({
      el: '#vue-app',
      store,
      router,
      apolloProvider,
      render: h => h(App)
    });

    // expose Router and Store as global vars to facilitate debugging/introspection
    window.Router = router;
    window.Store = store;
  }
});
