<template lang="html">
  <div class="profile-container">
    <div class="nav">
      <div class="back-button" @click="$router.push('/profile')">
        <i class="fa fa-chevron-left"></i>
        <p>Summary</p>
      </div>
      <p class="title">Profile</p>
      <div class="logout" @click="logout">
        <p>Logout</p>
      </div>
    </div>
    <div class="content">
      <div class="profile-multi-input-row">
        <div class="profile-input-row">
          <p class="input-label">First Name</p>
          <div class="profile-input disabled">
            <input v-model="user.first_name" :disabled="true" type="text" placeholder="First Name" />
          </div>
        </div>
        <div class="profile-input-row">
          <p class="input-label">Last Name</p>
          <div class="profile-input disabled">
            <input v-model="user.last_name" :disabled="true" type="text" placeholder="Last Name" />
          </div>
        </div>
      </div>
      <div class="profile-input-row">
        <p class="input-label">Email Address</p>
        <div class="profile-input">
          <input v-model="user.email" type="email" placeholder="Email Address" @focus="focused_input = 'email'" @blur="focused_input = ''" />
          <div v-if="focused_input === 'email'" class="clear-button" @click="user.email = ''">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
      <div v-if="focused_input !== 'password' && !user.current_password && !user.new_password" class="profile-input-row">
        <p class="input-label">Password</p>
        <div class="profile-input" @click="focused_input = 'password'">
          <input v-model="user.password" :disabled="true" type="password" placeholder="Password" />
        </div>
      </div>
      <div v-if="focused_input === 'password' || user.current_password || user.new_password" class="profile-input-row">
        <p class="input-label">Old Password</p>
        <div class="profile-input">
          <input v-model="user.current_password" type="password" placeholder="Old Password" />
          <div class="clear-button" @click="user.current_password = ''">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
      <div v-if="focused_input === 'password' || user.current_password || user.new_password" class="profile-input-row">
        <p class="input-label">New Password</p>
        <div class="profile-input">
          <input v-model="user.new_password" type="password" placeholder="New Password" />
          <div class="clear-button" @click="user.new_password = ''">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
      <div class="radios">
        <div :class="{'radio': true, 'active': statistic_set.gender === 'female', 'disabled': true}">
          <div class="indicator">
            <div class="outer">
              <div class="inner"></div>
            </div>
          </div>
          <span>Female</span>
        </div>
        <div :class="{'radio': true, 'active': statistic_set.gender === 'male', 'disabled': true}">
          <div class="indicator">
            <div class="outer">
              <div class="inner"></div>
            </div>
          </div>
          <span>Male</span>
        </div>
      </div>
      <div class="profile-input-row">
        <p class="input-label">Date of Birth</p>
        <div class="profile-input disabled">
          <i class="fa fa-calendar"></i>
          <input v-model="statistic_set.birthday" :disabled="true" type="text" placeholder="Date of Birth" />
        </div>
      </div>
      <div class="profile-multi-input-row">
        <div class="profile-input-row">
          <p class="input-label">Height</p>
          <div class="profile-input disabled">
            <input :value="formatted_height" :disabled="true" type="text" placeholder="Height" />
          </div>
        </div>
        <div class="profile-input-row">
          <p class="input-label">Weight ({{ weight_unit }})</p>
          <div class="profile-input">
            <input v-model.number="statistic_set.weight" type="number" placeholder="Weight" @focus="focused_input = 'weight'" @blur="focused_input = ''" />
            <div v-if="focused_input === 'weight'" class="clear-button" @click="statistic_set.weight = ''">
              <i class="fa fa-times"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-input-row">
        <p class="input-label">Timezone</p>
        <div class="profile-input select">
          <select v-model="preferences.timezone" class="timezone-selector">
            <option v-for="zone in timezones" :value="zone[1]">{{ zone[0] }}</option>
          </select>
          <i class="fa fa-chevron-down"></i>
        </div>
      </div>
      <div class="button" @click="save">SAVE CHANGES</div>
    </div>
  </div>
</template>

<script>
import API from "@js/api/rails_client";
import updateIntercom from "@js/lib/intercom";
import { pick } from "lodash";

export default {
  data() {
    const { email, first_name, last_name, preferences: { preferred_input_weight_unit, timezone } } = this.$store.state.rails.user;

    let statistic_set = pick(this.$store.state.rails.statistic_set, ["weight", "height", "gender", "birthday"]);
    if (preferred_input_weight_unit === "kg") {
      statistic_set.weight = Math.round(statistic_set.weight / 2.205);
    }

    return {
      user: {
        email,
        first_name,
        last_name,
        password: "password",
      },
      statistic_set,
      preferences: { timezone },
      focused_input: null,
      original_weight: statistic_set.weight,
    };
  },
  computed: {
    timezones() {
      return this.$store.state.rails.timezones;
    },
    formatted_height() {
      // TODO handle when preferred unit is metric
      return `${Math.floor(this.statistic_set.height / 12)} ft ${this.statistic_set.height %= 12} in`;
    },
    weight_unit() {
      return this.$store.state.rails.user.preferences.preferred_input_weight_unit;
    }
  },
  methods: {
    async logout() {
      this.$store.dispatch("loading", true);

      try {
        await API.sign_out();
      } catch (e) {
        console.error(e);
      } finally {
        window.location.href = "/";
      }
    },
    async save() {
      try {
        this.$store.dispatch("loading", true);
        // TODO validate presence of inputs

        let { weight } = this.statistic_set;
        if (this.weight_unit === "kg") {
          weight = Math.round(weight * 2.205);
        }

        await Promise.all([
          this.$store.dispatch("rails/update_user_credentials", this.user),
          this.$store.dispatch("rails/update_user_preferences", this.preferences),
          this.$store.dispatch("rails/update_user_statistic_set", { weight })
        ]);
        if (weight !== this.original_weight) {
          updateIntercom({ profile_weight_updated_at: moment().unix(), profile_weight: weight });
        }

        this.$store.dispatch("notify_user", { type: "info", msg: "Profile successfully updated :)" });
        this.$router.push('/profile');
      } catch (e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to update profile." });
        console.error(e);
      } finally {
        this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/components/profile";

.logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 50px;
  padding-right: 16px;
  color: white;
  font-family: 'open sans';
  margin: 0px 0px 0px 8px;
  cursor: pointer;

  p {
    font-size: 12px;
    font-weight: 700;
    margin: 0px;
    text-shadow: 0px 1px rgba(63, 139, 110, 1);
  }
}

.radios {
  margin-bottom: 24px;
  padding: 10px 0px;

  .indicator {
    margin-right: 8px;
  }
}

.timezone-selector {
  width: 100%;
  height: 40px;
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 700;
  border: none;
  background-color: white;
  padding: 0px 0px 0px 8px;
  -webkit-appearance: none;

  &:focus {
    outline: none;
    border: none;
    -webkit-appearance: none;
  }
}

.button {
  align-self: flex-end;
}
</style>
