<template lang="html">
  <!-- conditionally render so if this route is loaded directly, computed props won't break
   before data has been fetched and loaded into store -->
  <div v-if="mtc_challenge" class="container">
    <div class="active-challenge">
      <div class="back-icon" @click="$router.push('/food_log')">
        <img src="https://icons.sfo2.cdn.digitaloceanspaces.com/mtc-back.svg" class="mtc-back-button" alt="Back" />
      </div>

      <div class="header-container">
        <div class="label">
          <div>GOOD JOB</div>
          <div>{{user_first_name}}!</div>
        </div>
        <div class="award">
          <img src="https://sfo2.digitaloceanspaces.com/icons/mtc-award.svg" height="60" width="60" />
        </div>
      </div>

      <div class="day-count">
        <div class="day-label">DAY</div>

        <div class="day-of-challenge">
          <span class="current">{{current_day}}</span>/<span>{{total_days}}</span>
        </div>
      </div>

      <div class="contentful-container">
         <div class="weekly-challenge">
          <p>Weekly Challenge:</p>
        </div>

        <div class="weekly-image">
          <img :src="weeklyChallengeImage" height='240' width='240'>
        </div>
      </div>

      <div class="info-text">
        <p>{{weeklyChallengeText1}}</p>
      </div>
      <div class="info-text">
        <p>{{weeklyChallengeText2}}</p>
      </div>

      <div class="mtc-faq">
        <a href="https://www.macrostax.com/mtc">Challenge FAQ</a>
      </div>

      <div class="goals-header">
        <p>Revisit Your Goals:</p>
      </div>

      <div class="goals-container" v-for="goal in user_goals">
        <div class="green-thumb">
          <img src="https://icons.sfo2.cdn.digitaloceanspaces.com/green-thumb.svg" height="24" width="26">
        </div>
        <div class="goal-value">
          <p>{{ goal.value }}</p>
        </div>
      </div>

      <div class="action-button-container">
        <button id="Intercom" class="stax-chat" >StaxChat</button>
      </div>

      <a href="https://www.facebook.com/groups/staxformation" class="social-media-container">
        <div class="icon">
          <img src="https://icons.sfo2.cdn.digitaloceanspaces.com/fb-logo.png">
        </div>
        <div class="label">
          Join the challenge group on Facebook!
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import Countdown from '@js/food_log/mtc/shared/countdown.vue';
import Header from '@js/food_log/mtc/shared/header.vue';

export default {
  components: { Countdown, Header },
  data() {
    return {
      weeklyChallengeText1: '',
      weeklyChallengeText2: '',
      weeklyChallengeImage: ''
    };
  },
  created() {
    this.$contentful
      .getEntries({'content_type': 'mtc'})
      .then((entry) => {
        let item = entry.items[0];

        this.weeklyChallengeText1 = item.fields.caption;
        this.weeklyChallengeText2 = item.fields.caption2;
        this.weeklyChallengeImage = item.fields.image.fields.file.url
      });
  },
  computed: {
    mtc_challenge() {
      return this.$store.state.rails.mtc_challenge;
    },
    user_first_name() {
      return this.$store.state.rails.user.first_name.toUpperCase();
    },
    current_day() {
      let currentDay = moment().diff(this.mtc_challenge.start_date, "days") + 1;
      if (currentDay > this.total_days) {
        currentDay = this.total_days;
      }
      return currentDay;
    },
    total_days() {
      return moment(this.mtc_challenge.end_date).diff(this.mtc_challenge.start_date, "days") + 1;
    },
    user_goals() {
      const { default_goals, custom_goals } = this.mtc_challenge.user_data;
      return [ ...default_goals, ...custom_goals ];
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  background: white;
  overflow: scroll !important;
  margin-bottom: 64px;

  .active-challenge {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    // until we get fabs into parent vue app
    // iphone was showing fabs above mtc pages
    z-index: 20;
    position: relative;

    .back-icon {
      margin-top: 25px;
      width: 25%;
    }

    .header-container {
      display: flex;
      flex-direction: row;
      margin-top: 9px;

      .label  {
        display: flex;
        flex-direction: column;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: -0.25px;
        color: #000000;
      }

      .award {
        flex-grow: 1;
        margin-left: 50px;
      }
    }

    .day-count {

      .day-label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-top: 15px;
      }

      .day-of-challenge {

        span.current {
          font-size: xxx-large;
        }
      }
    }

    .info-text {

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-top: 24px;
        letter-spacing: 0.5px;
        color: #4F4F4F;
      }
    }

    .mtc-faq {
      margin-top: 10px;
      margin-bottom: 34px;

      a {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #6B40AB;
      }
    }

    .goals-header {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 40px;
      color: #000000;
    }

    .goals-container {
      display: flex;
      flex-direction: row;
      padding-bottom: 31px;

      .green-thumb {
        width: 12%;
        display: flex;
        align-items: flex-start;
        justify-content: left;
      }

      .goal-value {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        border-bottom: 1px solid #D8D8D8;
        padding-bottom: 10px;
        width: 88%;
      }
    }

    .action-button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .stax-chat {
        margin-top: 24px;
        margin-bottom: 23px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        background: #6B40AB;
        border-radius: 22px;
        padding-right: 64px;
        padding-left: 64px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-color: transparent;
      }
    }

    .contentful-container {

      .weekly-challenge {
        display: flex;

        p {
          background: linear-gradient(0.50turn, white 70%, #34B6E4 50% 100%);
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 0px;
          margin-top: 14px;
          color: #000000;
          padding-right: 10px;
        }
      }

      .weekly-image {
        display: flex;
        justify-content: center;
        margin-top: 29px;
      }
    }

    .social-media-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;

      .icon {
        margin-right: 10px;

        img {
          height: 24px;
          width: 24px;
        }
      }

      .label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #4F4F4F;
      }
    }
  }
}
</style>
