<template>
  <div class="filters-container">
    <div class="nav-bar">
      <div class="left-button" @click="close">
        <i class="fa fa-chevron-left fa-lg" />
      </div>
      <div class="title">
        Filters
      </div>
    </div>

    <div class="filters-screen">

      <div class="filters-info-row">
        <p class="count">{{selected_filters.length}} Filters Currently Applied</p>
        <p class="clear-all" @click="clear_filters">Clear All</p>
        <div class="filter-cloud-toggle" @click="toggle_filter_cloud">
          <i :class="{ 'fa': true, 'fa-chevron-down': !filter_cloud_visible, 'fa-chevron-up': filter_cloud_visible }"></i>
        </div>
      </div>

      <FilterCloud
        v-if="filter_cloud_visible"
        :filters="selected_filters"
        @deselect="deselect"
        @reselect="select"
      />

      <div class="select-container">
        <Select
          v-slot:default="slot_props"
          :multi="true"
          :options="dietary_filter_options"
          :name_field="'display_name'"
          @toggle="toggle"
        >
          <div class="select-box">
            <p>Dietary Preferences</p>
            <div class="indicator"/>
            <i v-if="slot_props.expanded" class="fa fa-chevron-up fa-sm icon purple" />
            <i v-else class="fa fa-chevron-down fa-sm icon purple" />
          </div>
        </Select>

        <Select
          v-slot:default="slot_props"
          :multi="true"
          :options="meal_filter_options"
          :name_field="'display_name'"
          @toggle="toggle"
        >
          <div class="select-box">
            <p>Meal Type</p>
            <div class="indicator"/>
            <i v-if="slot_props.expanded" class="fa fa-chevron-up fa-sm icon purple" />
            <i v-else class="fa fa-chevron-down fa-sm icon purple" />
          </div>
        </Select>

        <Select
          v-slot:default="slot_props"
          :options="time_filter_options"
          :name_field="'display_name'"
          @select="select"
          @deselect="deselect"
        >
          <div class="select-box">
            <p>Cook Time</p>
            <div class="indicator"/>
            <i v-if="slot_props.expanded" class="fa fa-chevron-up fa-sm icon purple" />
            <i v-else class="fa fa-chevron-down fa-sm icon purple" />
          </div>
        </Select>

        <Select
          v-slot:default="slot_props"
          :multi="true"
          :options="cook_filter_options"
          :name_field="'display_name'"
          @toggle="toggle"
        >
          <div class="select-box">
            <p>Cooking Method</p>
            <div class="indicator"/>
            <i v-if="slot_props.expanded" class="fa fa-chevron-up fa-sm icon purple" />
            <i v-else class="fa fa-chevron-down fa-sm icon purple" />
          </div>
        </Select>
      </div>

      <div class="filters-footer">
        <div v-if="no_results && selected_filters.length == 0" class="match-warning">
          <p>
            We were unable to find any meals that match your remaining macros for the day.
          </p>
        </div>
        <div v-if="no_results && selected_filters.length > 0" class="match-warning">
          <p>
            We were unable to find any meals that match your selection. Try removing some of the filters you have chosen or clear them all and start fresh.
          </p>
          <div class="reset-button-container">
            <div class="reset-button" @click="clear_filters">Clear Filters</div>
          </div>
        </div>
        <div :class="{ 'save-filters-button': true, loading: loading, disabled: results_count === 0 } " @click="close">
          <p v-if="results_count === 0">No results</p>
          <p v-else>See {{ results_count }} results</p>
          <template v-if="loading">
            <Spinner
              :radius="18"
              :stroke="4"
              :logo="false"
              :forward="true"
              :loop="true"
              :start_color="'#6b40ab'"
              :end_color="'#6b40ab'"
            />
          </template>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import _ from "lodash";

import Select from "@js/shared/select.vue";
import FilterCloud from "@js/recipes/filter_cloud.vue";
import Spinner from "@js/recipes/spinner.vue";

export default {
  components: { FilterCloud, Select, Spinner },
  props: {
    loading: { type: Boolean, required: true },
    results_count: {type: Number, required: true},
    context: {type: String, required: true, validator: val => ["match", "search"].includes(val)},
    close: {type: Function, required: true}
  },
  data() {
    return {
      filter_cloud_visible: true
    };
  },
  computed: {
    filters() {
      return this.$store.state.filters;
    },
    selected_filter_ids() {
      return this.$store.state.selected_filter_ids;
    },
    selected_filters() {
      return this.$store.getters.selected_filters;
    },
    no_results() {
      return !this.loading && this.results_count === 0;
    },
    dietary_filter_options() {
      return this.$store.getters.dietary_filters.map(filter => (
        { ...filter, selected: this.selected_filter_ids.includes(filter.id) }
      ));
    },
    time_filter_options() {
      return this.$store.getters.time_filters.map(filter => (
        { ...filter, selected: this.selected_filter_ids.includes(filter.id) }
      ));
    },
    meal_filter_options() {
      return this.$store.getters.meal_filters.map(filter => (
        { ...filter, selected: this.selected_filter_ids.includes(filter.id) }
      ));
    },
    cook_filter_options() {
      return this.$store.getters.cook_filters.map(filter => (
        { ...filter, selected: this.selected_filter_ids.includes(filter.id) }
      ));
    }
  },
  methods: {
    toggle(filter_id) {
      const is_selected = this.selected_filter_ids.includes(filter_id);

      if (is_selected) {
        this.deselect(filter_id);
      } else {
        this.select(filter_id);
      }
    },
    select(filter_id, selection_value) {
      this.$store.dispatch("select_filter", filter_id);
    },
    deselect(filter_id) {
      this.$store.dispatch("deselect_filter", filter_id);
    },
    clear_filters() {
      this.$store.dispatch("deselect_all_filters");
    },
    toggle_filter_cloud() {
      this.filter_cloud_visible = !this.filter_cloud_visible;
    }
  }
};
</script>

<style scoped lang="scss">
  @import "@css/_reset.scss";
  @import "@css/_tracker_reset.scss";

  .purple {
    color: $endurance;
  }
  .white {
    color: #ffffff;
  }

  .matches-loader {
    margin-top: 7rem;
  }

  button {
    background-color: $sturdy;
    &.selected {
      background-color: $movement;
    }
  }

  .filters-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding: 0px;
    background-color: #ffffff;
    z-index: 50;
  }

  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    background-color: #00A881;

    .left-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 58px;
      height: 58px;
      cursor: pointer;

      i {
        color: #ffffff;
      }
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
    }
  }

  .filters-info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px;
    margin: 0px 0px 12px 0px;
    .count {
      font-size: 12px;
      font-weight: 600;
      color: #000000;
      margin: 0px 16px 0px 0px;
    }
    .clear-all {
      font-size: 12px;
      font-weight: 600;
      color: $endurance;
      margin: 0px 12px 0px 0px;
    }
    .filter-cloud-toggle {
      margin-left: auto;
      padding: 4px 0px 4px 10px;
      i {
        font-size: 14px;
        color: $endurance;
      }
    }
  }

  .filters-container {
    transition: max-height 0.3s ease;
    &.minimize {
      max-height: 55px;
    }
  }

  .results-count {
    padding: 2px 24px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    color: #4f4f4f;
  }

  .select-container {
    padding: 8px 24px;

    .select-box {
      display: flex;
      align-items: center;
      padding: 16px 12px;
      border-bottom: 1px solid #e2e2e2;
      margin-top: 8px;
      cursor: pointer;

      p {
        margin: 0px 8px 0px 0px;
      }
      .indicator {
        display: none;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #FFCD3C;
        margin-right: 8px;
      }
      .icon {
        margin-left: auto;
      }
    }
  }

  .filters-screen {
    display: flex;
    flex-direction: column;
    height: calc(100% - 56px);
    text-align: center;
    padding-top: 16px;
  }

  .filters-footer {
    // margin-top: auto;
    position: fixed;
    width: 100%;
    max-width: 450px;
    bottom: 84px;
  }

  .save-filters-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 24px 24px 24px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
    background-color: $endurance;
    border-radius: 20px;
    padding: 0px 16px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.20);
    cursor: pointer;
    &.disabled {
      background-color: #979797;
      cursor: not-allowed;
    }

    p {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
    }

    &.loading {
      background-color: #ffffff;
      border: 1px solid $endurance;

      p {
        margin-right: 16px;
        color: $endurance;
      }
    }
  }

  .match-warning {
    font-family: 'Open Sans';

    p {
      color: #4f4f4f;
      line-height: 1.2rem;
      font-size: 14px;
      line-height: 20px;
      padding: 16px 24px 0px 24px;
      text-align: left;
    }

    .reset-button-container {
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 16px 24px;

      .reset-button {
        color: $energy;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: auto;
        cursor: pointer;
      }
    }
  }

</style>
