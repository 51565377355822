<template lang="html">
  <div class="new-modal-backdrop" @click="close">
    <div class="new-modal-card" v-on:click.stop>
      <div class="close-button-top" @click="close">
        <i class="fa fa-times"></i>
      </div>

      <div class="new-modal-content">
        <p class="modal-header">{{ header_text }}</p>
        <p v-if="sub_header_text" class="modal-sub-header">{{ sub_header_text }}</p>
        <p class="modal-text" v-html="explanation"></p>
      </div>

      <div class="close-button-bottom">
        <p @click="close">{{ close_button_text }}</p>
        <p v-if="action" @click="action">CONFIRM</p>
      </div>
    </div>
  </div>
</template>

<script>
import { startCase } from "lodash";

const explanations = {
  phase_info: {
    cut_1: "You are on the starting phase of Fat Loss. Stay here until you have reached a plateau, then move to Phase 2.",
    cut_2: "You are on the second phase of Fat Loss. This should only be selected if you have plateaued at Phase 1.",
    cut_3: "You are on the last phase of Fat Loss. This should only be selected if you have plateaued at Phases 1 and 2.",
    gain_1: "You are on the starting phase of Muscle Gain. Stay here until you have reached a plateau, then move to Phase 2.",
    gain_2: "You are on the second phase of Muscle Gain. This should only be selected if you have plateaued at Phase 1.",
    gain_3: "You are on the last phase of Muscle Gain. This should only be selected if you have plateaued at Phases 1 and 2.",
    maint_1: "This is the first phase of Maintenance, used as reverse dieting to work your way up to true maintenance (Phase 4).",
    maint_2: "This is the second phase of Maintenance, used as reverse dieting to work your way up to true maintenance (Phase 4).",
    maint_3: "This is the third phase of Maintenance, used as reverse dieting to work your way up to true maintenance (Phase 4).",
    maint_4: "This is the true maintenance phase. Stay here if you want to maintain weight or fuel for performance."
  },
  program_info: {
    "fat loss":
      `The Fat Loss plan features progressive phases to help you lose weight sustainably over time. Always start with Phase 1!
      <br>
      <br>
      When you are ready, you will move to Phase 2, and then Phase 3. The rate at which you move through the phases depends on your progress and varies per person. Reach out to a nutrition coach in StaxChat if you need assistance.`,
    "weight gain":
      `The Muscle Gain plan features progressive phases to help you increase mass sustainably over time. When you first start the Muscle Gain program, you will always begin on Phase 1. 
      <br>
      <br>
      When you are ready, you will move to Phase 2, and then Phase 3. The rate at which you move through the phases depends on your progress and varies per person. Reach out to a nutrition coach in StaxChat if you need assistance.`,
    "maintain":
      `The Maintenance plan features 4 different phases. If you are looking for true maintenance, select Maintenance 4. Maintenance phases 1 through 3 are typically used as reverse dieting. If you need assistance, reach out to StaxChat.`
  },
  phase_change: {
    "fat loss": "It is important to stay on the current phase for as long as possible, starting with Phase 1. For further assistance, please reach out to StaxChat.",
    "weight gain": "It is important to stay on the current phase for as long as possible, starting with Phase 1. For further assistance, please reach out to StaxChat.",
    "maintain": "If you are completing a reverse diet, you may progress through Maintenance Phases 1-3 as you see fit. Please note that Maintenance 4 is the true maintenance phase."
  }
};

// should be able to just use startCase(this.goal), but names in DB records are inconsistent :/
const goal_titles = {
  "fat loss": "Fat Loss",
  "weight gain": "Muscle Gain",
  maintain: "Maintenance"
};

export default {
  components: {},
  props: {
    type: { type: String, required: true },
    goal: { type: String, required: true },
    phase: { type: String },
    action: { type: Function },
    close: { type: Function, required: true }
  },
  created() {
    if (!this.$route.path.match("/profile")) {
      // this component is nested less deeply when rendered from /profile so .new-modal-backdrop
      // z-index will overlay NavFooter and dispatching this action to toggle NavFooter's
      // .modal-backdrop will double-apply the opacity
      this.$store.dispatch("set_modal_visible", true);
    }
  },
  beforeDestroy() {
    if (!this.$route.path.match("/profile")) {
      this.$store.dispatch("set_modal_visible", false);
    }
  },
  computed: {
    phase_number() {
      return this.phase.slice(-1);
    },
    header_text() {
      return this.type === "phase_change" ? "Change Phases" : goal_titles[this.goal];
    },
    sub_header_text() {
      return this.type === "phase_info" && `Phase ${this.phase_number}`;
    },
    close_button_text() {
      return this.type === "phase_change" ? "CANCEL" : "CLOSE";
    },
    explanation() {
      const explanation = explanations[this.type];
      return this.type === "phase_info" ? explanation[this.phase] : explanation[this.goal];
    }
  }
};
</script>

<style scoped lang="scss">
  .new-modal-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    .new-modal-card {
      position: relative;
      width: 80%;
      max-width: 375px;
      max-height: 85%;
      background-color: white;
      border-radius: 12px;
      overflow: auto;
      .new-modal-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 30px 0px 0px 0px;
        padding: 0px 20px 14px 20px;
        .modal-header {
          color: #000000;
          font-size: 18px;
          font-weight: 700;
          margin: 0px 0px 12px 0px;
          text-align: left;
        }
        .modal-sub-header {
          color: #000000;
          font-size: 15px;
          font-weight: 600;
          margin: 0px 0px 8px 0px;
          text-align: left;
        }
        .modal-text {
          color: #4f4f4f;
          font-size: 12px;
          font-weight: 500;
          margin: 0px 0px 12px 0px;
          text-align: left;
        }
      }
      .close-button-top {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 14px;
        cursor: pointer;
        i {
          color: $endurance;
          font-size: 15px;
        }
      }
      .close-button-bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        //padding: 14px;
        cursor: pointer;
        p {
          font-family: Montserrat;
          color: $endurance;
          font-size: 15px;
          font-weight: 700;
          margin: 0px;
          padding: 14px;
        }
      }
    }
  }
</style>
