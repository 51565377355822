<template lang="html">
  <div :id="`recipe-details${recipe && recipe.user_id ? '-custom' : ''}`">
    <LoadingScreen
      v-if="loading"
      :icon="'carrot_calculator'"
      :title="'Hang Tight!'"
      :description="'We’re calculating a portion size just perfect for YOU.'"
    />

    <template v-if="recipe && recipe.user_id">

      <div class="nav-bar">
        <div class="left-button" @click="goBack()">
          <i class="fa fa-chevron-left fa-lg" />
          Back
        </div>
      </div>

      <div v-scroll="on_scroll" class="recipe-details-content">
        <div class="body">
          <div class="info">
            <div class="info-inner">
              <img :src="recipe.photo_urls.large" class="recipe-photo">
              <div class="name-and-macros">
                <h3 class="name" v-text="recipe.name" />

                <Macros
                  :food="recipe"
                  :quantity="recipe.eat_quantity"
                  :cookLossFactor="recipe.cook_loss_factor"
                  :showQuantity="false"
                  :initials="true"
                  :is-recipe="true"
                />

                <div v-if="recipe.prep_time" class="prep-time">
                  <img :src="cook_time_icon" class="icon" />
                  {{ formattedMinutes(recipe.prep_time) }} prep
                  <div v-if="recipe.table_time !== recipe.prep_time" class="separator">|</div>
                  <span v-if="recipe.table_time !== recipe.prep_time">
                    {{ formattedMinutes(recipe.table_time) }} total
                  </span>
                </div>

                <div v-if="simple_recipe" class="eat-make">
                  <img :src="serving_icon" class="icon" />
                  <div class="eat-make-inner">
                    <div v-if="simple_recipe.show_make" class="makes-container">
                      <p>Makes</p>
                      <Macros
                        :food="simple_recipe"
                        :quantity="simple_recipe.makeQuantity"
                        :quantityKind="simple_recipe.quantity_kind"
                        :cookLossFactor="simple_recipe.cook_loss_factor"
                        :initials="true"
                        :only="'nada!'"
                        class="amount inline macrostax-font"
                      />
                    </div>
                    <div v-if="simple_recipe.show_make" class="separator">|</div>
                    <div class="eat-container has-border">
                      <p>Eat</p>
                      <Macros
                        :food="simple_recipe"
                        :gramStep="1"
                        :quantity="simple_recipe.eatQuantity"
                        :quantityKind="simple_recipe.quantity_kind"
                        :cookLossFactor="simple_recipe.cook_loss_factor"
                        :initials="true"
                        :only="'nada!'"
                        class="amount inline macrostax-font"
                      />
                    </div>
                  </div>
                </div>

                <router-link
                  v-if="recipe.user_id === this.user_id"
                  :to="`/custom/recipes/${recipe.id}/edit`"
                  class="edit-button"
                >
                  Edit Recipe
                </router-link>
              </div>
            </div>

            <div v-if="recipe.is_bad_match" class="match-warning">
              <p>
                Please adjust the serving size to your preferred portion.
              </p>
            </div>

            <div v-if="recipe.intro_line" class="section intro-line" v-text="recipe.intro_line" />

            <div class="add-to-tracker macrostax-font">
              <div class="add-to-tracker-button" @click="open_add_to_meal">Add to Food Log</div>
            </div>
          </div>

          <DetailSections
            :recipe="recipe"
            :is_multi_recipe="is_multi_recipe"
            :children="recipe_components"
            :sanitize="sanitize"
          />
        </div>
      </div>
    </template>

    <template v-if="recipe && !recipe.user_id">
      <div class="nav-bar-container" :class="{ 'scrolled-to-body': scrolled_to_body } ">
        <div class="left-button" @click="goBack()">
          <i class="fa fa-chevron-left fa-lg" />
        </div>
        <div class="right-button" @click="goBack()">
          <i class="fa fa-times fa-lg" />
        </div>
      </div>

      <div v-scroll="on_scroll" class="recipe-details-content">
        <div class="recipe-header">
          <img :src="recipe.photo_urls.large" class="photo">
        </div>

        <div class="body">
          <div class="info">
            <div class="name-and-macros">
              <h3 class="name" v-text="recipe.name" />

              <Macros
                :food="recipe"
                :quantity="recipe.eat_quantity"
                :cook-loss-factor="recipe.cook_loss_factor"
                :show-quantity="false"
                :initials="true"
              />

              <div v-if="recipe.prep_time" class="prep-time">
                <img :src="cook_time_icon" class="icon" />
                {{ formattedMinutes(recipe.prep_time) }} prep
                <div v-if="recipe.table_time !== recipe.prep_time" class="separator">|</div>
                <span v-if="recipe.table_time !== recipe.prep_time">
                  {{ formattedMinutes(recipe.table_time) }} total
                </span>
              </div>

              <div v-if="simple_recipe" class="eat-make">
                <img :src="serving_icon" class="icon" />
                <div class="eat-make-inner">
                  <div v-if="simple_recipe.show_make" class="makes-container">
                    <p>{{ simple_recipe.user_id ? "Total Servings" : "Makes" }}</p>
                    <Macros
                      :food="simple_recipe"
                      :quantity="simple_recipe.makeQuantity"
                      :quantity-kind="simple_recipe.quantity_kind"
                      :cook-loss-factor="simple_recipe.cook_loss_factor"
                      :initials="true"
                      :only="'nada!'"
                      class="amount inline macrostax-font"
                    />
                  </div>
                  <div v-if="simple_recipe.show_make" class="separator">|</div>
                  <div class="eat-container has-border">
                    <p>{{ simple_recipe.user_id ? "Single Serving" : "Eat" }}</p>
                    <Macros
                      :food="simple_recipe"
                      :gram-step="1"
                      :quantity="simple_recipe.eatQuantity"
                      :quantity-kind="simple_recipe.quantity_kind"
                      :cook-loss-factor="simple_recipe.cook_loss_factor"
                      :initials="true"
                      :only="'nada!'"
                      class="amount inline macrostax-font"
                    />
                  </div>
                </div>
              </div>
              <router-link
                v-if="recipe.user_id === this.user_id"
                :to="`/custom/recipes/${recipe.id}/edit`"
                class="edit-button"
              >
                Edit Recipe
              </router-link>
            </div>

            <div v-if="recipe.is_bad_match" class="match-warning">
              <p>
                Please adjust the serving size to your preferred portion.
              </p>
            </div>

            <div v-if="recipe.intro_line" class="section intro-line" v-text="recipe.intro_line" />

            <div class="add-to-tracker macrostax-font">
              <img :src="carrot_icon" class="carrot">
              <div class="add-to-tracker-button" @click="open_add_to_meal">ADD TO FOOD LOG</div>
            </div>
          </div>

          <DetailSections
            :recipe="recipe"
            :is_multi_recipe="is_multi_recipe"
            :children="recipe_components"
            :sanitize="sanitize"
          />
        </div>
      </div>
    </template>

    <AddToMealModal
      v-if="add_to_meal_visible"
      :recipe="recipe"
      :default_meal_id="meal_id"
      :add_to_meal="add_recipe_to_meal"
      :close="() => { add_to_meal_visible = false }"
    />
  </div>
</template>

<script>
import moment from "moment";

import recipe_details_query from "@js/api/graphql/recipe_details_query";

import Macros from "@js/shared/macros.vue";
import Select from "@js/shared/select.vue";
import LoadingScreen from "@js/recipes/loading_screen.vue";
import DetailSections from "@js/recipes/detail_sections.vue";
import AddToMealModal from "@js/recipes/add_to_meal_modal.vue";

const MEAL_IDS = ["meal-1", "meal-2", "meal-3", "meal-4", "meal-5"];

export default {
  components: { DetailSections, Macros, Select, LoadingScreen, AddToMealModal },
  props: {
    recipe_id: { type: Number, required: true },
    meal_id: { type: String, required: false, validator: val => MEAL_IDS.includes(val) },
    macro_targets: { type: Object },
    source: { type: String }
  },
  data() { // look at selected_meal_id and other add_to_meal logic
    return {
      is_visible: true,
      recipe: null,
      loading: true,
      preserveRecipeSearch: false,
      add_to_meal_visible: false,
      selected_meal_id: this.meal_id,
      scrolled_to_body: false,
      user_id: this.$store.state.rails.user.id,
      serving_icon: "https://d38il9bndsnkm4.cloudfront.net/assets/images/icons/serving.svg",
      cook_time_icon: "https://d38il9bndsnkm4.cloudfront.net/assets/images/icons/cook-time.svg",
      carrot_icon: "https://d38il9bndsnkm4.cloudfront.net/assets/images/icons/carrot.png"
    };
  },
  computed: {
    selected_meal() {
      return this.meal_select_options.find(option => option.id === this.selected_meal_id);
    },
    is_multi_recipe() {
      return this.recipe.child_recipes.length > 0;
    },
    simple_recipe() {
      return this.is_multi_recipe ? false : this.recipe_components[0];
    },
    recipe_components() {
      const components = this.is_multi_recipe ? this.recipe.child_recipes : [this.recipe];

      return components.map((child) => {
        let quantity_kind;

        if (this.recipe.user_id) {
          quantity_kind = child.conversion.name.toLowerCase().match("gram") ? "gram" : "common";
        } else {
          quantity_kind = child.is_discrete || child.miscible ? "common" : "gram";
        }

        // TODO (from Graham): why is this necessary? Borderline cases should just make the whole bulk meal?
        let show_make = child.is_bulk || child.make_quantity !== child.eat_quantity;
        let eatQuantity = this.eatQuantity(child);
        let makeQuantity = this.makeQuantity(child);

        return {
          ...child,
          show_make,
          quantity_kind,
          eatQuantity,
          makeQuantity
        };
      });
    }
  },
  async created() {
    const macro_targets = this.macro_targets || this.$store.getters["rails/default_portion_macro_targets"];

    try {
      const { data } = await this.$apollo.query({
        query: recipe_details_query,
        fetchPolicy: "no-cache",
        variables: { id: this.recipe_id, macro_targets }
      })

      this.recipe = data.recipe_details;
      this.loading = false;
    } catch(e) {
      console.error(e);
      this.$router.back();
      this.$store.dispatch("notify_user", { type: "error", msg: "Unable to load recipe" });
    }
  },
  destroyed() {
    if (!this.preserveRecipeSearch) {
      // user navigated away from RecipeDetails and isn't returning to Recipes or FillMacros, so
      // don't preserve search/match results
      localStorage.removeItem("preserveRecipeSearch");
    }
  },
  methods: {
    // TODO do this server-side
    makeQuantity(child) {
      return child.is_miscible && child.is_bulk && child.custom_serving_name ? 1 : child.make_quantity;
    },
    // TODO do this server-side
    eatQuantity(child) {
      return child.is_miscible && child.custom_serving_name ? 1 : child.eat_quantity;
    },
    on_scroll(event, position) {
      if (position.scrollTop <= 300) {
        this.scrolled_to_body = false;
      } else {
        this.scrolled_to_body = true;
      }
    },
    formattedMinutes(minutes) {
      let duration = new moment.duration({"minutes": minutes});
      if (minutes > 60) {
        return `${duration.hours()}h ${duration.minutes()}m`;
      } else {
        return `${minutes} mins`;
      }
    },
    sanitize(html) {
      let options = {
        allowedTags: ["p", "ol", "ul", "li", "h1", "h2", "h3", "h4", "h5", "a", "i", "em", "strong", "b", "bold", "br", "code", "blockquote", "s", "pre", "span", "hr"]
      };
      return this.$sanitize(html, options);
    },
    goBack() {
      this.preserveRecipeSearch = true;
      this.$router.back();
    },
    add_recipe_to_meal(recipe, meal_id) {
      this.selected_meal_id = meal_id;
      this.$store.dispatch("rails/log_recipe_portions", { recipe: this.recipe, meal_id });
      this.$store.dispatch("notify_user", {
        type: "info",
        msg: "Recipe has been successfully added to your food log :)"
      });
      this.$router.push({ path: "/food_log", query: { meal_id } });
    },
    open_add_to_meal() {
      if (this.meal_id) {
        // if user opened RecipeDetails from a meal card, immediately add this recipe to that meal
        this.add_recipe_to_meal(this.recipe, this.meal_id);
      } else {
        // otherwise open the AddToMealModal
        this.add_to_meal_visible = true;
      }
    }
  }
};

</script>

<style scoped lang="scss">
  @import "@css/_tracker_reset.scss";

  #recipe-details-custom {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .nav-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 58px;
      min-height: 58px;
      background-color: #ffffff;
      &.favorites {
        background-color: #00A881;
        .left-button {
          top: 0px;
          left: 0px;
          i {
            color: #ffffff;
            margin: 0px;
          }
        }
        .title {
          color: #ffffff;
        }
      }

      .left-button, .right-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 58px;
        height: 58px;
        cursor: pointer;
        color: $endurance;
        font-size: 12px;
      }
      .left-button {
        top: 0px;
        left: 12px;
        i {
          margin-right: 8px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
    }

    .recipe-details-content {
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      background-color: #ffffff;
    }

    .preview {
      font-size: 0.9rem;
      font-style: italic;
      text-align: center;
      margin-bottom: 0.5rem;
    }

    .recipe-header {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .photo {
        width: 100%;
        height: 350px;
        object-fit: cover;
      }
    }

    .body {
      position: relative;
      background-color: #ffffff;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      overflow: hidden;
      margin-bottom: 64px;
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0px 24px 14px 24px;
      border-bottom: 1px solid #e2e2e2;
      .info-inner {
        display: flex;
        flex-direction: row;
      }
      .recipe-photo {
        width: 80px;
        height: 80px;
        margin-right: 12px;
        border-radius: 8px;
        object-fit: cover;
      }
      .name {
        margin-bottom: 6px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        font-family: "Montserrat";
        color: #000000;
      }
      .macros {
        margin: 0px;
      }
      .prep-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        color: #4f4f4f;
        font-size: 14px;
        font-weight: 500;
        .icon {
          width: 22px;
          height: 22px;
          margin-right: 12px;
        }
        .separator {
          margin: 0px 3px;
        }
      }
      .edit-button {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: $endurance;
        margin-top: 10px;
        margin-bottom: 12px;
        cursor: pointer;
        display: inline-block;
      }
      .eat-make {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2px;
        .icon {
          width: 22px;
          height: 22px;
          margin-right: 12px;
        }
        .eat-make-inner {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .separator {
            margin: 0px 3px;
          }
          .makes-container, .eat-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 0px;
            &.has-border {
              border-bottom: 2px solid $movement;
            }
            p {
              font-size: 12px;
              font-style: italic;
              font-weight: 500;
              margin: 0px 6px 0px 0px;
              color: #000;
            }
            .amount {
              font-size: 12px !important;
              font-style: normal !important;
              font-weight: 600 !important;
            }
          }
        }
      }
      .intro-line {
        margin-top: 12px;
        font-size: 15px;
        font-weight: 500;
        color: #4f4f4f;
        letter-spacing: 0.5px;
      }
      .match-warning {
        margin: 0.5rem 0;
        margin-top: 1rem;

        p {
          margin: 0px;
        }
      }
      .add-to-tracker {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 0px;
        margin: 0px;
        width: 100%;
        img {
          height: 45px;
          margin-right: 12px;
        }
        .add-to-tracker-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 0px 32px;
          background-color: $energy;
          border-radius: 20px;
          color: #ffffff;
          font-size: 15px;
          font-family: "Open Sans";
          line-height: 20px;
          font-weight: 700;
          cursor: pointer;
          margin-top: 18px;
        }
      }
    }
  }

  #recipe-details {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .nav-bar-container {
      position: absolute;
      top: 0px;
      width: 100%;
      background-color: transparent;
      z-index: 2;
      &.scrolled-to-body {
        .left-button, .right-button {
          color: $energy;
          text-shadow: none;
        }
      }
      .left-button, .right-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        cursor: pointer;
        color: white;
        background-color: rgba(255, 255, 255, 0);
        transition: all 0.5s;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
      }
      .left-button {
        top: 0px;
        left: 0px;
        border-bottom-right-radius: 5px;
      }
      .right-button {
        top: 0px;
        right: 0px;
        border-bottom-left-radius: 5px;
      }
    }
    .recipe-details-content {
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      background-color: #ffffff;
    }
    .preview {
      font-size: 0.9rem;
      font-style: italic;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    .recipe-header {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .photo {
        width: 100%;
        height: 350px;
        object-fit: cover;
      }
    }
    .body {
      position: relative;
      background-color: #ffffff;
      margin-top: -35px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      overflow: hidden;
      margin-bottom: 64px;
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 14px 24px;
      border-bottom: 1px solid #e2e2e2;
      .name {
        margin-bottom: 12px;
        font-weight: bold;
      }
      .macros {
        margin: 0px;
        font-size: 13px;
        ::v-deep div {
          color: #4f4f4f !important;
        }
      }
      .prep-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        color: #4f4f4f;
        font-size: 14px;
        font-weight: 500;
        .icon {
          width: 22px;
          height: 22px;
          margin-right: 12px;
        }
        .separator {
          margin: 0px 3px;
        }
      }
      .edit-button {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: $endurance;
        margin-top: 10px;
        margin-bottom: 12px;
        cursor: pointer;
        display: inline-block;
      }
      .eat-make {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        .icon {
          width: 22px;
          height: 22px;
          margin-right: 12px;
        }
        .eat-make-inner {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .separator {
            margin: 0px 3px;
          }
          .makes-container, .eat-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 0px;
            &.has-border {
              border-bottom: 2px solid $movement;
            }
            p {
              font-size: 13px;
              font-style: italic;
              font-weight: 500;
              margin: 0px 6px 0px 0px;
            }
            .amount {
              font-size: 13px;
              font-style: normal !important;
              font-weight: 700 !important;
            }
          }
        }
      }
      .intro-line {
        margin-top: 24px;
        font-size: 15px;
        font-weight: 500;
        font-style: italic;
        color: #4f4f4f;
      }
      .match-warning {
        margin: 0.5rem 0;
        margin-top: 1rem;
      }
      .add-to-tracker {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14px 0px;
        margin: 0px;
        width: 100%;
        img {
          height: 45px;
          margin-right: 12px;
        }
        .add-to-tracker-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 35px;
          background-color: $energy;
          border-radius: 17px;
          color: #ffffff;
          font-size: 15px;
          line-height: 20px;
          font-weight: 700;
          cursor: pointer;
          margin-top: 6px;
        }
      }
    }
  }
</style>
