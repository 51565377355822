<template>

  <div v-bind:class="{'modal-visible': showPhotoPreview}" class="preview-modal" @click="closePhotoPreview">
    <LoadingSpinner v-if="loading" />
    <div class="header" v-on:click.stop>
      <div class="close-button" @click="closePhotoPreview">
        <i class="fa fa-times" />
      </div>
      <p>
        <span v-if="cropperEnabled">Crop Photo</span>
        <span v-else>View Photo</span>
      </p>
      <div v-if="cropperEnabled" class="rotate-button" @click="rotate">
        Rotate
      </div>
      <div v-if="!cropperEnabled" class="rotate-button" @click="cropExistingPhoto">
        Crop
      </div>
    </div>

    <div class="modal-toolbar">
      <div v-if="cropperEnabled" class="button-wrap relative" v-on:click.stop>
        <button class="button-primary accent-button cancel-button" @click="closePhotoPreview">
          <i class="fa fa-times button-icon" />Cancel
        </button>
        <button class="button-primary accent-button" @click="savePhoto">
          <i class="fa fa-save button-icon" />Save
        </button>
      </div>
      <div v-if="!cropperEnabled" class="button-wrap relative" v-on:click.stop>
        <button class="button-primary accent-button delete-button" @click="$emit('deletePhoto')">
          <i class="fa fa-trash button-icon" />Delete
        </button>
        <button class="button-primary accent-button" @click="$emit('replacePhoto')">
          <i class="fa fa-camera button-icon" />Replace
        </button>
      </div>
    </div>

    <div class="cropperjs-container" v-on:click.stop>
      <vue-cropper
        v-bind:class="{ 'modal-visible': cropperEnabled }"
        ref="cropper"
        drag-mode="crop"
        :guides="true"
        :view-mode="2"
        :aspect-ratio="3/4"
        :background="true"
        :modal="true"
        :rotatable="true"
        :check-cross-origin="false"
        :check-orientation="true"
        alt="Source Image"
        :ready="cropperReady"
        :img-style="{ 'width': '100%', 'height': 'calc(100vh - 80px - 60px)', 'object-fit': 'contain', 'visibility': 'hidden'}"
      >
      </vue-cropper>
      <img v-if="!cropperEnabled" class="preview-img" :src="previewImage" />
    </div>



  </div>

</template>
<script>

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import logo from "@images/favicon_blue.svg";
import updateIntercom from "@js/lib/intercom";
import LoadingSpinner from '@js/app/loading_spinner.vue';

export default {
  components: { VueCropper, LoadingSpinner },
  props: {
    previewImageProp: String,
    currentFormProp: Element,
    currentFileProp: File,
    currentPhotoProp: Object
  },
  data: function() {
    return {
      showPhotoPreview: false,
      cropperEnabled: false,
      loading: false
    };
  },
  computed: {
    previewImage() {
      return this.previewImageProp;
    },
    currentForm() {
      return this.currentFormProp;
    },
    currentFile() {
      return this.currentFileProp;
    },
    currentPhoto() {
      return this.currentPhotoProp;
    }
  },
  mounted() {
    document.addEventListener('orientationchange', (ev) => {
      if (this.cropperEnabled) {
        setTimeout(() => {
          const containerData = this.$refs.cropper.getContainerData();
          this.$refs.cropper.zoomTo(0, {
            x: containerData.width / 2,
            y: containerData.height / 2
          });

          this.cropperReady();
        }, 300);
      }
    });
  },
  methods: {
    openPhotoPreview(img) {
      if (img) {
        this.enableCropper(img);
      } else {
        this.disableCropper();
      }

      $(".app-container").css("overflow", "hidden");
      this.loading = false;
      this.showPhotoPreview = true;
    },
    closePhotoPreview() {
      this.disableCropper();

      $(".app-container").css("overflow", "auto");
      this.showPhotoPreview = false;
    },
    cropperReady() {
      $(".cropper-bg").css({"background-image": `url(${logo})`, "background-repeat": "repeat", "background-size": "50px", "background-color": "#fff", "background-position": "center top"});
      let canvasWidth = this.$refs.cropper.getCanvasData().width;
      let canvasHeight = this.$refs.cropper.getCanvasData().height;
      this.$refs.cropper.setCropBoxData({ width: canvasWidth });

      let newCropBoxWidth = this.$refs.cropper.getCropBoxData().width;
      let newCropBoxHeight = this.$refs.cropper.getCropBoxData().height;
      let cropBoxLeftPos = (canvasWidth / 2) - (newCropBoxWidth / 2);
      let cropBoxTopPos = (canvasHeight / 2) - (newCropBoxHeight / 2);

      this.$refs.cropper.setCropBoxData({ left: cropBoxLeftPos, top: cropBoxTopPos });

      this.loading = false;
    },
    cropExistingPhoto() {
      // window.showSpinner();
      this.loading = true;
      $.ajax({
        url: `/inputs/photos/get_base64?id=${this.currentPhoto.id}`,
        data: {},
        dataType: "json",
        type: "GET",
        success: function(data) {
          // window.hideSpinner();
          this.loading = false;
          let base64 = `data:image/png;base64,${data.base64}`;
          this.enableCropper(base64);
        }.bind(this),
        error: function(err) {
          this.loading = false;
          // window.hideSpinner();
          console.log(err);
        }
      });
    },
    enableCropper(img) {
      this.$refs.cropper.replace(img);
      this.cropperEnabled = true;
      this.loading = false;
    },
    disableCropper() {
      this.cropperEnabled = false;
      $(".cropper-container").hide();
      let $cropperImage = $(this.$refs.cropper.$el.children[0]);
      $cropperImage.removeClass("cropper-hidden");
      this.loading = false;
    },
    rotate() {
      this.$refs.cropper.rotate(90);
    },
    savePhoto() {
      let fileType = "image/png";
      if (this.currentFile) {
        fileType = this.currentFile.type;
      } else if (this.currentPhoto) {
        fileType = this.currentPhoto.content_type;
      }
      this.loading = true;
      updateIntercom({ photos_updated_at: moment().unix(), has_photos: true });
      this.$emit("savePhoto", this.$refs.cropper.getCroppedCanvas({
        maxWidth: 2048,
        maxHeight: 2048,
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high',
      }).toDataURL(fileType, 0.5));
      // this.$emit("savePhoto", this.$refs.cropper.getCroppedCanvas().toDataURL(fileType));
    }
  }
};

</script>
<style scoped lang="scss">

@import "@css/vendors/_include-media";

@import "@css/base/_functions.scss";
@import "@css/base/_mixins.scss";
@import "@css/base/_helpers.scss";

.preview-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 90;
  .header {
    width: 100%;
    height: 60px;
    background-color: transparent;
    margin: 0px;
    p {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      width: 100%;
      line-height: 60px;
      margin: 0px;
    }
    .close-button {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 60px;
      width: 60px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .rotate-button {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 60px;
      padding: 10px 15px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .cropperjs-container {
    display: block;
    position: relative;
    //width: 100%;
    //height: calc(100vh - 80px - 60px);
    height: calc(100% - 110px);
    vue-cropper {
      img {
        width: 100%; /* From CropperJS: This rule is very important, please do not ignore this! */
      }
    }
    .cropper-container {
      width: 100%;
      height: 100%;
    }
    .cropper-crop-box {
      width: 100%;
      height: 100%;
    }
    .cropper-view-box {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .cropper-canvas {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .point-ne {
      width: 40px !important;
      height: 40px !important;
    }
    .point-se {
      width: 5px !important;
      height: 5px !important;
    }
    .preview-img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
      top: 0px;
      left: 0px;
    }
  }
  .photo-container {
    width: 100%;
    img {
      margin: 0px;
      width: 100%;
      max-height: calc(100vh - 80px - 60px);
    }
  }
  .modal-toolbar {
    //position: absolute;
    //bottom: 0px;
    width: 100%;
    padding: 0px 20px 10px 20px;
    background-color: #000000;
    .button-wrap {
      background-color: transparent !important;
      padding: 0px;
      min-height: 0px;
      height: auto;
      max-height: none;
      button {
        font-size: 15px;
        margin: 0px;
        flex-grow: 1;
        .button-icon {
          margin-right: 8px;
        }
      }
      .cancel-button {
        background-color: #e2e2e2;
        border: 1px solid #e2e2e2;
        color: #000;
      }
      .delete-button {
        background-color: $power;
        border: 1px solid $power;
        color: #fff;
      }
      button:last-child {
        margin-left: 10px;
      }
    }
  }
}
.modal-visible {
  height: 100%;
  visibility: visible;
}

</style>
