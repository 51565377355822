<template lang="html">
  <div class="modal-backdrop">
    <div class="message-modal" @click="close">
      <div class="message">
        <img :src="icon_url" width="40" height="40" />
        <p>{{ text }}</p>
      </div>
      <div class="close-button">Close</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
    callback: { type: Function, required: false },
    icon_url: { type: String, default: "https://sfo2.digitaloceanspaces.com/icons/error.svg" },
  },
  methods: {
    close() {
      this.callback && this.callback();
      this.$store.dispatch("close_message_modal");
    }
  }
};
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);

  .message-modal {
    cursor: pointer;
    color: #4f4f4f;
    background-color: white;
    opacity: 1;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
    font-family: 'open sans';
    transition: 0.3s opacity, 0.5s right;
    width: 200px;
    padding: 16px;
    border-radius: 10px;

    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        margin: 0px;
        font-size:14px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    .close-button {
      float: right;
      color: #6B40AB;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
</style>
