export default `
id
name
is_default
carb_grams
protein_grams
fat_grams
calories
weight_grams
`;
