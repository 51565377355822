<template lang="html">
  <div v-click-outside="away" class="macrostax-select" @click="expanded = !expanded" style="width:100%;">
    <div :class="{prompt: true, selection: has_selection}">
      <slot :expanded="expanded" />
    </div>

    <div :class="{ 'select-list': true, expanded: expanded }">
      <div
        v-for="item in items"
        :key="item.id"
        :class="{ 'select-item': true, selected: item.selected }"
        @click="select(item)"
      >
        <p>
          {{ item[name_field] }}
        </p>
        <i :style="{ color: '#6b40ab' }" class="fa fa-check fa-sm icon" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ClickOutside from "@js/directives/macrostax-click-outside";

export default {
  directives: { ClickOutside },
  props: {
    items: { type: Array, required: true },
    name_field: { type: String, default: "name" }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    has_selection() {
      return _.some(this.items, (item) => {
        return item.selected;
      });
    }
  },
  methods: {
    select(item) {
      event.stopPropagation();
      this.clear();
      this.expanded = false;
      if (!item.selected) {
        this.$emit("select", item.id);
      } else {
        this.$emit("clear", undefined);
      }
    },
    clear() {
      _.reject(this.items, {selected: false}).map((item) => {
        this.$emit("deselect", item.id);
      });
    },
    away(e) { 
      this.expanded = false;
    }
  }
};
</script>

<style scoped lang="scss">
  .macrostax-select {
    position: relative;
    background-color: rgba(107, 64, 171, 0.04);
    .prompt {
      &.selection {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
        .indicator {
          display: block;
        }
      }
    }
    .select-list {
      width: 100%;
      height: auto;
      max-height: 0px;
      overflow-y: auto;
      background-color: white;
      font-size: 16px;
      font-family: 'Open Sans';
      z-index: 1;
      box-shadow: 0 2px 4px rgba(0,0,0,0.20);
      transition: max-height 0.3s ease-out;
      &.expanded {
        max-height: 235px;
        transition: max-height 0.3s ease-in;
      }
      &.expanded-add-to-meal {
        max-height: 140px !important;
        transition: max-height 0.3s ease-in;
      }
      .select-item {
        display: flex;
        align-items: center;
        padding: 16px;
        cursor: pointer;
        text-align: left;
        p {
          margin: 0px 8px 0px 0px;
          font-size: 14px;
          font-weight: 600;
          .meal-macros {
            font-size: 10px;
            color: #4f4f4f;
            margin: 0px 0px 0px 0px;
          }
        }
        .helper {
          font-size: 12px;
          font-weight: 600;
          color: #4f4f4f;
          margin: 0px 0px 0px 4px;
          line-height: 14px;
        }
        .icon {
          display: none;
          margin-left: auto;
        }
        &:hover {
          background-color: #ECECEC;
        }
        &.selected {
          p {
            font-weight: bold;
          }
          .icon {
            display: block;
          }
        }
      }
    }
  }
</style>
