<template lang="html">
  <div v-show="isVisible" class="container">
    <div id="cancellation-warning">
      <div class="cancellation-body">
        <div class="cancellation-message">
          <p class="cancellation-warning-text">
            You have cancelled your subscription.  You will only have access to Macrostax for
            <span class="days-left">{{ daysRemaining }} more {{daysRemaining === 1 ? 'day': 'days'}}.</span>
          </p>
          <div class="cancellation-reactivate">
            <a v-if='csCancellation' id='Intercom'>Reactivate My Subscription</a>
            <a v-else @click='this.reactivateSubscription' id="reactivate-subscription">Reactivate My Subscription</a>
          </div>
        </div>
        <div class="close-cancellation-warning">
          <img :src="close_icon" @click="this.updateCancellation" class="close icon-close-icon acknowledge-cancellation-warning"></img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@js/api/rails_client";
import close_icon from "@images/wh-close-mobile.png";

export default {
  data() {
    return {
      isVisible: true,
      close_icon,
    }
  },
  methods: {
    async updateCancellation() {
      this.isVisible = false;
      const cancellationID = this.$store.state.rails.billing.cancellation.id;
      const response = await API.updateCancellation(
        cancellationID,
        { reminder_closed: true }
      );
      this.$store.dispatch("rails/updateCancellation", response.data);
    },
    async reactivateSubscription() {
      try {
        this.$store.dispatch("loading", true);
        const cancellationID = this.$store.state.rails.billing.cancellation.id;
        const response = await API.reactivateSubscription(cancellationID);

        this.$store.dispatch("loading", false);
        this.$store.dispatch("rails/setReactivatedSubscription", response.data);
        this.$store.dispatch("notify_user", { type: "info", msg: "Auto-Renew Successfully Reactivated"});
      } catch {
        this.$store.dispatch("loading", false);
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to Reactivate Auto-Renew"});
      }
    },
  },
  computed: {
    daysRemaining() {
      let nextBill = moment.unix(this.$store.state.rails.billing.subscription.next_bill_date);
      let today = moment();
      return nextBill.diff(today, 'days');
    },
    csCancellation() {
      return this.$store.state.rails.billing.cancellation.cs_cancellation;
    },
  }
};
</script>

<style scoped lang="scss">
.container {
  padding: 0px;
  #cancellation-warning {
    background-color: #E84C71;
    width: 100%;
    padding: 8px 15px;

    .close {
      cursor: pointer;
    }

    .cancellation-body{
      display: flex;
      justify-content: center;

      .cancellation-message {

        p.cancellation-warning-text {
          margin: 5px 5px 0px 5px;
          font-size: 13px;
          text-align: left;
          font-weight: normal;
          line-height: 1.6;
          letter-spacing: 0.8px;
          color: #fff;
          span.days-left {
            font-weight: bold;
          }
        }

        .cancellation-reactivate {
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            color: #fff;
            border-bottom: 1px solid;
            width: fit-content;
            cursor: pointer;
            margin: 10px 0px 9px 0px;
          }
        }

        #reactivate-subscription {

          a {
            font-size: 10px;
            margin: 0 auto;
            text-align: left;
            color: #fff;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: 0.8px;
            text-decoration: underline;
          }
        }
      }
      .close-cancellation-warning {
        position: relative;
        width: 30px;
        color: #fff;
        top: 15px;

        .close.icon-close-icon {
          text-decoration: none;
          left: 1%;
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
  }
}

</style>
