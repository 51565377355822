<template lang="html">
  <div class="current-subscription">
    <p class="label">Current Subscription</p>
    <div class="info">
      <p class="subscription-type">{{ plan[planName] }} Plan</p>
      <p class="cancellation-pending" v-if="canceled">Cancellation Pending</p>
    </div>
    <p class="description">{{ subscriptionDescription[planName] }}</p>
    <div v-if="paidSubscription" class="cycle-information">
      <div v-if="canceled" class="cancellation">
        <p>Account End Date</p>
        <div>{{ nextBillDate }}</div>
      </div>
      <div class="price">
        <p>Price</p>
        <div class="price-information">
          <div class="total">
            <p class="next-invoice" v-if="loadingNextInvoice"></p>
            <p class="amount" v-else>${{ amount }}</p>
            <p class="months">/{{ interval === 'Month' && interval_count === 3 ? 'Quarter' : interval}}</p>
          </div>
        </div>
      </div>
      <div class="next-bill">
        <p>Next Bill</p>
        <div>{{ canceled ? 'None' : nextBillDate }}</div>
      </div>
      <div class="billing-cycle">
        <p>Billing Cycle</p>
        <div>Every {{ billingCycle }}</div>
      </div>
    </div>
    <div v-if="planName === 'active_gym_challenge'">
      <ActionButton
        :onSubmit="() => {this.$router.push({path: '/billing/new'})}"
        :planName='planName'
      />
    </div>
  </div>
</template>

<script>
import ActionButton from './action_button.vue';
import API from "@js/api/rails_client";

export default {
  components: { ActionButton },
  props: {
    planName: { type: String, required: true },
    amount: { type: String },
    interval: { type: String },
    interval_count: { type: Number },
    nextBillDate: { type: String },
    canceled: { type: Boolean, default: false },
    legacyPlan: { type: Boolean, require: true },
  },
  data() {
    return {
      loadingNextInvoice: false
    }
  },
  created() {
    if (!this.legacyPlan && !this.canceled) {
      this.getNextInvoice();
    }
  },
  computed: {
    subscriptionDescription() {
      let defaultText = "Subscriptions are billed on a recurring basis.  Any changes will take effect on the first day of your next billing cycle.";

      return {
        'macrostax_monthly': defaultText,
        'macrostax_quarterly': defaultText,
        'macrostax_yearly': defaultText,
        'active_gym_challenge': "You've been using Macrostax through your gym and loving it!  Why not join us?",
        'grandfathered': 'It looks like you are currently signed up on a legacy plan.  For more information about your subscription, please reach out to StaxChat at the link below for further assistance.',
        'lifetime': 'It looks like you are currently signed up on a Lifetime plan.  For more information about your subscription, please reach out to StaxChat at the link below for further assistance.'
      }
    },
    plan() {
      return {
        'macrostax_monthly': 'Monthly',
        'macrostax_quarterly': 'Quarterly',
        'macrostax_yearly': 'Yearly',
        'active_gym_challenge': 'Gym Challenge Group',
        'grandfathered': 'Grandfathered',
        'lifetime': 'Lifetime',
      }
    },
    paidSubscription() {
      return ['macrostax_monthly', 'macrostax_yearly', 'macrostax_quarterly'].includes(this.planName);
    },
    billingCycle() {
      return this.interval == 'Year' ? '12 Months' : `${this.interval_count} ${this.interval}${this.interval_count !== 1 ? 's' : ''}`;
    },
  },
  methods: {
    async getNextInvoice() {
      try {
        this.loadingNextInvoice = true;
        const response = await API.getNextInvoice();
        this.$store.dispatch("rails/updateSubscriptionPlan", response.data);
      } catch {
        // do nothing
      } finally {
        this.loadingNextInvoice = false;
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .current-subscription {
    background: #FAFAFA;
    box-shadow: 0px 1px 2px rgba(79, 79, 79, 0.25);
    border-radius: 8px;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 18px;
    padding-left: 15px;
    padding-right: 15px;

    p.label {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #4F4F4F;
    }


    .info {
      display: flex;
      flex-direction: row;

      .subscription-type {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
      }

      .cancellation-pending{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #E84C71;
        margin-left: 22px;
      }
    }

    p.description {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
    }

    .cycle-information {

      .price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #4F4F4F;
        }

        .price-information {
          display: flex;
          flex-direction: column;

          .total {
            display: flex;
            flex-direction: row;
            margin-bottom: 4px;
            margin-left: auto;
            align-items: baseline;

            .amount {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              color: #000000;
              margin-bottom: 0px;
            }

            .months {
              margin-left: 5px;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 18px;
              margin-bottom: 0px;
            }
          }

          .monthly-promo {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #6B40AB;
          }

          .next-invoice {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            font-size: 10px;
            border-top: 1px solid #35D0BA;
            border-right: 1px solid #35D0BA;
            border-bottom: 1px solid #35D0BA;
            border-left: 1px solid transparentize(#35D0BA, 0.4);
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            animation: load8 1.1s infinite linear;
            color: white;
          }
        }
      }

      .cancellation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #E84C71;
        }

        div {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #4F4F4F;
        }
      }

      .next-bill {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #4F4F4F;
        }

        div {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #4F4F4F;
        }
      }

      .billing-cycle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #4F4F4F;
        }

        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #4F4F4F;
        }
      }
    }
  }
</style>
