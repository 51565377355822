import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloLinkTimeout from "apollo-link-timeout";
import { HttpLink } from "apollo-link-http";

let link = new HttpLink({ uri: process.env.PHX_API_ENDPOINT });

// JSON.parse converts both boolean and integer strings to their respective types
const timeout = JSON.parse(process.env.APOLLO_CLIENT_TIMEOUT);
if (timeout) {
  link = (new ApolloLinkTimeout(timeout)).concat(link);
}

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true
});

export default apolloClient;
