<template lang="html">
  <div id="date-nav" :class="{ condensed }">
    <Calendar
      v-if="calendar_open"
      :date="date.toDate()"
      :select_date="date => goto_day(date)"
      v-click-outside="toggle_calendar"
    />
    <template v-if="condensed">
      <div @click.stop="toggle_calendar" class="selected-day">
        <div class="icon-container">
          <i class="fa fa-calendar" />
        </div>
        <div class="date">
          <p>{{ day_of_week }}</p>
          <p>{{ date_short }}</p>
        </div>
      </div>
      <div class="selectors">
        <span @click.stop="goto_day('previous')">
          <i class="fa fa-chevron-left" />
        </span>
        <span @click.stop="goto_day('next')">
          <i class="fa fa-chevron-right" />
        </span>
      </div>
    </template>
    <template v-else>
      <div @click.stop="toggle_calendar" class="selected-day">
        <div class="date">
          <div class="short">{{ day_of_week }}</div>
          <div class="long">{{ date_long }}</div>
        </div>
        <i class="fa fa-chevron-down" />
      </div>
      <div class="previous-day" @click.stop="goto_day('previous')">
        <i class="fa fa-chevron-left" />
      </div>
      <div class="today" @click.stop="goto_day('today')" title="Go to Today">
       <i class="far fa-calendar" />
      </div>
      <div class="next-day" @click.stop="goto_day('next')">
        <i class="fa fa-chevron-right" />
      </div>
    </template>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

import Calendar from '@js/food_log/calendar.vue';

export default {
  components: { Calendar },
  directives: { ClickOutside },
  props: {
    condensed: { type: Boolean, default: false }
  },
  data() {
    return {
      selected_date: this.$store.state.rails.selected_date,
      calendar_open: false
    }
  },
  computed: {
    date() {
      return moment(this.selected_date);
    },
    day_of_week() {
      // NOTE: 'dddd' => day of week, e.g. "Monday"
      return this.date.calendar({
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        lastWeek: "dddd",
        nextWeek: "dddd",
        sameElse: "dddd"
      });
    },
    date_long() {
      return this.date.format("MMMM D, YYYY");
    },
    date_short() {
      return this.date.format("M/D/YY");
    }
  },
  methods: {
    toggle_calendar() {
      this.calendar_open = !this.calendar_open;
    },
    async goto_day(date) {
      if (!this.loading_date) {
        this.calendar_open = false;
        this.$store.dispatch("loading", true);

        let date_param;
        if (date === "previous") {
          date_param = this.date.clone().subtract(1, "day").format("YYYY-MM-DD");
        } else if (date === "next") {
          date_param = this.date.clone().add(1, "day").format("YYYY-MM-DD");
        } else if (date === "today") {
          date_param = moment().format("YYYY-MM-DD");
        } else { // JS Date object from vue-datepicker
          date_param = moment(date).format("YYYY-MM-DD");
        }

        await this.$store.dispatch("rails/get_food_log", date_param);

        this.$router.push({ path: "/food_log", query: { d: date_param } });
        this.selected_date = date_param;
        this.$store.dispatch("loading", false);
        this.loading_date = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.calendar {
  position: fixed !important;
  left: calc(50% - 150px) !important;
  top: 65px;
  z-index: 99;
}

#date-nav {
  display: flex;
  height: 75px;
  background: transparent;

  &.condensed {
    flex-direction: column;
    margin: 0px 18px 0px 0px;
    width: 140px;
    height: 66px;

    .selected-day {
      display: flex;
      flex-direction: row;
      padding-bottom: 12px;

      .icon-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-right: 6px;
        padding-top: 2px;

        i {
          font-size: 14px;
          color: #4f4f4f;
        }
      }

      .date {
        display: flex;
        flex-direction: column;

        p {
          font-size: 12px;
          color: #4f4f4f;
          font-weight: 600;
          margin: 0px;
          text-align: left;
        }
      }
    }

    .selectors {
      display: flex;
      flex-direction: row;
      height: 100%;

      span {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 12px;
          color: #4f4f4f;
        }
      }
    }
  }

  &:not(.condensed) {
    .selected-day {
      display: flex;
      flex-direction: row;
      color: #ffffff;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 700;
      align-items: flex-end;
      text-transform: uppercase;
      height: 100%;
      width: 100%;
      justify-content: flex-start;
      padding: 0px 0px 14px 24px;
      cursor: pointer;

      i {
        margin: 0px 0px 3px 12px;
      }

      .date {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .short, .long {
          font-size: 16px;
          font-family: 'Open Sans';
          font-weight: 600;
          text-align: center;
          text-shadow: 0px 1px rgba(63, 139, 110, 1);
          text-transform: capitalize;
        }

        .short {
          margin: 0px;
        }
      }
    }

    .previous-day,
    .today,
    .next-day {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 100%;
      color: white;
      font-size: 14px;
      width: 60px;
      text-align: center;
    }

    .today {
      font-size: 12px;
    }

    .next-day {
      margin-right: 4px;
    }
  }
}
</style>
