<template lang="html">
  <footer id="nav-footer">
    <div v-if="modalIsVisible" class="modal-backdrop" />

    <router-link
      to="/food_log"
      :class="{
        active: is_active('food_log'),
        notification: nav_footer_notification === 'food_log'
      }"
    >
      <img :src="icon('/food_log')" alt="Food Log" />
      <span>Food Log</span>
    </router-link>

    <router-link
      to="/recipes"
      :class="{
        active: is_active('recipes'),
        notification: nav_footer_notification === 'recipes'
      }"
    >
      <img :src="icon('/recipes')" alt="Recipes" />
      <span>Recipes</span>
    </router-link>

    <router-link
      to="/profile"
      :class="{
        active: is_active('profile'),
        notification: nav_footer_notification === 'profile'
      }"
    >
      <img :src="icon('/profile')" alt="Profile" />
      <span>Profile</span>
    </router-link>

    <a id="Intercom">
      <img :src="icon('stax_chat')" alt="StaxChat" />
      <div v-if="unread_count > 0" class="unread-count">{{ unread_count }}</div>
      <span>StaxChat</span>
    </a>
  </footer>
</template>

<script>
import { some } from "lodash";
import updateIntercom from "@js/lib/intercom";
import food_log_inactive_icon from "@images/track.svg";
import food_log_active_icon from "@images/track-purple.svg";
import recipes_inactive_icon from "@images/recipes.svg";
import recipes_active_icon from "@images/recipes-purple.svg";
import profile_inactive_icon from "@images/profile.svg";
import profile_active_icon from "@images/profile-purple.svg";
import chat_icon from "@images/chat.svg";

// NOTE: intercom-rails injected JS attaches to #Intercom

export default {
  data() {
    return {
      // TODO: refactor previous_path logic to use Store.state.routeHistory (since added)
      previous_path: null,
      unread_count: 0,
    };
  },
  mounted() {
    updateIntercom(null, this.$store.state);
    let t = this;
    if (typeof Intercom !== 'undefined') {
      if (this.$route && this.$route.query && this.$route.query.staxchat) {
        Intercom('show');
      }
      Intercom('onUnreadCountChange', function(unreadCount) {
        t.unread_count = unreadCount;
      });
    }
  },
  computed: {
    current_path() {
      return this.$route.path;
    },
    nav_footer_notification() {
      return this.$store.state.nav_footer_notification;
    },
    modalIsVisible() {
      return this.$store.state.modalIsVisible;
    }
  },
  watch: {
    current_path(current_path, previous_path) {
      if ((!previous_path.match("/custom") || !current_path.match("/custom")) && !previous_path.match("/scan_food")) {
        // we need previous_path to determine whether custom was opened from the food log or from recipes - but if the
        // user subsequently navigates between custom tabs and/or the recipe builder, we don't want to replace the
        // previous_path context
        this.previous_path = previous_path;
      }
    }
  },
  methods: {
    goto(path) {
      if (path !== this.$route.path) {
        this.$router.push(path);
      }
    },
    is_active(nav) {
      if (this.$route.path.match("/custom") && nav === "food_log") {
        // if a /custom/* page was directly loaded via URL, just default to food_log as the active nav
        return !this.previous_path || this.previous_path === "/food_log";
      } else if (this.$route.path.match("/custom") && nav === "recipes") {
        return this.previous_path && this.previous_path.match("/recipes");
      }

      const nav_paths = {
        food_log: ["/food_log", "/fill_macros", "/favorites"],
        recipes: ["/recipes"],
        profile: ["/profile", "/inputs", "/billing", "/update-billing"]
      };

      return some(nav_paths[nav], path => this.$route.path.match(`^${path}`));
    },
    icon(path) {
      if (path === "stax_chat") {
        return chat_icon;
      }
      return {
        "/food_log": { true: food_log_active_icon, false: food_log_inactive_icon },
        "/recipes": { true: recipes_active_icon, false: recipes_inactive_icon },
        "/profile": { true: profile_active_icon, false: profile_inactive_icon },
      }[path][path === this.$route.path];
    }
  }
};
</script>

<style scoped lang="scss">
#nav-footer {
  background-color: white;
  z-index: 99;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 450px;
  padding: 0px;
  position: fixed;
  bottom: 0px;
  transition: padding-left 300ms ease-in-out;
}

.modal-backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}

a {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  border-top: 2px solid #dedede;
  padding: 12px 0px;

  .iphone-x & {
    padding: 12px 0px 20px 0px;
  }

  span {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: #4f4f4f;
    margin: 4px 0px 0px 0px;
  }

  &.active {
    border-top: 2px solid #6b40ab;

    span {
      color: #6b40ab;
    }

    .indicator {
      display: block;
    }
  }

  img {
    display: block;
    width: 18px;
    height: 18px;
    margin: 0px;
  }
}

.notification {
  position: absolute;
  top: 8px;
  right: calc(50% - 20px);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #FFCD3C;
  margin-right: 8px;
}
i.notification-dot:after {
  position: absolute;
  top: 4px;
  right: 32px;
}
.unread-count {
  background-color: red;
  color: white;
  width: 18px;
  height: 18px;
  border-radius: 12px;
  position: absolute;
  top: 0;
  margin-left: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
}
</style>
