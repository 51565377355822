import Axios from 'axios';
import { pick, mapKeys } from "lodash";

const BUILD_FOODS_TIMEOUT = 30 * 1000;
const TRANSFORMATION_CHALLENGES_TIMEOUT = 10 * 1000;

const axios = Axios.create({
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
  }
});

axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error.response)
);

// TODO: use token auth instead of relying on session cookie
const API = {
  // just call it dinner if no meal_id provided (doesn't actually matter)
  generate_matching_foods(macro_targets, mandatory_portions, date, meal_id = "meal-5") {
    // specifying meal is functionally a noop, but unless one of these values is provided in the request,
    // MealPlanner::Meal fails validation and the request returns 400
    const meal_types = {
      "meal-1": "breakfast",
      "meal-2": "snack",
      "meal-3": "lunch",
      "meal-4": "snack",
      "meal-5": "dinner"
    };

    const meal_params = {
      d: date,
      meal: {
        identifier: meal_id,
        name: "any",
        meal_type: meal_types[meal_id],
        target_carbohydrates: macro_targets.carb,
        target_protein: macro_targets.protein,
        target_fat: macro_targets.fat,
        mandatory_portions: mandatory_portions,
        seed: Math.floor(Math.random() * 10000)
      }
    };

    return axios.post("/meal_planner/meals", meal_params, { timeout: BUILD_FOODS_TIMEOUT });
  },
  get_food_log(date) {
    return axios.get(`/food_log?d=${date}`);
  },
  create_portion(portion, meal, date) {
    const params = build_portion_params(portion, meal, date, true)

    return axios.post("/meal_planner/portions", params);
  },
  update_portion(portion, meal, date) {
    const params = build_portion_params(portion, meal, date, false);

    return axios.put(`/meal_planner/portions/${portion.id}`, params);
  },
  delete_portion(portion_id) {
    return axios.delete(`/meal_planner/portions/${portion_id}`);
  },
  update_custom_food(ingredient_id, attrs) {
    return axios.put(`/meal_planner/custom_foods/${ingredient_id}`, { ingredient: attrs });
  },
  create_custom_food(ingredient_attrs) {
    return axios.post('/meal_planner/custom_foods', { ingredient: ingredient_attrs });
  },
  delete_custom_food(food_id) {
    return axios.delete(`/meal_planner/custom_foods/${food_id}`);
  },
  delete_custom_recipe(recipe_id) {
    return axios.delete(`/custom_recipes/${recipe_id}`);
  },
  update_user_preferences(user_id, preferences) {
    return axios.patch(`/api/v1/users/${user_id}`, { user: { preferences } });
  },
  update_meal_planner_settings(settings, date = null) {
    return axios.put("/meal_planner/settings", { ...settings, ...date && { d: date } });
  },
  copy_meal(source_date, target_date, source_meal_id, target_meal_id) {
    const params = {
      source_date,
      target_date,
      source_identifier: source_meal_id,
      target_identifier: target_meal_id
    };

    return axios.post("/meal_planner/meals/copy", { meal_set: params });
  },
  log_nutrient_intake(nutrient_intake) {
    return axios.post("/inputs/intake", { nutrient_intake });
  },
  log_weight(weight, date) {
    return axios.put("/inputs/weigh_in", { weigh_in: { weight, date } });
  },
  export_food_log(export_params) {
    return axios.post("/meal_planner/export", { export: export_params });
  },
  update_dietary_restrictions(dietary_restriction_ids) {
    return axios.put("/meal_planner/dietary_restrictions", { dietary_restriction_ids });
  },
  update_user_credentials(email, current_password, password) {
    return axios.patch("/account/credentials", { user: { email, current_password, password } });
  },
  update_user_statistic_set(statistic_set, selected_date) {
    return axios.patch("/statistic_set", { statistic_set, d: selected_date });
  },
  sign_out() {
    return axios.delete("/sign_out");
  },
  get_transformation_challenges() {
    return axios.get("/api/v1/transformation_challenges", { timeout: TRANSFORMATION_CHALLENGES_TIMEOUT });
  },
  enroll_in_transformation_challenge(challenge_id, goal_ids, goal_values) {
    return axios.post(
      "/api/v1/users_transformation_challenges",
      {
        users_transformation_challenge: {
          transformation_challenge_id: challenge_id,
          transformation_goals: goal_ids,
          custom_transformation_goals: goal_values
        }
      },
      { timeout: TRANSFORMATION_CHALLENGES_TIMEOUT }
    );
  },
  create_transformation_goal(challenge_id, goal_value) {
    return axios.post(
      "/api/v1/transformation_goals",
      { transformation_goal: { transformation_challenge_id: challenge_id, value: goal_value } },
      { timeout: TRANSFORMATION_CHALLENGES_TIMEOUT }
    );
  },
  update_transformation_goal(goal_id, challenge_id, goal_value) {
    return axios.put(
      `/api/v1/transformation_goals/${goal_id}`,
      { transformation_goal: { transformation_challenge_id: challenge_id, value: goal_value } },
      { timeout: TRANSFORMATION_CHALLENGES_TIMEOUT }
    );
  },
  update_user_transformation_goals(goal_ids) {
    return axios.put(
      "/api/v1/users_transformation_goals",
      { users_transformation_goal: { id_list: goal_ids } },
      { timeout: TRANSFORMATION_CHALLENGES_TIMEOUT }
    );
  },
  delete_transformation_goals() {
    return axios.delete("/api/v1/transformation_goals", { timeout: TRANSFORMATION_CHALLENGES_TIMEOUT });
  },
  getBillingData() {
    return axios.get("/api/v1/billing");
  },
  getCancellationReasons() {
    return axios.get("/api/v1/cancellation_reasons");
  },
  createCancellation(cancellationReasonID) {
    return axios.post(
      '/api/v1/cancellations',
      { cancellation: { cancellation_reason_id: cancellationReasonID } }
    )
  },
  createCancellationReason(customReason) {
    return axios.post(
      '/api/v1/cancellation_reasons',
      { cancellation_reason: { content: customReason } }
    )
  },
  reactivateSubscription(cancellationID) {
    return axios.put('/api/v1/cancellations/' + cancellationID + '/reactivate');
  },
  updateCancellation(cancellationID, data) {
    return axios.put('/api/v1/cancellations/' + cancellationID, {
      cancellation: data
    });
  },
  updateCreditCard(token) {
    return axios.put('/api/v1/billing', { billing: { token: token } });
  },
  getNextInvoice() {
    return axios.get('/api/v1/billing/next_invoice');
  },
  update_active_campaign() {
    return axios.put('/api/v1/active_campaign/update_user');
  },
};

function build_portion_params(portion, meal, date, set_ingredient = false) {
  const meal_param = {
    ...pick(meal, ["identifier", "meal_type", "name"]),
    ...mapKeys(meal.target, (val, key) => `target_${key}`),
    seed: Math.floor(Math.random() * 10000)
  };

  let portion_param = pick(portion, [
    "units",
    "food_id",
    "recipe_id",
    "root_recipe_id",
    "ingredient_id",
    "serving_size"
  ]);

  if (set_ingredient) {
    portion_param.ingredient = {
      ...pick(portion.ingredient, [
        "carbohydrates",
        "protein",
        "fat",
        "fiber",
        "calories"
      ]),
      ...pick(portion, [
        "name",
        "food_id",
        "recipe_id",
        "root_recipe_id",
        "user_id",
        "units",
        "common_serving_quantity"
      ]),
      common_unit_name: portion.common_serving_unit_name,
      measurement_serving_quantity: portion.measurement_size_quantity,
      measurement_unit_name: portion.measurement_size_unit_name
    };
    if (portion_param.serving_size === undefined && portion_param.units === 'common') {
      portion_param.serving_size = 1;
    }
  }

  return { portion: portion_param, d: date, meal_identifier: meal.identifier, meal: meal_param };
};

export default API;
