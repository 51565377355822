<template lang="html">
	<div ref="container" id="copy-meal-container">
    <p>What day do you want to copy from?</p>
    <div @click.stop="() => toggle_calendar('copy_from')" class="copy-meal-input-row">
      <p class="input-label">Date</p>
      <div id='copy-from-input' class="meal-set-input">
        <i class="fa fa-calendar meal-copy-calendar"></i>
        <input id="copy-meals-date-input" v-model='formatted_copy_from_date' :disabled="true" type="text" placeholder="Copy Date" />
      </div>
    </div>
    <Calendar
      v-if="copy_from_calendar_visible"
      :date="copy_from_date.toDate()"
      :select_date="select_date('copy_from')"
      v-click-outside="() => toggle_calendar('copy_from')"
    />
    <p class="copy-to-label">What day do you want to copy to?</p>
    <div @click.stop="() => toggle_calendar('copy_to')" class="copy-meal-input-row">
      <p class="input-label">Date</p>
      <div id='copy-to-input' class="meal-set-input">
        <i class="fa fa-calendar meal-copy-calendar"></i>
        <input v-model='formatted_copy_to_date' :disabled="true" type="text" placeholder="Copy Date" />
      </div>
    </div>
    <Calendar
      v-if="copy_to_calendar_visible"
      :date="copy_to_date.toDate()"
      :select_date="select_date('copy_to')"
      v-click-outside="() => toggle_calendar('copy_to')"
    />
    <div class="copy-meal-input-row select-meal-area">
      <div class="meal-set-input source-select">
        <p class="copy-label">Copy From ({{ short_source_date }})</p>
        <MealSelect
          :items="source_meal_options.slice(0, 6)"
          @select="select_source_meal"
          class="meal-select"
        >
          <div class="sort-button">
            <p v-if="source_meal" class="selected-meal">{{ source_meal.name }}</p>
            <i class="fa fa-chevron-down fa-sm icon" />
          </div>
        </MealSelect>
        <div v-if="invalid_source_meal" id="error-notification">
          <img :src="error_icon" width="12" height="12"/>
          <p>Please select a meal with food data</p>
        </div>
      </div>
      <div v-if="selected_source_meal !== 'all'" class="target-select" >
        <div class="meal-set-input target-meal-area">
          <p class="copy-label">Copy To ({{ short_target_date }})</p>
          <MealSelect
            :items="target_meal_options.slice(0, 5)"
            @select="select_target_meal"
            class="meal-select"
          >
            <div class="sort-button">
              <p v-if="target_meal" class="selected-meal">{{ target_meal.name }}</p>
              <p v-else>No meal selected</p>
              <i class="fa fa-chevron-down fa-sm icon" />
            </div>
          </MealSelect>
        </div>
      </div>
    </div>
    <div class="button-row save">
      <div class="save-button" @click="copy_meal">COPY FOOD</div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

import { reloadPage } from "@js/router";

import Calendar from "@js/food_log/calendar.vue";
import MealSelect from "@js/food_log/fab/meal_select.vue";

import error_icon from "@images/error.svg";

const meal_options = [
  { id: "meal-1", name: "Breakfast", type: "breakfast" },
  { id: "meal-2", name: "Snack 1", type: "snack" },
  { id: "meal-3", name: "Lunch", type: "lunch" },
  { id: "meal-4", name: "Snack 2", type: "snack" },
  { id: "meal-5", name: "Dinner", type: "dinner" }
];

export default {
  components: { MealSelect, Calendar },
  directives: { ClickOutside },
  props: {
    close: { type: Function, required: true }
  },
  data() {
    const copy_to_date = moment(this.$store.state.rails.selected_date);
    const copy_from_date = copy_to_date.clone().subtract(1, "day");

    return {
      copy_to_date,
      copy_from_date,
      copy_from_calendar_visible: false,
      copy_to_calendar_visible: false,
      selected_source_meal: "all",
      selected_target_meal: null,
      invalid_source_meal: false,
      error_icon
    };
  },
  computed: {
    source_meal() {
      return this.source_meal_options.find(meal => meal.id === this.selected_source_meal);
    },
    target_meal() {
      return this.target_meal_options.find(meal => meal.id === this.selected_target_meal);
    },
    source_meal_options() {
      return [{ id: "all", name: "All Meals" }, ...meal_options].map(option => (
        { ...option, selected: option.id === this.selected_source_meal }
      ));
    },
    target_meal_options() {
      return meal_options.map(option => (
        { ...option, selected: option.id === this.selected_target_meal }
      ));
    },
    formatted_copy_to_date() {
      return this.copy_to_date.format("MMM Do YYYY");
    },
    formatted_copy_from_date() {
      return this.copy_from_date.format("MMM Do YYYY");
    },
    short_target_date() {
      return this.copy_to_date.format("MM/DD");
    },
    short_source_date() {
      return this.copy_from_date.format("MM/DD");
    }
  },
  methods: {
    select_date(type) {
      const attr = `${type}_date`;
      return (date) => {
        this[attr] = moment(date);
        this.toggle_calendar(type);
      };
    },
    toggle_calendar(type) {
      // NOTE: can't streamline template by returning a function (like select_date) because Vue seems to do some
      // function wrapping magic that prevents it from working
      const attr = `${type}_calendar_visible`;
      // when toggling calendars the height of the container will change. Need to notify the parent container
      // so that it can also adjust its own height as tall as needed
      setTimeout(() => this.$emit('heightChanged', this.$refs.container.scrollHeight));
      return this[attr] = !this[attr];
    },
    select_source_meal(meal_id) {
      this.selected_source_meal = meal_id;
      this.selected_target_meal = meal_id === "all" ? null : meal_id;
      this.invalid_source_meal = false;
    },
    select_target_meal(meal_id) {
      this.selected_target_meal = meal_id;
    },
    async copy_meal() {
      const source_date = this.copy_from_date.format("YYYY-MM-DD");
      const target_date = this.copy_to_date.format("YYYY-MM-DD");
      const source_meal_id = this.selected_source_meal === "all" ? null : this.selected_source_meal;
      const target_meal_id = this.selected_target_meal;

      // allow user to copy same day into different meals
      // prevent user from copying all meals in the same day
      if (source_date === target_date && source_meal_id === target_meal_id) {
        this.$store.dispatch("notify_user", {
          type: "error",
          msg: "Cannot copy into the same day! Please select a different day or meal"
        });

        return;
      }

      try {
        this.$store.dispatch("loading", true);

        await this.$store.dispatch("rails/copy_meal", {
          source_date,
          target_date,
          source_meal_id,
          target_meal_id,
        });

        this.close();
        this.$store.dispatch("notify_user", {
          type: "info",
          msg: "Foods were succesfully copied :)"
        });
      } catch (error) {
        if (error.status === 404) {
          this.invalid_source_meal = true;
        } else {
          this.$store.dispatch("notify_user", {
            type: "error",
            msg: "Whoops! Something went wrong copying that meal!"
          });
          console.error(error);
        }
      } finally {
        this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.calendar {
  margin-top: -18px;
  margin-bottom: 18px;
}

.copy-meal-input-row.select-meal-area {
  flex-direction: row;
  border-bottom: 0;
}

.target-select {
  width: 45%;
}

.copy-to-label {
  padding-top: 16px;
}

.copy-meal-input-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px;
  border-bottom: 2px solid $endurance;
  margin-bottom: 18px;
  width: 100%;

  .checkbox-label {
    margin-bottom: 12px;
  }

  #error-notification {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    img {
      color: #4f4f4f;
      font-size: 12px;
    }
    p {
      font-size: 12px;
      color: #4f4f4f;
      line-height: 18px;
      font-weight: 400;
      padding-left: 3px;
    }
  }

  .input-label {
    color: $endurance;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 0px 4px 0px;
  }

  .meal-set-input.source-select {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: fit-content;
  }

  .meal-set-input.target-meal-area {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .meal-set-input {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.disabled {
      background-color: #ececec;
      input {
        color: #4f4f4f;
      }
    }
    .selected-meal {
      margin: 0;
    }
    .copy-label {
      font-size: 12px;
      color: #6b40ab;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .meal-copy-calendar {
      padding-left: 8px;
    }

    i {
      color: $endurance;
      font-size: 13px;
      font-weight: 600;
      margin: 0px 6px 0px 6px;
    }

    .sort-button {
      display: flex;
      align-items: center;
    }

    input {
      width: 100%;
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 700;
      margin: 0px;
      padding: 4px 0px 4px 8px;
      border: none;
      background-color: transparent;
    }

    input:focus {
      outline: none;
      border: none;
    }

    input:disabled {
      color: #4f4f4f;
      -webkit-text-fill-color: #4f4f4f;
      opacity: 1; /* required on iOS */
    }
  }
}
</style>

