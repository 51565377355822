import not_active_icon from "@images/not-active-purple.svg";
import mild_active_icon from "@images/mild-active-purple.svg";
import moderate_active_icon from "@images/moderate-active-purple.svg";
import high_active_icon from "@images/high-active-purple.svg";
import extreme_active_icon from "@images/extreme-active-purple.svg";

// NOTE: these should be database records (sans icon), but they're not...
const activity_levels = [
  {
    title: "Little to No Activity",
    description: "You walk or light jog occasionally for exercise",
    value: 1.2,
    icon: not_active_icon
  },
  {
    title: "Mild Activity",
    description: "You exercise intensively 2-3 times per week for 30-60 minutes",
    value: 1.375,
    icon: mild_active_icon
  },
  {
    title: "Moderate Activity",
    description: "You exercise intensively 4-5 times per week for 60-90 minutes",
    value: 1.5,
    icon: moderate_active_icon
  },
  {
    title: "High Activity",
    description: "You exercise intensively 5-7 times per week for 90-120 minutes",
    value: 1.675,
    icon: high_active_icon
  },
  {
    title: "Extreme Activity",
    description: "You exercise intensively multiple times per day 5-7 times per week. You are a professional athlete or someone who trains a lot",
    value: 1.9,
    icon: extreme_active_icon
  }
];

export default activity_levels;
