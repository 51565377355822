<template>
  <div id="weight" class="dashboard-section">
    <header>
      <span class="title">Weight</span>
      <img :src="scale_icon" />
    </header>

    <p v-if="!last_weigh_in" class="explanation primary-gray-text-medium wide">
      Add two or more weigh-ins to track progress, see your trend line graphed
      and weekly average weight change.
    </p>

    <div class="content">
      <div v-if="last_weigh_in" class="weight-stats-wrapper">
        <div class="weight-stat">
          <div class="stat-label">
            {{ header_text }}
          </div>
          <div class="stat-value">
            {{ format_weight(last_weigh_in.weight) }}
          </div>
        </div>

        <template v-if="averages.avg_weight_change">
          <div class="weight-stat">
            <div class="stat-label">
              Last Week Avg Weight:
            </div>
            <div class="stat-value">
              {{ format_weight(averages.last_week_avg) }}
            </div>
          </div>
          <div class="weight-stat">
            <div class="stat-label">
              This Week Avg Weight:
            </div>
            <div class="stat-value">
              {{ format_weight(averages.this_week_avg) }}
            </div>
          </div>
          <div class="weight-stat">
            <div class="stat-label">
              Avg Weight Change:
            </div>
            <div class="stat-value">
              {{ format_weight(averages.avg_weight_change) }}
            </div>
          </div>
        </template>
      </div>

      <div class="graph">
        <Chart
          v-if="weight_chart"
          :options="weight_chart"
          class="highcharts-container "
        />
        <img v-else :src="chart_placeholder" />
      </div>
    </div>

    <footer>
      <button class="button" @click="log_weight_visible = true">
        <span>Add Weigh-in</span>
      </button>
    </footer>

    <div v-show="log_weight_visible" id="log-weight-card">
      <div class="card-header">
        <div class="card-icons">
          <div class="icon">
            <img :src="log_weight_icon" />
          </div>
          <div class="label">Log Weight</div>
        </div>
        <div class="close-button" @click="log_weight_visible = false">
          <i class="fa fa-times" />
        </div>
      </div>
      <div class="card-content">
        <LogWeight :standalone="true" :close="reload_inputs" />
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

import { round } from "@js/lib/utils";
import { reloadPage } from "@js/router";
import LogWeight from "@js/food_log/fab/log_weight.vue";

import scale_icon from "@images/scale.svg";
import log_weight_icon from "@images/weight-loss.svg";
import lose_weight_chart from "@images/weigh-ins-loss-placeholder.png";
import gain_weight_chart from "@images/weigh-ins-gain-placeholder.png";

export default {
  components: { Chart, LogWeight },
  data() {
    return {
      log_weight_visible: false,
      scale_icon,
      log_weight_icon,
      lose_weight_chart,
      gain_weight_chart,
    };
  },
  computed: {
    weigh_ins() {
      return this.$store.state.rails.weigh_ins;
    },
    last_weigh_in() {
      // array is in descending order
      return this.weigh_ins[0];
    },
    averages() {
      return this.$store.state.rails.dashboard.weight_change_calc;
    },
    unit() {
      return this.$store.state.rails.user.preferences
        .preferred_input_weight_unit;
    },
    header_text() {
      const date = moment(this.last_weigh_in.date).format("MMMM Do, YYYY");
      return `Last weigh-in on ${date}:`;
    },
    weight_chart() {
      if (this.weigh_ins.length < 2) {
        return null;
      }
      const {
        series_data,
        options: { title, xAxis, yAxis, colors },
      } = this.$store.state.rails.dashboard.weight_chart;

      // HighCharts API: https://api.highcharts.com/highcharts/
      return {
        title,
        xAxis,
        yAxis,
        colors,
        series: series_data,
        credits: false,
      };
    },
    chart_placeholder() {
      if (this.$store.state.rails.statistic_set.goal === "fat loss") {
        return this.lose_weight_chart;
      }
      return this.gain_weight_chart;
    },
  },
  methods: {
    format_weight(weight) {
      weight = parseFloat(weight);

      if (this.unit === "kg") {
        weight = round(weight / 2.205, 0.1);
      }
      // Conditionally round decimal weights only to two places
      let formattedWeight = Math.round((weight + Number.EPSILON) * 100) / 100

      return `${formattedWeight} ${this.unit}`;
    },
    open_log_weight() {
      this.log_weight_visible = true;
    },
    reload_inputs() {
      this.log_weight_visible = false;
      reloadPage();
    },
    
  },
};
</script>

<style scoped lang="scss">
@import "@css/components/inputs";

.weight-stats-wrapper {
  width: 100%;
}

.weight-stat {
  width: 90%;
  align-self: start;
  display: flex;
  justify-content: space-between;

  .stat-label {
    margin-right: 6px;
    min-width: 170px;
  }
}

#log-weight-card {
  position: fixed;
  left: calc(50% - 180px + 6px);
  width: calc(100% - 12px);
  max-width: calc(360px - 12px);
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.15);
  z-index: 5 !important;
  padding: 24px 24px 0px 24px;
  bottom: 60px;

  .iphone-x & {
    bottom: 68px;
  }

  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .card-icons {
      display: flex;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background-color: #ececec;
      border-radius: 50%;
      margin-right: 16px;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .label {
      font-size: 13px;
      line-height: 25px;
      text-transform: uppercase;
      color: #000000;
      font-weight: 600;
      border-bottom: 3px solid $endurance;
      margin-right: 8px;
    }

    .close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      color: #4f4f4f;
      font-size: 15px;
      cursor: pointer;
    }
  }

  ::v-deep .card-content {
    .static-label p {
      color: #4f4f4f;
      font-size: 13px;
      font-weight: 700;
      text-align: left;
    }

    .error {
      margin-bottom: 16px;
    }

    .save-button {
      border-color: transparent;
      font-family: Montserrat, "open sans";
      font-size: 12px;
      font-weight: 700;
      color: #ffffff;
      padding: 8px 16px;
      margin-bottom: 28px;
      background-color: $endurance;
      border-radius: 20px;
      cursor: pointer;

      &.clear {
        background-color: transparent;
        color: $endurance;
      }

      &:focus {
        outline: 0px;
      }
    }
  }
}
</style>
