<template lang="html">
  <div v-click-outside="away" class="macrostax-select" @click="expanded = !expanded">
    <div class="prompt" :class="{ selection: has_selection }">
      <slot :expanded="expanded" />
    </div>

    <div class="select-list" :class="{ expanded }">
      <div
        v-for="item in options"
        :key="item.id"
        class="select-item"
        :class="{ selected: item.selected }"
        @click.stop="select(item)"
      >
        <p>
          {{ item[name_field] }}
          <span v-if="item.helper" class="helper">{{ item.helper }}</span>
        </p>
        <i :style="{ color: '#6b40ab' }" class="fa fa-check fa-sm icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { some } from "lodash";
import ClickOutside from "vue-click-outside";

export default {
  directives: { ClickOutside },
  props: {
    multi: { type: Boolean, default: false },
    options: { type: Array, required: true },
    name_field: { type: String, default: "name" }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    has_selection() {
      return some(this.options, item => item.selected);
    }
  },
  methods: {
    select(item) {
      if (this.multi) {
        this.$emit("toggle", item.id);
      } else {
        this.clear();
        this.expanded = false;
        if (!item.selected || this.source === "meal_select") {
          this.$emit("select", item.id);
        } else {
          this.$emit("clear", undefined);
        }
      }
    },
    clear() {
      this.options.filter(option => option.selected).map((item) => {
        this.$emit("deselect", item.id);
      });
    },
    away() { this.expanded = false; }
  }
};
</script>

<style scoped lang="scss">
.macrostax-select {
  position: relative;
  cursor: pointer;

  .prompt {
    &.selection {
      .indicator {
        display: block;
      }
    }
  }
  .select-list {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 0px;
    right: 0px;
    overflow-y: auto;
    background-color: white;
    font-size: 16px;
    font-family: 'Open Sans';
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0,0,0,0.20);
    transition: max-height 0.3s ease-out;

    &.expanded {
      max-height: 235px;
    }

    .select-item {
      display: flex;
      align-items: center;
      padding: 16px;
      cursor: pointer;
      text-align: left;

      p {
        margin: 0px 8px 0px 0px;
        font-size: 14px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .helper {
        font-size: 12px;
        font-weight: 600;
        color: #4f4f4f;
        margin: 0px 0px 0px 4px;
        line-height: 14px;
      }
      .icon {
        display: none;
        margin-left: auto;
      }

      &:hover {
        background-color: #ECECEC;
      }
      &.selected {
        p {
          font-weight: bold;
        }
        .icon {
          display: block;
        }
      }
    }
  }
}

</style>
