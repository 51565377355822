<template lang="html">
  <div id="log-weight" ref="container">
    <div class="profile-multi-input-row">
      <div class="weight profile-input-row">
        <p class="input-label">Weight</p>
        <div class="profile-input">
          <input v-model.number="weight" type="number" step="any" min="0" placeholder="Weight" />
          <div class="clear-button" @click="weight = ''">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
      <div class="static-label">
        <p>{{ weight_preference }}</p>
      </div>
    </div>
    <div v-if="invalid_weight" class="error left">
      <span>
        <img :src="error_icon" width="12" height="12"/>
      </span>
      Please enter your weight
    </div>

    <div @click.stop="toggle_calendar" class="date profile-input-row">
      <p class="input-label">Date</p>
      <div class="profile-input">
        <i class="fa fa-calendar"></i>
        <input v-model="formatted_date" :disabled="true" type="text" placeholder="Enter Date" />
      </div>
    </div>
    <Calendar
      v-if="show_calendar"
      :date="date"
      :select_date="select_date"
      v-click-outside="toggle_calendar"
    />

    <div class="button-row save">
      <button class="save-button" type="button" @click="save_weight">SAVE WEIGHT</button>
      <button class="save-button clear" type="button" @click="go_to_inputs">VIEW PROGRESS</button>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

import API from "@js/api/rails_client";
import updateIntercom from "@js/lib/intercom";

import Calendar from "@js/food_log/calendar.vue";

import error_icon from "@images/error.svg";

export default {
  components: { Calendar },
  directives: { ClickOutside },
  props: {
    close: { type: Function, required: true }
  },
  data() {
    return {
      weight: null,
      // vue-datepicker works with JS Date objects
      date: moment(this.$store.state.rails.selected_date).toDate(),
      show_calendar: false,
      invalid_weight: false,
      error_icon
    };
  },
  computed: {
    weight_preference() {
      return this.$store.state.rails.user.preferences.preferred_input_weight_unit;
    },
    formatted_date() {
      return moment(this.date).format("MMM Do YYYY");
    }
  },
  methods: {
    toggle_calendar() {
      this.show_calendar = !this.show_calendar;
      setTimeout(() => this.$emit('heightChanged', this.$refs.container.scrollHeight));
    },
    select_date(date) {
      this.date = date;
      this.show_calendar = false;
      setTimeout(() => this.$emit('heightChanged', this.$refs.container.scrollHeight));
    },
    go_to_inputs() {
      window.location.href = "/inputs";
    },
    async save_weight() {
      let weight = this.weight;

      if (!weight || weight <= 0) {
        this.invalid_weight = true;
      } else {
        try {
          this.$store.dispatch("loading", true);

          if (this.weight_preference === "kg") {
            weight = weight * 2.205;
          }
          const date = moment(this.date).format("YYYY-MM-DD");

          await API.log_weight(weight, date);
          updateIntercom({ weight_updated_at: moment().unix(), weight, weight_preference: this.weight_preference });
          this.$store.dispatch("notify_user", { type: "info", msg: "Weight saved successfully :)" });
          this.close();
        } catch (e) {
          this.$store.dispatch("notify_user", { type: "error", msg: "Unable to save weight." });
          console.error(e);
        } finally {
          if (!this.$route.path.match("/inputs")) {
            this.$store.dispatch("loading", false);
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.calendar {
  margin-top: -18px;
  margin-bottom: 18px;
}
.static-label p {
  margin-left: 24px;
}
#log-weight {
  transition: all 0.3s;
}
.card-content {
  .profile-input-row {
    &.date, &.weight {
      min-width: 50%;
      width: 50%;
    }
  }
}

.profile-multi-input-row {
  display: flex;
  flex-direction: row;

  div:first-child {
    margin-right: 24px;
  }

  .static-label {
    margin-top: 20px;
    margin: 20px 0px 0px auto;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 2px 0px 8px 0px;
  }
}

.profile-input-row {
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-bottom: 2px solid $endurance;
  margin-bottom: 18px;

  .checkbox-label {
    margin-bottom: 12px;
  }

  .input-label {
    color: $endurance;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 0px 4px 0px;
  }

  .profile-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(107, 64, 171, 0.04);

    &.disabled {
      background-color: #ececec;
      input {
        color: #4f4f4f;
      }
    }

    i {
      color: $endurance;
      font-size: 13px;
      font-weight: 600;
      margin: 0px 6px 0px 6px;
    }

    input {
      width: 100%;
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 700;
      margin: 0px;
      padding: 4px 0px 4px 8px;
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
        border: none;
      }

      :disabled {
        color: #4f4f4f;
        -webkit-text-fill-color: #4f4f4f;
        opacity: 1; /* required on iOS */
      }

      &::-webkit-input-placeholder {
        font-weight: 700;
      }
    }

    .clear-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 45px;
      height: 40px;

      i {
        color: $endurance;
        font-size: 14px;
      }
    }
  }
}
</style>

