import gql from "graphql-tag";

import conversion_fields from "@js/api/graphql/conversion_fields";

export default gql`
	query restaurant($search_term: String!, $user_id: Int!) {
		restaurant(search_term: $search_term, user_id: $user_id) {
			id
			name
			brand_name
			nix_photo_url
			quantity
			is_favorite
			conversion {
				id
				name
				is_default
				carb_grams
				protein_grams
				fat_grams
				calories
				weight_grams
				default_serving_qty
			}
			conversions {
				id
				name
				is_default
				carb_grams
				protein_grams
				fat_grams
				calories
				weight_grams
				default_serving_qty
			}
		}
	}
`;
