import gql from "graphql-tag";

import recipe_fields from "@js/api/graphql/recipe_fields";
import conversion_fields from "@js/api/graphql/conversion_fields";

// Note: macro_targets is a hack until we have sane default portions and/or support saving user-preferred portions
export default gql`
query KitchenFavorites($user_id: Int!, $macro_targets: MacroTargets!) {
  favorite_recipes(user_id: $user_id, macro_targets: $macro_targets) {
    ${recipe_fields}
    is_favorite
    conversion {
      ${conversion_fields}
    }
    conversions {
      ${conversion_fields}
    }
    child_recipes {
      ${recipe_fields}
      conversion {
        ${conversion_fields}
      }
      conversions {
        ${conversion_fields}
      }
      foods {
        name
        quantity
        conversion {
          ${conversion_fields}
        }
      }
    }
  }
  favorite_foods(user_id: $user_id) {
    id
    name
    brand_name
    nix_photo_url
    quantity
    is_favorite
    conversion {
      ${conversion_fields}
    }
    conversions {
      ${conversion_fields}
    }
  }
}
`;
