<template lang="html">
  <canvas ref="canvas"></canvas>
</template>

<script>
import { Doughnut } from "vue-chartjs";

const CHART_CONFIG = {
  cutoutPercentage: 75,
  animation: {
    animateRotate: true,
    animateScale: true
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  }
};

export default {
  extends: Doughnut,
  computed: {
    macro_targets() {
      return this.$store.getters["rails/macro_targets_for_day"];
    }
  },
  mounted() {
    this.render();
  },
  watch: {
    macro_targets() {
      this.render();
    }
  },
  methods: {
    render() {
      const { carbohydrates, protein, fat } = this.macro_targets;

      this.renderChart({
        datasets: [
          {
            data: [carbohydrates, protein, fat],
            backgroundColor: ["#FFCD3C", "#FF6699", "#34B6E4"],
            borderWidth: 0
          }
        ]
      }, CHART_CONFIG)
    }
  }
};
</script>
