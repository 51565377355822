<template lang="html">
  <div class="spinner" :style="style" />
</template>

<script>
export default {
  props: {
    radius: { type: Number, default: 60 }
  },
  computed: {
    style() {
      return `
        width: ${this.radius}px;
        height: ${this.radius}px;
        border-width: ${this.radius / 60}em;
      `;
    }
  }
};
</script>

<style scoped lang="scss">
.spinner,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  clear: both;
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-style: solid;
  border-top-color: $movement;
  border-right-color: $movement;
  border-bottom-color: $movement;
  border-left-color: transparentize($movement, 0.4);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  color: #fff;

  // variations
  &.white {
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
    border-left-color: transparentize(white, 0.4);
  }
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
