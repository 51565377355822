<template lang="html">
  <div
    class="recipe"
    :style="{ height: '142px !important' }"
    @click="viewRecipe(recipe.id)"
  >
    <div :class="{ 'recipe-photo': true, 'placeholder': !!recipe.photo_urls.small.match('placeholder') }">
      <img :src="recipe.photo_urls.small" />
    </div>
    <div class="favorite-button" v-on:click.stop @click="toggle_favorite">
      <i :class="{'fa': true, 'fa-heart': is_favorite, 'fa-heart-o': !is_favorite}" :style="{color: is_favorite ? '#FF6699 !important' : '#6B40AB'}"></i>
    </div>
    <div class="info">
      <div class="name-and-macros">
        <div class="recipe-name">
          <p ref="recipe_name" v-text="recipe.name" />
          <p v-if="is_name_overflown" class="overflow-ellipsis">...</p>
        </div>

        <div class="tags">
          <div v-for="filter in selected_filters" :key="filter.id" class="tag">
            {{ filter.display_name }}
          </div>
        </div>

      </div>
      <div class="view-button">
        <p>View Recipe</p>
      </div>
    </div>
    <div
      v-if="recipe.is_simple"
      @click.stop="$router.push(`/custom/recipes/${recipe.id}/customize`)"
      class="customize-button"
    >
      <img class="icon" :src="custom_recipes_icon" />
    </div>
    <div class="quick-add-button-absolute">
      <i class="fa fa-plus-circle" @click.stop="$emit('quick_add', recipe)" />
    </div>
  </div>
</template>

<script>
import { is_element_overflown } from "@js/lib/utils";
import { show_favorited_alert, show_unfavorited_alert } from "@js/lib/rails_compatibility_helpers";

export default {
  props: {
    recipe: {type: Object, required: true},
    selected_filters: {type: Array, required: false},
    viewRecipe: { type: Function, required: true }
  },
  data() {
    return {
      custom_recipes_icon: "https://d38il9bndsnkm4.cloudfront.net/assets/images/icons/chef-hat-purple.svg"
    };
  },
  computed: {
    is_favorite() {
      return this.$store.state.favorite_recipes.find(r => r.id === this.recipe.id);
    },
    // TODO: name is never truncated, template just adds an ellipsis
    is_name_overflown() {
      return is_element_overflown(this.$refs["recipe_name"]);
    }
  },
  methods: {
    async toggle_favorite() {
      try {
        this.$store.dispatch("loading", true);

        if (this.is_favorite) {
          await this.$store.dispatch("unfavorite_recipe", this.recipe.id);
          show_unfavorited_alert();
        } else {
          await this.$store.dispatch("favorite_recipe", {
            id: this.recipe.id,
            macro_targets: this.$store.getters["rails/default_portion_macro_targets"]
          });
          show_favorited_alert();
        }
      } catch(e) {
        console.log(e);
      } finally {
        this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.recipe {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin: 0px 0px 20px 0px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0,0,0,0.20);
  background-color: #ffffff;
  cursor: pointer;
  // supe hacky offscreen rendering on long lists (ios only???)
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  perspective: 1000;
  -webkit-perspective: 1000;
  // end of hacky offscreen rendering solution

  // &.first {
  //   padding-top: 0px;
  // }

  .recipe-photo {
    min-width: 128px;
    max-width: 128px;
    min-height: 100%;
    max-height: 100%;

    &.placeholder {
      img {
        opacity: 0.5;
      }
    }

    img {
      // min-width: 128px;
      // max-width: 128px;
      // min-height: 128px;
      // max-height: 128px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .favorite-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    left: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.75);
    i {
      color: $endurance;
      font-size: 14px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 16px 16px 8px 16px;
    overflow: hidden;
    width: 100%;

    .name-and-macros {
      flex: 1;
      .recipe-name {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin: 0px 0px 4px 0px;
        p {
          margin: 0px;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          width: 100%;
          overflow: hidden;
        }
        .overflow-ellipsis {
          margin: 0px 0px 0px 2px !important;
          padding: 0px 0px 3px 0px;
          min-width: 12px;
          max-width: 12px;
        }
      }
      .macros {
        margin: 0px 0px 4px 0px;
        overflow: hidden;
        ::v-deep * {
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 600;
          color: #4f4f4f !important;
        }
        ::v-deep .actual {
          padding-right: 0px;
          margin-right: 0px;
        }
      }
    }

    &.multimeal {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px dashed $sturdy;
      .name {
        font-size: 1rem;
        color: #777;
      }
    }
    &.submeal {
      margin: 0 0.2rem;
      .name {
        color: #777;
      }
      .add {
        background-color: lighten($macrostax_blue, 20%);
      }
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 0px 0px 0px;
      max-height: 35px;
      overflow: hidden;
      .tag {
        background-color: #f4f4f4;
        border-radius: 4px;
        font-size: 10px;
        font-family: 'Open Sans';
        color: #4f4f4f;
        font-weight: 500;
        padding: 3px 8px;
        margin: 0px 8px 8px 0px;
        min-height: 20px;
      }
    }
    .view-button {
      display: flex;
      flex-direction: row;
      color: $endurance;
      text-transform: uppercase;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 700;
      text-align: left;
      padding: 6px 10px 6px 0px;
      margin: 4px 0px 0px 0px;
      align-items: center;
      position: absolute;
      bottom: 4px;
      p {
        margin: 0px auto 0px 0px;
      }
      i {
        color: $endurance;
        font-size: 16px;
      }
    }
  }
  .customize-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px !important;
    height: 45px !important;
    margin: 0px 0px 0px auto;
    right: 45px;
    bottom: 0px;

    img.icon {
      color: $endurance;
    }
  }
  .quick-add-button-absolute {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px !important;
    height: 45px !important;
    margin: 0px 0px 0px auto;
    right: 0px;
    bottom: 0px;
    i {
      color: $endurance;
      font-size: 16px;
    }
  }
}
.recipe:last-child {
  margin: 0px;
  .favorites & {
    margin-bottom: 84px;
  }
}
</style>
