<template>
  <div id="progress-photos" class="dashboard-section">
    <header>
      <span v-if="last_photo" class="title">Photos</span>
      <span v-else class="title">Weekly Photos</span>
      <img :src="photos_icon" />
    </header>

    <p v-if="!last_photo" class="explanation">
      Watch your body change over time. Add photos to see your progress and
      compare side by side.
    </p>

    <div class="content">
      <div v-if="last_photo" class="body-box">
        <p class="primary-gray-text-medium">Week of {{ week_title }}</p>
        <div class="gallery">
          <div class="gallery-grid">
            <div v-for="photo in photos" class="slide" :key="photo.id">
              <div class="img-box">
                <img :src="photo.url.trim()" :alt="photo.photo_type" />
              </div>
              <span class="photo-label">
                {{ capitalize(photo.photo_type) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="placeholder">
          <img :src="gender_placeholder" />
        </div>
      </div>
    </div>

    <footer class="photos-footer-container">
      <span class="button-row">
        <a href="/inputs/photos" class="button">
          <span>Add Photos</span>
        </a>
        <a
          v-if="last_photo"
          href="/inputs/photos/comparison"
          class="button"
        >
          <span>Compare</span>
        </a>
      </span>

      <small class="privacy-note">
        Your photos are private and will not be shared.
      </small>
    </footer>
  </div>
</template>

<script>
import { capitalize } from "lodash";
import moment from "moment";

import photos_icon from "@images/photos.svg";
import male_placeholder from "@images/weekly-photos-block-male-placeholder.png";
import female_placeholder from "@images/weekly-photos-block-female-placeholder.png";

export default {
  data() {
    return {
      photos_icon,
      capitalize,
    };
  },
  computed: {
    dashboard() {
      return this.$store.state.rails.dashboard;
    },
    previous_photos() {
      return this.$store.state.rails.dashboard.previous_photos;
    },
    last_photo() {
      return this.previous_photos ? this.previous_photos.front : undefined;
    },
    gender_placeholder() {
      return this.$store.state.rails.statistic_set.gender === "female"
        ? female_placeholder
        : male_placeholder;
    },
    previous_photo_week() {
      return this.last_photo && !isNaN(this.last_photo.week, 10)
        ? this.last_photo.week
        : undefined;
    },
    week_title() {
      return this.previous_photo_week
        ? moment(`${this.last_photo.year}-${this.previous_photo_week}`, 'YYYY-WW')
          .startOf("isoWeek")
          .format("MMMM Do, YYYY")
        : "";
    },
    photos() {
      const photos = [];
      const types = ["front", "back", "side"];
      const { previous_photo_urls } = this.$store.state.rails;
      if (this.previous_photos) {
        types.forEach((t) => {
          if (previous_photo_urls[t]) {
            photos.push({
              ...this.previous_photos[t],
              url: previous_photo_urls[t],
            });
          }
        });
      }
      return photos;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@css/components/inputs";
@import "@css/vendors/_include-media";


.placeholder {
  min-height: 125px;
  max-height: 250px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}

// Responsive Image Gallery
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-template-rows: 1fr;
  gap: 10px 10px;
  max-height: 400px;
  overflow: hidden;
}

.gallery .slide {
  overflow: hidden;
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
}

.slide {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.photo-label {
  font-weight: 600;
  font-size: 10px;
  color: #4f4f4f;
  width: 100%;
  display: flex;
  padding: 3px 0;
  text-align: center;
  justify-content: center;
}

.photos-footer-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  * {
    display: flex;
  }

  .button-row {
    width: 100%;
    justify-content: center;
  }

  .privacy-note{
    font-size: 12px;
  }
}
</style>
