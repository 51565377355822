<template lang="html">
  <div class="action-button-container">
    <div class="information">
      <img :src="icon" width="51px" height="95px">
      <div class="card">
        <div class="card-header">
          <div>
            {{ headerText[planName] }}
          </div>
        </div>
        <div class="card-body">
          {{ bodyText[planName] }}
        </div>
      </div>
    </div>
    <div class="action-button">
      <button @click='this.onSubmit'>
        {{ actionButtonText[planName] }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    planName: { type: String, required: true },
    onSubmit: { type: Function, required: true },
  },
  computed: {
    actionButtonText() {
      return {
        'macrostax_monthly': 'Switch to Yearly',
        'macrostax_quarterly': 'Switch to Yearly',
        'macrostax_yearly': 'Enter Promo Code',
        'active_gym_challenge': 'Subscribe Now'
      }
    },
    bodyText() {
      return {
        'macrostax_monthly': 'When you switch to yearly!',
        'macrostax_quarterly': 'When you switch to yearly!',
        'macrostax_yearly': 'Save money on your next bill!',
        'active_gym_challenge': 'Ready to become a Macrostaxer?'
      }
    },
    headerText() {
      return {
        'macrostax_monthly': 'Save 50%',
        'macrostax_quarterly': 'Save 50%',
        'macrostax_yearly': 'Promo Code?',
        'active_gym_challenge': 'Join Now!'
      }
    },
    icon() {
      if(this.planName === 'active_gym_challenge' ) {
        return 'https://icons.sfo2.cdn.digitaloceanspaces.com/carrot.png';
      } else {
        return 'https://icons.sfo2.cdn.digitaloceanspaces.com/calculator.png';
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .action-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .information {
      display: flex;
      flex-direction: row;
      margin-bottom: 23px;

      img {
        margin-right: 25px;
      }

      .card {
        display: flex;
        flex-direction: column;
        width: 144px;
        left: 148.42px;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-top: auto;

        .card-header {
          display: flex;
          justify-content: center;
          background: #FBE0E6;
          border-radius: 8px 8px 0px 0px;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: #E84C71;
          margin-bottom: 0px;
        }

        .card-body {
          display: flex;
          justify-content: center;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 10px;
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #4F4F4F;
        }
      }
    }

    .action-button {
      margin-bottom: 32px;
      width: 100%;

      button {
        width: 100%;
        height: 40px;
        background: #6B40AB;
        border-radius: 22px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
      }
    }
  }
</style>
