<template lang="html">
  <div id="day-details-bar">
    <router-link to="/fill_macros" class="fill-macros-button">
      <div class="icon">
        <i class="fa fa-magic" />
      </div>
      <p>Fill My Macros</p>
    </router-link>
    <router-link :to="{ path: '/food_log', query: { fab: 'activity' } }" class="info">
      <div class="info-item">
        <img v-if="day_type === 'rest'" :src="not_active_icon" class="icon" />
        <img v-else :src="moderate_active_icon" class="icon" />
        <p>{{ day_type }}</p>
      </div>
      <div class="info-item">
        <i v-if="day_type !== 'rest'" class="fa fa-clock-o" />
        <p v-if="day_type !== 'rest'">{{ workout_time }}</p>
      </div>
      <div class="edit">
        <p>Edit</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import { get_next_empty_meal } from "@js/lib/rails_compatibility_helpers";

import not_active_icon from "@images/not-active.svg"
import moderate_active_icon from "@images/moderate-active.svg"

export default {
  data() {
    return {
      not_active_icon,
      moderate_active_icon
    }
  },
  computed: {
    day_type() {
      return this.$store.state.rails.day_details.day_type;
    },
    workout_time() {
      return this.$store.state.rails.day_details.workout_time;
    }
  }
};
</script>

<style scoped lang="scss">
  #day-details-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(79, 79, 79, 0.25);
    margin: 0 0 8px 0;
    min-height: 42px;

    .fill-macros-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px 8px 0px 0px;
      color: $endurance;
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        background-color: $endurance;
        border-radius: 50%;
        i {
          color: #ffffff;
          font-size: 10px;
        }
      }
      p {
        font-size: 12px;
        font-weight: 600;
        margin: 0px 0px 0px 8px;
        color: $endurance;
      }
      &.disabled {
        .icon {
          background-color: #959595;
        }
        p {
          color: #959595;
        }
      }
    }
    .info {
      display: flex;
      flex-direction: row;
      margin: 0px 0px 0px auto;
      align-items: center;
      .info-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 12px 0px 0px;
        .icon {
          width: 16px;
          height: 16px;
          margin: 0px;
        }
        i {
          font-size: 14px;
          color: #000000;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          margin: 0px 0px 0px 8px;
          color: #000000;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-transform: capitalize;
        }
      }
    }
    .edit {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-left: auto;
      cursor: pointer;
      p {
        color: $endurance;
        font-weight: 600;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0px;
      }
      i {
        color: $endurance;
        margin-right: 4px;
      }
    }
  }
</style>
