<template lang="html">
  <div v-show="visible" id="log-weight-card">
    <div class="card-header">
      <div class="card-icons">
        <div class="icon">
          <img :src="log_weight_icon" />
        </div>
        <div class="label">Log Weight</div>
      </div>
      <div class="close-button" @click="close">
        <i class="fa fa-times" />
      </div>
    </div>
    <div class="card-content">
      <LogWeight :standalone="true" :close="reload" />
    </div>
  </div>
</template>

<script>
import { reloadPage } from "@js/router";

import LogWeight from "@js/food_log/fab/log_weight.vue";

import log_weight_icon from "@images/weight-loss.svg";

export default {
  components: { LogWeight },
  data() {
    return {
      visible: false,
      log_weight_icon
    };
  },
  computed: {
    weight_preference() {
      return this.$store.state.rails.user.preferences.preferred_input_weight_unit;
    },
    formatted_date() {
      return moment(this.date).format("MMM Do YYYY");
    }
  },
  methods: {
    open() {
      this.visible = true;
    },
    reload() {
      reloadPage();
    },
    close() {
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="scss">
#log-weight-card {
  position: fixed;
  bottom: 0;
  width: calc(100% - 12px);
  max-width: calc(360px - 12px);
  left: calc(50% - 180px + 6px);
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.15);
  z-index: 5 !important;
  padding: 24px 24px 0px 24px;

  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .card-icons {
      display: flex;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background-color: #ececec;
      border-radius: 50%;
      margin-right: 16px;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .label {
      font-size: 13px;
      line-height: 25px;
      text-transform: uppercase;
      color: #000000;
      font-weight: 600;
      border-bottom: 3px solid $endurance;
      margin-right: 8px;
    }

    .close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      color: #4f4f4f;
      font-size: 15px;
      cursor: pointer;
    }
  }
}

::v-deep .card-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 24px;

  p {
    font-size: 13px;
    font-weight: 700;
    color: #4f4f4f;
    text-align: left;
    margin: 0px 0px 16px 0px;

    &.error {
      font-size: 12px;
      color: #d65f5f;
    }
  }

  .profile-input-row {
    display: flex;
    flex-direction: column;
    padding: 0px;
    border-bottom: 2px solid $endurance;
    margin-bottom: 18px;
    width: 100%;
  }

  .profile-input-checkbox {
    display: flex;
    flex-direction: row;

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      border: 2px solid $endurance;
      margin: 0px 16px 0px 0px;
      cursor: pointer;
      &.selected {
        background-color: $endurance;
        i {
          display: block;
        }
      }
      i {
        display: none;
        color: #ffffff;
        font-size: 10px;
      }
    }
  }

  .checkbox-label {
    margin-bottom: 12px;
  }

  .input-label {
    color: $endurance;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 0px 4px 0px;
  }

  .profile-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(107, 64, 171, 0.04);

    &.disabled {
      background-color: #ececec;
      input {
        color: #4f4f4f;
      }
    }

    i {
      color: $endurance;
      font-size: 13px;
      font-weight: 600;
      margin: 0px 6px 0px 6px;
    }

    input {
      width: 100%;
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 700;
      margin: 0px;
      padding: 4px 0px 4px 8px;
      border: none;
      background-color: transparent;
    }

    input:focus {
      outline: none;
      border: none;
    }

    input:disabled {
      color: #4f4f4f;
      -webkit-text-fill-color: #4f4f4f;
      opacity: 1; /* required on iOS */
    }

    input::-webkit-input-placeholder {
      font-weight: 700;
    }

    .clear-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 45px;
      height: 40px;

      i {
        color: $endurance;
        font-size: 14px;
      }
    }
  }

  .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.save {
      margin-top: 12px;
    }

    .save-button {
      border-color: transparent;
      font-family: Montserrat, 'open sans';
      font-size: 12px;
      font-weight: 700;
      color: #ffffff;
      padding: 8px 16px;
      background-color: $endurance;
      border-radius: 20px;
      cursor: pointer;

      &.clear {
        background-color: transparent;
        color: $endurance;
      }

      &:focus {
        outline: 0px;
      }
    }

    .radio {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;
      .indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $endurance;
        background-color: #ffffff;
        margin-right: 8px;
        div {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ffffff;
        }
      }
      &.disabled {
        .indicator {
          border: 1px solid #e2e2e2;
          background-color: #e2e2e2;
          div {
            display: none;
          }
        }
      }
      &.active {
        .indicator {
          div {
            background-color: $endurance;
          }
        }
      }
      span {
        font-size: 11px;
        font-weight: 700;
        color: #000000;
      }
    }
  }
}
</style>

