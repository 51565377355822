<template lang="html">
  <div class="current-billing-information">
    <p>Billing Information</p>
    <div class="existing-card">
      <div class="card-info-container">
        <p class="label">Card Number</p>
        <p class="data">************{{ card.last4 }}</p>
      </div>
      <div class="card-exp">
        <div class="card-info-container expire">
          <p class="label">Exp Date</p>
          <div>
            <i class="far fa-calendar" />
            <p class="data">{{ card.expireDate }}</p>
          </div>
        </div>
        <div class="card-info-container">
          <p class="label">CVV</p>
          <p class="data cvv">***</p>
        </div>
      </div>
    </div>
    <div @click="updateCreditCard()" class="update-billing">
      <p>Update Billing Information</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: { type: Object, required: true },
    scrollToTop: { type: Function, required: true },
  },
  methods: {
    updateCreditCard() {
      this.$router.push({
        path: '/update-billing',
        params: {
          scrollToTop: this.scrollToTop
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
  .current-billing-information {
    background: #FAFAFA;
    box-shadow: 0px 1px 2px rgba(79, 79, 79, 0.25);
    border-radius: 8px;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 18px;
    padding-left: 15px;
    padding-right: 15px;

    .existing-card {
      width: 75%;

      .card-exp {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;
      }

      .card-info-container {
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
        border-radius: 8px;
        padding-left: 16px;
        padding-top: 10px;
        padding-bottom: 3px;
        flex-grow: 1;

        &.expire {
          margin-right: 24px;

          div {
            display: flex;
            flex-direction: row;
            margin-top: 5px;

            i {
              line-height: unset;
              margin-right: 10px;
            }
          }
        }

        .label {
          margin-bottom: 0px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #6B40AB;
        }

        .data {
          margin-bottom: 0px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #000000;

          &.cvv {
            margin-top: 5px;
          }
        }
      }
    }

    .update-billing {
      margin-top: 19px;

      p {
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #6B40AB;
      }
    }
  }
</style>
