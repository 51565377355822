<template lang="html">
  <div class="radial">
    <p class="short-label">{{ label }}</p>
    <div :class="{'radial-progress': true, 'progress': true, 'carbs': short_name === 'carbs', 'protein': short_name === 'protein', 'fat': short_name === 'fat', 'calories': short_name === 'calories', 'over-100': over_target, 'on-target': on_target}" :data-percentage="percentage === 100 ? 0 : percentage">
      <span class="progress-left">
        <span class="progress-bar"></span>
      </span>
      <span class="progress-right">
        <span class="progress-bar"></span>
      </span>
      <div :class="{'progress-flexwrap': true}">
        <div class="progress-value">
          <p v-if="!on_target" class="remaining-value">{{ Math.abs(remaining) }}</p>
          <p v-if="on_target" class="check">
            <i class="fa fa-check" />
          </p>
          <p v-if="over_target" class="over">over</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    short_name: {type: String, default: ""},
    label: {type: String, default: ""},
    target: {type: Number, default: 0},
    actual: {type: Number, default: 0},
    remaining: {type: Number, default: 0},
    percentage: {type: Number, default: 0},
    on_target: {type: Boolean, default: false},
    over_target: {type: Boolean, default: false}
  },
  data() {
    return {
      green_thumb: "data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7fS5jbHMtMntmaWxsOiNhZGUyY2Q7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5ncmVlbi10aHVtYjwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMCwwSDI0VjI0SDBaTTAsMEgyNFYyNEgwWiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTEzLjEyLDIuMDYsNy41OCw3LjZBMiwyLDAsMCwwLDcsOVYxOWEyLDIsMCwwLDAsMiwyaDlhMiwyLDAsMCwwLDEuODQtMS4yMWwzLjI2LTcuNjFBMywzLDAsMCwwLDIwLjM0LDhIMTQuNjlsMS00LjU4YTEuNTEsMS41MSwwLDAsMC0uNDEtMS4zN0ExLjQ5LDEuNDksMCwwLDAsMTMuMTIsMi4wNlpNMywyMWEyLDIsMCwwLDAsMi0yVjExYTIsMiwwLDAsMC00LDB2OEEyLDIsMCwwLDAsMywyMVoiLz48L3N2Zz4="
    };
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="scss">  
  // from vendor
  // Sourced from http://blog.greggant.com/posts/2017/10/23/pure-css-scss-circular-progress-bar.html
  $borderWidth: 3px;
  $animationTime: 0.5s;
  $border-color-default: #fff;
  $border-color-over-100: #96cfeb;
  $size: 72px;

  //Create how many steps
  $howManySteps: 100; //this needs to be even.
  //for fun try using 20 and changing in the HTML the data-percentage to 15 or 85

  .progress {
    width: $size;
    height: $size;
    line-height: $size;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: $borderWidth solid $border-color-default;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.over-100-radial {
      &:after {
        border: $borderWidth solid $border-color-over-100;
      }
    }
    > span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .progress-left {
      left: 0;
    }
    .progress-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: $borderWidth;
      border-style: solid;
      position: absolute;
      top: 0;
    }
    .progress-left .progress-bar {
      left: 100%;
      border-top-right-radius: ($size/2);;
      border-bottom-right-radius: ($size/2);;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
      //animation: loading-2 1.5s linear forwards 1.8s;
    }
    .progress-right {
      right: 0;
      .progress-bar {
        left: -100%;
        border-top-left-radius: ($size/2);;
        border-bottom-left-radius: ($size/2);;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
        //animation: loading-1 1.8s linear forwards;
      }
    }
    .progress-flexwrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .progress-value {
        border-radius: 50%;
        font-size: 12px;
        text-align: center;
        line-height: 12px;
        font-weight: normal;
        display: inline-block;
        span.remaining-value {
          font-size: 22px;
        }

        i.icon-like {
          font-size: 20px;
        }
      }
    }
  }

  /* This for look creates the   necessary css animation names
  Due to the split circle of progress-left and progress right, we must use the animations on each side.
  */
  @for $i from 1 through $howManySteps {
    $stepName: ($i*(100 / $howManySteps));

    //animation only the left side if below 50%
    @if $i <= ($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{$i} $animationTime linear forwards;
        }

        .progress-left .progress-bar {animation: 0;}
      }
    }

    //animation only the right side if above 50%
    @if $i > ($howManySteps/2)  {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
        }
        .progress-left .progress-bar {
          animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
        }
      }
    }
  }

  //animation
  @for $i from 1 through ($howManySteps/2) {
    $degrees: (180/($howManySteps/2));
    $degrees: ($degrees*$i);
    @keyframes loading-#{$i}{
      0%{
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100%{
          -webkit-transform: rotate($degrees);
        transform: rotate(#{$degrees}deg);
      }
    }
  }
  // end from vendor

  $borderWidth: 2.5px;
  $border-color-gray: #FAFAFA;
  $border-color-actual: #E7E7E7;
  $border-color-default: #00A881;
  $border-color-carbs: #FFCD3C;
  $border-color-protein: #FF6699;
  $border-color-fat: #34B6E4;
  $border-color-calories: #B254B2;
  $border-color-green: #ADE2CD;
  $border-color-over-100: #FF9234;
  $size: 50px;

  .macro-success {
    color: $border-color-actual !important;
  }

  ::v-deep .progress-value {
    color: #000000;
    font-family: "Open Sans";
    font-size: 10px !important;
    font-weight: 700 !important;

    .remaining-value {
      color: #000000;
      font-family: "Open Sans";
      font-size: 14px !important;
      font-weight: 600;
      margin: 0px;
    }
    .check {
      font-size: 16px;
      color: #00A881;
      margin: 0px;
    }
    .over {
      color: #000000;
      font-family: "Open Sans";
      font-size: 10px !important;
      font-weight: 700;
      margin: 0px;
    }
  }

  .radial {
    display: flex;
    flex-direction: column;
    width: 70px;
    overflow: hidden;
    align-items: flex-start;
    .carrot {
      width: 40px;
      margin-bottom: 8px;
    }
    .short-label {
      color: #000000;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 600;
      margin: 0px;
      text-align: left;
      width: 100%;
    }
    .allowance {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4px;
      .label, .value {
        color: #000000;
        font-family: "Open Sans";
        font-size: 12px !important;
        font-weight: 600;
        text-align: center;
        text-transform: capitalize;
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
      .remaining-small {
        color: #4f4f4f;
        font-family: "Open Sans";
        font-size: 12px !important;
        font-weight: 600;
        &.remaining-small-hidden {
          display: none;
        }
      }
    }
    ::v-deep .progress {
      width: $size;
      height: $size;
      line-height: $size;
      margin: 0px 6px;
      &:after {
        border: $borderWidth solid $border-color-default !important;
      }
      &.carbs {
        &:after {
          border: $borderWidth solid $border-color-carbs !important;
        }
      }
      &.protein {
        &:after {
          border: $borderWidth solid $border-color-protein !important;
        }
      }
      &.fat {
        &:after {
          border: $borderWidth solid $border-color-fat !important;
        }
      }
      &.calories {
        &:after {
          border: $borderWidth solid $border-color-calories !important;
        }
      }
      &.over-100 {
        &:after {
          border: $borderWidth solid $border-color-gray !important;
        }
        .progress-bar {
          color: $border-color-over-100 !important;
          border-color: $border-color-over-100 !important;
        }
      }
      &.on-target {
        &:after {
          border: $borderWidth solid $border-color-default !important;
        }
        .progress-bar {
          color: $border-color-green !important;
          border-color: $border-color-green !important;
        }
      }
      .progress-bar {
        border-width: $borderWidth !important;
        color: $border-color-actual !important;
        border-color: $border-color-actual !important;
      }
      .progress-left .progress-bar {
        border-top-right-radius: ($size/2);;
        border-bottom-right-radius: ($size/2);;
      }
      .progress-right {
        .progress-bar {
          border-top-left-radius: ($size/2);;
          border-bottom-left-radius: ($size/2);;
        }
      }
      .progress-flexwrap {
        background-color: #ffffff;
        border-radius: 50%;
        &.thumbs {
          background-color: $border-color-green;
        }
        .green-thumb {
          width: 23px;
        }
      }
    }
  }
</style>
