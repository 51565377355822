<template>
  <div class="mini-spinner-container">
    <div class="mini-spinner" :class="color" />
  </div>
</template>

<script>
export default {
  props: {
    color: { type: String, required: true, validator: color => ["white", "pink", "red"].includes(color) }
  }
};
</script>

<style scoped lang="scss">
.mini-spinner-container {
  // display: none;
  position: relative;
  z-index: 100;
  background-color: rgba(255,255,255,0.8);
  margin-top: 2px;

  .mini-spinner,
  .mini-spinner:after {
    border-radius: 50%;
  }
  .mini-spinner {
    width: 18px;
    height: 18px;
    border-width: 18 / 60em;

    clear: both;
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-style: solid;
    border-top-color: $confident;
    border-right-color: $confident;
    border-bottom-color: $confident;
    border-left-color: transparentize($confident, 0.6);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    color: #FFF;

    // variations
    &.white {
      border-top-color: white;
      border-right-color: white;
      border-bottom-color: white;
      border-left-color: transparentize(white, 0.4);
    }
    &.pink {
      border-top-color: $confident;
      border-right-color: $confident;
      border-bottom-color: $confident;
      border-left-color: transparentize($confident, 0.6);
    }
    &.red {
      border-top-color: $power;
      border-right-color: $power;
      border-bottom-color: $power;
      border-left-color: transparentize($power, 0.6);
    }
  }
  @-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  }
  @keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  }

}
</style>
