<template lang="html">
  <div class="profile-container">
    <div class="nav">
      <div class="back-button" @click="$router.push('/billing')">
        <i class="fa fa-chevron-left"></i>
        <p>Summary</p>
      </div>
      <p class="title">Billing</p>
    </div>
    <form v-on:submit.prevent id="update-credit-card">
      <div class="content update-billing-information">
        <p class="title">Update Billing Information</p>
        <p class="description">Subscriptions are billed on a recurring basis.  Any changes will take effect on the first day of your next billing cycle.</p>
        <div class="existing-card">
          <div class="credit-card-label">
            Credit Card
          </div>
          <div class="card-info-container" @click='$refs.owner.focus()'>
            <p class="label owner">Name on Card</p>
            <input ref='owner' type='text' class='data' v-model='formData.cardOwner'>
            <p v-if="submitted && !formData.cardOwner" class="error-label owner">Required field</p>
          </div>
          <div class="card-info-container" @click="elements.getElement('cardNumber').focus()">
            <p class="label">Card Number</p>
            <div id="card-number"></div>
            <p v-if="submitted && !formData.cardNumber" class="error-label card-number">Required field</p>
          </div>
          <div class="sub-fields">
            <div class="exp-cvc">
              <div class="card-info-container exp" @click="elements.getElement('cardExpiry').focus()">
                <p class="label">Exp Date</p>
                <div class="expire-input">
                  <i class="far fa-calendar" />
                  <div id="card-expiry"></div>
                </div>
                <p v-if="submitted && !formData.cardExpiry" class="error-label card-expiry">Required field</p>
              </div>
              <div class="cvc-container cvc">
                <div class="card-info-container cvc" @click="elements.getElement('cardCvc').focus()">
                  <p class="label">CVC</p>
                  <div id="card-cvc"></div>
                </div>
                <p v-if="submitted && !formData.cardCvc" class="error-label">Required field</p>
              </div>
            </div>
            <div class="zip-field">
              <div class="card-info-container" @click='$refs.zipcode.focus()'>
                <p class="label zipcode">Billing Zip Code</p>
                <input tabindex=1 ref='zipcode' type='text' class="data zipcode" v-model='formData.cardZipcode'>
                <p v-if="submitted && !formData.cardZipcode" class="error-label zipcode">Required field</p>
              </div>
            </div>
          </div>
        </div>
        <div class="submit-button">
          <button @click='validateForm()'>Save Changes</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Store from "@js/store";
import API from "@js/api/rails_client";
export default {
  props: {
    scrollToTop: { type: Function, required: true },
  },
  data() {
    return {
      stripe: Stripe(process.env.STRIPE_TOKEN),
      elements: [],
      submitted: false,
      formData: {
        cardCvc: null,
        cardOwner: null,
        cardNumber: null,
        cardExpiry: null,
        cardZipcode: null,
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!Store.state.rails.billing || !Store.state.rails.billing.subscription.plan.name) {
      Store.dispatch("rails/getBillingData").then( () => next() );
    } else {
      next();
    }
  },
  mounted() {
    this.mountStripeElements();
  },
  computed: {
    owner() {
      return this.$store.state.rails.billing.card.owner;
    },
    zipcode() {
      return this.$store.state.rails.billing.card.zipcode;
    },
    expireDate() {
      return this.$store.state.rails.billing.card.exp_date;
    },
    last4() {
      return this.$store.state.rails.billing.card.last4;
    },
  },
  methods: {
    async updateCard(token) {
      this.$store.dispatch("loading", true);

      try {
        const payload = {
          name: this.formData.cardOwner,
          address_zip: this.formData.cardZipcode,
        }
        const element = this.elements.getElement('cardNumber');

        const stripeResponse = await this.stripe.createToken(element, payload);
        await API.updateCreditCard(stripeResponse.token.id);

        const card = stripeResponse.token.card;
        const exp_month = `0${card.exp_month}`.slice(-2);
        const exp_year = `${card.exp_year}`.slice(-2);
        const cardInfo = {
          owner: card.name,
          last4: card.last4,
          zipcode: card.address_zip,
          exp_date: `${exp_month}/${exp_year}`,
        }
        this.$nextTick(() => {
          this.scrollToTop();
        });
        this.$store.dispatch("rails/updateCreditCard", cardInfo);
        this.$store.dispatch("notify_user", { type: "info", msg: 'Billing information has been updated successfully.' });
        this.$store.dispatch("loading", false);
        this.resetElements();
      } catch(e) {
        this.$store.dispatch("loading", false);
        this.$store.dispatch("showMessageModal", {
          text: "Please enter valid credit card information.",
          icon_url: "https://icons.sfo2.digitaloceanspaces.com/error.svg",
        });
      }
    },
    validateForm() {
      this.submitted = true;
      const formValues = Object.values(this.formData);
      const valid = formValues.every((value) => value);

      valid ? this.updateCard()
            : this.$store.dispatch("showMessageModal", {
                text: "Unable to save.  Please fill all required fields.",
                icon_url: "https://icons.sfo2.digitaloceanspaces.com/error.svg",
              });
    },
    mountStripeElements() {
      this.elements = this.stripe.elements();

      const cardNumberElement = this.elements.create("cardNumber", { 'showIcon': true, 'placeholder': '' });
      cardNumberElement.mount("#card-number");
      cardNumberElement.on('change', (e) => { this.formData.cardNumber = e.complete });

      const cardExpiry = this.elements.create("cardExpiry", { 'placeholder': '' });
      cardExpiry.mount("#card-expiry");
      cardExpiry.on('change', (e) => { this.formData.cardExpiry = e.complete });

      const cardCvc = this.elements.create("cardCvc", { 'placeholder': '' });
      cardCvc.mount("#card-cvc");
      cardCvc.on('change', (e) => { this.formData.cardCvc = e.complete });
    },
    resetElements() {
      this.submitted = false;
      this.formData.cardOwner = null;
      this.formData.cardZipcode = null;
      this.elements.getElement('cardCvc').clear();
      this.elements.getElement('cardExpiry').update({'placeholder': ''}).clear();
      this.elements.getElement('cardNumber').update({'placeholder': ''}).clear();
    },
  }
};
</script>

<style scoped lang="scss">
@import "@css/components/profile";
form#update-credit-card {
  height: 95%;

  .update-billing-information {
    overflow: auto;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    color: #4F4F4F;
    height: 90%;
    background: #FAFAFA;
    box-shadow: 0px 1px 2px rgba(79, 79, 79, 0.25);
    border-radius: 8px;

    p.error-label {
      font-size: 12px;
      line-height: 18px;
      color: #E84C71;

      &.owner {
        margin-top: 2px;
      }

      &.card-number {
        margin-top: 4px;
      }

      &.card-expiry {
        margin-top: 4px;
      }

      &.zipcode {
        margin-top: 2px;
      }
    }

    p.title {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
    }

    p.description{
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #4F4F4F;
    }

    .submit-button {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      button {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #6B40AB;
        background-color: transparent;
        border: none;
        margin-top: 37px;
        outline: none;
      }
    }

    .existing-card {
      width: 80%;

      .sub-fields {
        margin-top: 16px;

        .exp-cvc {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .card-info-container {

            &.exp {
              flex-basis: 60%;
              flex-grow: 0;
              flex-shrink: 0;
              margin-right: 24px;
            }

            .expire-input {
              display: flex;
              flex-direction: row;

              #card-expiry {
                width: 100%;
                margin-left: 15px;
              }
            }
          }
        }

        .zip-field {
          display: flex;
          flex-direction: column;
          height: 48px;
          width: 60%;

          .card-info-container {

            input {
              width: 95%;
            }
          }
        }

        .cvc {
          margin-bottom: 0px;
          flex-basis: 30%;
        }
      }

      .credit-card-label {
        font-size: 12px;
        line-height: 18px;
        color: #4F4F4F;
        margin-bottom: 20px;
      }

      .card-info-container {
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
        border-radius: 8px;
        padding-left: 16px;
        padding-top: 5px;
        padding-bottom: 3px;
        flex-grow: 1;
        margin-bottom: 23px;
        height:48px;

        .label {
          margin-bottom: 4px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #6B40AB;

          &.owner {
            margin-bottom: 2px;
          }

          &.zipcode {
            margin-bottom: 2px;
          }
        }

        .data {
          margin-bottom: 0px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #000000;
          border: none;
          outline: none;
          padding: 0px;
          height: auto;
          width: 130px;

          &.zipcode {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
