<template>
  <div class="expandable-row">
    <div class="row-header" @click="toggle_section">
      <p>Ingredients</p>
      <div v-if="!is_valid" class="indicator" />
      <i v-if="expanded" class="fa fa-chevron-up fa-sm icon purple" />
      <i v-else class="fa fa-chevron-down fa-sm icon purple" />
    </div>

    <transition name="fade">
      <div v-if="expanded" class="row-content">
        <Search :add_item="add_new_ingredient" :on_open_barcode_scanner="on_open_barcode_scanner" :update_count="update_count" />

        <p v-if="search_results_length === 0" class="no-results-warning">We couldn't find any results</p>

        <div id="ingredient-list">
          <Ingredient
            v-for="food in ingredients"
            v-bind:key="food.id"
            :food="food"
            :is_editing="editing_ingredient === food.id"
            :edit_ingredient="id => editing_ingredient = id"
            :save_ingredient="save_ingredient"
            :remove_ingredient="remove_ingredient"
            />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import Search from "@js/shared/search/search.vue";
import Ingredient from "@js/custom/recipe_builder/ingredient.vue";

export default {
  name: "Ingredients",
  components: { Search, Ingredient },
  props: {
    ingredients: { type: Array, required: true },
    add_ingredient: { type: Function, required: true },
    remove_ingredient: { type: Function, required: true },
    on_open_barcode_scanner: { type: Function, required: true },
    expanded: { type: Boolean, required: true },
    is_valid: { type: Boolean, required: true },
    toggle_section: { type: Function, required: true }
  },
  data() {
    return {
      editing_ingredient: null,
      search_results_length: null
    };
  },
  methods: {
    add_new_ingredient(food) {
      this.add_ingredient(food);
      this.editing_ingredient = food.id;
    },
    edit_ingredient(food_id) {
      this.editing_ingredient = food_id;
    },
    save_ingredient(food) {
      this.editing_ingredient = null;
    },
    update_count(count) {
      this.search_results_length = count;
    },
    create_custom_ingredient() {
      // TODO: display custom ingredient modal
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/transitions";
#ingredient-list {
  padding: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

#kitchen-search {
  margin-bottom: 20px;
}

.expandable-row {
  padding: 0px;
  margin-bottom: 8px;

  .row-header {
    display: flex;
    align-items: center;
    padding: 16px 12px;
    border-bottom: 1px solid #e2e2e2;
    margin-top: 8px;
    cursor: pointer;
    p {
      margin: 0px 12px 0px 0px;
    }
    .indicator {
      display: block;
      min-width: 8px;
      min-height: 8px;
      border-radius: 50%;
      background-color: #a189cb;
      margin-right: 8px;
    }
    .icon {
      margin-left: auto;
      color: $endurance;
    }
  }
  .row-content {
    padding: 24px 12px 0px 12px;

    .input-row {
      display: flex;
      flex-direction: row;
      width: 100%;

      .input-container {
        background-color: #FAFAFA;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 2px solid $endurance;
        padding: 6px 12px;
        margin: 0px 24px 24px 0px;
        width: 100%;

        .input-label {
          color: $endurance;
          font-size: 12px;
          font-weight: 600;
          margin: 0px 0px 6px 0px;
        }

        input {
          color: #000000;
          background-color: transparent;
          font-size: 14px;
          font-weight: 600;
          padding: 0px;
          margin: 0px;
          resize: none;
          width: 100%;
          height: 28px;
        }

        input::placeholder {
          color: #acacac;
          font-size: 16px;
          font-weight: normal;
        }

        input:focus {
          outline: none !important;
        }

        textarea {
          color: #000000;
          background-color: transparent;
          font-size: 14px;
          font-weight: 600;
          padding: 0px;
          margin: 0px;
          resize: none;
          width: 100%;
        }

        textarea::placeholder {
          color: #4f4f4f;
          font-size: 16px;
          font-weight: normal;
        }

        textarea:focus {
          outline: none !important;
        }

        select {
          background-color: transparent;
          width: 100%;
        }

        select:focus {
          outline: none !important;
        }
      }
    }
  }
}

.create-custom-ingredient-button {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: $endurance;
  margin-top: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  display: inline-block;
}

.no-results-warning {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 12px;
  display: inline-block;
  width: 100%;
  text-align: center;
}
</style>
