<template lang="html">
  <div id="scoreboard" :class="{'on-target': macros_on_target}">
    <img v-if="macros_on_target" :src="confettiScoreboard" class="confetti" />

    <div v-if="!collapsed" class="scoreboard-header" @click="toggle_targets_section">
      <span class="title">
        <p>Macros Remaining</p>
      </span>
      <span class="dropdown">
        <p>Details</p>
        <i v-if="targets_section_visible" class="fa fa-chevron-up"></i>
        <i v-else class="fa fa-chevron-down"></i>
      </span>
    </div>

    <div class="scoreboard-inner">
      <DateNav v-if="collapsed" :condensed="true" />
      <div class="scoreboard-radials" @click="toggle_targets_section">
        <ScoreboardRadial
          v-for="macro in macros"
          :key="macro.short_name"
          :macro="macro"
          :target="targets[macro.long_name]"
          :actual="logged[macro.long_name]"
          :remaining="remaining[macro.long_name]"
          :percentage="macro.short_name === 'calories' ? calories_percentage() : macro_percentage(macro)"
          :on_target="macro_on_target(macro)"
          :over_target="macro_over_target(macro)"
          :macros_on_target="macros_on_target"
          :collapsed="collapsed"
        />
      </div>
    </div>

    <div
      class="targets-section"
      :class="{ visible: targets_section_visible }"
      @click="toggle_targets_section"
    >
      <p class="targets-section-header">Macros Consumed</p>
      <div class="targets-section-rows">
        <div class="targets-section-row">
          <p class="label">C</p>
          <p class="value">{{ logged.carbohydrates }}g <span class="target">/ {{ targets.carbohydrates }}g</span></p>
          <div class="progress-bar-container" :class="{ 'over-100': macro_over_target(macros[0])}">
            <div
              class="progress-bar"
              :style="{ width: `${macro_percentage(macros[0])}%`, backgroundColor: '#FFCD3C' }"
            />
          </div>
        </div>
        <div class="targets-section-row">
          <p class="label">P</p>
          <p class="value">
            {{ logged.protein }}g
            <span class="target">/ {{ targets.protein }}g</span>
          </p>
          <div class="progress-bar-container" :class="{ 'over-100': macro_over_target(macros[1])}">
            <div
              class="progress-bar"
              :style="{ width: `${macro_percentage(macros[1])}%`, backgroundColor: '#FF6699' }"
            />
          </div>
        </div>
        <div class="targets-section-row">
          <p class="label">F</p>
          <p class="value">
            {{ logged.fat }}g
            <span class="target">/ {{ targets.fat }}g</span>
          </p>
          <div class="progress-bar-container" :class="{ 'over-100': macro_over_target(macros[2])}">
            <div
              class="progress-bar"
              :style="{ width: `${macro_percentage(macros[2])}%`, backgroundColor: '#34B6E4' }"
            />
          </div>
        </div>
        <div class="targets-section-row">
          <p class="label">Cals</p>
          <p class="value">
            {{ logged.calories }}
            <span class="target">/ {{ targets.calories }}</span>
          </p>
          <div class="progress-bar-container" :class="{ 'over-100': remaining['calories'] < 0}">
            <div
              class="progress-bar"
              :style="{ width: `${calories_percentage(true)}%`, backgroundColor: '#B254B2' }"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ScoreboardRadial from "@js/food_log/scoreboard_radial.vue";
import DateNav from "@js/food_log/date_nav.vue";
import confettiScoreboard from "@images/confetti-scoreboard.svg";
import * as moment from 'moment';

export default {
  components: { ScoreboardRadial, DateNav },
  props: {
    collapsed: { type: Boolean, required: true }
  },
  data() {
    return {
      confettiScoreboard,
      macros: [
        {
          long_name: "carbohydrates",
          short_name: "carbs",
          abbrev: "c",
          on_target_thresholds: {
            over: -5,
            under: 5
          }
        },
        {
          long_name: "protein",
          short_name: "protein",
          abbrev: "p",
          on_target_thresholds: {
            over: -5,
            under: 5
          }
        },
        {
          long_name: "fat",
          short_name: "fat",
          abbrev: "f",
          on_target_thresholds: {
            over: -2,
            under: 2
          }
        },
        {
          long_name: "calories",
          short_name: "calories",
          abbrev: "cal",
          on_target_thresholds: {
            over: 0,
            under: 0
          }
        }
      ],
      targets_section_visible: false,
      touch_handled: false
    }
  },
  computed: {
    logged() {
      return this.$store.getters["rails/macros_logged_for_day"];
    },
    targets() {
      return this.$store.getters["rails/macro_targets_for_day"];
    },
    remaining() {
      return this.$store.getters["rails/macros_remaining_for_day"];
    },
    macros_on_target() {
      return this.macro_on_target(this.macros[0]) && this.macro_on_target(this.macros[1]) && this.macro_on_target(this.macros[2]);
    }
  },
  methods: {
    toggle_targets_section() {
      this.targets_section_visible = !this.targets_section_visible;
    },
    macro_percentage(macro) {
      if (this.macro_on_target(macro)) {
        return 100;
      } else {
        let percentage = (this.logged[macro.long_name] / this.targets[macro.long_name]) * 100;
        if (percentage > 200) { // the radial animation library expects percentage values from 0 - 100, anything over 200 exceeds the "overage" and should be shown as solid yellow
          percentage = 100;
        } else if (percentage > 100) { // percentages over 100 need to be converted to their 0 - 100 value for the yellow "overage" display
          percentage = percentage - 100;
        }
        return Math.round(percentage);
      }
    },
    calories_percentage(bar) {
      let percentage = (this.logged.calories / this.targets.calories) * 100;

      if (bar) {
        if (percentage > 100) {
          percentage = 100;
        }
        return Math.round(percentage);
      } else {
        if (percentage > 100) {
          percentage = 0;
        }
        return Math.round(percentage);
      }
    },
    macro_on_target(macro) {
      return this.remaining[macro.long_name] <= macro.on_target_thresholds.under && this.remaining[macro.long_name] >= macro.on_target_thresholds.over;
    },
    macro_over_target(macro) {
      return this.remaining[macro.long_name] < macro.on_target_thresholds.over;
    }
  }
};
</script>

<style scoped lang="scss">
  $borderWidth: 8px;
  $border-color-gray: #FAFAFA;
  $border-color-default: #00A881;
  $border-color-actual: #E7E7E7;
  $border-color-over-100: #FEEBB1;

  #scoreboard {
    display: block;
    background-color: #ffffff;
    padding: 16px;
    box-shadow: 0px 1px 2px rgba(79, 79, 79, 0.25);
    position: relative;
    cursor: pointer;
    margin: -75px 8px 4px 8px;
    border-radius: 8px;
  }

  .scoreboard-header {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 14px 0px;
    .title {
      margin: 0px 8px 0px 0px;
      p {
        color: #4f4f4f;
        font-size: 12px;
        font-weight: 600;
        margin: 0px;
        text-align: left;
      }
    }
    .dropdown {
      display: flex;
      flex-direction: row;
      margin: 0px 0px 0px auto;
      text-align: right;
      align-items: center;
      p {
        color: #6B40AB;
        font-size: 12px;
        font-weight: 600;
        margin: 0px 10px 0px 0px;
      }
      i {
        color: #6B40AB;
        font-size: 10px;
        font-weight: 600;
        margin: 0px 0px 0px auto;
      }
    }
  }

  .scoreboard-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    margin: 0px;
    position: relative;

    .calendar-container-min {
      display: flex;
      flex-direction: column;
      margin: 0px 18px 0px 0px;
      width: 140px;
      height: 66px;
      .date-row-min {
        display: flex;
        flex-direction: row;
        padding-bottom: 12px;
        .icon {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-right: 6px;
          padding-top: 2px;
          i {
            font-size: 14px;
            color: #4f4f4f;
          }
        }
        .date {
          display: flex;
          flex-direction: column;
          p {
            font-size: 12px;
            color: #4f4f4f;
            font-weight: 600;
            margin: 0px;
            text-align: left;
          }
        }
      }
      .selectors {
        display: flex;
        flex-direction: row;
        height: 100%;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          i {
            font-size: 12px;
            color: #4f4f4f;
          }
        }
      }
    }
  }

  .scoreboard-radials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #000000;
    padding: 0px;
    position: relative;
    width: 100%;
    &.on-target {
      padding-bottom: 0px;
    }
    &.collapsed {
      align-items: center;
      justify-content: flex-start;
    }
  }

  .confetti {
    background-color: rgba(255, 255, 255, 0) !important;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
  }

  .targets-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    opacity: 0;
    height: 0px;
    transition: all 0.3s;
    overflow: hidden;
    &.visible {
      opacity: 1;
      height: 152px;
      padding: 12px 0px 0px 0px;
    }
    .targets-section-header {
      color: #4f4f4f;
      font-size: 12px;
      font-weight: 600;
      margin: 0px 0px 8px 0px;
      text-align: left;
    }
    p {
      color: #000000;
      font-size: 12px;
      font-weight: 600;
      text-align: left;
      margin: 0px;
    }
    .targets-section-rows {
      display: flex;
      flex-direction: column;
      padding: 8px 0px 8px 8px;
      .targets-section-row {
        display: flex;
        flex-direction: row;
        margin: 0px 0px 12px 0px;
        align-items: center;
        .label, .value {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .label {
          margin: 0px 8px 0px 0px;
          width: 60px;
        }
        .value {
          margin: 0px 18px 0px 0px;
          width: 140px;
          text-align: center;
          .target {
            color: #4f4f4f;
            font-weight: 600;
          }
        }
      }
      .targets-section-row:last-child {
        margin: 0px;
      }
    }
    .progress-bar-container {
      position: relative;
      width: 100%;
      height: 8px;
      margin: 0px;
      background-color: $border-color-actual !important;
      .progress-bar {
        width: 0%;
        height: 100%;
        transition: width 0.5s ease;
      }
      &.over-100 {
        background-color: $border-color-gray !important;
        .progress-bar {
          background-color: $border-color-over-100 !important;
        }
      }
    }
  }
</style>
