<template>
  <LoadingScreen
    v-if="loading"
    :icon="'carrot_search'"
    :title="'On the Lookout!'"
    :description="'We\'re finding recipes that perfectly match your macros.'"
  />
  <div v-else id="matching-recipes">
    <div class="search-container">
      <div class="search-input">
        <div class="icon">
          <i class="fa fa-search" />
        </div>
        <input
          v-bind:value="search_term"
          v-on:input="search_term = $event.target.value.trim()"
          type="text"
          placeholder="Search Recipe Results"
        />
        <div class="icon clear-button" @click="search_term = ''">
          <i class="fa fa-times" />
        </div>
      </div>
      <div class="filters-button" @click="open_filters">
        <div v-if="filters_active" class="active-indicator"></div>
        <i class="fa fa-filter" />
        <p>Filters</p>
      </div>
    </div>
    <div class="recipe-results">
      <RecipeMatch
        v-for="recipe in recipes"
        :key="recipe.id"
        :recipe="recipe"
        :goto_recipe="viewRecipe"
        @quick_add="open_add_to_meal($event)"
      />

      <p v-if="recipes.length === 0" class="no-results-warning">
        We weren’t able to find any recipes that perfectly fill your macros. Try editing your macro targets selection in the dropdown above or <span @click="filtering = true">adjust your filters</span>
      </p>
    </div>
  </div>
</template>

<script>
import { filter_by_search_term } from "@js/lib/utils";

import RecipeMatch from "@js/recipes/fill_macros/recipe_match.vue";
import LoadingScreen from "@js/recipes/loading_screen.vue";

export default {
  components: { RecipeMatch, LoadingScreen },
  props: {
    meal_id: { type: String },
    macro_targets: { type: Object, required: true },
    open_filters: {type: Function, required: true},
    open_add_to_meal: {type: Function, required: true},
  },
  data() {
    return {
      search_term: localStorage.preserveRecipeSearch || ""
    }
  },
  created() {
    if (localStorage.hasOwnProperty("preserveRecipeSearch")) {
      localStorage.removeItem("preserveRecipeSearch");
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading.recipe_match;
    },
    recipes() {
      // filter recipes by name client-side until backend match algorithm is faster
      return filter_by_search_term(this.$store.state.recipe_match_results, this.search_term);
    },
    filters_active() {
      return this.$store.getters.selected_filters.length > 0;
    }
  },
  methods: {
    viewRecipe(id) {
      localStorage.preserveRecipeSearch = this.search_term;

      this.$router.push({
        path: `/recipes/${id}`,
        query: { meal_id: this.meal_id, ...this.macro_targets }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/_reset.scss";
@import "@css/_tracker_reset.scss";

#matching-recipes {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .recipe-result:last-child {
    margin-bottom: 84px;
  }
}

.search-container {
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  min-height: 72px;
  .search-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F0E8FB;
    border-radius: 8px;
    margin: 0px 12px 0px 0px;
    width: 100%;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 100%;
      i {
        color: $endurance;
        font-size: 12px;
      }
      &.clear-button {
        margin-left: auto;
      }
    }
    input {
      font-size: 16px;
      font-weight: normal;
      color: #4f4f4f;
      background-color: transparent;
      padding: 0px;
      height: 40px !important;
      line-height: 40px;
    }
    input::placeholder {
      color: #4f4f4f;
      font-size: 16px;
      font-weight: normal;
    }
    input:focus {
      outline: none !important;
    }
  }
  .filters-button {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px auto;
    i {
      color: $endurance;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      font-weight: 600;
      color: $endurance;
    }
    .active-indicator {
      position: absolute;
      top: 2px;
      right: -1px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #FFCD3C;
      margin-right: 8px;
    }
  }
}

.recipe-results {
  padding: 18px 24px 0px 24px;
  width: 100%;
  height: calc(100% - 58px);
  overflow: auto;
}

.no-results-warning {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  letter-spacing: 0.5px;
  span {
    color: $endurance;
    font-weight: 700;
  }
}
</style>
