<template>
  <div class="search-result" @mousedown="select_item">
    <div :class="'item ' + item_type">
      <div class="item-group">
        <div class="photo">
          <img v-if="photo" :src="photo">
          <i v-else class="fa fa-apple-alt placeholder" flip="horizontal" />
        </div>

        <div class="text-content">
          <div class="name-group">
            <div class="name">
              <span v-html="highlighted_name" />
            </div>
          </div>
          <div v-if="brand_name" class="brand-name">
            <span v-html="brand_name" />
          </div>
          <div v-if="show_macros" class="macro-group">
            <Macros :food="item" :show-targets="false" :initials="true" :quantity-kind="quantity_kind"/>
          </div>
        </div>

        <button
          v-if="item.is_favorite"
          class="search-fav-button"
        >
          <svg
            class="search-fav-button-img heart"
            :class="'fav-disabled'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 63.6 55.62"
          >
            <path
              class="cls-1"
              d="M32,56.52C7.64,36.68,2.7,30.64,2.7,21A14.28,14.28,0,0,1,17,6.69c6.26,0,10,3.3,13.16,6.87L32,15.69l1.88-2.13C37,10,40.78,6.69,47,6.69A14.28,14.28,0,0,1,61.3,21C61.3,30.65,56.35,36.69,32,56.52Z"
              transform="translate(-0.2 -4.19)"
            />
            <path
              class="cls-1"
              d="M47,9.19A11.78,11.78,0,0,1,58.8,21c0,8.51-4.57,14.15-26.8,32.33C9.77,35.11,5.2,29.47,5.2,21A11.78,11.78,0,0,1,17,9.19c5.53,0,8.61,3,11.29,6L32,19.48l3.75-4.27c2.68-3,5.76-6,11.29-6m0-5c-7.72,0-12.11,4.39-15,7.72-2.93-3.33-7.32-7.72-15-7.72A16.78,16.78,0,0,0,.2,21c0,11.31,6.52,18.22,30.74,37.92l1.06.93,1.06-.93C57.28,39.18,63.8,32.27,63.8,21A16.78,16.78,0,0,0,47,4.19Z"
              transform="translate(-0.2 -4.19)"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Macros from "@js/shared/macros.vue";

export default {
  components: { Macros },
  props: {
    item: { type: Object, required: true },
    select_item: { type: Function, required: true },
    show_macros: { type: Boolean, required: true },
    search_term: { type: String, required: true }
  },
  computed: {
    item_type() {
      return (this.item.__typename || "custom_food").toLowerCase();
    },
    photo() {
      if (this.item.__typename == "Food") {
        return this.item.nix_photo_url;
      } else if (this.item.__typename == "Recipe") {
        const url = this.item.photo_urls.small;
        return !url.match("placeholder") && url;
      } else {
        return null;
      }
    },
    brand_name() {
      if (this.item.brand_name) {
        let name = this.item.brand_name;
        let sanitized = this.search_term.replace(/\W/g, "");
        let pattern = new RegExp(_.escapeRegExp(sanitized), "i");
        let match = name.match(pattern, this.search_term);
        if (sanitized && match) {
          return name.replace(pattern, `<b>${match[0]}</b>`);
        } else {
          return name;
        }
      } else if (this.item.is_raw) {
        // TODO is is_raw attr now present w/ new data model?
        return "raw"
      } else {
        return null;
      }
    },
    quantity_kind() {
      // note: custom template didn't show macros so this wasn't used (and still isn't), but might change in future?
      if (this.item.is_custom) { return "common"; }

      let defaultConversion = _.find(this.item.conversions, {default: true});
      let gramConversion = _.find(this.item.conversions, {name: "gram"});
      let isGramDefault = defaultConversion ? defaultConversion.name === "gram" : false;

      if (!gramConversion) {
        return "common";
      } else if (!isGramDefault && (!this.item.divisible || this.item.miscible)) {
        return "common";
        // return "both";
      } else {
        return "gram";
      }
    },
    highlighted_name() {
      let name = this.item.name;
      let sanitized = this.search_term.replace(/\W/g, "");
      let pattern = new RegExp(_.escapeRegExp(sanitized), "i");
      let match = name.match(pattern, this.search_term);
      if (sanitized && match) {
        return name.replace(pattern, `<b>${match[0]}</b>`);
      } else {
        return name;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .search-result {
    z-index: 10;
    background-color: #fff;
    border-bottom: solid 1px $border-gray;
    cursor: pointer;

    &:last-child{
      border-bottom: none;
    }

    &:hover{
      background-color: $background-lightest-gray;
    }

    ::v-deep .item {
      display: flex;
      flex-direction: column;

      min-height: 50px;
      padding: 12px;

      .item-group {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        .photo {
          min-width: 30px;
          width: 30px;
          min-height: 30px;
          height: 30px;
          margin-right: 12px;
          overflow: hidden;
          border-radius: 4px;
          .placeholder {
            width: 100%;
            height: 100%;
            padding: 5px 0;
            text-align: center;
            path {
              fill: $weak-gray;
            }
          }
          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }
        }

        .text-content {
          max-width: 100%;
          width: 300px;
          overflow: hidden;

          .name-group {
            display: flex;
            flex: 1;
            justify-content: space-between;

            > .name {
              font-size: 14px;
              font-weight: normal;
              margin-bottom: 0px;
              color: #000;
              max-height: 40px;
              overflow: hidden;
              position: relative;
              width: 100%;
              text-overflow: ellipsis;

              & .highlight {
                background-color: transparentize($happy, 0.6);
                padding: 1px;
                border: 1px solid $sturdy;
                margin: 0px -1px;
              }
            }
          }
          .brand-name {
            font-size: 12px;
            text-align: left;
            color: #4f4f4f;
            margin-left: auto;
            margin-bottom: 2px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: 80px;
            font-weight: normal;
          }
        }
      }

      .macros {
        width: 100%;
        overflow: hidden;
        flex-wrap: nowrap;
        .macros-container {
          margin-right: 4px;
          flex-wrap: nowrap;
        }
        .amount {
          min-width: 0px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-left: auto;
        }
      }

      .macro-group {
        display: flex;
        font-size: 12px;
        color: #000;
        font-weight: normal;
      }

      button.search-fav-button {
        // disable default button styles
        -webkit-appearance: none;
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;

        &:focus {
          outline: none;
        }

        width: 50px;
        display: flex;

        .search-fav-button-img {
          margin: 0px auto;
          width: 20px;
          min-width: 20px;
          fill: $confident;
        }

        .fav-disabled {
          fill: transparentize($confident, 0.8) !important;
        }
      }
    }
  }
</style>
