<template lang="html">
  <div id="food-log">
    <DietaryPreferencesReminder v-if="show_dietary_prefs_reminder" />
    <div class="food-log-header" :class="{ short: expanded_meal_card }">
      <DateNav v-if="!expanded_meal_card" />
    </div>
    <Scoreboard :collapsed="!!expanded_meal_card" />
    <div class="meal-cards" :class="{ mtc: mtc_challenge }">
      <DayDetailsBar v-if="!expanded_meal_card" />
      <MealCard
        v-for="meal_id in meal_ids"
        :key="meal_id"
        :meal_id="meal_id"
        :expanded="meal_id === expanded_meal_card"
      />
    </div>
    <DeleteModal v-if="portions_to_delete" :multiple="portions_to_delete.length > 1" />
    <UpgradeMonthlyModal v-if="show_upgrade" />
    <MTCButton v-if="mtc_challenge && fab_state != 'menu'" :challenge_data="mtc_challenge" />
    <Fab :fab_state="fab_state" />
  </div>
</template>

<script>
import DateNav from '@js/food_log/date_nav.vue';
import Calendar from '@js/food_log/calendar.vue';
import Scoreboard from '@js/food_log/scoreboard.vue';
import DayDetailsBar from '@js/food_log/day_details_bar.vue';
import MealCard from '@js/food_log/meal_card.vue';
import Fab from '@js/food_log/fab/fab.vue';
import MTCButton from '@js/food_log/mtc/mtc_button.vue';
import DeleteModal from "@js/food_log/delete_modal.vue";
import UpgradeMonthlyModal from "@js/food_log/upgrade_monthly_modal.vue";
import DietaryPreferencesReminder from "@js/app/modals/dietary_preferences_reminder.vue";

export default {
  components: {
    DateNav,
    Calendar,
    Scoreboard,
    DayDetailsBar,
    MealCard,
    Fab,
    MTCButton,
    DietaryPreferencesReminder,
    DeleteModal,
    UpgradeMonthlyModal,
  },
  props: {
    expanded_meal_card: { type: String, required: false },
    fab_state: { type: String, required: false }
  },
  data() {
    return {
      meal_ids: [1, 2, 3, 4, 5].map(i => `meal-${i}`)
    };
  },
  computed: {
    mtc_challenge() {
      return this.$store.state.rails.mtc_challenge;
    },
    show_dietary_prefs_reminder() {
      return this.$store.state.rails.user.preferences.kitchen_prefs_reminder;
    },
    portions_to_delete() {
      const to_delete = this.$store.state.rails.portions_to_delete;
      return to_delete && to_delete.portions;
    },
    show_upgrade() {
      return this.$store.state.rails.show_upgrade_modal;
    }
  }
};
</script>

<style scoped lang="scss">
#food-log {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #f9f9f9;
  height: 100%;

  .food-log-header {
    background: #00A881;
    text-align: center;
    min-height: 150px;
    height: 150px;
    max-height: 150px;
    box-shadow: none !important;
    transition: all 0.4s;
    cursor: pointer;

    &.short {
      min-height: 90px;
      height: 90px;
      max-height: 90px;
    }

    p {
      font-weight: 600;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .meal-cards {
    margin-top: 4px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    // padding-bottom of 66 replicates pre-rewrite styling (though I question if this is actually optimal...)
    // padding: 0 8px 66px 8px;
    padding: 0 8px;
    width: 100%;
    z-index: 4;
    margin-bottom: 66px;
  }
  .meal-cards::after {
    flex: 0 0 64px;
    content: '';
  }
  .meal-cards.mtc::after {
    flex: 0 0 120px;
  }
}
</style>
