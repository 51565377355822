<template lang="html">
  <div class="profile-container">
    <div class="nav">
      <div class="back-button" @click="$router.back()">
        <i class="fa fa-chevron-left"></i>
        <p>Summary</p>
      </div>
      <p class="title">Activity</p>
    </div>

    <div class="content">
      <p class="page-header">Please select your activity level below</p>
      <p class="page-info">
        The activity level you select will help us recommend the best macro numbers to help you meet your goals
      </p>
      <div class="radios">
        <div
          v-for="{ title, description, value, icon } in activity_levels"
          class="radio"
          :class="{ active: user_activity_level === value }"
          @click="update(value, title)"
        >
          <div class="indicator">
            <div class="outer">
              <div class="inner"></div>
            </div>
          </div>
          <div class="info">
            <p class="title">
              <img :src="icon" />
              {{ title }}
            </p>
            <p class="description">{{ description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import activity_levels from "@js/profile/activity_levels";
import updateIntercom from "@js/lib/intercom";

export default {
  data() {
    return { activity_levels };
  },
  computed: {
    user_activity_level() {
      return this.$store.state.rails.statistic_set.activity_level;
    }
  },
  methods: {
    async update(activity_level, title) {
      try {
        this.$store.dispatch("loading", true);

        const statistic_set = await this.$store.dispatch("rails/update_user_statistic_set", { activity_level });
        updateIntercom({ activity_level_updated_at: moment().unix(), activity_level: title });

        this.$store.dispatch("notify_user", { type: "info", msg: "Profile successfully updated :)" });
      } catch (e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to update profile." });
        console.error(e);
      } finally {
        this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/components/profile";

.content {
  padding-top: 30px;
}

.radios {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  .radio {
    margin-bottom: 24px;
    margin-right: 16px;
    align-items: flex-start;

    .info .description {
      margin: 0;
    }

    .indicator {
      margin-top: 8px;
    }
  }
}
</style>
