<template>
  <LoadingScreen
    v-if="loading"
    :icon="'carrot_search'"
    :title="'On the Lookout!'"
    :description="'We’re finding foods that perfectly match your macros.'"
  />
  <div v-else id="matching-foods">
    <div class="shuffle-foods-container">
       <div class="shuffle-foods-button" v-on:click.stop="build_foods">
      <div class="icon">
        <i class="fa fa-random" />
      </div>
      <p>{{foods.length === 0 ? 'Try Again' : 'Shuffle Unselected Foods'}}</p>
       </div>
      <p
        v-on:click.stop="toggle_keep_foods"
        v-if="foods.length > 0"
        class="select-all-button"
      >
        {{  this.foods_keep.length === foods.length ? "Unselect All" : "Select All" }}
      </p>
    </div>
    <div class="food-results">
      <div
        v-for="food in foods_with_calculated_macros"
        v-bind:key="food.id"
        class="food-result"
        :style="{ 'border-left-color': foods_keep.indexOf(food.ingredient_id) === -1 ? '#C4C4C4' : foods_new_results.indexOf(food.ingredient_id) !== -1 ? '#00A881' : 'rgba(0, 168, 129, 0.4)' }"
        >
        <div class="info">
          <p class="food-name">{{food.name}}</p>
          <div class="macros-container">
            <div class="macro">
              <div class="name">C</div>
              <div class="actual">{{ food.carbohydrates }}g</div>
            </div>
            <div class="macro">
              <div class="name">P</div>
              <div class="actual">{{ food.protein }}g</div>
            </div>
            <div class="macro">
              <div class="name">F</div>
              <div class="actual">{{ food.fat }}g</div>
            </div>
            <div class="macro">
              <div class="name">Cals</div>
              <div class="actual">{{ food.calories }}</div>
            </div>
          </div>
        </div>
        <div class="checkbox-container" @click="toggle_keep(food.ingredient_id)">
          <div :class="{ box: true, selected: foods_keep.indexOf(food.ingredient_id) !== -1 }">
            <i class="fa fa-check" />
          </div>
        </div>
      </div>
      <p v-if="foods.length === 0" class="no-results-warning">
        We weren’t able to find any foods that fill your macros.
      </p>
      <p v-if="foods.length > 0" class="radials-row-label">
        Macros remaining
        <span>
          for <span class="bold">{{selected_profile_name}}</span>
        </span>
      </p>
      <div v-if="foods.length > 0" class="radials-row">
        <Radial
          v-for="radial in radials"
          :key="radial.name"
          :short_name="radial.name"
          :label="radial.label"
          :target="macro_targets[radial.name]"
          :actual="parseFloat(foods_total[radial.name])"
          :remaining="macro_remaining(radial.name)"
          :percentage="radial.name === 'calories' ? calories_percentage() : macro_percentage(radial)"
          :on_target="macros_reached_target(radial)"
          :over_target="macros_over_target(radial)"
          />
        <div v-if="macros_filled" class="macros-filled-text">
          <p>
            Filled!
          </p>
        </div>
      </div>
      <div v-if="foods.length > 0" class="button-row">
        <div 
          :class="`add-to-food-log-button ${foods_keep.length === 0 ? 'disable' : ''}`" 
          @click="save_foods"
        >
          Add to Food Log
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@js/api/rails_client";
import { calculate_calories, round_macros } from "@js/lib/utils";

import Radial from "@js/recipes/radial.vue";
import LoadingScreen from "@js/recipes/loading_screen.vue";

export default {
  components: {LoadingScreen, Radial},
  props: {
    selected_profile_name: {type: String, required: true},
    macro_targets: {type: Object, required: false},
    meal_id: {type: String, required: true},
    add_to_meal: {type: Function, required: true},
  },
  data() {
    return {
      build_foods_running: false,
      foods: [],
      foods_keep: [],
      foods_new_results: [],
      foods_with_calculated_macros: [],
      radials: [
        {
          name: "carb",
          label: "C",
          on_target_thresholds: {
            over: -5,
            under: 5
          }
        },
        {
          name: "protein",
          label: "P",
          on_target_thresholds: {
            over: -5,
            under: 5
          }
        },
        {
          name: "fat",
          label: "F",
          on_target_thresholds: {
            over: -2,
            under: 2
          }
        }
      ]
    }
  },
  computed: {
    loading() {
      return this.build_foods_running;
    },
    foods_total() {
      return {
        carb: this.total_carb,
        protein: this.total_protein,
        fat: this.total_fat,
        calories: this.total_calories
      };
    },
    total_carb() {
      return this.foods.reduce((total, food) => {
        if (this.foods_keep.indexOf(food.ingredient_id) !== -1) {
          return total + Math.round(food.carbohydrates);
        } else {
          return total;
        }
      }, 0);
    },
    total_protein() {
      return this.foods.reduce((total, food) => {
        if (this.foods_keep.indexOf(food.ingredient_id) !== -1) {
          return total + Math.round(food.protein);
        } else {
          return total;
        }
      }, 0);
    },
    total_fat() {
      return this.foods.reduce((total, food) => {
        if (this.foods_keep.indexOf(food.ingredient_id) !== -1) {
          return total + Math.round(food.fat);
        } else {
          return total;
        }
      }, 0);
    },
    total_calories() {
      return this.foods.reduce((total, food) => {
        if (this.foods_keep.indexOf(food.ingredient_id) !== -1) {
          return total + Math.round(food.calories);
        } else {
          return total;
        }
      }, 0);
    },
    macros_filled() {
      return this.macros_reached_target(this.radials[0]) && this.macros_reached_target(this.radials[1]) && this.macros_reached_target(this.radials[2]);
    }
  },
  methods: {
     // Bulk Checkbox Toggle
    toggle_keep_foods() {
      //Empty Food Keep Array -> Select All
      if (this.foods_keep.length === 0) {
        this.foods_keep = [];
        this.foods.forEach(({ ingredient_id }) =>
        this.toggle_keep(ingredient_id)
        );

      // When at least one box is un-checked -> "Select all"
      } else if (this.foods.length > this.foods_keep.length || this.foods_keep.length < this.foods_new_results.length ) {
        this.foods_keep = [];
        this.foods.forEach(({ ingredient_id }) =>
        this.toggle_keep(ingredient_id)
        );

      // When food results are initially shuffled -> Unselect All
      } else if (this.foods_keep.length > this.foods_new_results.length) {
        this.foods_keep = [];
      
      } else {
        this.foods_keep = [];
      }
    },
    // radials
    macro_percentage(macro) {
      if (this.macros_reached_target(macro)) {
        return 100;
      } else {
        let percentage = (this.foods_total[macro.name] / this.macro_targets[macro.name]) * 100;
        if (percentage > 200) { // the radial animation library expects percentage values from 0 - 100, anything over 200 exceeds the "overage" and should be shown as solid yellow
          percentage = 100;
        } else if (percentage > 100) { // percentages over 100 need to be converted to their 0 - 100 value for the yellow "overage" display
          percentage = percentage - 100;
        }
        return Math.round(percentage);
      }
    },
    calories_percentage(bar) {
      let percentage = (this.foods_total.calories / this.macro_targets.calories) * 100;

      if (percentage > 100) {
        percentage = 0;
      }
      return Math.round(100 - percentage);
    },
    macros_reached_target(macro) {
      return this.macro_remaining(macro.name) <= macro.on_target_thresholds.under && this.macro_remaining(macro.name) >= macro.on_target_thresholds.over;
    },
    macros_over_target(macro) {
      return this.macro_remaining(macro.name) < macro.on_target_thresholds.over;
    },
    macro_remaining(name) {
      return Math.round(this.macro_targets[name] - this.foods_total[name]);
    },
    async build_foods(keep_foods = true, targets = this.macro_targets) {
      this.build_foods_running = true;

      let mandatory_portions = keep_foods
          ? this.foods.filter(food => this.foods_keep.indexOf(food.ingredient_id) !== -1)
          : [];

      const remaining_macros = keep_foods 
          ? {
              carb: Math.max(0, Math.round(this.macro_targets.carb - this.total_carb)),
              protein: Math.max(0, Math.round(this.macro_targets.protein - this.total_protein)),
              fat: Math.max(0, Math.round(this.macro_targets.fat - this.total_fat))
            }
          : targets;

      try {
        const { data: { foods } } = await API.generate_matching_foods(
          remaining_macros,
          mandatory_portions,
          this.$store.state.rails.selected_date,
          this.meal_id
        );

       this.foods = [...mandatory_portions, ...foods];
       this.foods_new_results = foods.map(food => food.ingredient_id);
       this.foods_keep = [...this.foods_keep, ...this.foods_new_results];
       this.foods_with_calculated_macros = this.foods.map(f => {
         return {
          ...f,
          ...round_macros({
            carbohydrates: f.carbohydrates,
            protein: f.protein,
            fat: f.fat,
          }, 0.5),
          calories: Math.round(calculate_calories(f))
         }
       });
      } catch(e) {
       console.error('RUBY API CALL TO GENERATE MATCHING FOODS FAILED');
      } finally {
        this.build_foods_running = false;
      }
    },
    toggle_keep(foodId) {
      let index = this.foods_keep.indexOf(foodId);

      if (index !== -1) {
        this.foods_keep.splice(index, 1);
      } else {
        this.foods_keep.push(foodId);
      }
    },
    save_foods() {
      if (this.foods_keep.length === 0 ){
       return;
      }
      const foods = this.foods.filter(r => this.foods_keep.indexOf(r.ingredient_id) !== -1);
      this.add_to_meal(foods, this.meal_id);
    }
  }
}
</script>

<style scoped lang="scss">
#matching-foods {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin-bottom: 70px;
}

.shuffle-foods-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px 16px 24px;
  
 .shuffle-foods-button {
    display: flex;
    align-items: center;
    border-color: transparent;
    color: $endurance;

    cursor: pointer;
    .icon {
      display: inline-flex;
      align-items: center;
      width: 24px;
      height: 100%;
      i {
        color: $endurance;
        font-size: 12px;
      }
    }
    p {
      font-size: 12px;
      font-weight: 600;
      color: $endurance;
      margin: 0px;
    }
  }

  .select-all-button {
    align-items: center;
    border-color: transparent;
    color: $endurance;
    font-size: 12px;
    font-weight: 600;
    color: $endurance;
    margin: 0px;
    cursor: pointer;
  }
}

.food-results {
  padding: 18px 24px;
  width: 100%;
  height: 100%;
  overflow: auto;
  .food-result {
    display: flex;
    flex-direction: row;
    border-left: 8px solid #C4C4C4;
    padding: 0px 0px 0px 12px;
    margin: 0px 0px 12px 0px;
    .info {
      display: flex;
      flex-direction: column;
      margin: 0px 14px 0px 0px;
      .food-name {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin: 0px 0px 4px 0px;
      }
      .macros-container {
        display: flex;
        flex-direction: row;
        margin: 0px 0px 4px 0px;
        .macro {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          padding-right: 0.3rem;
          .name, .actual {
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 600;
            color: #4f4f4f !important;
          }
          .name {
            margin-left: 0.1rem;
            padding-right: 0.2rem;
          }
          .actual {
            padding-right: 0px;
            margin-right: 0px;
          }
        }
      }
    }
    .checkbox-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0px 0px 0px auto;
      width: 40px;
      height: 40px;
      cursor: pointer;
      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border: 1px solid $endurance;
        border-radius: 2px;
        width: 14px;
        height: 14px;
        &.selected {
          background-color: $endurance;
          i {
            display: block;
          }
        }
        i {
          display: none;
          font-size: 10px;
          color: #ffffff;
        }
      }
    }
  }
}

.radials-row-label {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  margin: 24px 0px 0px 0px;
  .bold {
    font-weight: bold;
  }
}

.radials-row {
  display: flex;
  flex-direction: row;
  padding: 12px 0px;
  align-items: center;
  justify-content: flex-start;
  .radial-container {
    p {
      font-size: 12px;
      font-weight: 700;
      color: #000000;
    }
    .radial {
      p {
        font-size: 12px;
        font-weight: 700;
        color: #000000;
      }
    }
  }
  .macros-filled-text {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
      font-size: 12px;
      color: #37A048;
      font-weight: 600;
      margin: 0px 0px 0px 0px;
      i {
        margin-right: 4px;
      }
    }
  }
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  .add-to-food-log-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    background-color: $endurance;
    height: 40px;
    padding: 0px 24px;
    border-radius: 22px;
    cursor: pointer;
    &.disable {
        cursor: not-allowed;
        background: #E6E6E6;
        color: #959595;
    }
  }
}

.no-results-warning {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 21px;
  letter-spacing: 0.5px;
  span {
    color: $endurance;
    font-weight: 700;
  }
}
</style>
