<template lang="html">
  <!-- conditionally render so if this route is loaded directly, computed props won't break
   before data has been fetched and loaded into store -->
  <div v-if="mtc_challenge" class="container">
    <div class="enrolled">
      <div @click="$router.push('/food_log')">
        <img src="https://icons.sfo2.cdn.digitaloceanspaces.com/mtc-back.svg" class="mtc-back-button" alt="Back" />
      </div>

      <Header />

      <Countdown />

      <div class="signed-up-label">
        <p>YOU'RE ALL SIGNED UP!</p>
      </div>

      <div class="info-text">
        <p>Get familiar with the app and how to hit your macros so you can get a headstart on the challenge! Feel free to reach out to a coach through StaxChat to have them walk you through anything!</p>
      </div>

      <div class="mtc-faq">
        <a href="https://www.macrostax.com/mtc">Challenge FAQ</a>
      </div>

      <div class="info-text">
        <p>Setting goals is always a great way to set yourself up for success. What do you want to achieve during this challenge?</p>
      </div>

      <GoalsForm
        :submit_action="update_goals"
        submit_text="Update Goals"
      />

      <a href="https://www.facebook.com/groups/staxformation" class="social-media-container">
        <div class="icon">
          <img src="https://icons.sfo2.cdn.digitaloceanspaces.com/fb-logo.png">
        </div>
        <div class="label">
          Join the challenge group on Facebook!
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import Countdown from '@js/food_log/mtc/shared/countdown.vue';
import Header from '@js/food_log/mtc/shared/header.vue';
import GoalsForm from '@js/food_log/mtc/shared/goals_form.vue';

export default {
  components: { Countdown, Header, GoalsForm },
  computed: {
    mtc_challenge() {
      return this.$store.state.rails.mtc_challenge;
    }
  },
  methods: {
    async update_goals(goals) {
      try {
        this.$store.dispatch("loading", true);

        await this.$store.dispatch("rails/update_user_mtc_goals", goals);

        this.$store.dispatch("notify_user", { type: "info", msg: "Your goals have been updated!" });
      } catch (e) {
        this.$store.dispatch("notify_user", {
          type: "error",
          msg: "Whoops! Something went wrong updating your goals."
        });
        console.error(e);
      } finally {
        this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  background: white;
  overflow: scroll!important;

  .enrolled {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    // until we get fabs into parent vue app
    // iphone was showing fabs above mtc pages
    z-index: 20;
    position: relative;

    .back {
      margin-top: 25px;
      width: 25%;
    }

    .info-text {

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-top: 24px;
        letter-spacing: 0.5px;
        color: #4F4F4F;
      }
    }

    .mtc-faq {
      margin-top: 10px;
      margin-bottom:45px;

      a {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #6B40AB;
      }
    }

    .signed-up-label {
      display: flex;

      p {
        background: linear-gradient(0.50turn, white 70%, #34B6E4 50% 100%);
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 0px;
        margin-top: 24px;
        color: #000000;
        padding-right: 10px;
      }
    }

    .social-media-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;

      .icon {
        margin-right: 10px;

        img {
          height: 24px;
          width: 24px;
        }
      }

      .label {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #4F4F4F;
      }
    }
  }
}
</style>
