<template>
  <div id="recipe-builder">
    <div class="nav-bar">
      <div class="left-button" @click="exit_prompt()">
        <i class="fa fa-chevron-left fa-lg" />
        Back
      </div>
      <div class="title">
        Recipe Builder
      </div>
    </div>

    <div v-if="!is_loading" class="scrollable-content">
      <div class="chart-row">
        <MacrosChart :macros="total_macros" />

        <div class="legend">
          <div class="legend-row">
            <p>Total Calories</p>
            <p class="value">{{ total_macros.calories }}</p>
          </div>
          <div class="legend-row">
            <div class="legend-dot carbs"></div>
            <p>Carbs</p>
            <p class="value">{{ total_macros.carb_grams }}g</p>
          </div>
          <div class="legend-row">
            <div class="legend-dot protein"></div>
            <p>Protein</p>
            <p class="value">{{ total_macros.protein_grams }}g</p>
          </div>
          <div class="legend-row">
            <div class="legend-dot fat"></div>
            <p>Fat</p>
            <p class="value">{{ total_macros.fat_grams }}g</p>
          </div>
        </div>
      </div>

      <RecipeDetails
        :recipe="recipe"
        :expanded="open_section === 'recipe_details'"
        :is_valid="details_valid"
        :errors="errors"
        :toggle_section="() => toggle_section('recipe_details')"
        :coming_soon_prompt="coming_soon_prompt"
      />

      <Ingredients
        :ingredients="ingredients"
        :add_ingredient="add_ingredient"
        :remove_ingredient="remove_ingredient"
        :expanded="open_section === 'ingredients'"
        :is_valid="ingredients_valid"
        :on_open_barcode_scanner="on_open_barcode_scanner"
        :toggle_section="() => toggle_section('ingredients')"
      />

      <ServingSize
        :serving="serving"
        :macros="serving_macros"
        :expanded="open_section === 'serving_size'"
        :is_valid="serving_valid"
        :toggle_section="() => toggle_section('serving_size')"
        :mode="mode"
      />

      <div class="button-row">
        <button
          class="save-button"
          :disabled="!recipe_valid"
          @click="save_recipe"
        >
          {{ this.mode === "edit" ? "Update" : "Create" }} Recipe
        </button>
      </div>
    </div>

    <div v-if="modal_visible" class="modal-backdrop">
      <div class="modal-body">
        <span v-if="modal_icon" class="modal-icon">
          <img :src="exclamation_icon" />
        </span>
        <span v-if="modal_header" class="modal-header">{{ modal_header }}</span>
        <span v-if="modal_text" class="modal-text">{{ modal_text }}</span>
        <div class="buttons">
          <span v-if="modal_action" @click="modal_action">EXIT WITHOUT SAVING</span>
          <span @click="cancel">{{ modal_cancel }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { omit, reduce, pick } from "lodash";
import gql from "graphql-tag";

import { multiply_macros, add_macros, round_macros } from "@js/lib/utils";

import RecipeDetails from "@js/custom/recipe_builder/recipe_details.vue";
import Ingredients from "@js/custom/recipe_builder/ingredients.vue";
import ServingSize from "@js/custom/recipe_builder/serving_size.vue";
import MacrosChart from "@js/recipes/macros_chart.vue";

import recipe_details_query from "@js/api/graphql/recipe_details_query";
import recipe_fields from "@js/api/graphql/recipe_fields";
import conversion_fields from "@js/api/graphql/conversion_fields";

import exclamation_icon from "@images/exclamation-gray.svg";

function populate_serving_data(recipe) {
  if (recipe.user_id) {
    return {
      serving_name: recipe.conversion.name,
      quantity: recipe.quantity,
      bulk_make_qty: recipe.bulk_make_qty
    };
  } else if (recipe.is_miscible) { // Note: this might also be discrete
    return {
      serving_name: recipe.custom_serving_name || "serving",
      quantity: 1,
      bulk_make_qty: 1
    };
  } else if (recipe.is_discrete) {
    return {
      serving_name: recipe.custom_serving_name || "serving",
      quantity: Math.round(recipe.eat_quantity),
      bulk_make_qty: Math.round(recipe.make_quantity)
    };
  } else {
    let cook_loss_factor;
    if (recipe.cooked_weight_grams) {
      cook_loss_factor = recipe.cooked_weight_grams / (recipe.conversion.weight_grams * recipe.bulk_make_qty);
    } else {
      cook_loss_factor = 1;
    }

    return {
      serving_name: "gram",
      quantity: Math.round(recipe.eat_quantity * recipe.conversion.weight_grams * cook_loss_factor),
      bulk_make_qty: Math.round(recipe.make_quantity * recipe.conversion.weight_grams * cook_loss_factor)
    };
  }
}

export default {
  components: { MacrosChart, RecipeDetails, Ingredients, ServingSize },
  props: {
    mode: { type: String, required: true, validator: mode => ["new", "edit", "customize"].includes(mode) },
    recipe_id: { type: Number }
  },
  data() {
    return {
      is_loading: true,
      recipe: { name: null, intro_line: null, prep: null },
      ingredients: [],
      serving: { serving_name: null, quantity: null, bulk_make_qty: null },
      errors: {},
      open_section: "ingredients",
      modal_visible: false,
      modal_icon: false,
      modal_header: "",
      modal_text: "",
      modal_action: null,
      modal_cancel: "CANCEL",
      exclamation_icon
    };
  },
  async created() {
    if (this.$store.state.recipe_builder_form_state) {
      const { recipe, ingredients, serving } = this.$store.state.recipe_builder_form_state;

      this.recipe = recipe;
      this.ingredients = ingredients;
      this.serving = serving;

      this.$store.dispatch("save_recipe_builder_form_state", null);
    } else if (this.recipe_id) {
      try {
        const macro_targets = this.$store.getters["rails/default_portion_macro_targets"];

        const { data: { recipe_details: recipe } } = await this.$apollo.query({
          query: recipe_details_query,
          fetchPolicy: "no-cache",
          variables: { id: this.recipe_id, macro_targets }
        });

        const name = this.mode === "customize" ? `Custom ${recipe.name}` : recipe.name;
        this.recipe = { name, ...pick(recipe, ["intro_line", "prep", "notes"]) };
        if (this.recipe.notes) { // append recipe notes to end of prep section
          this.recipe.prep = this.recipe.prep ? `${this.recipe.prep}\n\n**Notes**\n\n${this.recipe.notes}` : `${this.recipe.notes}`;
        }
        this.recipe = omit(this.recipe, ["notes"]);
        this.ingredients = recipe.foods.map(food => ({ ...food, conversion_id: food.conversion.id }));
        this.serving = populate_serving_data(recipe);
      } catch(e) {
        console.error(e);
        this.$router.back();
      }
    }
    this.is_loading = false;
  },
  computed: {
    recipe_foods() {
      return this.ingredients.map(food => ({
        food_id: food.id,
        quantity: food.quantity,
        conversion_id: food.conversion_id
      }));
    },
    total_macros() {
      const zero_macros = { carb_grams: 0, protein_grams: 0, fat_grams: 0, calories: 0 };

      const macro_sum = this.ingredients.reduce((total_macros, food) => { //
        const selected_conversion = food.conversions.find(c => c.id === food.conversion_id);
        const food_macros = food.quantity ? multiply_macros(selected_conversion, food.quantity) : zero_macros;

        return add_macros(food_macros, total_macros);
      }, zero_macros);

      return round_macros(macro_sum, 0.5);
    },
    serving_macros() {
      const scale_factor = (this.serving.quantity || 1) / (this.serving.bulk_make_qty || 1);

      return round_macros(multiply_macros(this.total_macros, scale_factor), 0.5);
    },
    details_valid() {
      // 10/20/20 - we are allowing empty details section
      return true;
    },
    ingredients_valid() {
      // TODO validate server-side
      return this.recipe_foods.length > 0 && this.recipe_foods.reduce((bool, { quantity }) => bool && !!quantity, true);
    },
    serving_valid() {
      // 10/20/20 - we are allowing empty values

      let quantity = this.serving.quantity || 1;
      let bulk_make_qty = this.serving.bulk_make_qty || 1;

      return quantity <= bulk_make_qty;
    },
    recipe_valid() {
      return this.details_valid && this.ingredients_valid && this.serving_valid;
    }
  },
  watch: {
    ['recipe.name'](new_val, old_val) {
      if (new_val) {
        this.errors = omit(this.errors, ["name"]);
      }
    }
  },
  methods: {
    toggle_section(section) {
      this.open_section = section === this.open_section ? null : section;
    },
    is_editing(index) {
      return this.editing_ingredient === index;
    },
    on_open_barcode_scanner() {
      const form_state = { recipe: this.recipe, ingredients: this.ingredients, serving: this.serving };
      this.$store.dispatch("save_recipe_builder_form_state", form_state);
    },
    add_ingredient(food) {
      if (this.ingredients.find(ingredient => ingredient.id === food.id)) {
        this.$store.dispatch("notify_user", { type: "info", msg: `You"ve already added ${food.name} to this recipe!` });
      } else {
        const ingredient = { ...food, quantity: food.conversion.default_serving_qty, conversion_id: food.conversion.id };
        this.ingredients.unshift(ingredient);
      }
    },
    remove_ingredient(food_id) {
      this.ingredients = this.ingredients.filter(food => food.id !== food_id);
    },
    update_serving(attr, val) {
      this.serving[attr] = val;
    },
    save_recipe() {
      if (this.mode === "edit") {
        this.update_recipe();
      } else {
        this.create_recipe();
      }
    },
    async create_recipe() {
      try {
        this.$store.dispatch("loading", true);

        if (!this.recipe.name) {
          this.recipe.name = "My custom recipe";
        }

        if (!this.serving.quantity) {
          this.serving.quantity = 1;
        }

        if (!this.serving.serving_name) {
          this.serving.serving_name = "serving";
        }

        if (!this.serving.bulk_make_qty) {
          this.serving.bulk_make_qty = 1;
        }

        const recipe = { ...this.recipe, ...this.serving, recipe_foods: this.recipe_foods };

        const { result, successful, messages } = await this.$store.dispatch("create_custom_recipe", recipe);

        if (successful) {
          if (this.mode === "customize") {
            this.$router.replace(`/recipes/${result.id}`);
          } else {
            this.$router.back();
          }
          this.$store.dispatch("notify_user", { type: "info", msg: "Custom recipe was created!" });
        } else {
          // close section so that only sections with validation errors are opened
          this.open_section = null;
          this.errors = reduce(
            messages,
            (acc, { field, message }) => ({ ...acc, [field]: message }),
            {}
          );
        }
      } catch (e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to create custom recipe" });
        console.error(e);
      } finally {
        this.$store.dispatch("loading", false);
      }
    },
    async update_recipe() {
      try {
        this.$store.dispatch("loading", true);

        const params = {
          recipe_id: this.recipe_id,
          recipe: { ...this.recipe, ...this.serving, recipe_foods: this.recipe_foods }
        };
        const { successful, messages } = await this.$store.dispatch("update_custom_recipe", params);

        if (successful) {
          this.$router.back();
          this.$store.dispatch("notify_user", { type: "info", msg: "Custom recipe was updated!" });
        } else {
          // close section so that only sections with validation errors are opened
          this.open_section = null;
          this.errors = reduce(
            messages,
            (acc, { field, message }) => ({ ...acc, [field]: message }),
            {}
          );
        }
      } catch (e) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to update custom recipe" });
        console.error(e);
      } finally {
        this.$store.dispatch("loading", false);
      }
    },
    cancel() {
      this.modal_visible = false;
    },
    exit_prompt() {
      this.modal_icon = true;
      this.modal_header = false;
      this.modal_text = "Are you sure you want to exit? All unsaved changes made to this recipe will be lost.";
      this.modal_action = () => { this.$router.back(); };
      this.modal_visible = true;
      this.modal_cancel = "CANCEL";
    },
    coming_soon_prompt() {
      this.modal_icon = false;
      this.modal_header = "Coming Soon";
      this.modal_text = "We are working hard on a photo uploader so you can use your own photos with your recipes!";
      this.modal_action = null;
      this.modal_visible = true;
      this.modal_cancel = "CLOSE";

      //fire off GA event
      if (typeof gtag !== 'undefined') {
        gtag('event', 'click', {
          'event_category': 'Custom Recipe Builder',
          'event_label': 'Upload Photo',
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "@css/_reset.scss";
  @import "@css/_tracker_reset.scss";

  #recipe-builder {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;

    .nav-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 58px;
      min-height: 58px;
      background-color: #ffffff;
      &.favorites {
        background-color: #00A881;
        .left-button {
          top: 0px;
          left: 0px;
          i {
            color: #ffffff;
            margin: 0px;
          }
        }
        .title {
          color: #ffffff;
        }
      }

      .left-button, .right-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 58px;
        height: 58px;
        cursor: pointer;
        color: $endurance;
        font-size: 12px;
      }
      .left-button {
        top: 0px;
        left: 12px;
        i {
          margin-right: 8px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
    }

    .scrollable-content {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 8px 24px;
      margin-bottom: 70px;
    }

    .no-ingredients-message {
      font-size: 14px;
      line-height: 21px;
    }

    .search-container {
      display: flex;
      flex-direction: row;
      padding: 0px;
      min-height: 40px;
      margin-bottom: 12px;
      .search-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #FAFAFA;
        border-radius: 8px;
        margin: 0px;
        width: 100%;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          min-width: 30px;
          height: 100%;
          cursor: pointer;
          i {
            color: #4f4f4f;
            font-size: 12px;
          }
          &.clear-button {
            margin-left: auto;
          }
        }
        input {
          font-size: 16px;
          font-weight: normal;
          color: #4f4f4f;
          background-color: transparent;
          padding: 0px;
          height: 40px !important;
          line-height: 40px;
          width: 100%;
        }
        input::placeholder {
          color: #4f4f4f;
          font-size: 16px;
          font-weight: normal;
        }
        input:focus {
          outline: none !important;
        }
      }
    }

    .chart-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
      padding: 0px;
      ::v-deep canvas {
        width: 45% !important;
        height: auto !important;
      }
      .legend {
        width: 100%;
        padding: 0px 12px 0px 0px;
        .legend-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 6px;
          .legend-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 12px;
            &.carbs {
              background-color: #FECE3C;
            }
            &.protein {
              background-color: #F77975;
            }
            &.fat {
              background-color: #35B6E2;
            }
          }
          p {
            font-size: 13px;
            font-weight: 500;
            color: #000000;
            margin: 0px 12px 0px 0px;
            &.value {
              font-weight: 700;
              text-align: right;
              margin: 0px 0px 0px auto;
            }
          }
        }
        .legend-row:last-child {
          margin: 0px;
        }
      }
    }

    .button-row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 20px;

      .save-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        background-color: $endurance;
        height: 40px;
        padding: 0px 24px;
        border-radius: 22px;
        cursor: pointer;
        
        &:disabled {
          background-color: #E6E6E6;
          color: #959595;
          cursor: not-allowed;
        }
      }
    }
  }

  .modal-backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.3);
    -webkit-overflow-scrolling: touch;
    z-index: 100;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    padding: 18px 24px 14px 24px;
    width: 80%;
    max-width: 375px;

    .modal-icon {
      align-self: center;
      color: black;
      font-size: 16px;
      font-weight: 600;
      font-family: Montserrat;
      margin-bottom: 16px;

      i {
        font-size: 32px;
      }
    }

    .modal-header {
      align-self: flex-start;
      color: black;
      font-size: 16px;
      font-weight: 600;
      font-family: Montserrat;
      margin-bottom: 8px;
    }

    .modal-text {
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
      text-align: left;
    }

    .buttons {
      display: flex;
      align-self: flex-end;
      cursor: pointer;

      span {
        font-family: "open sans";
        color: $endurance;
        font-size: 14px;
        font-weight: 600;
        padding: 14px 0px;
        margin-left: 0px;

        &:first-of-type {
          margin-right: 12px;
        }
      }
    }
  }
</style>
