<template>
  <div class="modal kitchen-prefs-reminder-modal">
    <div class="modal-content-container" v-click-outside="dismiss">
      <h1 class="accent-text-large">Let's Review your Preferences</h1>
      <p>Please make sure your dietary preferences are accurate before using the Kitchen!</p>

      <br />

      <h4>Current Settings</h4>
      <ul>
        <li v-for="{ name, selected } in allDietaryRestrictions">
          <span>{{ name }}</span>
          <strong>{{ selected }}</strong>
        </li>
      </ul>
      <div class="button-wrap relative">
        <router-link to="/profile/diet">
          <div class="button-secondary accent-button">Change in Settings</div>
        </router-link>
        <div class="button-primary accent-button" @click="dismiss">Looks Good!</div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: { ClickOutside },
  computed: {
    userDietaryRestrictions() {
      return this.$store.state.rails.user.dietary_restriction_ids;
    },
    allDietaryRestrictions() {
      return this.$store.state.rails.dietary_restrictions.map(r => {
        const selected = this.userDietaryRestrictions.includes(r.id) ? "yes" : "no";
        return { ...r, selected };
      });
    }
  },
  methods: {
    dismiss() {
      this.$store.dispatch("rails/update_user_preferences", { kitchen_prefs_reminder: false });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
