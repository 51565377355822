<template>
  <div v-click-outside="toggle_options" class="food-results" @click="toggle_options">
    <div v-for="item in items_with_macros" v-bind:key="item.id" class="food-result">
      <div class="info">
        <p class="food-name">{{ item.name }}</p>
        <div class="macros-container">
          <div class="macro">
            <div class="name">C</div>
            <div class="actual">{{ item.serving_macros.carb_grams }}g</div>
          </div>
          <div class="macro">
            <div class="name">P</div>
            <div class="actual">{{ item.serving_macros.protein_grams }}g</div>
          </div>
          <div class="macro">
            <div class="name">F</div>
            <div class="actual">{{ item.serving_macros.fat_grams }}g</div>
          </div>
          <div class="macro">
            <div class="name">Cals</div>
            <div class="actual">{{ item.serving_macros.calories }}</div>
          </div>
        </div>
      </div>
      <div class="options-container" v-on:click.stop @click="toggle_options(item.id)">
        <i class="fa fa-ellipsis-v" />
      </div>
      <div v-if="visible_options === item.id" class="options-dropdown" v-on:click.stop>
        <router-link v-if="item_type === 'recipes'" :to="`/recipes/${item.id}`" tag="p">
          <i class="fa fa-eye"></i>
          View
        </router-link>
        <p @click="() => edit(item)">
          <i class="fa fa-pencil"></i>
          Edit
        </p>
        <p @click="delete_item(item)">
          <i class="fa fa-trash"></i>
          Delete
        </p>
      </div>

      <div class="checkbox-container" @click="add_item(item)">
        <div v-if="item_type === 'foods'" :class="{ box: true, selected: item.selected }">
          <i class="fa fa-check" />
        </div>
        <div v-else class="quick-add-button">
          <i class="fa fa-plus-circle"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import _ from "lodash";

import { filter_by_search_term, multiply_macros, round_macros } from "@js/lib/utils";

function get_serving_macros(item_type, item) {
  let macros;
  if (item_type === "recipes") {
    macros = multiply_macros(item.conversion, item.quantity);
  } else {
    macros = {
      carb_grams: item.carbohydrates,
      protein_grams: item.protein,
      fat_grams: item.fat,
      calories: item.calories
    };
  }
  return round_macros(macros, 0.5);
}

export default {
  name: "ItemList",
  directives: { ClickOutside },
  props: {
    item_type: { type: String, required: true },
    items: { type: Array, required: true },
    add_item: { type: Function, required: true },
    edit_item: { type: Function, required: true },
    delete_item: { type: Function, required: true }
  },
  data() {
    return {
      visible_options: null
    };
  },
  computed: {
    items_with_macros() {
      return this.items.map(item => (
        { ...item, serving_macros: get_serving_macros(this.item_type, item) }
      ));
    }
  },
  methods: {
    toggle_options(item_id) {
      this.visible_options = item_id;
    },
    edit(item_id) {
      this.visible_options = null;
      this.edit_item(item_id);
    }
  }
};
</script>

<style scoped lang="scss">
  .food-results {
      padding: 18px 24px;
      width: 100%;
      overflow: auto;

      .food-result {
        position: relative;
        display: flex;
        flex-direction: row;
        border-left: 8px solid #C4C4C4;
        padding: 0px 0px 0px 12px;
        margin: 0px 0px 12px 0px;
        &:last-child {
          margin-bottom: 64px;
        }

        .info {
          display: flex;
          flex-direction: column;
          margin: 0px 14px 0px 0px;

          .food-name {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            margin: 0px 0px 4px 0px;
          }

          .macros-container {
            display: flex;
            flex-direction: row;
            margin: 0px 0px 4px 0px;

            .macro {
              display: flex;
              flex-direction: row;
              white-space: nowrap;
              padding-right: 0.3rem;

              .name, .actual {
                font-family: 'Open Sans';
                font-size: 12px;
                font-weight: 600;
                color: #4f4f4f !important;
              }

              .name {
                margin-left: 0.1rem;
                padding-right: 0.2rem;
              }

              .actual {
                padding-right: 0px;
                margin-right: 0px;
              }
            }
          }
        }

        .options-container, .checkbox-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 0px 0px 0px;
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
          cursor: pointer;

          i {
            font-size: 12px;
            color: $endurance;
          }
        }

        .options-container {
          margin-left: auto;
        }

        .checkbox-container {
          margin-left: 0px;

          .quick-add-button i {
            font-size: 24px;
            color: $endurance;
          }

          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            border: 1px solid $endurance;
            border-radius: 2px;
            width: 14px;
            height: 14px;

            &.selected {
              background-color: $endurance;
              i {
                display: block;
              }
            }
            i {
              display: none;
              font-size: 10px;
              color: #ffffff;
            }
          }
        }
      }
  }

  .options-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    top: 40px;
    right: 55px;
    z-index: 49;
    background-color: #ffffff;

    p {
      padding: 6px 18px;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 600;
      color: #4f4f4f;
      cursor: pointer;

      &:first-child {
        padding-top: 12px;
      }

      &:last-child {
        padding-bottom: 12px;
      }

      i {
        margin-right: 6px;
        font-size: 10px;
      }
    }
  }
</style>
