<template>
  <div v-if="sorted_filters.length > 0" :class="{ filters: true, expanded: expanded }">
    <span
      v-for="filter in sorted_filters"
      :key="filter.id"
      :class="{ filter: true, selected: filter.selected, deselected: filter.deselected }"
      :style="`transition: color ${deselect_timeout}ms`"
      @click="click(filter)"
    >
      <template v-if="filter.deselected">
        <Spinner
          :duration="deselect_timeout"
          :radius="12"
          :stroke="3"
          :logo="false"
          :forward="false"
          :start_color="'#b254b2'"
          :end_color="'#b254b2'"
          :ref="'deselect-spinner'"
          class="loader"
        />
      </template>
      <template v-else>
        <i class="fa fa-times fa-sm icon" />
      </template>
      <p>{{ filter.display_name }}</p>
    </span>
  </div>
</template>

<script>
import _ from "lodash";

import Spinner from "@js/recipes/spinner.vue";

let deleteDebounce;

export default {
  components: { Spinner },
  props: {
    filters: {type: Array, required: true}
  },
  data() {
    return {
      deselected: [],
      deselect_timeout: 750,
      expanded: false
    };
  },
  computed: {
    sorted_filters() {
      let all = _.concat(this.filters, this.deselected).map((filter) => {
        filter.deselected = !!_.find(this.deselected, {id: filter.id});
        return filter;
      });

      return _.sortBy(all, function(item) {
        // selected should be a timestamp in ms
        return -item.selected;
      });
    }
  },
  methods: {
    click(filter) {
      // start spinning fresh if they change any selections
      if (this.$refs["deselect-spinner"]) {
        this.$refs["deselect-spinner"].map((spinner) => {
          spinner.start_over();
        });
      }

      if (deleteDebounce)  { deleteDebounce.cancel(); }
      deleteDebounce = _.debounce(() => { this.deselected = []; }, this.deselect_timeout);
      deleteDebounce();

      if (filter.deselected) {
        this.deselected = _.reject(this.deselected, {id: filter.id});
        this.$emit("reselect", filter.id, filter.selected);
      } else {
        let cloned_filter = _.clone(filter);
        this.deselected = _.concat(this.deselected, cloned_filter);
        this.$emit("deselect", filter.id);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.filters {
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // grid-column-gap: 8px;
  // grid-row-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 24px;
  width: 100%;
  white-space: normal;
  //max-height: 95px;
  //max-height: 55px;
  //overflow-y: auto;

  &.expanded {
    max-height: none;
  }

  .filter {
    display: inline-flex;
    align-items: center;
    height: 24px;
    background-color: #F0E8FB;
    color: $endurance;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Open Sans';
    padding: 0px 12px;
    border-radius: 12px;
    margin: 0px 8px 6px 0px;
    // max-width: 100%;
    // overflow: hidden;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0px;
    }

    .loader {
      margin-left: auto;
    }

    .icon {
      margin-right: 8px;
    }

    .expand-button {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 16px;
      .icon {
        margin: 0px;
      }
    }

    &.deselected {
      text-decoration: line-through;
      background-color: #999;
      padding: 0px 4px 0px 12px;
    }

    &.ellipsis {
      background-color: #ffffff;
    }
  }
}
</style>
