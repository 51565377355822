import { pick } from "lodash"

import { multiply_macros, calculate_calories, round } from "@js/lib/utils";

// TODO: implement in Vue
export function show_favorited_alert() {
  var $favoritedAlert = $("#favorited-alert");

  $favoritedAlert.css("cssText", "display: flex !important;").hide().fadeIn(200);

  setTimeout(function() {
    $favoritedAlert.fadeOut(800);
  }, 500);
};

// TODO: implement in Vue
export function show_unfavorited_alert() {
  var $unfavoritedAlert = $("#unfavorited-alert");

  $unfavoritedAlert.css("cssText", "display: flex !important;").hide().fadeIn(200);

  setTimeout(function() {
    $unfavoritedAlert.fadeOut(800);
  }, 500);
};

// NOTE: these firsts three functions were replicated (more or less) from portion_presenter.rb
export function portion_servings_to_quantity(portion) {
  const { servings, units, common_serving_quantity, measurement_size_quantity, serving_size } = portion;

  if (units === "measurement" && serving_size) {
    return serving_size;
  }
  const qty_per_serving = units === "common" ? common_serving_quantity : measurement_size_quantity;

  return round(servings * qty_per_serving, 0.01);
}

export function portion_quantity_to_servings(portion, quantity) {
  const { units, common_serving_quantity, measurement_size_quantity } = portion;
  const qty_per_serving = units === "common" ? common_serving_quantity : measurement_size_quantity;

  return round(quantity / qty_per_serving, 0.01);
}

export function serving_units(portion) {
  const { units, common_serving_unit_name, measurement_size_unit_name } = portion;

  if (units === "common") {
    return common_serving_unit_name;
  }
  return measurement_size_unit_name || "grams";
}

export function is_pre_workout(meal_id, workout_time) {
  return {
    "meal-1": "morning",
    "meal-2": "afternoon",
    "meal-3": "evening"
  }[meal_id] === workout_time
}

export function is_post_workout(meal_id, workout_time) {
  return {
    "meal-2": "morning",
    "meal-3": "afternoon",
    "meal-4": "evening"
  }[meal_id] == workout_time;
}
// TODO just convert carbohydrates to carb in controller and can stop doing this BS name remapping everywhere
export function to_phx_macro_targets(macros) {
  return {
    carb: macros.carbohydrates,
    protein: macros.protein,
    fat: macros.fat
  };
}

function to_rails_macros(conversion) {
  return {
    carbohydrates: conversion.carb_grams,
    protein: conversion.protein_grams,
    fat: conversion.fat_grams,
    calories: conversion.calories
  };
}

export function build_custom_food_portion(ingredient) {
  // NOTE:
  //   a) custom foods' default serving_size always equals their common_serving_quantity
  //   b) custom foods are always added with the default serving_size (there's no input field to specify otherwise)
  //   => servings == 1

  const ingredient_macros = {
    ...pick(ingredient, ["carbohydrates", "protein", "fat", "calories"]),
    fiber: 0,
    calories: calculate_calories(ingredient)
  };

  // NOTE: if servings != 1, would have to `multiply_macros(ingredient_macros, servings)`
  const macros = ingredient_macros;

  return {
    ingredient_id: ingredient.id,
    ingredient: ingredient_macros,
    servings: 1,
    serving_size: ingredient.common_serving_quantity || 1,
    is_custom: true,
    is_favorite: true,
    ...macros,
    ...build_rails_food_portion(ingredient)
  };
}

export function build_fmf_food_portion(portion) {
  // NOTE: FMF ingredient records were migrated to the Phx DB as foods with author == :legacy_rails
  // and then updated with the Phx food_ids
  const { food_id, ingredient_id, servings, serving_size } = portion;

  const ingredient_macros = {
    ...pick(portion.ingredient, ["carbohydrates", "protein", "fat", "calories"]),
    fiber: 0,
    calories: calculate_calories(portion.ingredient)
  };

  const macros = {
    ...pick(portion, ["carbohydrates", "protein", "fat", "calories"]),
    fiber: 0,
    calories: calculate_calories(portion)
  };

  return {
    food_id,
    ingredient_id,
    ingredient: ingredient_macros,
    servings,
    serving_size,
    is_custom: false,
    is_favorite: false, // may subsequently be set to true by initialize_favorite_portions
    ...macros,
    ...build_rails_food_portion(portion)
  }
}

function build_rails_food_portion(portion_or_ingredient) {
  const {
    name,
    common_serving_quantity,
    common_serving_unit_name,
    measurement_size_unit_name,
    measurement_size_quantity
  } = portion_or_ingredient;

  return {
    name,
    common_serving_quantity,
    common_serving_unit_name,
    measurement_size_unit_name,
    measurement_size_quantity,
    serving_step: 1,
    units: "common",
    favoritable: true
  };
}

export function build_food_portion(food, use_conversion = false) {
  const gramConversions = food.conversions.filter(({ name }) => name === "gram");
  const commonConversions = food.conversions.filter(({ name }) => name !== "gram");
  let commonConversion;
  let gramConversion;

  if (commonConversions && commonConversions.length) {
    // we may have multiple common conversions so see if one is marked as default
    commonConversion = use_conversion ? commonConversions.find(c => c.id === food.conversion.id) : commonConversions.find(c => c.is_default);
    if (!commonConversion) {
      commonConversion = commonConversions[0];
    }
  }

  if (gramConversions && gramConversions.length) {
    // we may have multiple gram conversions so see if one is marked as default
    gramConversion = use_conversion ? gramConversions.find(c => c.id === food.conversion.id) : gramConversions.find(c => c.is_default);
    if (!gramConversion) {
      gramConversion = gramConversions[0];
    }
  }

  let measurement = {};
  if (gramConversion) {
    measurement = {
      measurement_size_unit_name: "gram",
      measurement_size_quantity: gramQuantity(food, commonConversion)
    };
    if (measurement.measurement_size_quantity === 0) {
      measurement = {};
    }
  }

  let common = {};
  if (commonConversion) {
    common = {
      common_serving_unit_name: commonConversion.name,
      common_serving_quantity: commonQuantity(food, commonConversion)
    };
    if (common.common_serving_quantity === 0) {
      common = {};
    }
  }

  let units, serving_size;
  if (food.conversion.name === "gram") {
    units = "measurement";
    serving_size = measurement.measurement_size_quantity;
  } else {
    units = "common";
    serving_size = common.common_serving_quantity;
  }

  const macros = { ...to_rails_macros(multiply_macros(food.conversion, food.quantity)), fiber: 0 };

  return {
    food_id: food.id,
    favoritable: true,
    name: food.name,
    ...macros,
    ingredient: macros,
    units,
    servings: 1,
    serving_size,
    ...common,
    ...measurement
  };
}

export function build_recipe_portion(recipe) {
  const gramConversion = recipe.conversions.find(({ name }) => name === "gram");
  // NOTE: currently Phx API always returns recipes' macrostax_serving as the selected conversion
  const commonConversion = recipe.conversion;

  let units = "common";
  let measurement = {};

  if (!recipe.is_discrete && !recipe.user_id) {
    units = "measurement";
    measurement = {
      measurement_size_unit_name: "gram",
      measurement_size_quantity: gramQuantity(recipe, commonConversion)
    };
  }

  const common = {
    common_serving_unit_name: recipe.user_id ? commonConversion.name : recipe.custom_serving_name || "serving",
    common_serving_quantity: recipe.is_miscible ? 1 : recipe.eat_quantity
  };

  const serving_size = units === "common"
        ? common.common_serving_quantity
        : measurement.measurement_size_quantity;

  const macros = { ...to_rails_macros(multiply_macros(recipe.conversion, recipe.eat_quantity)), fiber: 0 };

  // if we're logging a sub-recipe, store the id of the root recipe as well so the multi-recipe
  // can be un/favorited all at once
  return {
    recipe_id: recipe.id,
    root_recipe_id: recipe.root_recipe_id,
    user_id: recipe.user_id,
    is_custom: !!recipe.user_id,
    favoritable: true,
    name: recipe.name,
    ...macros,
    ingredient: macros,
    units,
    servings: 1,
    serving_size,
    ...common,
    ...measurement
  };
}

function commonQuantity(ingredient, commonConversion) {
  const quantity = ingredient.eat_quantity || ingredient.quantity;

  if (ingredient.conversion.id === commonConversion.id) {
    return quantity;
  } else {
    if (commonConversion.weight_grams === null || isNaN(commonConversion.weight_grams, 10) || commonConversion.weight_grams === 0) {
      // the default conversion is grams but the common conversion does not contain a valid weight_grams value. So, see if
      // we can derive this from the default conversion instead
      // const defaultConversion = ingredient.conversions.find(c => c.is_default === true);
      // if (defaultConversion && defaultConversion.default_serving_qty) {
      //   return quantity / defaultConversion.default_serving_qty;
      // }
      // return quantity;
      return 0;
    }
    return quantity / commonConversion.weight_grams;
  }
}

function gramQuantity(ingredient, commonConversion) {
  const quantity = ingredient.eat_quantity || ingredient.quantity;

  if (!commonConversion || ingredient.conversion.id !== commonConversion.id) {
    return quantity;
  } else {
    const cookLossFactor = ingredient.cook_loss_factor || 1;
    return Math.round(commonConversion.weight_grams * quantity * cookLossFactor);
  }
}

function serving_name_for(food, commonConversion) {
  if (food.custom_serving_name) {
    return food.custom_serving_name;
  } else if(commonConversion.name === "macrostax_serving") {
    return "serving";
  } else {
    return commonConversion.name;
  }
}
