<template>
  <div class="ingredient-container">
    <div class="ingredient">
      <div class="info">
        <p class="food-name">{{ food.name }}</p>
        <p v-if="food.brand_name" class="food-brand">{{ food.brand_name }}</p>
        <Macros
          v-if="!is_editing"
          :food="food"
          :quantity="food.quantity"
          :quantityKind="serving_name === 'gram' ? 'gram' : 'both'"
          :customEditor="true"
          :only="'none'"
          :pluralize="false"
          class="amount inline macrostax-font"
        />
      </div>
      <template v-if="!is_editing">
        <div class="options-container" @click="edit_ingredient(food.id)">
          <i class="fa fa-pencil" />
        </div>
        <div class="options-container" style="margin-left: 8px;" @click="remove_ingredient(food.id)">
          <i class="fa fa-trash" />
        </div>
      </template>
    </div>

    <div v-if="is_editing" class="edit-food-container">
      <div class="input-row" style="margin-top: 12px;">
        <div class="input-container" style="margin-bottom: 0px;">
          <p class="input-label">Quantity</p>
          <input type="number" v-model.number="food.quantity" />
        </div>

        <div class="input-container" style="margin-bottom: 0px;">
          <p class="input-label">Unit</p>
          <select v-model.number="food.conversion_id">
            <option
              v-for="conversion in food.conversions"
              v-bind:key="conversion.id"
              :value="conversion.id"
            >
              {{ conversion.name }}
            </option>
          </select>
        </div>

        <div class="save-button" @click="save_ingredient">SAVE</div>
      </div>
    </div>
  </div>
</template>

<script>
import find from "lodash/find";
import { to_fraction, is_kitchen_measurable } from "@js/lib/utils";

import Macros from "@js/shared/macros.vue";

export default {
  components: { Macros },
  name: "Ingredient",
  props: {
    food: { type: Object, required: true },
    is_editing: { type: Boolean, required: true },
    edit_ingredient: { type: Function, required: true },
    save_ingredient: { type: Function, required: true },
    remove_ingredient: { type: Function, required: true }
  },
  computed: {
    serving_name() {
      return find(this.food.conversions, ({ id }) => id === this.food.conversion_id).name;
    },
    quantity() {
      return is_kitchen_measurable(this.food.quantity) ? to_fraction(this.food.quantity) : this.food.quantity;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/_reset.scss";
@import "@css/_tracker_reset.scss";

.ingredient-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px 0px 12px 0px;

    .ingredient {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;

      .info {
        display: flex;
        flex-direction: column;
        margin: 0px 14px 0px 0px;

        .food-name {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
          margin: 0px 0px 4px 0px;
        }

        .food-serving, .food-brand {
          font-size: 12px;
          font-weight: 500;
          color: #4f4f4f;
          margin: 0px 0px 4px 0px;
        }

        .food-brand {
          font-style: italic;
          line-height: 18px;
        }
      }

      .options-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 0px 0px auto;
        width: 30px;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
        cursor: pointer;

        i {
          font-size: 12px;
          color: #4f4f4f;
        }
      }
    }

    .edit-food-container {
      position: relative;
      display: flex;
      flex-direction: row;
      padding: 0px;
      margin: 0px 0px 12px 0px;

      .input-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .input-container {
          background-color: #FAFAFA;
          border-radius: 8px 8px 0px 0px;
          border-bottom: 2px solid $endurance;
          padding: 6px 12px;
          margin: 0px 24px 24px 0px;
          width: 100%;

          .input-label {
            color: $endurance;
            font-size: 12px;
            font-weight: 600;
            margin: 0px 0px 6px 0px;
          }

          input {
            color: #000000;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            padding: 0px;
            margin: 0px;
            resize: none;
            width: 100%;
            height: 28px;
          }

          input::placeholder {
            color: #4f4f4f;
            font-size: 16px;
            font-weight: normal;
          }

          input:focus {
            outline: none !important;
          }

          textarea {
            color: #000000;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            padding: 0px;
            margin: 0px;
            resize: none;
            width: 100%;
          }

          textarea::placeholder {
            color: #4f4f4f;
            font-size: 16px;
            font-weight: normal;
          }

          textarea:focus {
            outline: none !important;
          }

          select {
            background-color: transparent;
            width: 100%;
          }

          select:focus {
            outline: none !important;
          }
        }
      }
    }

    .save-button {
      font-size: 14px;
      color: $endurance;
      font-weight: 600;
      cursor: pointer;
    }
  }
</style>
