<template lang="html">
  <div
    :class="{initials: initials}"
    class="macros"
  >
    <div class="macros-container">
      <div v-if="showMacro('carb')" class="macro">
        <div v-if="showLabels" class="name">
          {{ initials ? "C" : "Carb" }}
        </div>
        <div class="actual">
          {{ carbActualFormatted }}g
        </div>
        <div
          v-if="showTargets && carbTarget"
          class="target"
        >
          <div class="divider">/</div>{{ carbTarget }}
        </div>
      </div>
      <div v-if="showMacro('protein')" class="macro">
        <div v-if="showLabels" class="name">
          {{ initials ? "P" : "Pro" }}
        </div>
        <div class="actual">
          {{ proteinActualFormatted }}g
        </div>
        <div
          v-if="showTargets && proteinTarget"
          class="target"
        >
          <div class="divider">/</div>{{ proteinTarget }}
        </div>
      </div>
      <div v-if="showMacro('fat')" class="macro">
        <div v-if="showLabels" class="name">
          {{ initials ? "F" : "Fat" }}
        </div>
        <div class="actual">
          {{ fatActualFormatted }}g
        </div>
        <div
          v-if="showTargets && fatTarget"
          class="target"
        >
          <div class="divider">/</div>{{ fatTargetFormatted }}
        </div>
      </div>
      <div v-if="showMacro('cal')" class="macro calories">
        <div v-if="showLabels && !isRecipe" class="name">
          Cal
        </div>
        <div class="actual">{{ kaloriesActualFormatted }}</div>
        <div
          v-if="showTargets && kaloriesTarget"
          class="target"
        >
          <div class="divider">/</div>{{ kaloriesTarget }}
        </div>
        <div v-if="showLabels && isRecipe" class="name">
          Cals
        </div>
      </div>
    </div>
    <div
      v-if="showQuantity"
      class="amount"
    >
      <template v-if="showCommon">
        {{ commonQuantity }} {{ servingName }}
      </template>
      <template v-if="showGramConversion && this.gramQuantity !== 0 && !this.isSeasoning">
        <span>({{ gramQuantity }}g)</span>
      </template>
      <template v-else-if="showGram && this.gramQuantity !== 0">
        <span>{{ gramQuantity }}g</span>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import inflect from "@js/lib/inflect";
import { to_fraction, round, is_kitchen_measurable } from "@js/lib/utils";

function format_macro_value(value, step) {
  value = parseFloat(value); // TODO is this actually necessary? maybe for nulls or something?
  return round(value, step);
}

export default {
  props: {
    food: {type: Object, required: true},
    gramStep: {type: Number, default: 0.5},
    // showCalories: {type: Boolean, default: false},
    showTargets: {type: Boolean, default: false},
    showQuantity: {type: Boolean, default: true},
    quantityKind: {type: String, default: "gram"}, // gram, common, both
    showLabels: {type: Boolean, default: true},
    only: {type: String, default: ""}, // carb, protein, fat, cal or 'none!!'
    carbTarget: {type: Number, default: 0},
    proteinTarget: {type: Number, default: 0},
    fatTarget: {type: Number, default: 0},
    kaloriesTarget: {type: Number, default: 0},
    cookLossFactor: {type: Number, default: 1},
    initials: {type: Boolean, default: false},
    showKitchenUnmeasureable: {type: Boolean, default: true},
    pluralize: {type: Boolean, default: true},
    quantity: {type: Number, default: undefined},
    proportion: {type: Number, default: 1},
    customEditor: {type: Boolean, default: false},
    isRecipe: {type: Boolean, default: false}
  },
  computed: {
    // TODO hackypants fix for tracker.js stuff
    conversion() {
      // TODO standardize what's returned
      return this.food.conversion || this.food.default_conversion || _.find(this.food.conversions, {is_default: true});
    },
    currentQuantity() {
      // TODO standardize what's returned
      return this.quantity || this.food.quantity || this.food.make_quantity;
    },
    isSeasoning () {
     return  _.findIndex(this.food.tags, (tag) => tag.name === 'is_seasoning') !== -1;
    },
    gramQuantity() {
      if (this.customEditor && this.serving === 'gram') {
       return this.currentQuantity;
      } else if (this.customEditor) {
        const quantity = this.currentQuantity * _.find(this.food.conversions, ({ id }) => id === this.food.conversion_id).weight_grams * this.cookLossFactor;
        return round(quantity, this.gramStep);
      } else {
        const quantity = this.currentQuantity * this.conversion.weight_grams * this.cookLossFactor;
        return round(quantity, this.gramStep);
      }
    },
    showGramConversion() {
      return this.quantityKind === 'both' && this.showCommon && this.isCommon;
    },
    showGram () {
      return this.quantityKind === 'gram' || !this.showCommon;
    },
    commonQuantity() {
      return is_kitchen_measurable(this.currentQuantity) ? to_fraction(this.currentQuantity) : `${this.currentQuantity}`;
    },
    showCommon() {
      return this.quantityKind === "common" || 
             // show common quantity unless it's a macrostax recipe, then defer to kitchen measureable
             (this.quantityKind === "both" && this.isCommon && 
              (is_kitchen_measurable(this.currentQuantity) || this.showKitchenUnmeasureable))
    },
    isCommon() {
      // prevents rendering a gram measurement as both a commonQuantity AND a conversion
      // this occurs if the user selected "gram" as the unit when building a custom recipe
      return this.food.custom_serving_name || 
             (this.customEditor
               ? _.find(this.food.conversions, ({ id }) => id === this.food.conversion_id).name !== "gram" 
               : this.conversion.name !== "gram");
    },
    serving() {   
      if (this.food.custom_serving_name) {
        return this.food.custom_serving_name;
      } else if (this.customEditor) {
         return _.find(this.food.conversions, ({ id }) => id === this.food.conversion_id).name;
      } else if (this.conversion.name === "macrostax_serving") {
        return "serving";
      } else {
        return this.conversion.name;
      }
    },
    servingName() {
      const quantity = to_fraction(this.currentQuantity);
      const pluralize_quantity = quantity.match(/^\d+\/\d+$/) ? 1 : parseInt(quantity);
      return this.pluralize ? inflect(this.serving, pluralize_quantity) : this.serving;
    },
    carbActual() { return this.currentQuantity * this.conversion.carb_grams; },
    carbActualFormatted() { return format_macro_value(this.carbActual, 0.5); },
    carbTargetFormatted() { return _util.formatValue(this.carbTarget, 1.0); },
    proteinActual() { return this.currentQuantity * this.conversion.protein_grams; },
    proteinActualFormatted() { return format_macro_value(this.proteinActual, 0.5); },
    proteinTargetFormatted() { return _util.formatValue(this.proteinTarget, 1.0); },
    fatActual() { return this.currentQuantity * this.conversion.fat_grams; },
    fatActualFormatted() { return format_macro_value(this.fatActual, 0.5); },
    fatTargetFormatted() { return _util.formatValue(this.fatTarget, 1.0); },
    kaloriesActual() { return this.currentQuantity * this.conversion.calories; },
    kaloriesActualFormatted() { return format_macro_value(this.kaloriesActual, 1.0); },
    kaloriesTargetFormatted() { return _util.formatValue(this.caloriesTarget, 1.0); }
  },
  methods: {
    showMacro(name) {
      if (!this.only) { return true; }
      return this.only === name;
    }
  }
};
</script>

<style scoped lang="scss">
  .macros-container, .macros {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .macro {
    display: flex;
    line-height: inherit;
    font-size: 12px;
    padding-right: 0px;
    white-space: nowrap;
    .name, .actual, .target {
      flex: 1;
      padding: 0;
      margin: 0;
      margin-right: 8px;
      letter-spacing: 0.5px;
    }
    .name {
      font-weight: normal;
      color: #000;
      text-align: center;
      margin-right: 4px;
    }
    .actual {
      font-weight: normal;
      color: #000;
      margin-right: 8px;
    }
    .target {
      font-weight: 700;
      color: $sturdy;
      .divider {
        padding: 0px 1px;
      }
    }
  }
  .both-separator {
    font-weight: bold;
  }
  .initials {
    .calories .actual {
      color: #000;
    }
  }
  .amount {
    white-space: nowrap;
    color: #000;
    font-size: 12px;
  }
</style>
