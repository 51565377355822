var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{attrs:{"id":"nav-footer"}},[(_vm.modalIsVisible)?_c('div',{staticClass:"modal-backdrop"}):_vm._e(),_vm._v(" "),_c('router-link',{class:{
      active: _vm.is_active('food_log'),
      notification: _vm.nav_footer_notification === 'food_log'
    },attrs:{"to":"/food_log"}},[_c('img',{attrs:{"src":_vm.icon('/food_log'),"alt":"Food Log"}}),_vm._v(" "),_c('span',[_vm._v("Food Log")])]),_vm._v(" "),_c('router-link',{class:{
      active: _vm.is_active('recipes'),
      notification: _vm.nav_footer_notification === 'recipes'
    },attrs:{"to":"/recipes"}},[_c('img',{attrs:{"src":_vm.icon('/recipes'),"alt":"Recipes"}}),_vm._v(" "),_c('span',[_vm._v("Recipes")])]),_vm._v(" "),_c('router-link',{class:{
      active: _vm.is_active('profile'),
      notification: _vm.nav_footer_notification === 'profile'
    },attrs:{"to":"/profile"}},[_c('img',{attrs:{"src":_vm.icon('/profile'),"alt":"Profile"}}),_vm._v(" "),_c('span',[_vm._v("Profile")])]),_vm._v(" "),_c('a',{attrs:{"id":"Intercom"}},[_c('img',{attrs:{"src":_vm.icon('stax_chat'),"alt":"StaxChat"}}),_vm._v(" "),(_vm.unread_count > 0)?_c('div',{staticClass:"unread-count"},[_vm._v(_vm._s(_vm.unread_count))]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("StaxChat")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }