import Vue from "vue";
import { forEach, mapKeys, mapValues, keyBy, values, pick } from "lodash";
import { flow, pick as pickFp } from "lodash/fp";
import { v4 as uuid } from "uuid";
import moment from 'moment';

import API from "@js/api/rails_client";

import {
  sum_macros,
  subtract_macros,
  multiply_macros,
  round_macros,
  calculate_calories,
  round,
  setLocalStorage,
  getLocalStorage,
} from "@js/lib/utils";

import upgrade_messaging from '@js/lib/upgrade_messaging.json';

import {
  build_food_portion,
  build_recipe_portion,
  build_custom_food_portion,
  build_fmf_food_portion,
  portion_quantity_to_servings,
  to_phx_macro_targets
} from "@js/lib/rails_compatibility_helpers";

export default {
  namespaced: true,
  state: {
    user: undefined,
    selected_date: undefined,
    statistic_set: undefined,
    day_details: undefined,
    dietary_restrictions: undefined,
    meal_planner_settings: undefined,
    meal_set: undefined,
    meals: {
      "meal-1": undefined,
      "meal-2": undefined,
      "meal-3": undefined,
      "meal-4": undefined,
      "meal-5": undefined,
    },
    day_details: undefined,
    timezones: undefined,
    custom_foods: [],
    portions_to_delete: null,
    show_upgrade_modal: null,
    upgrade_message: null,
    mtc_challenge: null,
    csrf_token: document.getElementsByName("csrf-token")[0].content,
    cancellation_reasons: [],
    billing: {
      cancellation: {
        id: undefined,
        status: undefined,
        cs_cancellation: false,
        reminder: false,
      },
      card: {
        brand: undefined,
        exp_date: undefined,
        last4: undefined,
        owner: undefined,
        zipcode: undefined,
      },
      subscription: {
        next_bill_date: undefined,
        plan: {
          amount: undefined,
          canceled: undefined,
          interval: undefined,
          name: undefined,
        }
      }
    }
  },
  getters: {
    is_favorite: (state, getters, rootState) => (portion) => {
      const favorite_food_ids = rootState.favorite_foods.map(food => food.id);
      const favorite_recipe_ids = rootState.favorite_recipes.map(recipe => recipe.id);

      let { food_id, recipe_id, root_recipe_id } = portion;
      recipe_id = root_recipe_id || recipe_id;

      return (
        favorite_food_ids.includes(food_id) ||
        favorite_recipe_ids.includes(recipe_id) ||
        // NOTE: custom foods are sent from Rails with is_favorite already set
        portion.is_favorite
      );
    },
    macro_targets_for_day(state, getters) {
      // This could be (and was at first) implemented by just loading @meal_plan.macro_targets[:summary]
      // in MacrosController#show into Gon (which includes calories).
      //
      // But then it wasn't auto-magically reactive upon receiving updated per meal macro targets from the API
      // in the update_day_details action
      return sum_macros(values(getters.macro_targets_per_meal), 1);
    },
    macro_targets_per_meal(state) {
      return mapValues(state.meals, meal => (
        { ...meal.target, calories: calculate_calories(meal.target) }
      ));
    },
    macros_logged_for_day(state, getters) {
      return sum_macros(values(getters.macros_logged_per_meal), 1);
    },
    macros_logged_per_meal: (state) => {
      return mapValues(state.meals, meal => sum_macros(meal.portions));
    },
    macros_remaining_for_day(state, getters) {
      // round to nearest integer per Sarah
      return round_macros(
        subtract_macros(getters.macro_targets_for_day, getters.macros_logged_for_day),
        1,
        false
      );
    },
    macros_remaining_per_meal: (state, getters) => {
      return mapValues(state.meals, (meal, meal_id) => (
        subtract_macros(getters.macro_targets_per_meal[meal_id], getters.macros_logged_per_meal[meal_id])
      ));
    },
    default_portion_macro_targets(state, getters) {
      const avg_meal_targets = mapValues(getters.macro_targets_for_day, val => round(val / 5, 0.5));
      return to_phx_macro_targets(avg_meal_targets);
    },
    favorite_custom_foods(state) {
      return state.custom_foods.filter(food => food.is_favorite);
    },
  },
  actions: {
    initialize_state({ commit, dispatch }) {
      commit("INITIALIZE_STATE", gon);
      dispatch('initialize_upgrade_modal');
    },
    async get_food_log({ commit, dispatch }, date) {
      const { data } = await API.get_food_log(date);

      commit("INITIALIZE_STATE", data);

      dispatch("initialize_favorite_portions");
    },
    initialize_favorite_portions({ rootState, state, getters, commit }) {
      const all_recipe_ids = [
        ...rootState.macrostax_recipes,
        ...rootState.custom_recipes
      ].map(recipe => recipe.id);

      const meals = mapValues(state.meals, meal => {
        const portions = meal.portions.map(portion => {
          let { recipe_id, root_recipe_id } = portion;
          recipe_id = root_recipe_id || recipe_id;

          if (getters.is_favorite(portion)) {
            return { ...portion, is_favorite: true, favoritable: true };
          } else if (recipe_id && !all_recipe_ids.includes(recipe_id)) {
            // this is a portion for a recipe that was subsequently deleted
            return { ...portion, favoritable: false };
          } else {
            return portion;
          }
        });

        return { ...meal, portions };
      });

      commit("UPDATE_MEALS", meals);
    },
    async update_meal_planner_settings({ state, commit }, settings) {
      const date_param = settings.date ? settings.date : settings.workout_time && state.selected_date;

      const { data } = await API.update_meal_planner_settings(settings, date_param);

      commit("UPDATE_MEAL_PLANNER_SETTINGS", data);

      if (data.day_type || data.workout_time) {
        commit("UPDATE_DAY_DETAILS", data);
      }

      if (data.meals) {
        commit("UPDATE_MEAL_TARGETS", data.meals);
      }
    },
    log_food_portion({ dispatch }, { food, meal_id, use_conversion = false }) {
      dispatch("create_portion", { portion: build_food_portion(food, use_conversion), meal_id });
    },
    async log_recipe_portions({ dispatch }, { recipe, meal_id }) {
      let portions;
      if (recipe.child_recipes.length > 0) {
        portions = recipe.child_recipes.map(child_recipe => (
          build_recipe_portion({ ...child_recipe, root_recipe_id: recipe.id })
        ));
      } else {
        portions = [build_recipe_portion(recipe)];
      }

      for (const portion of portions) {
        // Ensure MealCard portions watcher is immediately triggered upon ADD_PORTION.
        // See: https://stackoverflow.com/questions/56164247/vuex-two-commits-in-same-action-does-not-trigger-watch
        //
        // NOTE: interestingly, this seems to be a problem even for a simple recipe (single portion),
        // like merely the *potential* for multiple ADD_PORTION commits causes the watcher to wait?
        await Vue.nextTick();

        dispatch("create_portion", { portion, meal_id });
      }
    },
    log_custom_food_portion({ dispatch }, { food, meal_id }) {
      dispatch("create_portion", { portion: build_custom_food_portion(food), meal_id });
    },
    log_fmf_food_portion({ dispatch }, { food, meal_id }) {
      dispatch("create_portion", { portion: build_fmf_food_portion(food), meal_id });
    },
    async create_portion({ state, getters, commit, dispatch }, { portion, meal_id }) {
      // optimistically add portion so it immediately appears in food log and then...
      const temp_id = uuid();
      const optimistic_portion = {
        ...portion,
        id: temp_id,
        is_favorite: getters.is_favorite(portion)
      };
      commit("ADD_PORTION", { meal_id, portion: optimistic_portion });

      try {
        const { data: { id, ingredient_id } } = await API.create_portion(
          portion,
          state.meals[meal_id],
          state.selected_date
        );

        // ... set its id and ingredient_id upon receipt from the API
        commit("PERSIST_PORTION", { meal_id, temp_id, id, ingredient_id });

        // MAYBE: move dispatch of success notifications from components here
      } catch (e) {
        commit("REMOVE_PORTIONS", { meal_id, portion_ids: [optimistic_portion.id] });

        dispatch("notify_user", {
          type: "error",
          msg: "There was an error saving your food."
        }, { root: true });

        console.error(e);
      }
    },
    update_portion({ state, commit }, { portion, meal_id, persist }) {
      const servings = portion_quantity_to_servings(portion, portion.serving_size);

      const macros = flow(
        pickFp(["carbohydrates", "protein", "fat", "calories"]),
        macros => multiply_macros(macros, servings),
        macros => ({ ...macros, calories: calculate_calories(macros) })
      )(portion.ingredient);

      const updated_portion = { ...portion, ...macros, servings };

      commit("UPDATE_PORTION", { updated_portion, meal_id });

      if (persist) {
        API.update_portion(portion, state.meals[meal_id], state.selected_date);
      }
    },
    set_portions_to_delete({ commit }, { meal_id, portions }) {
      commit("SET_PORTIONS_TO_DELETE", { meal_id, portions });
    },
    clear_portions_to_delete({ commit }) {
      commit("SET_PORTIONS_TO_DELETE", null);
    },
    async delete_portions({ state, commit, dispatch }) {
      const { meal_id, portions } = state.portions_to_delete;
      const portion_ids = portions.map(({ id }) => id);

      // optimistically update UI
      commit("REMOVE_PORTIONS", { meal_id, portion_ids });
      commit("SET_PORTIONS_TO_DELETE", null);

      const results = await Promise.all(
        portion_ids.map(async (id) => {
          try {
            return await API.delete_portion(id);
          } catch (e) {
            console.error(e);
            return id;
          }
        })
      );

      const failed_portion_ids = results.filter(result => typeof result === "number");
      const failed_portions = portions.filter(portion => failed_portion_ids.includes(portion.id));

      if (failed_portions.length > 0) {
        let msg;
        if (failed_portions.length === portions.length && portions.length > 1) {
          msg = "portions";
        } else if (failed_portions.length === 1) {
          msg = "portion";
        } else {
          msg = "some portions";
        }
        dispatch("notify_user", { type: "error", msg: `Unable to delete ${msg}.` }, { root: true });

        failed_portions.map(portion => commit("ADD_PORTION", { meal_id, portion }));
      }
    },
    initialize_upgrade_modal({ commit, state }) {
      const { user: { terms_and_conditions_accepted_at, meals_opted_into_at, stripe_plan_id, id }} = state;
      const key = `upgradeModalLastShownAt_${id}`;
      if (terms_and_conditions_accepted_at && meals_opted_into_at && stripe_plan_id === "macrostax_monthly") {
        // see if we should show the modal based on whether it's already been displayed,
        // and how many days it's been since they accepted T&Cs.
        const now = moment();
        const tcs = moment(terms_and_conditions_accepted_at);
        const daysSinceFirstLogin = now.diff(tcs, 'days');

        // when a user dismisses the modal we'll store the timestamp in localStorage
        let lastSeenDate = undefined;
        const lastSeen = getLocalStorage(key);
        if (lastSeen) {
          lastSeenDate = moment(lastSeen);
        }
        
        // we know how many days it's been since they first logged in and accepted T&Cs
        // now iterate over each of our messages and find the appropriate one
        let msg = undefined;
        let firstDisplayDate;
        upgrade_messaging.forEach(m => {
          const { days } = m;
          if (daysSinceFirstLogin >= days) {
            msg = m;
            firstDisplayDate = tcs.clone().add(days, 'days'); // we shouldn't show it before this date
          }
        });

        // if we found a message that we may want to display, we only want to display it if the user hasn't already dismissed it
        if (msg && (lastSeenDate === undefined || lastSeenDate.isBefore(firstDisplayDate, 'days'))) {
          if (msg.message.indexOf('{{savings}}') > -1) {
            const { billing: { prices }} = state;
            const monthly = prices.find((p) => p.product === process.env.STRIPE_MONTHLY_SUBSCRIPTION_PRODUCT_ID);
            const yearly = prices.find((p) => p.product === process.env.STRIPE_ANNUAL_SUBSCRIPTION_PRODUCT_ID);
            const yearlyPrice = (yearly.unit_amount * (msg.percent_off / 100)) / 100;
            const monthlyPrice = (monthly.unit_amount * 12) / 100;
            const savings = monthlyPrice - yearlyPrice;
            msg.message = msg.message.replace('{{savings}}', `$${savings}`);
            msg.message = msg.message.replace('{{yearly_price}}', `$${yearlyPrice}`);
          }
          commit('SET_UPGRADE_MESSAGE', msg);
          commit('SHOW_UPGRADE_MODAL', true);
        }
      }
      
    },
    dismiss_upgrade_modal({ commit, state }, localStorage) {
      commit("SHOW_UPGRADE_MODAL", false);
      if (localStorage) {
        const { user: { id }} = state;
        const key = `upgradeModalLastShownAt_${id}`;
        setLocalStorage(key, new Date());
      }
    },
    async create_custom_food({ state, commit }, custom_food) {
      const { data: { ingredient: { id } } } = await API.create_custom_food(custom_food);

      const custom_foods = [...state.custom_foods, { ...custom_food, id }];

      commit("SET_CUSTOM_FOODS", custom_foods);
      return id;
    },
    async update_custom_food({ state, commit }, custom_food) {
      await API.update_custom_food(custom_food.id, custom_food);

      const custom_foods = state.custom_foods.map(food => (
        food.id === custom_food.id ? { ...food, ...custom_food } : food
      ));

      commit("SET_CUSTOM_FOODS", custom_foods);
    },
    async delete_custom_food({ state, commit }, food_id) {
      // optimistically update UI
      commit("SET_CUSTOM_FOODS", state.custom_foods.filter(food => food.id !== food_id));

      API.delete_custom_food(food_id);
    },
    async delete_custom_recipe({ state, commit }, recipe_id) {
      await API.delete_custom_recipe(recipe_id);

      commit("UPDATE_DELETED_RECIPE_PORTIONS", recipe_id)
    },
    async favorite_custom_food({ state, commit, dispatch }, ingredient_id) {
      await API.update_custom_food(ingredient_id, { is_favorite: true });

      const custom_foods = state.custom_foods.map(food => (
        food.ingredient_id === ingredient_id ? { ...food, is_favorite: true } : food
      ));

      commit("UPDATE_FAVORITE_PORTIONS", { ingredient_id, is_favorite: true });
      commit("SET_CUSTOM_FOODS", custom_foods);
    },
    async unfavorite_custom_food({ state, commit, dispatch }, ingredient_id) {
      await API.update_custom_food(ingredient_id, { is_favorite: false });

      const custom_foods = state.custom_foods.map(food => (
        food.ingredient_id === ingredient_id ? { ...food, is_favorite: false } : food
      ));

      commit("SET_CUSTOM_FOODS", custom_foods);
      commit("UPDATE_FAVORITE_PORTIONS", { ingredient_id, is_favorite: false });
    },
    async log_nutrient_intake({ state, getters }) {
      // REVIEW: huh? this data is all already persisted in the Rails DB... wtf is the point of this?
      const nutrient_intake = { ...state.day_details, ...getters.macros_logged_for_day };
      await API.log_nutrient_intake(nutrient_intake);
    },
    async copy_meal({ state, commit }, { source_date, target_date, source_meal_id, target_meal_id }) {

      // NOTE: null source_meal_id => copy all meals
      const { data: { meal_portions } } = await API.copy_meal(
        source_date,
        target_date,
        source_meal_id,
        target_meal_id
      );

      if (target_date === state.selected_date) {
        forEach(meal_portions, (portions, meal_id) => {
          portions.forEach(portion => commit("ADD_PORTION", { meal_id, portion }));
        });
      }
    },
    async update_user_statistic_set({ state, commit }, statistic_set_attrs) {
      const {
        data: { statistic_set, meal_planner_settings, meal_targets }
      } = await API.update_user_statistic_set(statistic_set_attrs, state.selected_date);


      commit("UPDATE_STATISTIC_SET", statistic_set);
      commit("UPDATE_MEAL_PLANNER_SETTINGS", meal_planner_settings);

      if (meal_targets) {
        commit("UPDATE_MEAL_TARGETS", meal_targets);
      }

      return Promise.resolve();
    },
    async update_user_preferences({ state, commit }, new_preferences) {
      const preferences = { ...state.user.preferences, ...new_preferences };
      await API.update_user_preferences(state.user.id, preferences);

      commit("UPDATE_USER", { preferences });

      return Promise.resolve();
    },
    async toggle_dietary_restriction({ state, commit }, restriction_id) {
      let restriction_ids = state.user.dietary_restriction_ids;
      if (restriction_ids.includes(restriction_id)) {
        restriction_ids = restriction_ids.filter(id => id !== restriction_id);
      } else {
        restriction_ids = [...restriction_ids, restriction_id];
      }
      await API.update_dietary_restrictions(restriction_ids);

      commit("UPDATE_USER", { dietary_restriction_ids: restriction_ids });
    },
    async update_user_credentials({ state, commit }, { email, current_password, new_password }) {
      await API.update_user_credentials(email, current_password, new_password);

      commit("UPDATE_USER", { email });

      return Promise.resolve();
    },
    async fetch_mtc_challenge({ commit }) {
      try {
        const response = await API.get_transformation_challenges();
        if (response.status !== 204) {
          commit("SET_MTC_CHALLENGE", response.data);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async enroll_in_mtc_challenge({ state, commit }, { default_goals, custom_goals }) {
      const default_goal_ids = default_goals.map(goal => goal.id);
      const custom_goal_values = custom_goals.map(goal => goal.value);

      await API.enroll_in_transformation_challenge(state.mtc_challenge.id, default_goal_ids, custom_goal_values);

      commit("UPDATE_MTC_USER_DATA", { enrolled: true, default_goals, custom_goals });
    },
    async update_user_mtc_goals({ state, dispatch, commit }, { default_goals, custom_goals }) {
      const default_goal_ids = default_goals.map(goal => goal.id);

      await Promise.all([
        API.update_user_transformation_goals(default_goal_ids),
        dispatch("update_user_mtc_custom_goal", custom_goals[0])
      ]);

      commit("UPDATE_MTC_USER_DATA", { default_goals, custom_goals });
    },
    async update_user_mtc_custom_goal({ state }, custom_goal) {
      const params = custom_goal && {
        transformation_challenge_id: state.mtc_challenge.id,
        value: custom_goal.value
      };

      if (!custom_goal) {
        return API.delete_transformation_goals();
      } else if (custom_goal.editable && custom_goal.custom) {
        return API.update_transformation_goal(custom_goal.id, params);
      } else {
        return API.create_transformation_goal(params);
      }
    },
    async getBillingData({ commit }) {
      try {
        const response = await API.getBillingData();
        commit("SET_BILLING_DATA", response.data);
      } catch (e) {
        console.error(e);
      }
    },
    async getCancellationReasons({ commit }) {
      try {
        const response = await API.getCancellationReasons();
        commit("SET_CANCELLATION_REASON_DATA", response.data);
      } catch (e) {
        console.error(e);
      }
    },
    setCanceledSubscription({ commit }, data) {
      commit("SET_CANCELED_SUBSCRIPTION", data);
    },
    setReactivatedSubscription({ commit }, data) {
      commit("SET_REACTIVATED_SUBSCRIPTION", data);
    },
    updateCreditCard({ commit }, data) {
      commit("UPDATE_CREDIT_CARD", data);
    },
    updateCancellation({ commit }, data) {
      commit('UPDATE_CANCELLATION', data)
    },
    updateSubscriptionPlan({ commit }, data) {
      commit('UPDATE_SUBSCRIPTION_PLAN', data)
    }
  },
  mutations: {
    START_LOADING(state, query_type) { state.loading = { ...state.loading, [query_type]: true }; },
    END_LOADING(state, query_type) { state.loading = { ...state.loading, [query_type]: false }; },
    INITIALIZE_STATE(state, rails_data) {
      Object.assign(state, rails_data);
    },
    SET_CUSTOM_FOODS(state, custom_foods) { state.custom_foods = custom_foods; },
    ADD_PORTION(state, { meal_id, portion }) {
      const meal = state.meals[meal_id];

      state.meals[meal_id] = { ...meal, portions: [...meal.portions, portion] };
    },
    PERSIST_PORTION(state, { meal_id, temp_id, id, ingredient_id }) {
      const meal = state.meals[meal_id];

      state.meals[meal_id] = {
        ...meal,
        portions: meal.portions.map(portion => (
          portion.id === temp_id ? { ...portion, id, ingredient_id } : portion
        ))
      };
    },
    UPDATE_PORTION(state, { meal_id, updated_portion }) {
      const meal = state.meals[meal_id];

      state.meals[meal_id] = {
        ...meal,
        portions: meal.portions.map(portion => (
          portion.id === updated_portion.id ? updated_portion : portion
        ))
      };
    },
    SET_PORTIONS_TO_DELETE(state, meal_portions) {
      state.portions_to_delete = meal_portions;
    },
    REMOVE_PORTIONS(state, { meal_id, portion_ids }) {
      const meal = state.meals[meal_id];
      state.meals[meal_id] = { ...meal, portions: meal.portions.filter(({ id }) => !portion_ids.includes(id)) };
    },
    SHOW_UPGRADE_MODAL(state, val) {
      state.show_upgrade_modal = val;
    },
    SET_UPGRADE_MESSAGE(state, val) {
      state.upgrade_message = val;
    },
    UPDATE_MEALS(state, meals) {
      state.meals = meals;
    },
    UPDATE_MEAL_TARGETS(state, mealTargets) {
      state.meals = mapValues(state.meals, (meal, id) => (
        {
          ...meal,
          target: mapKeys(mealTargets[id], (val, macro) => macro.replace("target_", ""))
        }
      ));
    },
    UPDATE_DAY_DETAILS(state, day_details) {
      state.day_details = { ...state.day_details, ...day_details };
    },
    UPDATE_MEAL_PLANNER_SETTINGS(state, settings) {
      state.meal_planner_settings = { ...state.meal_planner_settings, ...settings };
    },
    UPDATE_FAVORITE_PORTIONS(state, { is_favorite, food_id, recipe_id, ingredient_id }) {
      state.meals = mapValues(state.meals, meal => {
        const portions = meal.portions.map(portion => {
          if (food_id && food_id === portion.food_id ||
              recipe_id && [portion.recipe_id, portion.root_recipe_id].includes(recipe_id) ||
              ingredient_id === portion.ingredient_id) {
            return { ...portion, is_favorite };
          }
          return portion;
        });

        return { ...meal, portions };
      });
    },
    UPDATE_DELETED_RECIPE_PORTIONS(state, recipe_id) {
      state.meals = mapValues(state.meals, meal => {
        const portions = meal.portions.map(portion => {
          if (portion.recipe_id === recipe_id) {
            return { ...portion, is_favorite: false, favoritable: false, recipe_id: -recipe_id };
          }
          return portion;
        });

        return { ...meal, portions };
      });
    },
    UPDATE_USER(state, user) {
      state.user = { ...state.user, ...user };
    },
    UPDATE_STATISTIC_SET(state, statistic_set) {
      state.statistic_set = { ...state.statistic_set, ...statistic_set };
    },
    SET_MTC_CHALLENGE(state, mtc_challenge) {
      state.mtc_challenge = mtc_challenge;
    },
    UPDATE_MTC_USER_DATA(state, user_data) {
      state.mtc_challenge = {
        ...state.mtc_challenge,
        user_data: { ...state.mtc_challenge.user_data, ...user_data }
      };
    },
    SET_BILLING_DATA(state, data) {
      state.billing = { ...state.billing, ...data }
    },
    SET_CANCELLATION_REASON_DATA(state, data) {
      state.cancellation_reasons = data.cancellation_reasons;
    },
    SET_CANCELED_SUBSCRIPTION(state, data) {
      state.billing.cancellation = data.cancellation;
      state.billing.subscription.plan.canceled = true;
    },
    SET_REACTIVATED_SUBSCRIPTION(state, data) {
      state.billing.cancellation = data.cancellation;
      state.billing.subscription.plan.canceled = false;
    },
    UPDATE_CREDIT_CARD(state, data) {
      state.billing.card = { ...state.billing.card, ...data };
    },
    UPDATE_CANCELLATION(state, data) {
      state.billing.cancellation = {...state.data, ...data.cancellation};
    },
    UPDATE_SUBSCRIPTION_PLAN(state, data) {
      state.billing.subscription.next_bill_date = data.subscription.next_bill_date
      state.billing.subscription.plan = {...state.billing.subscription.plan, ...data.subscription.plan};
    },
  }
};
