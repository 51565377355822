<template>
  <div id="macro-intake" class="dashboard-section">
    <header>
      <span class="title">Daily Macronutrient Intake</span>
      <img :src="chart_icon" />
    </header>

    <!-- REVIEW: this is in rails template but it seems the condition `unless dashboard` is *always* false (?) so it isn't rendered -->
    <p v-if="!last_intake" class="explanation">
      Add two or more macronutrient intakes to track your own progress with
      graphs.
    </p>

    <div class="content">
      <div v-if="last_intake" class="previous-intake">
        <p class="primary-gray-text-medium">
          {{ last_intake_header }}
        </p>

        <div class="macros">
          <div class="stat-row">
            <div class="stat-label">Carbs:</div>
            <div class="stat-value">{{ last_intake.carbohydrate }} g</div>
          </div>

          <div class="stat-row">
            <div class="stat-label">Protein:</div>
            <div class="stat-value">{{ last_intake.protein }} g</div>
          </div>

          <div class="stat-row">
            <div class="stat-label">Fat:</div>
            <div class="stat-value">{{ last_intake.fat }} g</div>
          </div>
        </div>
      </div>

      <div class="graph">
        <Chart
          v-if="nutrient_intake_chart"
          :options="nutrient_intake_chart"
          class="highcharts-container "
        />
        <img v-else :src="chart_placeholder" />
      </div>
    </div>

    <footer>
      <a href="/inputs/intake" class="button">
        <span>Add Intake</span>
      </a>
    </footer>
  </div>
</template>

<script>
import { capitalize } from "lodash";
import { Chart } from "highcharts-vue";

import chart_icon from "@images/chart.svg";
import chart_placeholder from "@images/nutrient-intake-chart-placeholder.png";

export default {
  components: { Chart },
  data() {
    return {
      chart_icon,
      chart_placeholder,
    };
  },
  computed: {
    dashboard() {
      return this.$store.state.rails.dashboard;
    },
    nutrient_intakes() {
      return this.$store.state.rails.nutrient_intakes;
    },
    last_intake() {
      // array is in descending order
      return this.nutrient_intakes[0];
    },
    last_intake_header() {
      return `Last intake on ${moment(this.last_intake.date).format(
        "MMMM Do, YYYY"
      )} (${capitalize(this.last_intake.day_type)} Day):`;
    },
    nutrient_intake_chart() {
      if (this.nutrient_intakes.length < 2) {
        return null;
      }
      const {
        series_data,
        options: { title, xAxis, yAxis, colors },
      } = this.dashboard.nutrient_intake_chart;

      // HighCharts API: https://api.highcharts.com/highcharts/
      return {
        title,
        xAxis,
        yAxis,
        colors,
        series: series_data,
        credits: false
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@css/components/inputs";
.previous-intake {
  width: 100%;
  margin-bottom: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .macros {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }

  .stat-row {
    display: flex;
    align-items: baseline;
  }
}
</style>
