<template lang="html">
  <div id="mtc-button-container">
    <router-link v-if="visible" :to="route" id="mtc-button">
      <img
        src="https://sfo2.digitaloceanspaces.com/icons/mtc-award.svg"
        class="mtc-action-button-icon"
        alt="Open MTC"
      />
    </router-link>
  </div>
</template>

<script>
import API from "@js/api/rails_client";

export default {
  props: {
    challenge_data: { type: Object, required: true }
  },
  mounted() {
    this.check_force_show();
  },
  computed: {
    user() {
      return this.$store.state.rails.user;
    },
    visible() {
      const { active, signup_deadline, user_data: { enrolled } } = this.challenge_data;
      const before_deadline = moment().isBefore(signup_deadline);

      if (this.user.admin) {
        return true;
      }

      return active && (enrolled || before_deadline);
    },
    route() {
      const user = this.user;
      const { start_date, user_data: { enrolled } } = this.challenge_data;

      let path;
      if (!enrolled) {
        path = "/mtc_signup";
      } else if (moment().isBefore(start_date)) {
        path = "/mtc_enrolled";
      } else {
        path = "/mtc";
      }

      return { path, params: { data: this.challenge_data, user: this.user } };
    }
  },
  methods: {
    check_force_show() {
      // first time a user logs on when there is an active challenge
      // they will be shown the MTC signup page
      if (this.visible && this.user.preferences.prompt_mtc) {
        this.$store.dispatch("rails/update_user_preferences", { prompt_mtc: false });
        this.$router.push({ path: "/mtc_signup" });
      }
    },
  }
};
</script>

<style scoped lang="scss">
#mtc-button-container {
  width: 100%;
  max-width: 450px;
  position: fixed;
  bottom: calc(56px + 12px + 70px);
  z-index: 4;
  transition: bottom 0.3s;
}
#mtc-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  bottom: 0;
  margin-bottom: 12px;
  width: 56px;
  height: 56px;
  border-color: $endurance;
  background: #FFFFFF;
  border: 4px solid #6B40AB;
  border-radius: 50%;
  font-size: 20px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.20), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  transition: bottom 0.3s;
  cursor: pointer;
  z-index: 5;

  .mtc-action-button-icon {
    width: 24px;
    height: 24px;
  }
}
</style>
