import moment from 'moment';
import API from "@js/api/rails_client";

function updateActiveCampaign() {
  let ac_activity = sessionStorage.getItem("ac_activity");

  if (moment().isAfter(moment(ac_activity), "day")) {
    remoteCall();
  } else if (!ac_activity) {
    remoteCall();
  } else {
    // console.log("no need to update the user, same day")
  }
}

async function remoteCall() {
  try {
    await API.update_active_campaign();
    sessionStorage.setItem('ac_activity', moment().format());
  } catch(e) {
    sessionStorage.removeItem('ac_activity');
  }
}

function setActiveCampaignListeners() {
  updateActiveCampaign();
  window.addEventListener("focus", updateActiveCampaign);
  // mobile specific
  window.addEventListener("tabsactivate", updateActiveCampaign);
  window.addEventListener("load", updateActiveCampaign);
}

export default setActiveCampaignListeners;