<template lang="html">
  <div class="modal-backdrop">
    <div class="upgrade-modal">
      <span class="modal-header">{{ upgrade_message.title }}</span>
      <span class="modal-text" v-html="upgrade_message.message" />
      <div class="buttons">
        <span @click="dismiss">DISMISS</span>
        <router-link :to="'/billing/upgrade?coupon=' + upgrade_message.coupon" @click.native="dismiss">
          SAVE NOW
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { },
  computed: {
    upgrade_message() {
      return this.$store.state.rails.upgrade_message;
    },
  },
  methods: {
    dismiss(track) {
      this.$store.dispatch("rails/dismiss_upgrade_modal", true);
      if (track && typeof gtag !== 'undefined') {
        gtag('event', 'click', {
          'event_category': 'Monthly Upgrade Prompt',
          'event_label': 'Clicked Save Now Button',
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/base/_variables.scss";

.modal-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
  -webkit-overflow-scrolling: touch;
  z-index: 100;
}

.upgrade-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  padding: 18px 20px 14px 20px;
  width: 85%;
  max-width: 375px;

  .modal-header {
    align-self: flex-start;
    color: black;
    font-size: 16px;
    font-weight: 600;
    font-family: Montserrat;
    margin-bottom: 18px;
  }

  .modal-text {
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .buttons {
    display: flex;
    align-self: flex-end;
    cursor: pointer;

    span, a {
      font-family: Montserrat;
      color: $endurance;
      font-size: 15px;
      font-weight: 700;
      padding: 14px 0px;
      margin-left: 14px;
    }
    span {
      &:first-of-type {
        margin-right: 14px;
      }
    }
  }
}
</style>
