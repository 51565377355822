export default `
id
user_id
name
photo_urls {
  small
  large
}
is_discrete
is_miscible
is_bulk
bulk_make_qty
custom_serving_name
quantity
make_quantity
eat_quantity
cooked_weight_grams
cook_loss_factor
intro_line
prep
prep_time
table_time
notes
`;
