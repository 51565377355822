import Vue from "vue";
import Vuex from "vuex";
import gql from "graphql-tag";

import rails from "@js/store/rails";
import apolloClient from "@js/api/phx_apollo_client";

import all_tags_query from "@js/api/graphql/all_tags_query";
import frequents_query from "@js/api/graphql/frequents_query";
import favorites_query from "@js/api/graphql/favorites_query";
import restaurant_query from "@js/api/graphql/restaurant_query";
import matching_recipes_query from "@js/api/graphql/matching_recipes_query";
import custom_recipes_query from "@js/api/graphql/custom_recipes_query";
import recipe_fields from "@js/api/graphql/recipe_fields";
import conversion_fields from "@js/api/graphql/conversion_fields";

Vue.use(Vuex);

// Conditionally enable usage of devtools in staging/production.
// NOTE: devtools must be enabled before the Vuex store is created for it to be detected.
if (process.env.ENABLE_VUE_DEV_TOOLS) {
  // this defaults to process.env.NODE_ENV === "development"
  Vue.config.devtools = true;
}

let current_match;

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: { rails },
  state: {
    routeHistory: [],
    loading: {
      global: false,
      food_search: false,
      restaurant_search: false,
      recipe_search: false,
      recipe_match: false
    },
    notification: null,
    message_modal: null,
    nav_footer_notification: null,
    modalIsVisible: false,
    tags: [],
    macrostax_recipes: [],
    favorite_recipes: [],
    favorite_foods: [],
    featured_recipes: [],
    custom_recipes: [],
    restaurant: [],
    food_results: [],
    frequents: {},
    recipe_search_results: [],
    recipe_match_results: [],
    filters: [],
    selected_filter_ids: [],
    scanned_food: null,
    // save recipe builder state if the scanner is mounted, to rehydrate recipe builder state upon re-mounting it
    recipe_builder_form_state: null,
    csrf_token: document.getElementsByName("csrf-token")[0].content,
    cdn_url: "https://d15q7q9r6e6oen.cloudfront.net/phoenix"
  },
  getters: {
    selected_filters(state) {
      return state.filters.filter(filter => state.selected_filter_ids.includes(filter.id));
    },
    filter_params(state, getters) {
      return getters.selected_filters.map(filter => ({ id: filter.id, value: filter.value }));
    },
    user_default_filters(state) {
      const filter_names = {
        2: "has_gluten",
        3: "has_dairy",
        4: "is_vegetarian",
        5: "is_vegan",
        6: "is_pescatarian"
      };

      const active_filter_names = state.rails.user.dietary_restriction_ids.map(id => filter_names[id]);

      return state.filters.filter(filter => active_filter_names.includes(filter.name));
    },
    dietary_filters: ({ filters }) => {
      const tag_names = [
        "is_vegetarian",
        "is_vegan",
        "is_pescatarian",
        "has_dairy",
        "has_gluten"
      ];
      // less efficient than `filters.filter(...)` but preserves order for dropdown selects
      return tag_names.map(name => filters.find(filter => filter.name === name));
    },
    time_filters({ filters }) {
      // return tags.filter(tag => tag.type == "time") // doesn't quite match -> TODO recategorize tag records

      const tag_names = [
        "is_15_minutes",
        "is_30_minutes",
        "is_60_minutes"
      ];
      // less efficient than `filters.filter(...)` but preserves order for dropdown selects
      return tag_names.map(name => filters.find(filter => filter.name === name));
    },
    meal_filters({ filters }) {
      // return tags.filter(tag => tag.type == "meal_type") // doesn't quite match -> TODO recategorize tag records
      const tag_names = [
        "is_breakfast",
        "is_lunch",
        "is_snack",
        "is_dinner",
        "is_side",
        "is_dessert",
        "is_shake",
        "is_freezer_friendly",
        "is_kid_friendly",
        "is_budget_friendly",
        "is_holiday"
      ];
      // less efficient than `filters.filter(...)` but preserves order for dropdown selects
      return tag_names.map(name => filters.find(filter => filter.name === name));
    },
    cook_filters({ filters }) {
      // return tags.filter(tag => tag.type == "meal_kind") // doesn't quite match -> TODO recategorize tag records
      const tag_names = [
        "is_meal_prep",
        "is_slow_cooker",
        "is_no_cook",
        "is_instant_pot"
      ];
      // less efficient than `filters.filter(...)` but preserves order for dropdown selects
      return tag_names.map(name => filters.find(filter => filter.name === name));
    }
  },
  actions: {
    routeChanged({ state, commit }, route) {
      commit("UPDATE_ROUTE_HISTORY", [route, ...state.routeHistory.slice(0, 10)]);
    },
    loading({ commit }, load) {
      if (load) {
        commit("START_LOADING", "global");
      } else {
        commit("END_LOADING", "global");
      }
    },
    // TODO support multiple toast notifications
    notify_user({ state, commit }, { type, msg }) {
      state.notification && clearTimeout(state.notification.timer);

      const timer = setTimeout(() => commit("CLEAR_NOTIFICATION"), 3000);

      commit("SET_NOTIFICATION", { type, msg, timer });
    },
    showMessageModal({ state, commit }, props) {
      state.message_modal && clearTimeout(state.message_modal.timer);

      const timer = setTimeout(() => commit("CLOSE_MESSAGE_MODAL"), 6000);

      commit("SET_MESSAGE_MODAL", { props, timer });
    },
    close_message_modal({ commit }) {
      commit("CLOSE_MESSAGE_MODAL");
    },
    // TODO consolidate nav_footer_notification logic/state with ^
    nav_footer_notification({ state, commit }, nav_route) {
      commit("SET_NAV_FOOTER_NOTIFICATION", nav_route);
    },
    set_modal_visible({ commit }, isVisible) {
      commit("SET_MODAL_VISIBLE", isVisible);
    },
    async fetch_foods({ state, commit }, search_term) {
      if (!search_term) {
        commit("SET_FOOD_RESULTS", []);
      } else {
        commit("START_LOADING", "food_search");

        const conversion_fields = `
          id
          name
          is_default
          carb_grams
          protein_grams
          fat_grams
          calories
          weight_grams
          default_serving_qty
        `;

        const { data: { foods } } = await apolloClient.query({
          query: gql`
            query Foods($search_term: String!, $user_id: Int!) {
              foods(search_term: $search_term, user_id: $user_id) {
                id
                name
                brand_name
                nix_photo_url
                quantity
                is_favorite
                conversion {
                  ${conversion_fields}
                }
                conversions {
                  ${conversion_fields}
                }
              }
            }
          `,
          variables: { search_term, user_id: state.rails.user.id },
          fetchPolicy: "no-cache"
        });

        commit("SET_FOOD_RESULTS", foods);
        commit("END_LOADING", "food_search")
      }
    },
    async fetch_food_by_barcode({ commit }, barcode) {
      const { data: { scanned_food } } = await apolloClient.query({
        query: gql`
          query ScannedFood($barcode: String!) {
            scanned_food(barcode: $barcode) {
              id
              name
              brand_name
              quantity
              conversion {
                id
                name
                is_default
                carb_grams
                protein_grams
                fat_grams
                calories
                weight_grams
                default_serving_qty
              }
              conversions {
                id
                name
                is_default
                carb_grams
                protein_grams
                fat_grams
                calories
                weight_grams
                default_serving_qty
              }
            }
          }
        `,
        fetchPolicy: "no-cache",
        variables: { barcode }
      });

      commit("SET_SCANNED_FOOD", scanned_food);

      return !!scanned_food;
    },
    unset_scanned_food({ commit }) {
      commit("SET_SCANNED_FOOD", null);
    },
    async fetch_restaurant({ commit, state, getters }, search_term) {
      if (!search_term) {
        commit("SET_RESTAURANT", []);
      } else {
        commit("START_LOADING", "recipe_search");
        return apolloClient
          .query({
            query: restaurant_query,
            variables: {
              search_term: search_term,
              user_id: state.rails.user.id
            },
            fetchPolicy: "no-cache"
          })
          .then(response => {
            commit("SET_RESTAURANT", response.data.restaurant);
          })
          .finally(() => commit("END_LOADING", "food_search"));
      }
    },
    fetch_recipes({ state, getters, commit }, search_params = {}) {
      commit("START_LOADING", "recipe_search");

      return apolloClient
        .query({
          query: gql`
            query KitchenRecipes(
              $search_term: String
              $tag_filters: [TagFilter]
              $user_id: Int!
              $featured_first: Boolean
            ) {
              kitchen_recipes(
                search_term: $search_term
                tag_filters: $tag_filters
                user_id: $user_id
                featured_first: $featured_first
                load_children: false
                randomize_order: true
              ) {
                id
                name
                is_favorite
                is_simple
                photo_urls {
                  small
                }
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            search_term: search_params.search_term,
            user_id: state.rails.user.id,
            featured_first: search_params.featured_first,
            ...(search_params.use_tag_filters && {
              tag_filters: getters.filter_params
            })
          }
        })
        .then(response => {
          if (search_params.initial_fetch) {
            // populate the full set of kitchen recipes to use as the default results in the
            // search component recipes tab (absent a search_term)
            commit("SET_MACROSTAX_RECIPES", response.data.kitchen_recipes);
          } else {
            commit("SET_RECIPE_SEARCH_RESULTS", response.data.kitchen_recipes);
          }
        })
        .finally(() => commit("END_LOADING", "recipe_search"));
    },
    fetch_matching_recipes({ state, getters, commit }, { macro_targets, strict_match }) {
      commit("START_LOADING", "recipe_match");

      // TODO current_match timestamp logic needs refactoring
      let timestamp = moment().valueOf();
      current_match = timestamp;

      apolloClient.query({
        query: matching_recipes_query,
        fetchPolicy: "no-cache",
        variables: {
          macro_targets,
          strict_match,
          tag_filters: getters.filter_params,
          user_id: state.rails.user.id
        }
      })
        .then((response) => {
          if (timestamp === current_match) {
            commit("SET_RECIPE_MATCH_RESULTS", response.data.kitchen_recipes);
          }
        })
        .catch((error) => {
          commit("SET_RECIPE_MATCH_RESULTS", []);
          // TODO toast notification
          // _util.genericVuexError(error);
        })
        .finally(() => {
          if (timestamp === current_match) {
            commit("END_LOADING", "recipe_match");
          }
        });
    },
    async fetch_custom_recipes({ state, commit }) {
      return apolloClient.query({
        query: custom_recipes_query,
        fetchPolicy: "no-cache",
        variables: { user_id: state.rails.user.id }
      })
        .then(response => commit("SET_CUSTOM_RECIPES", response.data.custom_recipes))
        .catch(error => console.log(error));
    },
    fetch_filters({ commit, dispatch }) {
      const tags_to_invert = ["has_gluten", "has_dairy"];

      apolloClient.query({ query: all_tags_query }).then((response) => {
        const filters = response.data.tags.map(tag => {
          const inverted = tags_to_invert.includes(tag.name);

          return {
            id: tag.id,
            name: tag.name,
            display_name: inverted ? tag.inverse_name : tag.display_name,
            value: !inverted
          };
        });

        commit("SET_FILTERS", filters);
      });
    },
    select_filter({ commit }, filter_id) { commit("SELECT_FILTER", filter_id); },
    select_filters({ commit }, filter_ids) {
      filter_ids.forEach(id => commit("SELECT_FILTER", id));
    },
    select_user_default_filters({ dispatch, getters}) {
      return dispatch("select_filters", getters.user_default_filters.map(({ id }) => id));
    },
    deselect_filter({ commit }, filter_id) { commit("DESELECT_FILTER", filter_id); },
    deselect_filters({ getters, commit }, filter_ids) {
      filter_ids.forEach(id => commit("DESELECT_FILTER", id));
    },
    deselect_all_filters({ commit }) { commit("DESELECT_ALL_FILTERS"); },
    async fetch_frequents({ state, commit, getters }, id) {
	 // Note: macro_targets is a hack until we have sane default portions and/or support saving user-preferred portions
	  const macro_targets = getters['rails/default_portion_macro_targets'];
	  return apolloClient.query({
	    query: frequents_query,
	    variables: { user_id: state.rails.user.id, macro_targets },
	    fetchPolicy: 'no-cache'
	  })
	  .then(response => {
	    const {
	      frequent_foods: foods,
	      frequent_restaurant_foods: restaurant,
		  frequent_recipes: recipes
	    } = response.data;
	    commit('SET_FREQUENTS', { foods, restaurant, recipes });
	  });
	},
    async unset_frequents({ state, commit }) {
	  commit('SET_FREQUENTS', {});
    },
    async fetch_favorites({ commit, state, getters }) {
      // Note: macro_targets is a hack until we have sane default portions and/or support saving user-preferred portions
      const macro_targets = getters["rails/default_portion_macro_targets"];

      return apolloClient.query({
        query: favorites_query,
        variables: { user_id: state.rails.user.id, macro_targets },
        fetchPolicy: "no-cache"
      })
        .then((response) => {
          commit("SET_FAVORITE_FOODS", response.data.favorite_foods);
          commit("SET_FAVORITE_RECIPES", response.data.favorite_recipes);
        });
    },
    async favorite_recipe({ state, commit, dispatch }, { id, macro_targets }) {
      // Note: macro_targets is a hack until we have sane default portions and/or support saving user-preferred portions
      const { data } = await apolloClient.mutate({
        mutation: gql`
        mutation FavoriteRecipe($user_id: Int!, $recipe_id: Int!, $macro_targets: MacroTargets!) {
          favorite_recipe(user_id: $user_id, recipe_id: $recipe_id, macro_targets: $macro_targets) {
            ${recipe_fields}
            is_favorite
            conversion {
              ${conversion_fields}
            }
            conversions {
              ${conversion_fields}
            }
            child_recipes {
              ${recipe_fields}
              conversion {
                ${conversion_fields}
              }
              conversions {
                ${conversion_fields}
              }
            }
          }
        }
      `,
        variables: {
          user_id: state.rails.user.id,
          recipe_id: id,
          ...(macro_targets && { macro_targets })
        }
      });

      commit("rails/UPDATE_FAVORITE_PORTIONS", { recipe_id: id, is_favorite: true });
      commit("SET_FAVORITE_RECIPES", [data.favorite_recipe, ...state.favorite_recipes]);
      commit("UPDATE_MACROSTAX_RECIPES", { id, is_favorite: true });
    },
    async unfavorite_recipe({ state, commit }, id) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation UnfavoriteRecipe($user_id: Int!, $recipe_id: Int!) {
            unfavorite_recipe(user_id: $user_id, recipe_id: $recipe_id)
          }
        `,
        variables: { user_id: state.rails.user.id, recipe_id: id }
      });

      commit("rails/UPDATE_FAVORITE_PORTIONS", { recipe_id: id, is_favorite: false });
      commit("SET_FAVORITE_RECIPES", state.favorite_recipes.filter(recipe => recipe.id !== id));
      commit("UPDATE_MACROSTAX_RECIPES", { id, is_favorite: false });
    },
    async favorite_food({ state, commit }, { id, serving_qty, conversion_name }) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
        mutation FavoriteFood($user_id: Int!, $food_id: Int!, $serving_qty: Decimal!, $conversion_name: String!) {
          favorite_food(user_id: $user_id, food_id: $food_id, serving_qty: $serving_qty, conversion_name: $conversion_name) {
            id
            name
            brand_name
            nix_photo_url
            quantity
            is_favorite
            conversion {
              ${conversion_fields}
            }
            conversions {
              ${conversion_fields}
            }
          }
        }
      `,
        variables: {
          user_id: state.rails.user.id,
          food_id: id,
          serving_qty,
          conversion_name
        }
      })
      const food = data.favorite_food;
      let favorite_foods = state.favorite_foods;
      const i = favorite_foods.findIndex(food => food.id === id);

      // if we're updating existing favorite, replace it in array - otherwise append it to beginning
      if (i > -1) {
        favorite_foods[i] = food;
      } else {
        favorite_foods = [food].concat(favorite_foods);
      }

      commit("rails/UPDATE_FAVORITE_PORTIONS", { food_id: id, is_favorite: true });
      commit("SET_FAVORITE_FOODS", favorite_foods);
    },
    async unfavorite_food({ state, commit, dispatch }, id) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation UnfavoriteFood($user_id: Int!, $food_id: Int!) {
            unfavorite_food(user_id: $user_id, food_id: $food_id)
          }
        `,
        variables: { user_id: state.rails.user.id, food_id: id }
      })

      commit("rails/UPDATE_FAVORITE_PORTIONS", { food_id: id, is_favorite: false });
      commit("SET_FAVORITE_FOODS", state.favorite_foods.filter(food => food.id !== id));
    },
    fetch_featured_recipes({ commit }) {
      apolloClient.query({
        query: gql`
          query FeaturedRecipes {
            featured_recipes {
              id
              name
              photo_urls {
                small
              }
            }
          }
        `,
        fetchPolicy: "cache-first"
      })
        .then(response => commit("SET_FEATURED_RECIPES", response.data.featured_recipes));
    },
    async create_custom_recipe({ state, commit }, recipe) {
      const { data } = await apolloClient.query({
        query: gql`
          mutation CreateUserRecipe($user_id: Int!, $recipe: UserRecipe!) {
            create_user_recipe(user_id: $user_id, recipe: $recipe) {
              successful
              messages {
                field
                message
              }
              result {
                ${recipe_fields}
                conversion {
                  ${conversion_fields}
                }
                conversions {
                  ${conversion_fields}
                }
                child_recipes {
                  id
                }
                foods {
                  id
                  name
                  quantity
                  conversion {
                    ${conversion_fields}
                  }
                  conversions {
                    ${conversion_fields}
                  }
                }
              }
            }
          }
        `,
        variables: { user_id: state.rails.user.id, recipe },
        fetchPolicy: "no-cache"
      });

      const { successful, result, messages } = data.create_user_recipe;

      if (successful) {
        commit("SET_CUSTOM_RECIPES", [...state.custom_recipes, result]);
      }
      return Promise.resolve({ result, successful, messages });
    },
    async update_custom_recipe({ state, commit }, { recipe_id, recipe }) {
      const { data } = await apolloClient.query({
        query: gql`
          mutation UpdateUserRecipe($user_id: Int!, $recipe_id: Int!, $recipe: UserRecipe!) {
            update_user_recipe(user_id: $user_id, recipe_id: $recipe_id, recipe: $recipe) {
              successful
              messages {
                field
                message
              }
              result {
                ${recipe_fields}
                conversion {
                  ${conversion_fields}
                }
                conversions {
                  ${conversion_fields}
                }
                child_recipes {
                  id
                }
                foods {
                  id
                  name
                  quantity
                  conversion {
                    ${conversion_fields}
                  }
                  conversions {
                    ${conversion_fields}
                  }
                }
              }
            }
          }
        `,
        variables: { user_id: state.rails.user.id, recipe_id, recipe },
        fetchPolicy: "no-cache"
      });

      const { successful, result, messages } = data.update_user_recipe;

      if (successful) {
        const custom_recipes = state.custom_recipes.map(recipe => (
          recipe.id === result.id ? result : recipe
        ));

        commit("SET_CUSTOM_RECIPES", custom_recipes);
      }
      return Promise.resolve({ successful, messages });
    },
    async delete_custom_recipe({ state, dispatch, commit }, recipe_id) {
      await Promise.all([
        dispatch("rails/delete_custom_recipe", recipe_id),
        apolloClient.query({
          query: gql`
            mutation DeleteUserRecipe($user_id: Int!, $recipe_id: Int!) {
              delete_user_recipe(user_id: $user_id, recipe_id: $recipe_id)
            }
          `,
          variables: { user_id: state.rails.user.id, recipe_id },
          fetchPolicy: "no-cache"
        })
      ]);

      commit("SET_CUSTOM_RECIPES", state.custom_recipes.filter(r => r.id !== recipe_id));

      return Promise.resolve();
    },
    save_recipe_builder_form_state({ commit }, form_state) {
      commit("SAVE_RECIPE_BUILDER_FORM_STATE", form_state)
    }
  },
  mutations: {
    UPDATE_ROUTE_HISTORY(state, routeHistory) { state.routeHistory = routeHistory },
    START_LOADING(state, query_type) { state.loading = { ...state.loading, [query_type]: true }; },
    END_LOADING(state, query_type) { state.loading = { ...state.loading, [query_type]: false }; },
    SET_NOTIFICATION(state, notification) { state.notification = notification; },
    CLEAR_NOTIFICATION(state) { state.notification = null; },
    SET_MESSAGE_MODAL(state, { props, timer }) { state.message_modal = { props, timer } },
    CLOSE_MESSAGE_MODAL(state) { state.message_modal = null },
    SET_NAV_FOOTER_NOTIFICATION(state, nav_route) { state.nav_footer_notification = nav_route; },
    SET_MODAL_VISIBLE(state, isVisible) { state.modalIsVisible = isVisible; },
    SET_FILTERS(state, filters) { state.filters = filters; },
    SET_FREQUENTS(state, frequents) { state.frequents = frequents; },
    SET_FAVORITE_FOODS(state, favorite_foods) { state.favorite_foods = favorite_foods; },
    SET_FAVORITE_RECIPES(state, favorite_recipes) { state.favorite_recipes = favorite_recipes; },
    SET_FEATURED_RECIPES(state, featured_recipes) { state.featured_recipes = featured_recipes; },
    SET_CUSTOM_RECIPES(state, custom_recipes) { state.custom_recipes = custom_recipes; },
    SET_TAGS(state, tags) { state.tags = tags; },
    SET_FOOD_RESULTS(state, foods) { state.food_results = foods; },
    SET_SCANNED_FOOD(state, food) { state.scanned_food = food; },
    SET_RESTAURANT(state, restaurant) { state.restaurant = restaurant; },
    SAVE_RECIPE_BUILDER_FORM_STATE(state, form_state) { state.recipe_builder_form_state = form_state; },
    SET_MACROSTAX_RECIPES(state, recipes) { state.macrostax_recipes = recipes; },
    SET_RECIPE_SEARCH_RESULTS(state, recipes) { state.recipe_search_results = recipes; },
    SET_RECIPE_MATCH_RESULTS(state, recipes) { state.recipe_match_results = recipes; },
    UPDATE_MACROSTAX_RECIPES(state, { id, is_favorite }) {
      state.macrostax_recipes = state.macrostax_recipes.map(recipe => (
        recipe.id === id ? { ...recipe, is_favorite } : recipe
      ));
    },
    SELECT_FILTER(state, filter_id) { state.selected_filter_ids = [...state.selected_filter_ids, filter_id]; },
    DESELECT_FILTER(state, filter_id) {
      state.selected_filter_ids = state.selected_filter_ids.filter(id => id !== filter_id);
    },
    DESELECT_ALL_FILTERS(state) { state.selected_filter_ids = []; }
  }
});
