import activity_levels from '../profile/activity_levels';
import programs from '../profile/programs';

const updateIntercom = (atts, state) => {
  if (typeof Intercom !== 'undefined') {
    try {
      const today = moment();
      let intAttrs = {
        last_legacy_activity_at: today.unix(),
      };
      let isBirthday = false;
      let userId = '';
      if (!atts && state) {
        const { rails } = state;
        const { meal_planner_settings, statistic_set, user, dashboard, measurements, weigh_ins } = rails;
        let phase;
        if (meal_planner_settings) {
          ({ phase } = meal_planner_settings);
          intAttrs = {
            ...intAttrs,
            mkt_phase: phase,
          };
        }
        if (statistic_set) {
          const { birthday, gender, activity_level, weight, goal } = statistic_set;
          const age = today.diff(birthday, 'years');
          isBirthday = today.format('MM-DD') === moment(birthday).format('MM-DD');
          const g = programs.find(p => p.goal === goal);

          intAttrs = {
            ...intAttrs,
            age,
            birthday,
            gender,
            profile_weight: weight,
          };
          if (g) {
            intAttrs.goal = g.label;
            if (g.phases) {
              phase = g.phases.find(p => p.id === phase)
              if (phase) {
                intAttrs.phase = phase.label;
              }
            }
          }
          if (activity_levels) {
            intAttrs.activity_level = activity_levels.find(a => a.value === activity_level).title;
          }
        }
        if (user) {
          const { stripe_customer_id, stripe_plan_id, stripe_subscription_id, subscription_status, preferences, id } = user;
          userId = id;
          intAttrs = {
            ...intAttrs,
            stripe_id: stripe_customer_id,
            stripe_plan: stripe_plan_id === process.env.STRIPE_QUARTERLY_SUBSCRIPTION_PLAN_ID ? 'macrostax_quarterly' : stripe_plan_id === process.env.STRIPE_ANNUAL_SUBSCRIPTION_PLAN_ID ? 'macrostax_yearly' : stripe_plan_id === process.env.STRIPE_LIFETIME_SUBSCRIPTION_PLAN_ID ? 'macrostax_lifetime' : stripe_plan_id,
            stripe_subscription_id,
            stripe_subscription_status: subscription_status,
            weight_preference: preferences.preferred_input_weight_unit,
          }
        }
        if (weigh_ins && measurements) {
          intAttrs = {
            ...intAttrs,
            has_weigh_ins: weigh_ins.length > 0,
            weight_updated_at: weigh_ins[0] ? moment(weigh_ins[0].created_at).unix() : null,
            has_measurements: measurements.length > 0,
          }
          if (weigh_ins[0]) {
            intAttrs.weight = weigh_ins[0].weight;
          }
        }
      } else {
        intAttrs = atts;
      }
      if (intAttrs.weight_preference && intAttrs.weight_preference === 'kg') {
        if (intAttrs.profile_weight) {
          intAttrs.profile_weight = numeral(intAttrs.profile_weight / 2.205).format('0');
        }
        if (intAttrs.weight) {
          intAttrs.weight = numeral(intAttrs.weight / 2.205).format('0');
        }
      }
      Intercom('update', intAttrs);
      if (isBirthday) {
        // send an event, unless we've already sent one
        const localStorageKey = `intercom_birthday_${userId}_${today.format('YYYY-MM-DD')}`;
        if (!localStorage[localStorageKey]) {
          Intercom('trackEvent', 'birthday');
          localStorage[localStorageKey] = true;
        }
      }
    } catch(e) {
      console.log('error updating Intercom', e);
    }
  }
}

export default updateIntercom;
