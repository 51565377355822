<template lang="html">
  <div class="modal-backdrop">
    <div class="delete-modal">
      <span class="modal-header">{{ header }}</span>
      <span class="modal-text">{{ text }}</span>
      <div class="buttons">
        <span @click="cancel">CANCEL</span>
        <span @click="confirm">DELETE</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    multiple: { type: Boolean, required: true },
  },
  computed: {
    header() {
      return this.multiple ? "Delete All Items" : "Delete Item";
    },
    text() {
      if (this.multiple) {
        return "Are you sure you want to delete all items from this meal?";
      }
      return "Are you sure you want to delete this item from this meal?";
    }
  },
  methods: {
    cancel() {
      this.$store.dispatch("rails/clear_portions_to_delete");
    },
    confirm() {
      this.$store.dispatch("rails/delete_portions");
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/base/_variables.scss";

.modal-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
  -webkit-overflow-scrolling: touch;
  z-index: 100;
}

.delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  padding: 18px 20px 14px 20px;
  width: 85%;
  max-width: 375px;

  .modal-header {
    align-self: flex-start;
    color: black;
    font-size: 16px;
    font-weight: 600;
    font-family: Montserrat;
    margin-bottom: 18px;
  }

  .modal-text {
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .buttons {
    display: flex;
    align-self: flex-end;
    cursor: pointer;

    span {
      font-family: Montserrat;
      color: $endurance;
      font-size: 15px;
      font-weight: 700;
      padding: 14px 0px;
      margin-left: 14px;

      &:first-of-type {
        margin-right: 14px;
      }
    }
  }
}
</style>
