<template lang="html">
  <form @submit="export_food_log">
    <div class="profile-input-checkbox">
      <div :class="{'checkbox': true, 'selected': smtp}" @click="smtp = !smtp">
        <i class="fa fa-check"></i>
      </div>
      <div class="profile-input-row">
        <p class="checkbox-label">Email Meals</p>
        <p class="input-label">Email Address</p>
        <div class="profile-input">
          <input v-model="email" placeholder="Email Address" />
          <div class="clear-button" @click="user.email = ''">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!email_valid" class="error-message left">Please enter a valid email address</div>
    <div class="profile-input-checkbox">
      <div :class="{'checkbox': true, 'selected': sms}" @click="sms = !sms">
        <i class="fa fa-check"></i>
      </div>
      <div class="profile-input-row">
        <p class="checkbox-label">Text Meals</p>
        <p class="input-label">Phone Number</p>
        <div class="profile-input">
          <input v-model="phone" type="tel" placeholder="Phone Number"/>
          <div class="clear-button" @click="user.preferences.export_phone_number = ''">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!phone_valid" class="error-message left">Please enter a valid phone number</div>
    <div class="button-row save">
      <input class="save-button" type="submit" value="SEND NOW">
    </div>
  </form>
</template>

<script>
import { reduce, mapValues, pickBy, partition } from "lodash";
import { flow, values, map, flatMap, filter, uniq } from "lodash/fp";
import gql from "graphql-tag";

import API from "@js/api/rails_client";
import { is_kitchen_measurable, to_fraction, round_macros } from "@js/lib/utils";
import { serving_units } from "@js/lib/rails_compatibility_helpers";

export default {
  props: {
    close: { type: Function, default: true }
  },
  data() {
    const { email, preferences: { export_phone_number } } = this.$store.state.rails.user;

    return {
      mealsData: {},
      smtp: true,
      sms: true,
      phone: export_phone_number,
      email,
      email_valid: true,
      phone_valid: true,
      foods: [],
      recipes: []
    };
  },
  computed: {
    export_params() {
      return {
        sms: this.sms,
        smtp: this.smtp,
        phone: this.phone && this.phone.toString(),
        email: this.email,
        day_details: this.day_details,
        day_summary: this.day_summary,
        meals: this.meal_params
      };
    },
    day_details() {
      return this.$store.state.rails.day_details;
    },
    day_summary() {
      return {
        date: this.$store.state.rails.selected_date,
        carbohydrates: `${this.macros_logged.carbohydrates} / ${this.macro_targets.carbohydrates}`,
        protein: `${this.macros_logged.protein} / ${this.macro_targets.protein}`,
        fat: `${this.macros_logged.fat} / ${this.macro_targets.fat}`,
        calories: `${this.macros_logged.calories} / ${this.macro_targets.calories}`
      };
    },
    meal_params() {
      // NOTE: this replicates behavior of previous implementation, but there were two problems with the generated export:
      //   1) the logged portion sizes of recipes are completely ignored: the default portion sizes returned by the Phx API are used instead
      //   2) if a food or recipe has been logged more than once within the same meal, the first portion is ignored/discarded - this implementation fixes this for foods but not recipes
      return mapValues(this.meals, (meal, meal_id) => {
        const [
          recipe_portions,
          food_portions
        ] = partition(meal.portions, portion => portion.root_recipe_id || portion.recipe_id);

        const recipes = flow(
          map(portion => portion.root_recipe_id || portion.recipe_id),
          uniq,
          map(recipe_id => this.recipes.find(recipe => recipe.id === recipe_id)),
          map(recipe => this.recipe_params(recipe))
        )(recipe_portions);

        const foods = food_portions.map(portion => this.food_params(portion));

        const { carbohydrates, protein, fat, calories } = round_macros(this.macros_logged_per_meal[meal_id], 0.5);

        return {
          display_name: meal.meal_type,
          recipes,
          foods,
          macros: {
            actual_carbs: carbohydrates,
            actual_proteins: protein,
            actual_fats: fat,
            actual_calories: calories
          }
        };
      });
    },
    meals() {
      return pickBy(this.$store.state.rails.meals, meal => meal.portions.length > 0);
    },
    macro_targets() {
      return this.$store.getters["rails/macro_targets_for_day"];
    },
    macros_logged() {
      return this.$store.getters["rails/macros_logged_for_day"];
    },
    macros_logged_per_meal() {
      return this.$store.getters["rails/macros_logged_per_meal"];
    },
    recipe_ids() {
      return flow(
        flatMap(meal => meal.portions),
        filter(portion => portion.recipe_id),
        map(portion => portion.root_recipe_id || portion.recipe_id),
        uniq
      )(this.meals);
    },
    food_ids() {
      return flow(
        flatMap(meal => meal.portions),
        filter(portion => portion.food_id),
        map(portion => portion.food_id),
        uniq
      )(this.meals);
    }
  },
  async created() {
    const { foods, recipes } = await this.fetch_food_and_recipe_data();
    this.foods = foods;
    this.recipes = recipes;
  },
  methods: {
    food_params(portion) {
      const brand = portion.food_id && this.foods.find(food => food.id === portion.food_id).brand_name;

      return {
        name: portion.name,
        brand,
        serving: portion.serving_size,
        serving_units: serving_units(portion)
      };
    },
    recipe_params(recipe) {
      return {
        recipe_name: recipe.name,
        foods: recipe.foods.map(food => this.recipe_food_params(food)),
        recipes: (recipe.child_recipes || []).map(recipe => this.recipe_params(recipe))
      };
    },
    recipe_food_params(food) {
      const serving = is_kitchen_measurable(food.quantity) ? to_fraction(food.quantity) : food.quantity;

      return {
        name: food.name,
        brand: food.brand_name,
        serving,
        serving_units: food.conversion.name
      };
    },
    async export_food_log(e) {
      e.preventDefault();

      if (this.validate_email() && this.validate_phone()) {
        try {
          this.$store.dispatch("loading", true);

          await API.export_food_log(this.export_params);

          this.$store.dispatch("notify_user", { type: "info", msg: "Meals sent successfully!" });
          this.close();
        } catch (e) {
          this.$store.dispatch("notify_user", { type: "error", msg: "Whoops! Something went wrong." });
          console.error(e);
        } finally {
          this.$store.dispatch("loading", false);
        }
      }
    },
    validate_email() {
      const is_valid = !this.smtp || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
      this.email_valid = is_valid;
      return is_valid;
    },
    validate_phone() {
      const is_valid = !this.sms || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(this.phone);
      this.phone_valid = is_valid;
      return is_valid;
    },
    async fetch_food_and_recipe_data() {
      const conversion_fields = `
        conversion {
          name
          weight_grams
          defaultServingQty
        }
      `;

      const { data: { foods, kitchen_recipes } } = await this.$apollo.query({
        query: gql`
          query($food_ids: [Int], $recipe_ids: [Int]) {
            foods(ids: $food_ids) {
              id
              name
              brand_name
            }
            kitchen_recipes(ids: $recipe_ids) {
              id
              name
              foods {
                id
                name
                brand_name
                quantity
                ${conversion_fields}
              }
              child_recipes {
                id
                name
                foods {
                  id
                  name
                  brand_name
                  quantity
                  ${conversion_fields}
                }
              }
            }
          }
        `,
        variables: { food_ids: this.food_ids, recipe_ids: this.recipe_ids }
      })

      return { foods, recipes: kitchen_recipes };
    }
  }
};
</script>
