<template>
  <div id="kitchen-search">
    <div class="search-field">
      <div class="icon">
        <i class="fa fa-search" />
      </div>
      <input
        v-bind:value="search_term"
        v-on:input="search_term = $event.target.value.replace(`’`,`'`) /* to correct curly apostrophe on iOS */"
        type="search"
        :placeholder="placeholder_text"
        ref="input"
        @focus="focus"
        @blur="blur"
      />
      <div v-show="search_term" class="icon clear-icon">
        <i class="fa fa-times" @click="clear" />
      </div>
      <div v-show="selected_tab === 'foods'" @click="open_barcode_scanner" class="icon barcode-icon">
        <i class="fa fa-barcode" />
      </div>
    </div>
    <div v-show="meal_id && show_results" class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        @click="select_tab(tab.id)"
        class="tab"
        :class="{ selected: tab.id == selected_tab }"
       >
        {{ tab.name }}
      </div>
    </div>
    <div v-if="show_results" class="search-results">
      <div v-if="!has_minimum_search_term(selected_tab)">
        <div v-if="!frequents" class="description">
          Start Typing!
        </div>
        <SearchList
          v-else
          v-for="(value, key) in frequents"
          :key="search_term"
          :search_list_heading="`${key} ${selected_tab}`"
          :search_results="value.results"
          :search_term="search_term"
          :show_macros="selected_tab !== 'recipes'"
          :meal_id="meal_id"
          :select_item="select_item"
          :starting_list_length="value.starting_list_length || value.results.length"
          :add_list_length="value.add_length || 0"
        /> 
      </div>
      <div v-else>
        <Spinner v-if="is_searching" :radius="25" />
        <div v-else-if="search_results.length === 0" class="description">
          No results found!
        </div>
        <SearchList
          v-else-if="selected_tab !== 'restaurant'"
          v-for="(value, key) in search_results"
          :key="`${value.starting_list_length}${value.results.length}`"
          :search_list_heading="`${key} ${selected_tab}`"
          :search_results="value.results"
          :search_term="search_term"
          :show_macros="selected_tab !== 'recipes'"
          :meal_id="meal_id"
          :select_item="select_item"
          :starting_list_length="value.starting_list_length || value.results.length"
          :add_list_length="value.add_length || 0"
        />
        <SearchResult
          v-else
          v-for="item in search_results"
          :key="item.id"
          :item="item"
          :search_term="search_term"
          :show_macros="selected_tab !== 'recipes'"
          :select_item="() => select_item(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { debounce, flatten, partition, omit, isEqual } from "lodash";
import ClickOutside from "vue-click-outside";

import { filter_by_search_term } from "@js/lib/utils";

import SearchList from "@js/shared/search/search_list.vue";
import SearchResult from "@js/shared/search/search_result.vue";
import BarcodeScanner from "@js/shared/search/barcode_scanner.vue";
import Spinner from "@js/food_log/spinner.vue";

export default {
  components: { SearchList, SearchResult, BarcodeScanner, Spinner },
  directives: { ClickOutside },
  props: {
    add_item: { type: Function, required: true },
    meal_id: { type: String },
    on_open_barcode_scanner: { type: Function },
    close_portion_edit: { type: Function, required: false, default: () => {} }, 
    update_count: { type: Function, default: () => {} }
  },
  data() {
    return {
      search_term: "",
      focused: false,
      tabs: [
        { name: "Foods", id: "foods"},
        { name: "Restaurant", id: "restaurant"},
        { name: "Recipes", id: "recipes"}
      ],
      selected_tab: "foods",
      show_results: false,
      food_search_started: false,
      recipe_search_started: false,
      restaurant_search_started: false,
      scanner_visible: false
    };
  },
  computed: {
    search_context() {
      if (this.meal_id) {
        return "food_log";
      }
      return "recipe_builder";
    },
    frequents() {
      if (isEqual(this.$store.state.frequents, {})) {
        return false;
      } else {
        const frequents = this.$store.state.frequents[this.selected_tab];
        return frequents.length === 0
          ? false
          : {"Frequently Used": {results: frequents, starting_list_length: 5, add_length: 5}};
      }
    },
    placeholder_text() {
      if (this.search_context === "recipe_builder") {
        return "Search for Ingredients";
      }
      return {
        foods: this.focused ? "Start typing!" : "Search All Foods...",
        restaurant: this.focused ? "Start typing!" : "Search Restaurant Foods..." ,
        recipes: this.focused ? "Start typing!" : "Search Recipes..."
      }[this.selected_tab];
    },
    is_searching() {
      // NOTE: see comment in watch() about search_started
      return {
        foods: this.$store.state.loading.food_search || this.food_search_started,
        recipes: this.$store.state.loading.recipe_search || this.recipe_search_started,
        restaurant: this.$store.state.loading.restaurant_search || this.restaurant_search_started
      }[this.selected_tab]
    },
    normalized_search_term() {
      // NOTE: when we changed v-model.trim="search_term" to v-bind:value + v-on:input to fix the Android debounce bug,
      // apparently $event.target.value.trim() isn't a 1-to-1 replacement. It seems like the former
      // tracks something about Vue component state to prevent an intentional whitespace from being removed upon
      // rapidly typing a character followed by a whitespace when the updated search_term triggers
      // some (but not all) types of secondary state updates (?).
      return this.search_term.trim();
    },
    search_results() {
      if (this.selected_tab === "foods") {
        return this.foods;
      } else if (this.selected_tab === "recipes") {
        return this.recipes;
      } else {
        return this.restaurants;
      }
    },
    foods() {
      const custom_foods = filter_by_search_term(this.$store.state.rails.custom_foods, this.search_term);
      const food_results = this.$store.state.food_results;
      const categorized_food_results = food_results.reduce((accumulator, search_result ) => {
        if (search_result.brand_name) {
          accumulator.Branded.results.push(search_result);
          return accumulator;
        } else {
            accumulator.Generic.results.push(search_result);
            return accumulator;
        }
      }, {"Custom" : {results: custom_foods, starting_list_length: 6, add_length: 6},
          "Generic": {results: [], starting_list_length: 4, add_length: 0},
          "Branded": {results: []}}
      );
      // remove search_context predicate to enable custom foods in the recipe builder
      return custom_foods.length > 0 && this.search_context === "food_log" 
               ? categorized_food_results
               : omit(categorized_food_results, 'Custom');
    },
    restaurants() {
      return this.$store.state.restaurant;
    },
    recipes() {
      const {recipe_search_results, custom_recipes} = this.$store.state;
      const custom_results = filter_by_search_term(custom_recipes, this.search_term);
      const results = {
        "Custom" : {results: custom_results, starting_list_length: 6, add_length: 6},
        "Macrostax": {results: recipe_search_results, starting_list_length: 10, add_length: 10}
      };
      return custom_results.length > 0 ? results : omit(results, 'Custom');
    },
  },
  async created() {
    this.search = debounce(() => {
      if (this.has_minimum_search_term("foods")) {
        this.$store.dispatch("fetch_foods", this.search_term);
      }
      if (this.has_minimum_search_term("restaurant")) {
        this.$store.dispatch("fetch_restaurant", this.search_term);
      }
      if (this.has_minimum_search_term("recipes")) {
        // TODO fetch_recipes with use_tag_filters: true
        this.$store.dispatch("fetch_recipes", { search_term: this.normalized_search_term, featured_first: false });
      };
    }, 300);

    if (this.$store.state.scanned_food) {
      this.select_item(this.$store.state.scanned_food);
      this.$store.dispatch("unset_scanned_food");
    }

    this.$store.dispatch("fetch_frequents");    
  },
  watch: {
    search_term() {
      // Set redundant local state for search_started that changes immediately upon typing search term.
      // Vuex updates is_searching too slowly. Absent this local state, when search starts "No results found!" appears for a split second before the spinner.
      if (this.has_minimum_search_term("foods")) {
        this.food_search_started = true;
      }
      if (this.has_minimum_search_term("recipes")) {
        this.recipe_search_started = true;
      }
      if (this.has_minimum_search_term("restaurant")) {
        this.restaurant_search_started = true;
      }
      this.search();
    },
    search_results() {
      // even if search hasn't finished for the active tab, it finishing for another tab implies that Vuex has had time to set is_searching
      this.food_search_started = false;
      this.restaurant_search_started = false;
      this.recipe_search_started = false;

      this.update_count(this.show_results && this.search_term !== "" ? this.search_results.length : null);
    }
  },
  methods: {
    open_barcode_scanner() {
      this.on_open_barcode_scanner && this.on_open_barcode_scanner();
      this.$router.push("/scan_food");
    },
    close() {
      this.show_results = false;
      this.update_count(null);
    },
    focus() {
      this.focused = true;
      this.show_results = true;
      this.close_portion_edit();
    },
    blur() {
      this.focused = false;
    },
    clear() {
      this.search_term = "";
      this.show_results = false;
      this.update_count(null);
    },
    select_tab(id) {
      this.selected_tab = id;
      this.$refs.input.focus();
    },
    has_minimum_search_term(tab) {
      // NOTE: Nix ToS dictate that we only query their API with search terms >= 3 characters
      // (this logic is also reflected in created() and the search_term watcher)
      if (tab === "foods" || tab === "restaurant") {
        return this.search_term.length >= 3;
      }
      return this.normalized_search_term.length > 0;
    },
    select_item(item) {
      this.add_item(item);

      if (this.search_context === "food_log") {
        this.selected_tab = "foods";
      }
      this.clear();
    }
  }
};
</script>

<style scoped lang="scss">
#kitchen-search {
  display: flex;
  flex-direction: column;
  margin: 4px 0px;
  border-radius: 8px;
  border: none;
  background-color: #F0E8FB;
  box-shadow: 0px 1px 2px rgba(79, 79, 79, 0.25);
  overflow: hidden;
}

.scanner-button {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 20px;
}

.search-field {
  display: flex;
  position: relative;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;

    i {
      color: $endurance;
      font-size: 16px;
    }
    &.barcode-icon {
      position: absolute;
      right: 0;
    }
    &.clear-icon {
      position: absolute;
      right: 26px;
    }
  }

  input {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    flex: 1;
    background-color: transparent;
    color: #4f4f4f;
    font-size: 16px;
    font-weight: normal;
    height: 40px;
    padding: 2px 2px;
    padding-left: 10px;

    &::placeholder {
      color: #4f4f4f;
      font-size: 16px;
      font-weight: normal;
    }
  }

  .clear-search {
    cursor: pointer;
    margin-right: 0px;
    align-self: center;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;

  .tab {
    padding: 8px;
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #4f4f4f;
    border-bottom: solid 1px #d2d2d2;
    text-transform: uppercase;
    font-weight: normal;
    cursor: pointer;

    &.selected {
      font-weight: 700;
      color: $endurance;
      border-bottom: 2px solid $endurance;
    }
  }
}

.search-results {
  margin: 0;
  padding: 0;
  z-index: 100;
  width: 100%;
  border: none;
  background-color: #ffffff;

  .spinner {
    margin: 16px auto;
  }

  .description {
    display: flex;
    justify-content: center;
    height: 50px;
    line-height: 50px;
  }

  .search-result {
    cursor: pointer;
    background-color: #fff;
    &:last-child{
      .ingredient-container {
        border-bottom: none;
      }
    }
    &:hover{
      background-color: $background-lightest-gray;
    }
  }
}
</style>
