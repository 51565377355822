<template lang="html">
  <div class="mtc-count-down">
    <div class="days number">
      <div class="counter-circle">
        {{days}}
      </div>
      <div class="label">
        DAYS
      </div>
    </div>

    <div class="hours number">
      <div class="counter-circle">
        {{hours}}
      </div>
      <div class="label">
        HOURS
      </div>
    </div>

    <div class="minutes number">
      <div class="counter-circle">
        {{minutes}}
      </div>
      <div class="label">
        MINUTES
      </div>
    </div>

    <div class="seconds number">
      <div class="counter-circle">
        {{seconds}}
      </div>
      <div class="label">
        SECONDS
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      current_time: moment(),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },
  computed: {
    start_date() {
      return moment(this.$store.state.rails.mtc_challenge.start_date);
    }
  },
  watch: {
    current_time() {
      const counter = moment.duration(this.start_date.diff(this.current_time));
      this.days = counter.days();
      this.hours = counter.hours();
      this.minutes = counter.minutes();
      this.seconds = counter.seconds();
    }
  },
  // use `created` lifecycle hook (rather than `beforeCreate`) so that computed prop `start_date` from store state is available
  created() {
    if (this.current_time.isBefore(this.start_date)) {
      this.countdown = setInterval(
        () => this.current_time = moment(),
        1000
      );
    }
  },
  beforeDestroy() {
    this.countdown && clearInterval(this.countdown);
  }
};
</script>

<style scoped lang="scss">
  .mtc-count-down {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .number {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .counter-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #34B6E4;
        box-sizing: border-box;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .label {

        margin-top: 5px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 13px;
      }
    }
  }
</style>
