import VueRouter from "vue-router";

import store from "@js/store";

import FoodLog from "@js/food_log/food_log.vue";
import Recipes from "@js/recipes/recipes.vue";
import RecipeDetails from "@js/recipes/recipe_details.vue";
import FillMacros from "@js/recipes/fill_macros/fill_macros.vue";
import CustomFoodsRecipes from "@js/custom/custom_foods_recipes.vue";
import RecipeBuilder from "@js/custom/recipe_builder.vue";
import BarcodeScanner from "@js/shared/search/barcode_scanner.vue";
import Favorites from "@js/food_log/favorites.vue";
import Profile from "@js/profile/profile.vue";
import UserSettings from "@js/profile/user_settings.vue";
import ProgramSettings from "@js/profile/program_settings.vue";
import ActivitySettings from "@js/profile/activity_settings.vue";
import DietaryPreferences from "@js/profile/dietary_preferences.vue";
import MTCSignup from "@js/food_log/mtc/signup.vue";
import Challenge from "@js/food_log/mtc/challenge.vue";
import Enrolled from "@js/food_log/mtc/enrolled.vue";
import ProgressOverview from "@js/profile/progress/overview.vue";
import Billing from "@js/profile/billing/base.vue";
import CancelSubscription from "@js/profile/billing/subscription/cancel.vue";
import UpdateCreditCard from "@js/profile/billing/credit_card/update.vue";

const routes = [
  {
    path: "/food_log",
    component: FoodLog,
    props: ({ query: { meal_id, fab } }) => ({ expanded_meal_card: meal_id, fab_state: fab })
  },
  { path: "/scan_food", component: BarcodeScanner },
  { path: "/recipes", component: Recipes },
  {
    path: "/recipes/favorites",
    component: Recipes,
    props: () => ({ view: "favorites" })
  },
  {
    path: "/recipes/:id",
    component: RecipeDetails,
    props: ({ params: { id }, query: { meal_id, carb, protein, fat } }) => (
      // NOTE: vue-router doesn't cleanly support nested query params so macro_targets are passed as separate params
      {
        recipe_id: parseInt(id),
        meal_id,
        ...(carb && protein && fat) && { macro_targets: { carb, protein, fat } }
      }
    )
  },
  {
    path: "/fill_macros",
    component: FillMacros,
    props: ({ query: { meal_id } }) => ({ tab: "foods", meal_id })
  },
  {
    path: "/custom/foods",
    component: CustomFoodsRecipes,
    // note: we sometimes deep link with 'mealid' instead of 'meal_id' because the before-each event below
    // (called on refresh) will drop the 'meal_id' param and we need it
    props: ({ query: { mealid, meal_id } }) => ({ tab: "foods", meal_id: mealid ? mealid : meal_id })
  },
  {
    path: "/custom/recipes",
    component: CustomFoodsRecipes,
    props: ({ query: { meal_id } }) => ({ tab: "recipes", meal_id })
  },
  {
    path: "/custom/recipes/new",
    component: RecipeBuilder,
    props: () => ({ mode: "new" })
  },
  {
    path: "/custom/recipes/:id/edit",
    component: RecipeBuilder,
    props: ({ params: { id } }) => ({ recipe_id: parseInt(id), mode: "edit" })
  },
  {
    path: "/custom/recipes/:id/customize",
    component: RecipeBuilder,
    props: ({ params: { id } }) => ({ recipe_id: parseInt(id), mode: "customize" })
  },
  {
    path: "/favorites",
    component: Favorites,
    props: ({ query: { meal_id } }) => ({ meal_id })
  },
  { path: "/profile", component: Profile },
  { path: "/profile/user", component: UserSettings },
  { path: "/profile/activity", component: ActivitySettings },
  { path: "/profile/diet", component: DietaryPreferences },
  { path: "/profile/program", component: ProgramSettings },
  { path: "/mtc", component: Challenge },
  { path: "/mtc_signup", component: MTCSignup },
  { path: "/mtc_enrolled", component: Enrolled },
  { path: "/inputs", component: ProgressOverview },
  { path: "/billing", component: Billing, },
  { path: "/cancel-subscription", component: CancelSubscription, },
  { path: "/update-billing", component: UpdateCreditCard, },
  { path: "*", component: FoodLog }
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: () => ({ x: 0, y: 0 })
});


router.beforeEach((to, from, next) => {
  let path;
  if (from.path === "/" && to.query.vue_route) {
    // Vue Router initializes with the current URL in `to` and `from.path` == "/".
    // Since we don't load/initialize the router for the Rails root path, `from.path` == "/" always implies this is
    // the initial page load, and the presence of a `vue_route` param implies that Rails redirected a
    // client-side route to a server-side route (e.g. user refreshed page).
    //
    // Now we need to redirect user back to the client-side route!
    // Note: route is base64 encoded since it might not be url safe (e.g. slashes), so we decode it with `atob`.
    next(atob(to.query.vue_route));
  } else if (to.query.vue_route) {
    // Only the Rails router sets the `vue_route` param, so this conditional implies the prior clause executed on
    // initial page load and then the user performed an action that called router.back() with no intervening router
    // navigation calls. We must strip the `vue_route` param or `next()` will execute the prior clause exactly
    // as it did on the initial page load and reload the same route - i.e. `router.back()` will amount to a no-op from
    // the user perspective.
    next(to.path);
  } else if (from.path === "/") { // initial page load
    next();
  } else if (path = pathServedFromDifferentController(from.path, to.path)) {
    const p = to.query ? to.fullPath : path;
    loadServerRoute(p);
  } else {
    next();
  }

  store.dispatch("routeChanged", to);
});

router.afterEach((to, from) => {
  // track in ActiveCampaign
  if (typeof vgo !== 'undefined') {
    if (window.gon && window.gon.user && window.gon.user.email) {
      vgo('setEmail', window.gon.user.email);
    }
    // need to call 'update' because this is a SPA so we have to tell AC to recalculate the URL
    vgo('update');
    vgo('process');
  }
});

function pathServedFromDifferentController(fromPath, toPath) {
  let fromController;
  if (fromPath.match("/inputs")) {
    fromController = "inputs";
    // temp catch, result of scope change for billing enhancements
  } else if (fromPath.match("/billing/upgrade")) {
    fromController = "billing";
  } else if (fromPath.match("/billing/new")) {
    fromController = "billing";
  } else {
    fromController = "macros";
  }

  let toController;
  if (toPath.match("/inputs")) {
    toController = "inputs";
    // temp catch, result of scope change for billing enhancements
  } else if (toPath.match("/billing/upgrade")) {
    toController = "billing";
  } else if (toPath.match("/billing/new")) {
    toController = "billing";
  } else {
    toController = "macros";
  }

  return fromController !== toController && toPath;
}

export function urlDateParam() {
  const date = store.state.rails.selected_date;

  if (date && !moment().isSame(date, "day")) {
    return `d=${date}`;
  }
  return "";
}

function loadServerRoute(path) {
  store.dispatch("loading", true);
  const dateParam = urlDateParam();
  window.location.href = dateParam ? `${path}?${dateParam}` : path;
}

export function reloadPage() {
  const dateParam = urlDateParam();
  if (dateParam && window.location.search) {
    window.location.href = `${window.location.href}&${dateParam}`;
  } else if (dateParam) {
    window.location.href = `${window.location.href}?${dateParam}`;
  } else {
    window.location.reload();
  }
}

export default router;
