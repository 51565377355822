<template>
  <div class="expandable-row">
    <div class="row-header" @click="toggle_section">
      <p>Recipe Details</p>
      <i v-if="expanded" class="fa fa-chevron-up fa-sm icon purple" />
      <i v-else class="fa fa-chevron-down fa-sm icon purple" />
    </div>
    <transition name="fade">
      <div v-if="expanded" class="row-content">
        <div class="input-field">
          <div :class="{ 'input-container': true, 'error-temp-class': !!errors['name'] }">
            <p class="input-label">Title</p>
            <input v-model.trim="recipe.name" placeholder="Enter Recipe Title" type="text" rows="1" />
          </div>
          <div v-if="errors['name']" class="error-msg-temp-class">
            {{ errors['name'] }}
          </div>
        </div>
        <div class="input-field">
          <div class="input-container">
            <p class="input-label">Recipe Description</p>
            <textarea
              class="textarea-lg"
              v-model.trim="recipe.intro_line"
              placeholder="Enter Recipe Description"
              type="text"
              rows="6"
            />
          </div>
        </div>
        <div class="input-field">
          <div class="input-container">
            <p class="input-label">Preparation Instructions</p>
            <textarea
              class="textarea-lg"
              v-model.trim="recipe.prep"
              :placeholder="'Enter Preparation Instructions \n \n 1. \n 2. \n 3.'"
              type="text" 
              rows="6"
            />
          </div>
        </div>

        <div class="button-container" @click="coming_soon_prompt">
          <div class="upload-photo-button">
            <img class="icon" :src="image_upload_icon" />
            <div>UPLOAD PHOTO</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "RecipeDetails",
  props: {
    recipe: { type: Object, required: true },
    errors: { type: Object, required: true },
    expanded: { type: Boolean, required: true },
    is_valid: { type: Boolean, required: true },
    toggle_section: { type: Function, required: true },
    coming_soon_prompt: { type: Function }
  },
  data() {
    return {
      image_upload_icon: "https://d38il9bndsnkm4.cloudfront.net/assets/images/icons/cloud-upload.svg"
    };
  }
};
</script>

<style scoped lang="scss">
@import "@css/_reset.scss";
@import "@css/_tracker_reset.scss";
@import "@css/transitions";

.expandable-row {
  padding: 0px;
  margin-bottom: 8px;

  .row-header {
    display: flex;
    align-items: center;
    padding: 16px 12px;
    border-bottom: 1px solid #e2e2e2;
    margin-top: 8px;
    cursor: pointer;

    p {
      margin: 0px 12px 0px 0px;
    }

    .indicator {
      display: block;
      min-width: 8px;
      min-height: 8px;
      border-radius: 50%;
      background-color: #a189cb;
      margin-right: 8px;
    }

    .icon {
      margin-left: auto;
      color: $endurance;
    }
  }

  .row-content {
    padding: 24px 12px 0px 12px;

    .input-field {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0px 24px 24px 0px;

      /* Rails CSS defines global .error and .error-message styles - until that's refactored use a scoped class name */
      .error-msg-temp-class {
        color: #E84C71;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        margin-top: 4px;
        margin-left: 12px;
      }

      .input-container {
        background-color: #FAFAFA;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 2px solid $endurance;
        padding: 6px 12px;
        width: 100%;

        &.error-temp-class {
          border-bottom: 2px solid #E84C71;

          .input-label {
            color: #E84C71;
          }
        }

        .input-label {
          color: $endurance;
          font-size: 12px;
          font-weight: 600;
          margin: 0px 0px 6px 0px;
        }

        input {
          color: #000000;
          background-color: transparent;
          font-size: 14px;
          font-weight: 600;
          padding: 0px;
          margin: 0px;
          resize: none;
          width: 100%;
          height: 28px;
        }

        input::placeholder {
          color: #acacac;
          font-size: 16px;
          font-weight: normal;
        }

        input:focus {
          outline: none !important;
        }

        textarea {
          color: #000000;
          background-color: transparent;
          font-size: 14px;
          font-weight: 600;
          padding: 0px;
          margin: 0px;
          resize: none;
          width: 100%;

          &.textarea-lg {
            height: 130px;
          }
        }

        textarea::placeholder {
          color: #acacac;
          font-size: 16px;
          font-weight: normal;
        }

        textarea:focus {
          outline: none !important;
        }

        select {
          background-color: transparent;
          width: 100%;
        }

        select:focus {
          outline: none !important;
        }
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
      height: 40px;
      cursor: pointer;
      color: #959595;

      .upload-photo-button {
        display: flex;

        img.icon {
          margin-right: 10px;

        }
      }

      p {
        margin-left: 20px;
      }
    }
  }
}
</style>
