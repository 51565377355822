<template>
  <div id="custom-foods-recipes">
    <AddToMealModal
      v-if="meal_modal_visible"
      :recipe="recipe_to_add"
      :default_meal_id="meal_id"
      :add_to_meal="add_recipe_to_meal"
      :close="() => { meal_modal_visible = false }"
    />

    <div class="nav-bar">
      <div class="left-button" @click="close">
        <i class="fa fa-chevron-left fa-lg" />
        Back
      </div>
      <div class="title">Custom</div>
    </div>

    <div v-if="!disable_foods_tab" class="tabs-container">
      <router-link
        :to="{ path: '/custom/recipes', query: $route.query }"
        tag="div"
        @click.native="setLocalStorage('recipes')"
        :class="{ tab: true, selected: tab === 'recipes' }"
        >
         <p>RECIPES</p>
      </router-link>
      <router-link
        :to="{ path: '/custom/foods', query: $route.query }"
        tag="div"
        @click.native="setLocalStorage('foods')"
        :class="{ tab: true, selected: tab === 'foods' }"
      >
        <p>FOODS</p>
      </router-link>
    </div>

      <div class="new-button" @click="new_food_or_recipe">
        <div class="icon">
          <i class="fa fa-plus-circle" />
        </div>
        <p>{{ button_text }}</p>
      </div>

      <div class="search-container">
        <div class="search-input">
          <div class="icon">
            <i class="fa fa-search" />
          </div>
          <input
            v-bind:value="search_term"
            v-on:input="search_term = $event.target.value.trim()"
            type="text"
            :placeholder="placeholder_text"
          />
          <div class="icon clear-button" @click="search_term = ''">
            <i class="fa fa-times" />
          </div>
        </div>

        <Select :options="sort_options" @select="change_sort">
          <div class="selected-field">
            <i class="fa fa-sort icon" />
            <p>{{ selected_sort }}</p>
          </div>
        </Select>
      </div>

      <div v-if="search_term" class="search-results-container">
        <ItemList
          :item_type="tab"
          :items="filtered_and_sorted_items"
          :add_item="tab === 'foods' ? toggle_food : open_add_recipe"
          :edit_item="tab === 'foods' ? edit_custom_food : edit_recipe"
          :delete_item="tab === 'foods' ? delete_custom_food : delete_recipe"
        />
      </div>
      <ItemList
        v-else
        :item_type="tab"
        :items="filtered_and_sorted_items"
        :add_item="tab === 'foods' ? toggle_food : open_add_recipe"
        :edit_item="tab === 'foods' ? edit_custom_food : edit_recipe"
        :delete_item="tab === 'foods' ? delete_custom_food : delete_recipe"
       />

      <div v-if="tab === 'foods'" class="button-row">
        <button
          :class="{ 'add-to-food-log-button': true, 'disabled': selected_food_ids.length === 0 }"
          :disabled="selected_food_ids.length === 0"
          @click="() => add_foods_to_meal()"
        >
          Add to Food Log
        </button>
      </div>

    <div v-if="custom_food_modal_visible" class="new-modal-backdrop" @click="custom_food_modal_visible = false">
      <div class="new-modal-card" v-on:click.stop>
        <div class="new-modal-content">
          <p class="modal-header">
            <span v-if="custom_food.id">Edit</span>
            <span v-else>Create New</span>
            Custom Food
          </p>
          <p class="modal-text">Enter macronutrients in grams for 1 serving as shown on label</p>

          <div class="input-container">
            <p class="select-meal-prompt">Food Name</p>
            <input v-model="custom_food.name" type="text" placeholder="Enter food name" />
          </div>
          <p v-if="show_form_errors && form_errors.name" class="select-meal-error">*Required Field</p>

          <div class="custom-macros-row">
            <div>
              <div class="input-container custom-macro-input">
                <p class="select-meal-prompt">Carb (g)</p>
                <input v-model.number="custom_food.carbohydrates" type="number" min="0" placeholder="0" />
              </div>
              <p v-if="show_form_errors && form_errors.carbohydrates" class="select-meal-error">*Required</p>
            </div>

            <div class="plus-sign">
              <p>+</p>
            </div>

            <div>
              <div class="input-container custom-macro-input">
                <p class="select-meal-prompt">Protein (g)</p>
                <input v-model.number="custom_food.protein" type="number" min="0" placeholder="0" />
              </div>
              <p v-if="show_form_errors && form_errors.protein" class="select-meal-error">*Required</p>
            </div>

            <div class="plus-sign">
              <p>+</p>
            </div>

            <div>
              <div class="input-container custom-macro-input">
                <p class="select-meal-prompt">Fat (g)</p>
                <input v-model.number="custom_food.fat" type="number" min="0" placeholder="0" />
              </div>
              <p v-if="show_form_errors && form_errors.fat" class="select-meal-error">*Required</p>
            </div>
          </div>
          <div class="custom-macros-row">
            <div class="plus-sign" style="margin-top: 0px;">
              <p>=</p>
            </div>
            <div class="input-container custom-macro-input" style="border-bottom: none;">
              <input :value="custom_food_calories" disabled type="text">
            </div>
          </div>
          <div class="custom-macros-row">
            <div style="margin-right: 12px;">
              <div class="input-container custom-macro-input">
                <p class="select-meal-prompt">Single Serving Size</p>
                <input v-model.number="custom_food.common_serving_quantity" type="number" placeholder="ex. 135" />
              </div>
              <p v-if="show_form_errors && form_errors.common_serving_quantity" class="select-meal-error">*Required Field</p>
            </div>

            <div>
              <div class="input-container custom-macro-input">
                <p class="select-meal-prompt">Single Serving Units</p>
                <input v-model="custom_food.common_serving_unit_name" type="text" placeholder="ex. grams" />
              </div>
              <p v-if="show_form_errors && form_errors.common_serving_unit_name" class="select-meal-error">*Required Field</p>
            </div>
          </div>
        </div>
        <div class="close-button-bottom">
          <p @click="custom_food_modal_visible = false; show_form_errors = false;">CANCEL</p>
          <p :class="{ 'disabled': !custom_food_is_valid }" :disabled="!custom_food_is_valid" @click="save_custom_food(custom_food)">SAVE</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { capitalize, orderBy } from "lodash";

import API from "@js/api/rails_client";
import { filter_by_search_term, calculate_calories } from "@js/lib/utils";

import ItemList from "@js/custom/item_list.vue";
import AddToMealModal from "@js/recipes/add_to_meal_modal.vue";
import Select from "@js/shared/select.vue";

export default {
  components: { ItemList, AddToMealModal, Select },
  props: {
    tab: { type: String, required: true },
    meal_id: { type: String }
  },
  data() {
    return {
      search_term: "",
      custom_food: {
        name: "",
        carbohydrates: 0,
        protein: 0,
        fat: 0,
        common_serving_quantity: 1,
        common_serving_unit_name: "serving"
      },
      selected_food_ids: [],
      custom_food_modal_visible: false,
      recipe_to_add: null,
      meal_modal_visible: false,
      sort_by: "recent",
      form_errors: {},
      show_form_errors: false
    };
  },
  created() {
    if (this.$route.query && this.$route.query.newfood) {
      this.edit_custom_food();
    }
  },
  computed: {
    disable_foods_tab() {
      // absence of meal_id implies this was opened from the <Recipes /> nav
      return !this.meal_id;
    },
    button_text() {
      // slice(0, -1) => singularize
      return `Create New Custom ${capitalize(this.tab).slice(0, -1)}`;
    },
    placeholder_text() {
      return `Search Custom ${capitalize(this.tab)}`;
    },
    selected_item_ids() {
      return this.tab === "foods" ? this.selected_food_ids : this.selected_recipe_ids;
    },
    sort_options() {
      return [
        { name: "Alphabetical", id: "alphabetical" },
        { name: "Recently Created", id: "recent" }
      ].map(option => ({ ...option, selected: option.id === this.sort_by}));
    },
    selected_sort() {
      return this.sort_options.find(option => option.id === this.sort_by).name;
    },
    items() {
      if (this.tab === "foods") {
        return this.custom_foods.map(food => (
          { ...food, selected: this.selected_food_ids.includes(food.id) }
        ));
      } else {
        return this.custom_recipes;
      }
    },
    filtered_and_sorted_items() {
      const filtered_items = filter_by_search_term(this.items, this.search_term);

      if (this.sort_by === "recent") {
        return orderBy(filtered_items, item => item.id, "desc");
      } else if (this.sort_by === "alphabetical") {
        return orderBy(filtered_items, item => item.name.toLowerCase(), "asc");
      }
    },
    custom_foods() {
      return this.$store.state.rails.custom_foods;
    },
    custom_recipes() {
      return this.$store.state.custom_recipes;
    },
    custom_food_is_valid() {
      if (
        this.name_is_valid() &&
        this.number_is_valid('common_serving_quantity') &&
        this.serving_unit_name_is_valid() &&
        this.number_is_valid('carbohydrates') &&
        this.number_is_valid('protein') &&
        this.number_is_valid('fat')
      ) {
        return true;
      } else {
        this.form_errors = {
          name: !this.name_is_valid(),
          common_serving_quantity: !this.number_is_valid('common_serving_quantity'),
          common_serving_unit_name: !this.serving_unit_name_is_valid(),
          carbohydrates: !this.number_is_valid('carbohydrates'),
          protein: !this.number_is_valid('protein'),
          fat: !this.number_is_valid('fat')
        };
        return false;
      }
    },
    custom_food_calories() {
      //Conditionally Round calculated calories to 1 decimal place
      let calculatedCalories = calculate_calories(this.custom_food, true);
      let formattedCalories =  Math.round(calculatedCalories)
      return `${formattedCalories} total calories`;
    }
  },
  methods: {
    close() {
      if (this.meal_id) {
        this.$router.push({ path: "/food_log", query: { meal_id: this.meal_id }});
      } else {
        this.$router.push("/recipes");
      }
    },
    new_food_or_recipe() {
      if (this.tab === "foods") {
        this.edit_custom_food();
      } else {
        this.$router.push("/custom/recipes/new");
      }
    },
    edit_custom_food(food) {
      if (food) {
        this.custom_food = { ...food };
      } else {
        const { name, carbs, protein, fat } = this.$route.query;
        this.custom_food = {
          name: name || "",
          carbohydrates: parseFloat(carbs) || 0,
          protein: parseFloat(protein) || 0,
          fat: parseFloat(fat) || 0,
          common_serving_quantity: 1,
          common_serving_unit_name: "serving"
        };
      }
      this.custom_food_modal_visible = true;
    },
    edit_recipe(recipe) {
      this.$router.push(`/custom/recipes/${recipe.id}/edit`);
    },
    toggle_food(food) {
      const i = this.selected_food_ids.indexOf(food.id);

      if (i > -1) {
        this.selected_food_ids.splice(i, 1);
      } else {
        this.selected_food_ids.push(food.id);
      }
    },
    async add_foods_to_meal(new_food) {
      let foods;
      if (new_food) {
        foods = [new_food];
      } else {
        foods = this.custom_foods.filter(food => this.selected_food_ids.includes(food.id));
      }

      foods.forEach(food => this.$store.dispatch("rails/log_custom_food_portion", { food, meal_id: this.meal_id }));

      this.$store.dispatch("notify_user", {
        type: "info",
        msg: "Food has been successfully added to your food log :)"
      });
      this.$router.push({ path: "/food_log", query: { meal_id: this.meal_id } });
    },
    add_recipe_to_meal(recipe, meal_id) {
      this.$store.dispatch("loading", true);

      this.$store.dispatch("rails/log_recipe_portions", { recipe, meal_id });

      this.$store.dispatch("loading", false);
      this.$store.dispatch("notify_user", {
        type: "info",
        msg: "Recipe has been successfully added to your food log :)"
      });
      this.$router.push({ path: "/food_log", query: { meal_id } });
    },
    open_add_recipe(recipe) {
      this.recipe_to_add = recipe;

      if (this.meal_id) {
        // if user opened Custom Foods from a meal card, immediately add this recipe to that meal
        this.add_recipe_to_meal(recipe, this.meal_id);
      } else {
        this.meal_modal_visible = true;
      }
    },
    async save_custom_food(food) {
      if (!this.custom_food_is_valid) {
        this.show_form_errors = true;
        return false;
      }
      this.show_form_errors = false;

      try {
        this.$store.dispatch("loading", true);

        food = {
          ...food,
          calories: calculate_calories(food, true),
          // API call requires name of the actual ingredient column as param
          common_unit_name: food.common_serving_unit_name,
          is_custom: true,
          is_favorite: true,
          favoritable: true
        };

        const action = food.id ? "update" : "create";
        const id = await this.$store.dispatch(`rails/${action}_custom_food`, food);
        if (action === 'create' && id) {
          food.id = id;
        }

        if (action === "update") { // existing food that's being updated
          this.$store.dispatch("notify_user", { type: "info", msg: "Food was successfully saved :)" });
        } else { // new food that's being created
          this.add_foods_to_meal(food);
        }
      } catch (error) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to save food" });
        console.log(error);
      } finally {
        this.custom_food_modal_visible = false;
        this.$store.dispatch("loading", false);
      }
    },
    delete_custom_food(food) {
      try {
        this.$store.dispatch("rails/delete_custom_food", food.id);

        this.$store.dispatch("notify_user", { type: "info", msg: "Custom food was deleted" });
      } catch (error) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to delete custom food" });
        console.log(error.response);
      }
    },
    async delete_recipe(recipe) {
      try {
        this.$store.dispatch("loading", true);
        this.$store.dispatch("delete_custom_recipe", recipe.id);
        this.$store.dispatch("notify_user", { type: "info", msg: "Custom recipe was deleted" });
      } catch (error) {
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to delete custom recipe" });
        console.log(error);
      } finally {
        this.$store.dispatch("loading", false);
      }
    },
    change_sort(sort_by) {
      this.sort_by = sort_by;
    },
    // custom food form validation
    name_is_valid() {
      let name = this.custom_food.name || '';
      name = name.trim();
      return name && name !== '';
    },
    serving_unit_name_is_valid() {
      let unit_name = this.custom_food.common_serving_unit_name || '';
      unit_name = unit_name.trim();
      return unit_name && unit_name !== '' && isNaN(unit_name);
    },
    number_is_valid(field) {
      return this.custom_food[field] || this.custom_food[field] === 0; // we accept any number, including 0
    },
    // end custom food form validation
    setLocalStorage(type) {
      try {
        localStorage.customTab = type;
      } catch (ex) {}
    }
  }
};
</script>

<style scoped lang="scss">
  @import "@css/_reset.scss";
  @import "@css/_tracker_reset.scss";

  #custom-foods-recipes {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    height: calc(100% - 64px);

    .iphone-x & {
      height: calc(100% - 72px);
    }

    .nav-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 58px;
      min-height: 58px;
      background-color: #ffffff;
      &.favorites {
        background-color: #00A881;
        .left-button {
          top: 0px;
          left: 0px;
          i {
            color: #ffffff;
            margin: 0px;
          }
        }
        .title {
          color: #ffffff;
        }
      }

      .left-button, .right-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 58px;
        height: 58px;
        cursor: pointer;
        color: $endurance;
        font-size: 12px;
      }
      .left-button {
        top: 0px;
        left: 12px;
        i {
          margin-right: 8px;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
    }

    .tabs-container {
      display: flex;
      flex-direction: row;
      padding: 0px;
      margin: 0px 0px 12px 0px;
      .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #E6E6E6;
        width: 100%;
        height: 40px;
        cursor: pointer;
        p {
          font-weight: 600;
          font-size: 14px;
          color: #4f4f4f;
        }
        &.selected {
          border-bottom: 2px solid $endurance;
          p {
            color: $endurance;
          }
        }
      }
    }

    .food-results {
      flex: 1;
    }

    .search-container {
      display: flex;
      flex-direction: row;
      padding: 10px 24px;
      min-height: 72px;

      .search-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #F0E8FB;
        border-radius: 8px;
        margin: 0px;
        width: 100%;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          min-width: 30px;
          height: 100%;
          cursor: pointer;
          i {
            color: $endurance;
            font-size: 12px;
          }
          &.clear-button {
            margin-left: auto;
          }
        }
        input {
          font-size: 16px;
          font-weight: normal;
          color: #4f4f4f;
          background-color: transparent;
          padding: 0px;
          height: 40px !important;
          line-height: 40px;
          width: 100%;
        }
        input::placeholder {
          color: #4f4f4f;
          font-size: 16px;
          font-weight: normal;
        }
        input:focus {
          outline: none !important;
        }
      }

      .macrostax-select {
        display: inline-block;
        width: 25%;
        margin: 8px 0px 0px 8px;

        ::v-deep .select-list {
          min-width: 200px !important;
        }
      }
      ::v-deep .selected-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-bottom: none;
        margin: 0px;
        padding: 6px;
        max-width: 80px;
        min-width: 80px;
        p {
          margin: 0px 0px 0px 6px;
          color: $endurance;
          font-size: 12px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        i {
          color: $endurance;
          font-size: 12px;
          margin: 0px 0px 0px auto;
        }
      }
      &.collapsed-header {
        .sort-select {
          display: inline-block;
          width: 100%;
          margin-top: 0px;
          ::v-deep .select-list {
            min-width: 200px !important;
          }
        }
        ::v-deep .selected-field {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          border-bottom: none;
          margin: 0px;
          padding: 6px;
          p {
            margin: 0px 8px 0px 0px;
            color: #4f4f4f;
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: right;
          }
          i {
            color: #4f4f4f;
            font-size: 8px;
            margin: 0px 0px 0px auto;
          }
        }
        ::v-deep .select-list {
          min-width: 200px !important;
          right: 0px;
        }
      }
    }

    .search-results-container {
      width: 100%;
      height: 100%;
      margin: -16px 0px 0px 0px;
      padding: 0px 24px 16px 24px;
      overflow: auto;
      .food-results {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 8px 8px;
        border-top: 2px solid $endurance;
        .food-result {
          cursor: pointer;
        }
      }
    }

    .new-button {
      display: flex;
      flex-direction: row;
      padding: 18px 24px 10px 24px;
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        width: 24px;
        height: 100%;
        i {
          color: $endurance;
          font-size: 16px;
        }
      }
      p {
        font-size: 12px;
        font-weight: 600;
        color: $endurance;
      }
    }

    .button-row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 0px;
      .add-to-food-log-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        background-color: $endurance;
        height: 40px;
        padding: 0px 24px;
        border-radius: 22px;
        cursor: pointer;
        &.disabled {
          background-color: #E6E6E6;
          color: #959595;
        }
      }
    }
  }

  .new-modal-backdrop {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 50;
  }
  .new-modal-card {
    position: relative;
    width: 80%;
    max-width: 375px;
    max-height: 85%;
    background-color: #ffffff;
    border-radius: 12px;
    overflow: auto;

    .new-modal-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 18px 0px 0px 0px;
      padding: 0px 20px 14px 20px;

      .modal-header {
        font-family: Montserrat;
        color: #000000;
        font-size: 18px;
        font-weight: 600;
        margin: 0px 0px 18px 0px;
        text-align: left;
      }

      .modal-sub-header {
        color: #000000;
        font-size: 15px;
        font-weight: 600;
        margin: 0px 0px 8px 0px;
        text-align: left;
      }

      .modal-text {
        color: #4f4f4f;
        font-size: 12px;
        font-weight: 600;
        margin: 0px 0px 12px 0px;
        text-align: left;
      }

      .input-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 2px solid $endurance;
        margin-bottom: 16px;
        input {
          width: 100%;
          text-align: left;
          font-weight: 600;
          border: none !important;
        }
        input:focus {
          outline: none !important;
        }
      }
    }
    .close-button-top {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 14px;
      cursor: pointer;

      i {
        color: $endurance;
        font-size: 15px;
      }
    }
    .close-button-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;

      p {
        font-family: Montserrat;
        color: $endurance;
        font-size: 15px;
        font-weight: 700;
        margin: 0px;
        padding: 14px 14px 20px 14px;

        &.disabled {
          color: #4f4f4f;
        }
      }
    }
  }

  .select-meal-prompt {
    color: $endurance;
    font-size: 12px;
    font-weight: 600;
    margin: 8px 0px 0px 0px;
    text-align: left;
  }

  .select-meal-error {
    color: #E84C71;
    font-size: 12px;
    font-weight: 600;
    margin: -6px 0px 8px 0px;
    text-align: left;
  }

  .custom-macros-row {
    display: flex;
    flex-direction: row;
    .custom-macro-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 2px solid $endurance;
      margin: 0px;
      input {
        text-align: center !important;
      }
    }
    .plus-sign {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0px 16px 0px;
      min-width: 30px;
      p {
        font-size: 18px;
      }
    }
  }
</style>
