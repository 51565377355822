<template lang="html">
  <div class="goal-form">
    <p>Select all that apply:</p>

    <div class="goals-container" v-for="goal in goals">
      <div class="goal" @click="toggle_goal(goal)" v-if="!goal.editable">
        <div class="checkbox-container">
          <label>
            <input disabled="true" type="checkbox" :id="goal.id" :value="goal" v-model="checked_goals">
            <span></span>
          </label>
        </div>
        <div class="value-container">
          <label :for="goal">{{ goal.value }}</label>
        </div>
      </div>
      <div class="goal" @click="toggle_goal(goal, 'custom')" v-else>
        <div class="checkbox-container">
          <label>
            <input disabled="true" type="checkbox" :value="goal" v-model="checked_custom_goals">
            <span></span>
          </label>
        </div>
        <div class="value-container custom">
          <p>Custom Goal</p>
          <input @click.stop type="text" :placeholder="goal.value" v-model="goal.value">
        </div>
      </div>
    </div>

    <div class="action-button-container">
      <button class="primary-action-button" @click="submit">{{ submit_text }}</button>
      <button class="cancel-button" @click="$router.push('/')">CANCEL</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submit_action: { type: Function, required: true },
    submit_text: { type: String, required: true }
  },
  data() {
    const { default_goals, custom_goals } = this.$store.state.rails.mtc_challenge.user_data;

    return {
      checked_goals: default_goals,
      checked_custom_goals: custom_goals
    }
  },
  computed: {
    goals() {
      let { transformation_goals, user_data: { custom_goals } } = this.$store.state.rails.mtc_challenge;

      // clone objects to prevent mutation of store state
      transformation_goals = transformation_goals.map(goal => ({ ...goal }));
      custom_goals = custom_goals.map(goal => ({ ...goal }));

      if (custom_goals.length > 0) {
        // remove default editable goals
        transformation_goals = transformation_goals.filter(goal => goal.editable === false);
        // add custom goals
        return [...transformation_goals, ...custom_goals];
      }

      return transformation_goals;
    }
  },
  methods: {
    toggle_goal(goal, type) {
      let goals = type === "custom" ? this.checked_custom_goals : this.checked_goals;

      if (this.is_checked(goal, goals)) {
        goals = goals.filter(({ id }) => goal.id !== id);
      } else {
        goals = [...goals, goal];
      }

      if (type === "custom") {
        this.checked_custom_goals = goals;
      } else {
        this.checked_goals = goals;
      }
    },
    is_checked(goal, goals) {
      return goals.find(({ id }) => id === goal.id);
    },
    submit() {
      if ([ ...this.checked_goals, ...this.checked_custom_goals].length === 0) {
        this.$store.dispatch("notify_user", {
          type: "error",
          msg: "Please select at least one goal."
        });
      } else {
        this.submit_action({ default_goals: this.checked_goals, custom_goals: this.checked_custom_goals });
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .goal-form {
    margin-bottom: 33px;

    p {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: #4F4F4F;
      margin-bottom: 27px;
    }

    .goals-container {
      padding-bottom: 32px;

      .goal {
        display: flex;
        flex-direction: row;

        .checkbox-container {
          display: flex;
          padding-top: 4px;
          margin-left: 15px;
          margin-right: 15px;

          label {
            position: relative;
          }
          span {
            width: 16px;
            height: 16px;
            border: 1px solid #4F4F4F;
            display: inline-block;
            border-radius: 2px;
            &:after {
              content: "";
              position: absolute;
              top: 2px;
              left: 6px;
              border-bottom: 1px solid #FFFFFF;
              border-right: 1px solid #FFFFFF;
              height: 9px;
              width: 5px;
              transform: rotate(45deg);
              visibility: hidden;
            }
          }
          input {
            display: none;
            &:checked ~ span {
              border: 1px solid #6B40AB;
              background: #6B40AB;
              &:after {
                visibility: visible;
              }
            }
          }
        }

        .value-container {
          border-bottom: 1px solid #D8D8D8;
          padding-bottom: 24px;
          flex-grow: 1;
          justify-content: left;

          &.custom {
            background: #FAFAFA;
            border-radius: 8px 8px 0px 0px;
            padding-bottom: 0px;
            border-bottom: 2px solid #6B40AB;

            p {
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              color: #6B40AB;
              margin-left: 20px;
              margin-top: 5px;
              margin-bottom: 0px;
            }

            input {
              background: #FAFAFA;
              border: none;
              margin-left: 20px;
              padding-top: 0px;
              padding-bottom: 0px;
              height: auto;
              margin-bottom: 6px;
            }
          }

          label {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.25px;
            color: #4F4F4F;
          }
        }
      }
    }

    .action-button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;

      .primary-action-button {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        background: #6B40AB;
        border-radius: 22px;
        padding-right: 64px;
        padding-left: 64px;
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: nowrap;
        border-color: transparent;
      }

      .cancel-button {
        margin-left: 24px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #6B40AB;
        background: none;
        border: none;
      }
    }
  }
</style>
