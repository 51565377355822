<template lang="html">
  <div class="profile-container" style="overflow: hidden">
    <div class="nav">
      <div class="back-button" @click="$router.push('/profile')">
        <i class="fa fa-chevron-left"></i>
        <p>Summary</p>
      </div>
      <p class="title">Billing</p>
    </div>

    <div class="content">
      <Subscription
        :planName="planName"
        :amount="amount"
        :interval="interval"
        :interval_count="interval_count"
        :nextBillDate="nextBillDate"
        :canceled="canceled"
        :legacyPlan="legacyPlan"
      />

      <CreditCard
        v-if="!legacyPlan"
        :card="existingCard"
        :scrollToTop="scrollToTop"
      />

      <div v-if="!legacyPlan && !canceled" class="auto-renew">
        <button @click="toggleModal">Stop Auto-Renew</button>
      </div>

      <Cancel
        v-if="showModal"
        @close="toggleModal"
        :reactivateSubscription="reactivateSubscription"
        :reasonsList="reasonsList"
        :nextBillDate="nextBillDate"
        :scrollToTop="scrollToTop"
      />

      <div v-if="canceled" class="auto-renew reactivate" >
        <button v-if="csCancellation" id="Intercom">Reactivate with StaxChat</button>
        <button v-else-if="!legacyPlan" @click="reactivateSubscription">Reactivate Auto-Renew</button>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@js/api/rails_client";
import Store from "@js/store";
import { shuffleArray } from "@js/lib/utils";

import Subscription from './subscription.vue';
import CreditCard from './credit_card/show.vue';
import Cancel from './subscription/cancel.vue';

export default {
  components: { Subscription, CreditCard, Cancel },
  props: {
    scrollToTop: { type: Function },
  },
  data() {
    return  {
      showModal: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToTop();
    });
  },
  methods: {
    async reactivateSubscription() {
      this.scrollToTop();
      this.$store.dispatch("loading", true);
      try {
        const cancellationID = this.$store.state.rails.billing.cancellation.id;
        const response = await API.reactivateSubscription(cancellationID);

        this.$store.dispatch("rails/setReactivatedSubscription", response.data);
        this.$store.dispatch("notify_user", { type: "info", msg: "Auto-Renew Successfully Reactivated"});
        this.$store.dispatch("loading", false);

        if (this.showModal) {
          this.toggleModal();
        }
      } catch(e) {
        this.$store.dispatch("loading", false);
        this.$store.dispatch("notify_user", { type: "error", msg: "Unable to reactivate subscription!"});
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
      $('#modal-background').toggle(this.showModal);
    },
  },
  computed: {
    legacyPlan() {
      return ['active_gym_challenge', 'grandfathered', 'lifetime'].includes(this.$store.state.rails.billing.subscription.plan.name);
    },
    planName() {
      return this.$store.state.rails.billing.subscription.plan.name;
    },
    nextBillDate() {
      return moment.unix(this.$store.state.rails.billing.subscription.next_bill_date).format("MMMM D, YYYY");
    },
    amount() {
      return this.$store.state.rails.billing.subscription.plan.amount;
    },
    interval() {
      return this.$store.state.rails.billing.subscription.plan.interval;
    },
    interval_count() {
      return this.$store.state.rails.billing.subscription.plan.interval_count || 0;
    },
    canceled() {
      return this.$store.state.rails.billing.subscription.plan.canceled;
    },
    existingCard() {
      return {
        owner: this.owner,
        last4: this.last4,
        zipcode: this.zipcode,
        expireDate: this.expireDate
      }
    },
    owner() {
      return this.$store.state.rails.billing.card.owner;
    },
    zipcode() {
      return this.$store.state.rails.billing.card.zipcode;
    },
    expireDate() {
      return this.$store.state.rails.billing.card.exp_date;
    },
    last4() {
      return this.$store.state.rails.billing.card.last4;
    },
    reasonsList() {
      shuffleArray(this.$store.state.rails.cancellation_reasons);
      return this.$store.state.rails.cancellation_reasons;
    },
    csCancellation() {
      return this.$store.state.rails.billing.cancellation.cs_cancellation;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!Store.state.rails.billing.subscription.plan.name) {
      Promise.all([
        Store.dispatch("rails/getBillingData"),
        Store.dispatch("rails/getCancellationReasons")
      ]).then(() => {
        next();
      })
    } else {
      next();
    }
  },
  destroyed() {
    $('#modal-background').hide();
  },
};
</script>

<style scoped lang="scss">
@import "@css/components/profile";

.content {
  padding-top: 30px;

  .auto-renew {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 98px;
    margin-top: 32px;

    &.reactivate {
      button {
        color: #6B40AB;
        border: 2px solid #6B40AB;
        outline: none;
      }
    }

    button {
      width: 224px;
      min-height: 40px;
      background: #FFFFFF;
      border: 2px solid #E84C71;
      box-sizing: border-box;
      border-radius: 22px;
      font-style: normal;
      font-weight: bold;
      line-height: 24px;
      color: #E84C71;
      outline: none;
    }
  }
}
</style>
