<template lang="html">
  <div class="portion-wrapper" :class="`position-${swipe_position}`">
    <div class="swipe-button favorite" @click.stop="toggle_favorite(portion)">
      <img :src="isFavorite ? heart_white_solid_icon : heart_white_outline_icon" />
    </div>

    <div class="portion">
      <div
        class="ingredient"
        @click.stop="toggle_edit"
        v-touch:swipe.left="on_swipe"
        v-touch:swipe.right="on_swipe"
      >
        <div class="icon">
          <img :src="ingredient_icon" alt="Ingredient Icon">
        </div>
        <div class="ingredient-info">
          <div class="ingredient-header">
            <span class="name">{{portion.name}}</span>
            <div class="actions" :class="{ disabled: isCreating }">
              <MiniSpinner v-if="isCreating" color="pink" />

              <div class="action">
                <img
                  v-if="portion.favoritable"
                  @click.stop="toggle_favorite(portion)"
                  :src="isFavorite ? heart_pink_solid_icon : heart_dark_gray_outline_icon"
                  :class="{ solid: isFavorite, outline: !isFavorite }"
                />
              </div>
              <div class="action">
                <img :src="trash_can_gray_outline_icon" @click.stop="delete_portion(portion)" />
              </div>
            </div>
          </div>

          <div v-if="editing === false" class="serving">
            <span>{{`${portion.serving_size} ${units}`}}</span>
            <div class="edit-icon">
              <img :src="pencil_gray_icon" alt="Edit" />
            </div>
          </div>

          <div class="macros">
            <span class="macro">C</span>
            <span class="value">{{macros.carbohydrates}}g</span>
            <span class="macro">P</span>
            <span class="value">{{macros.protein}}g</span>
            <span class="macro">F</span>
            <span class="value">{{macros.fat}}g</span>
            <span class="macro">Cal</span>
            <span class="value">{{macros.calories}}</span>
          </div>

          <!-- a negative recipe_id means this was a custom recipe that's since been deleted  -->
          <div
            v-if="recipe_id && recipe_id > 0 && editing === false"
            @click.stop="viewRecipe(recipe_id)"
            class="view-recipe"
          >
            VIEW RECIPE
          </div>
        </div>
      </div>
      <div v-if="editing === true" class="edit-form">
        <div class="fields">
          <div class="input-container" style="min-width: 88px; margin-right: 24px;">
            <p class="input-label">Quantity</p>
            <input :value="serving.serving_size" @input="debounceInput" step="any" min="0" type="number" v-on:blur="padZero" />
          </div>
          <div class="input-container" style="width: 100%">
            <p class="input-label">Unit</p>
            <select v-model="serving.units" name="unit_names">
              <option v-for="{ name, units } in unit_options" :value="units">{{ name }}</option>
            </select>
          </div>
        </div>
        <div v-if="invalid_serving_size" class="serving-invalid-notice">
          Serving size must be greater than zero.
        </div>
      </div>
    </div>

    <div class="swipe-button delete" @click.stop="delete_portion(portion)">
      <img :src="trash_can_white_outline_icon" />
    </div>
  </div>
</template>

<script>
import { pick, isEqual, debounce } from "lodash";
import { validate as is_uuid } from "uuid";

import { round_macros } from "@js/lib/utils";
import { serving_units } from "@js/lib/rails_compatibility_helpers";

import MiniSpinner from "@js/shared/mini_spinner.vue";

import trash_can_icon from "@images/trash-can.svg";
import trash_can_gray_outline_icon from "@images/trash-can-gray-outline.svg";
import trash_can_white_outline_icon from "@images/trash-can-white-outline.svg";
import heart_white_solid_icon from "@images/heart-white-solid.svg";
import heart_pink_solid_icon from "@images/heart-pink-solid.svg";
import heart_white_outline_icon from "@images/heart-white-outline.svg";
import heart_dark_gray_outline_icon from "@images/heart-dark-gray-outline.svg";
import pencil_gray_icon from "@images/pencil-gray.svg";

export default {
  components: { MiniSpinner },
  props: {
    portion: { type: Object, required: true },
    meal_id: { type: String, required: true },
    editing: { type: Boolean, required: true },
    edit_portion: { type: Function, required: true },
    toggle_favorite: { type: Function, required: true },
    delete_portion: { type: Function, required: true },
    viewRecipe: { type: Function, required: true }
  },
  data() {
    const serving = {
      serving_size: this.portion.serving_size,
      units: this.portion.units
    };

    return {
      serving,
      initial_serving: { ...serving },
      invalid_serving_size: false,
      invalid_feedback_timer: null,
      trash_can_icon,
      trash_can_gray_outline_icon,
      trash_can_white_outline_icon,
      heart_white_solid_icon,
      heart_pink_solid_icon,
      heart_white_outline_icon,
      heart_dark_gray_outline_icon,
      pencil_gray_icon,
      swipe_position: 'middle'
    };
  },
  computed: {
    units() {
      return serving_units(this.portion);
    },
    unit_options() {
      const { units, common_serving_unit_name, measurement_size_unit_name } = this.portion;
      let options = [];

      if (common_serving_unit_name) {
        options.push({ name: common_serving_unit_name, units: "common" });
      }
      if (measurement_size_unit_name) {
        // NOTE:
        // Ingredient.distinct.pluck(:measurement_unit_name) => ["custom", "gram", "oz", nil, "g", "ml"]
        // Ingredient.where("created_at >= ?", 1.year.ago).distinct.pluck(:measurement_unit_name) => [nil, "custom", "gram"]

        options.push({ name: measurement_size_unit_name, units: "measurement" });
      }

      return options;
    },
    macros() {
      const macros = pick(this.portion, ["carbohydrates", "protein", "fat", "calories"]);
      return round_macros(macros, 0.5);
    },
    recipe_id() {
      return this.portion.root_recipe_id || this.portion.recipe_id;
    },
    isFavorite() {
      return this.portion.is_favorite;
    },
    isCreating() {
      return is_uuid(this.portion.id);
    },
    pending_deletion() {
      return this.$store.state.rails.portions_to_delete;
    },
    ingredient_icon() {
      if (this.recipe_id) {
        return "https://sfo2.digitaloceanspaces.com/icons/ingredient_recipe.svg";
      } else if (this.portion.is_custom) {
        return "https://sfo2.digitaloceanspaces.com/icons/ingredient_custom.svg";
      } else {
        return "https://sfo2.digitaloceanspaces.com/icons/ingredient_food.svg";
      }
    }
  },
  mounted() {
    // Upon logging a new portion, scroll it into view.
    if (this.editing) {
      this.$el.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  },
  watch: {
    portion: {
      handler({ serving_size, units }) {
        if (this.serving.serving_size !== serving_size || this.serving.units !== units) { // needed to prevent infinite loop
          this.serving = {
            serving_size,
            units
          }
        }
      }
    },
    serving: {
      deep: true,
      handler({ serving_size, units }) {
        if (!serving_size || isNaN(serving_size) || serving_size <= 0) {
          this.invalid_serving_size = true;
        } else {
          this.invalid_serving_size = false;
          this.$store.dispatch("rails/update_portion", {
            portion: { ...this.portion, units, serving_size },
            meal_id: this.meal_id,
            persist: !this.isCreating
          });
        }
      }
    },
    isCreating() {
      if (!isEqual(this.serving, this.initial_serving)) {
        this.$store.dispatch("rails/update_portion", {
          portion: { ...this.portion, ...this.serving },
          meal_id: this.meal_id,
          persist: true
        });
      }
    },
    isFavorite() {
      this.swipe_position = "middle";
    },
    pending_deletion(portions) {
      if (!portions) {
        // wait for delete modal cancel/confirm before hiding swipe button
        this.swipe_position = "middle";
      }
    }
  },
  methods: {
    padZero() {
      this.updatePortionSize(parseFloat(this.serving.serving_size));
    },
    debounceInput: debounce(function(event) { 
      const debouncedQuantity = parseFloat(event.target.value);
      if (typeof debouncedQuantity === 'number') {
        // pass the input value rather than the parseFloat value because we don't
        // want to disrupt the user's input by adding a zero while they're typing
        this.updatePortionSize(event.target.value);
      } 
    }, 250),
    updatePortionSize(size) {
      this.serving.serving_size = size;
    },
    toggle_edit() {
      if (this.swipe_position === "middle") {
        this.edit_portion(this.editing ? null : this.portion.id);
      }
    },
    on_swipe(direction) {
      if (!this.editing && !this.isCreating && (direction === "left" || this.portion.favoritable)) {
        this.swipe_position = this.swipe_position === "middle" ? direction : "middle";
      }
    },
  }
};
</script>

<style scoped lang="scss">
.portion-wrapper {
  position: relative;
  display: flex;
  transition: margin 200ms linear;

  &.position-left {
    margin-right: 0px;
    margin-left: -140px;
  }

  &.position-middle {
    margin-left: -70px;
    margin-right: -70px;
  }

  &.position-right {
    margin-right: -140px;
    margin-left: 0px;
  }

  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .portion {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .swipe-button {
      margin-bottom: 0;

      &.favorite {
        border-bottom-left-radius: 8px;
      }

      &.delete {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.portion {
  position: relative;
  padding: 12px 12px 8px 12px;
  background-color: #fff;
  flex-grow: 1;
  overflow: hidden;
}

.swipe-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  margin: 1px 0;
  cursor: pointer;

  &.favorite {
    background-color: #f69;
  }

  &.delete {
    background-color: #e84c71;
  }

  img {
    width: 27px;
    height: 27px;
  }
}

.ingredient {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    width: 48px;
    min-height: 48px;
    height: 48px;
    border-radius: 4px;
    background-color: #def6ff;
    align-self: flex-start;
    margin-right: 12px;

    img {
      width: 32px;
      min-width: 32px;
      height: auto;
      object-fit: contain;
    }
  }

  .name {
    font-size: 14px;
    line-height: 18px;
    color: black;
    font-weight: 600;
    margin-bottom: 3px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .serving {
    display: flex;
    margin-bottom: 4px;

    span {
      font-size: 12px;
      font-weight: 600;
      color: #4f4f4f;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }

    .edit-icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;

      img {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.ingredient-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .ingredient-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;

    .actions {
      margin-left: auto;
      margin-right: 0px;
      display: flex;
      justify-content: flex-end;

      .action {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .macros {
    display: flex;
    font-weight: normal;
    color: black;
    font-size: 12px;
    margin-bottom: 6px;

    .macro {
      display: flex;

      &:not(:first-of-type) {
        margin-left: 10px;
      }
    }

    .value {
      margin-left: 2px;
    }
  }

  .view-recipe {
    color: $endurance;
    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat";
  }
}

.edit-form {
  display: flex;
  width: 100%;
  padding: 0px 12px;
  margin-bottom: 2px;
  overflow: hidden;
  // Not currently implemented; requires always rendering the form in the DOM with height 0 until opened.
  //   Can implement if someone notices/complains during QA, but I'm keeping the implementation simpler until then.
  // transition: max-height 200ms ease-in-out;

  .serving-invalid-notice {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: $error;
    padding: 8px 0 0;
    vertical-align: middle;
    margin: 0;
  }

  .fields {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 16px 0;

    .input-container {
      background-color: #FAFAFA;
      border-radius: 8px 8px 0px 0px;
      border-bottom: 2px solid $endurance;
      padding: 6px 12px;
      margin: 0px;

      .input-label {
        color: $endurance;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 6px;
      }

      input {
        color: #4f4f4f;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        padding: 0px;
        margin: 0px;
        resize: none;
        width: 100%;
        height: 28px;
        outline: none !important;
        border: none !important;
      }

      input::placeholder {
        color: #4f4f4f;
        font-size: 16px;
        font-weight: normal;
      }

      input:focus {
        outline: none !important;
        border: none !important;
      }

      textarea {
        color: #000000;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        padding: 0px;
        margin: 0px;
        resize: none;
        width: 100%;
      }

      textarea::placeholder {
        color: #4f4f4f;
        font-size: 16px;
        font-weight: normal;
      }

      textarea:focus {
        outline: none !important;
      }

      select {
        background-color: transparent;
        width: 100%;
        outline: none !important;
        border: none !important;
        font-weight: 600;
        color: #4f4f4f;
        font-size: 14px;
        margin-top: 3px;
      }

      select:focus {
        outline: none !important;
        border: none !important;
      }
    }

    .custom-select {
      width: 66%;
      min-width: unset;
      select {
        padding: 0 24px 0 16px;
      }
    }
  }
}
</style>
