<template lang="html">
  <div id="fab">
    <div id="fab-button-container">
      <router-link
        v-if="fab_state === 'menu'"
        to="/food_log"
        id="fab-button"
        :style="{ bottom: `${card_height}px` }"
      >
        <i class="fa fa-times close" />
      </router-link>
      <router-link
        v-else
        :to="{ path: '/food_log', query: { fab: 'menu' } }"
        id="fab-button"
        :style="{ bottom: `${card_height}px` }"
      >
        <img :src="expand_icon" class="open" alt="Open FAB" />
      </router-link>
    </div>

    <router-link to="/food_log" tag="div" class="backdrop" :class="{ visible: !!fab_state }" />

    <div id="cards-menu-container">
      <div class="cards-menu" :class="{ visible: fab_state === 'menu' }">
        <div
          v-for="{ name, id, label, icon, button_text } in cards"
          :key="id"
          @click="openFabCard(id)"
          class="option"
        >
          <div class="label">{{ button_text || name }}</div>
          <div class="button">
            <img :src="icon" class="option-icon" :alt="label" />
          </div>
        </div>
      </div>
    </div>

    <div id="fab-card-container" :style="{ height: `${card_height}px`}">
      <div ref="card" id="fab-card" :class="{ expanded: !!card }">
        <div v-if="card" class="card-header">
          <div class="card-icons">
            <div v-if="!card.no_header" class="icon">
              <img :src="card.icon" />
            </div>
            <div v-if="!card.no_header" class="label">{{ card.name }}</div>
          </div>
          <router-link to="/food_log" class="close-button">
            <i class="fa fa-times" />
          </router-link>
        </div>

        <div v-if="card" ref="cardContent" id="card-content" class="card-content" :style="{ height: `${card_height - 35 - 24 - 24}px`}">
          <component :is="card.component" :close="close" @heightChanged="onHeightChanged" ref="cardComponent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Activity from "@js/food_log/fab/activity.vue";
import SaveFood from "@js/food_log/fab/save_food.vue";
import CopyMeal from "@js/food_log/fab/copy_meal.vue";
import ExportFoodLog from "@js/food_log/fab/export_food_log.vue";
import LogWeight from "@js/food_log/fab/log_weight.vue";
import MacrosChart from "@js/food_log/macros_chart.vue";

import activity_icon from "@images/activity.svg";
import save_food_icon from "@images/log-food.svg";
import log_weight_icon from "@images/weight-loss.svg";
import text_menu_icon from "@images/text-menu.svg";
import copy_meal_icon from "@images/copy-meal.svg";
import expand_icon from '@images/more-vert.svg';

export default {
  props: {
    fab_state: { type: String }
  },
  data() {
    return {
      card_height: 0,
      initial_card_height: 0,
      cards: [
        {
          component: Activity,
          id: "activity",
          name: "Activity Plan",
          icon: activity_icon,
          button_text: "Activity"
        },
        {
          component: SaveFood,
          id: "save",
          name: "Save Food",
          icon: save_food_icon,
          no_header: true
        },
        {
          component: LogWeight,
          id: "weight",
          name: "Log Weight",
          icon: log_weight_icon,
        },
        {
          component: ExportFoodLog,
          id: "export",
          name: "Send Food Log",
          icon: text_menu_icon
        },
        {
          component: CopyMeal,
          id: "copy",
          name: "Copy Food",
          icon: copy_meal_icon
        }
      ],
      expand_icon
    };
  },
  watch: {
    card() {
      this.$nextTick(() => this.card_height = this.$refs.card.offsetHeight);
      this.$nextTick(() => this.initial_card_height = this.card_height);
    }
  },
  mounted() {
    if (this.card) {
      this.card_height = this.$refs.card.offsetHeight;
      this.initial_card_height = this.card_height;
    }
  },
  computed: {
    menu_visible() {
      return this.fab_state === "menu";
    },
    card() {
      return this.fab_state !== "menu" && this.cards.find(({ id }) => id === this.fab_state);
    },
    backdrop_visible() {
      return this.menu_visible || !!this.card;
    }
  },
  methods: {
    close() {
      this.$router.push("/food_log");
    },
    onHeightChanged(height) {
      this.$nextTick(() => this.card_height = height > this.initial_card_height ? height : this.initial_card_height);
    },
    async openFabCard(id) {
      if (id === "save") {
        try {
          this.$store.dispatch("loading", true);
          await this.$store.dispatch("rails/log_nutrient_intake");

          this.$router.push({ path: '/food_log', query: { fab: id } });
        } catch (e) {
          this.$store.dispatch("notify_user", { type: "error", msg: "Unable to save macro intake." });
          console.error(e);
        } finally {
          this.$store.dispatch("loading", false);
        }
      } else {
        this.$router.push({ path: '/food_log', query: { fab: id } });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@css/base/_variables.scss";
#fab-button-container, #cards-menu-container, #fab-card-container {
  width: 100%;
  max-width: 450px;
  position: relative;
  position: fixed;
  bottom: 70px;
  z-index: 6;
  transition: all 0.3s;
}
#cards-menu-container {
  z-index: 6;
  bottom: 64px;
}
#fab-card-container {
  z-index: 7;
  overflow-y: scroll;
  bottom: 64px;
  width: calc(100% - 16px);
  max-width: 434px;
  margin: 0 8px;
  height: 100%;
  max-height: calc(100% - 84px - 64px);
}
#fab-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  bottom: 0;
  width: 56px;
  height: 56px;
  background-color: $endurance;
  border-radius: 50%;
  font-size: 20px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.20), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  transition: bottom 0.3s;
  cursor: pointer;
  margin-bottom: 12px;
  z-index: 6;

  .close {
    color: #ffffff;
  }

  .open {
    width: 18px;
    height: 18px;
  }
}

.backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 5;
  transition: all 0.3s;
  display: none;
  opacity: 0;

  &.visible {
    opacity: 1;
    display: block;
  }
}

#fab-card {
  // card must be visible in DOM but positioned underneath viewport to properly animate opening up
  position: absolute;
  bottom: -100%;
  transition: bottom 0.3s;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.15);
  z-index: 5 !important;

  &.expanded {
    bottom: 0;
    // padding must be defined here so that when card is closed it doesn't displace FAB button
    padding: 24px 24px 0px 24px;
  }

  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .card-icons {
      display: flex;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background-color: #ececec;
      border-radius: 50%;
      margin-right: 16px;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .label {
      font-size: 13px;
      line-height: 25px;
      text-transform: uppercase;
      color: #000000;
      font-weight: 600;
      border-bottom: 3px solid $endurance;
      margin-right: 8px;
    }

    .close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      color: #4f4f4f;
      font-size: 15px;
      cursor: pointer;
    }
  }
}

.cards-menu {
  position: absolute;
  bottom: -100%;
  display: flex;
  flex-direction: column;
  height: 0;
  align-items: flex-end;
  right: 12px;
  z-index: 9;
  opacity: 1;
  transition: all .4s ease;
  overflow: hidden;

  &.visible {
    bottom: 78px;
    height: 280px;
    opacity: 1;
  }
  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    padding-right: 8px;

    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #4f4f4f;
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      padding: 8px 12px;
      background-color: #F0F0F0;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
      font-weight: 700;
      border-radius: 12px;
      margin-right: 8px;
      cursor: pointer;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: #F0F0F0;
      border-radius: 50%;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
      cursor: pointer;
      .option-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}

::v-deep .card-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 24px;
  transition: height 0.3s;
  max-height: calc(100% - 35px - 84px - 64px);

  p {
    font-size: 13px;
    font-weight: 700;
    color: #4f4f4f;
    text-align: left;
    margin: 0px 0px 16px 0px;

    &.error {
      font-size: 12px;
      color: #d65f5f;
    }
  }

  .profile-input-row {
    display: flex;
    flex-direction: column;
    padding: 0px;
    border-bottom: 2px solid $endurance;
    margin-bottom: 18px;
    width: 100%;
  }

  .profile-input-checkbox {
    display: flex;
    flex-direction: row;

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      border: 2px solid $endurance;
      margin: 0px 16px 0px 0px;
      cursor: pointer;
      &.selected {
        background-color: $endurance;
        i {
          display: block;
        }
      }
      i {
        display: none;
        color: #ffffff;
        font-size: 10px;
      }
    }
  }

  .checkbox-label {
    margin-bottom: 12px;
  }

  .input-label {
    color: $endurance;
    font-size: 12px;
    font-weight: 600;
    margin: 0px 0px 4px 0px;
  }

  .profile-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(107, 64, 171, 0.04);

    &.disabled {
      background-color: #ececec;
      input {
        color: #4f4f4f;
      }
    }

    i {
      color: $endurance;
      font-size: 13px;
      font-weight: 600;
      margin: 0px 6px 0px 6px;
    }

    input {
      width: 100%;
      color: #4f4f4f;
      font-size: 14px;
      font-weight: 700;
      margin: 0px;
      padding: 4px 0px 4px 8px;
      border: none;
      background-color: transparent;
    }

    input:focus {
      outline: none;
      border: none;
    }

    input:disabled {
      color: #4f4f4f;
      -webkit-text-fill-color: #4f4f4f;
      opacity: 1; /* required on iOS */
    }

    input::-webkit-input-placeholder {
      font-weight: 700;
    }

    .clear-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 45px;
      height: 40px;

      i {
        color: $endurance;
        font-size: 14px;
      }
    }
  }

  .button-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.save {
      margin-top: 12px;
    }
    &.centered {
      justify-content: center;
    }

    .save-button {
      border-color: transparent;
      font-family: Montserrat, 'open sans';
      font-size: 12px;
      font-weight: 700;
      color: #ffffff;
      padding: 8px 16px;
      background-color: $endurance;
      border-radius: 20px;
      cursor: pointer;

      &.clear {
        background-color: transparent;
        color: $endurance;
      }

      &:focus {
        outline: 0px;
      }
    }

    .radio {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;
      .indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $endurance;
        background-color: #ffffff;
        margin-right: 8px;
        div {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ffffff;
        }
      }
      &.disabled {
        .indicator {
          border: 1px solid #e2e2e2;
          background-color: #e2e2e2;
          div {
            display: none;
          }
        }
      }
      &.active {
        .indicator {
          div {
            background-color: $endurance;
          }
        }
      }
      span {
        font-size: 11px;
        font-weight: 700;
        color: #000000;
      }
    }
  }
}
</style>
